<template>
  <div class="cpt-box_popup">
    <div class="popup-inner-wrap">
      <div class="popup_inner">
        <el-input v-model="input" placeholder="请输入内容" class="input"></el-input>
        <el-button type="text" class="btn" @click="handle_save">保存</el-button>
        <el-button type="text" class="btn" @click="close">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import API from "@/api";

export default {
  data() {
    return {
      input: '',
    }
  },
  computed: {
    ...mapGetters(["current_tag_id", "current_tag_data"])
  },
  watch: {
    current_tag_id: {
      handler(val){
        this.init()
      },
      deep:true
    },
  },
  methods: {
    ...mapMutations({
      set_tag_modal_visible(commit, bool) {
        commit('mapmanage/SET_TAG_MODAL_VISIBLE', bool)
      },
      set_current_tag_id(commit, id) {
        commit('mapmanage/SET_CURRENT_TAG_ID', id)
      },
    }),
    close() {
      this.set_tag_modal_visible(false)
    },
    init() {
      this.input = this.current_tag_data.name
    },
    handle_save() {
      let options = {
        name: this.input
      }
      this.save_tag(options)
    },
    async save_tag(options) {
      let key 
      if(this.current_tag_id === 'add') {
        key = "AddTag"
      } else {
        key = "EditTag"
        options.id = this.current_tag_id
      }
      let res = await API.MAPMANAGE[key](options);
      if(res) {
        this.$message({
          message: '保存成功',
          type: 'success'
        });
        this.close()
        this.set_current_tag_id('update')
      } else {
        // this.$message.error('保存失败');
        this.$message.error('标签名称重复');
      }
    }
  },
  created() {
    this.init()
  }
};
</script>

<style lang="scss" scoped>
.cpt-box_popup {
  position: absolute;
  top: 280px;
  left: 500px;
  z-index: 3;
  padding: 2rem;
  width: 330px;
  box-sizing: border-box;
  background: #161d2d;
  opacity: 0.9;
  background: #161d2d;
  border-radius: 8px;
  .popup-inner-wrap {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    .popup_inner {
      display: flex;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      ::v-deep {
        .el-input__inner {
          color: #fff;
          background: #000000;
          border: 1px solid #08C2D1;
        }
      }
      .input {
        margin-right: 10px;
      }
      .btn {
        color: #00ffff;
      }
    }
  }
}
</style>