<template>
  <div class="cpt-command-device-drone-and-nest">
    <div class="search-box">
      <!-- <div class="search-box-left">
        <span @click="active = 1" :class="active == 1 ? 'active' : ''"
          >无人机管理</span
        >
        <span @click="active = 2" :class="active == 2 ? 'active' : ''"
          >鹰巢管理</span
        >
      </div> -->
      <div class="flex height">
               <div
          class="waikuang"
          @click="uav_guanl"
          :class="{ 'active': active == 1 }"
        >

        <img v-if="active == 1"
            src="~@/assets/images/equipmentImges/无人机管理.png"
            class="imgs-active"
            alt=""
          />
          <img v-else
            src="~@/assets/images/equipmentImges-isActive/无人机管理.svg"
            class="imgs"
            alt=""
          />
          
          <span>无人机管理</span>
        </div>
             <div
          class="waikuang"
          @click="yingchao"
          :class="{ 'active': active == 2 }"
        >
          <img  v-if="active == 2"
            src="~@/assets/images/equipmentImges/鹰巢管理.png"
            class="imgs-active"
            alt=""
          />
          <img  v-else
            src="~@/assets/images/equipmentImges-isActive/鹰巢管理.svg"
            class="imgs"
            alt=""
          />
          <span>鹰巢管理</span>
        </div>
            <div class="waikuang" @click="jiBtn(3)">
          <img
            src="~@/assets/images/equipmentImges-isActive/基站管理.svg"
            class="imgs"
            alt=""
          />
          <span>基站管理</span>
        </div>
           <div class="waikuang" @click="guaBtn(2)">
          <img
            src="~@/assets/images/equipmentImges-isActive/挂载管理.svg"
            class="imgs"
            alt=""
          />
          <span>挂载管理 </span>
        </div>
        <div class="waikuang" @click="dianBtn(1)">
          <img
            src="~@/assets/images/equipmentImges-isActive/电池管理.svg"
            class="imgs"
            alt=""
          />
          <span>电池管理</span>
        </div>
     
    
 
   
        <div class="waikuang" @click="addDevice">
          <span>注册设备  </span>
        </div>
      </div>
      <div class="search-box-right">
        <el-cascader
          v-model="search.organId"
          :options="options"
          :show-all-levels="false"
          clearable
          placeholder="请选择单位"
          v-if="active == 1"
          :props="{
            children: 'children',
            label: 'orgName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
          }"
        ></el-cascader>
        <el-cascader
          v-model="search.organId"
          :options="options1"
          :show-all-levels="false"
          clearable
          placeholder="请选择单位"
          v-if="active == 2"
          :props="{
            children: 'children',
            label: 'anotherName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
          }"
        ></el-cascader>
        <!-- <el-dropdown> -->
          <!-- <div class="iconfont icon-baobei"></div> -->
          <el-select v-model="btnName"
          placeholder="请选择报备类型" @change="handle_click">
              <el-option
              v-for="item in dropdownList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
          </el-option>
          </el-select>
          <!-- <div class="baobei flex justify-between item-center">
            <span>{{ btnName == "" ? "请选择报备类型" : btnName }}</span>
            <span class="el-icon-arrow-down el-icon--right"></span>
          </div>
          <el-dropdown-menu slot="dropdown" class="el-dropdown-menu-special">
            <el-dropdown-item v-for="item in dropdownList" :key="item.id">
              <span @click="handle_click(item.id, item.name)">
                {{ item.name }}
              </span>
            </el-dropdown-item>
          </el-dropdown-menu> -->
        <!-- </el-dropdown> -->
        <el-date-picker
          v-model="pickTime"
          @change="handle_change"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          popper-class="device-dronenest"
        >
        </el-date-picker>
      </div>
    </div>
    <Drone
      ref="uav"
      :search="search"
      v-show="active == 1"
      @getDetail="getDetail"
      @getwb="getwb"
    />
    <Nest
      ref="nest"
      :search="search"
      v-show="active == 2"
      @getDetail="getDetail"
      @getwb="getwb"
    />
    <Device
      v-if="deviceVisible"
      @closedevice="shuaxin"
      :active="choice"
      :form="form"
    />
    <WbList v-if="wbShow" :list="wbData" @close="wbShow = false" @detail="handleDetail"/>
    <WbDroneDetail v-if="droneShow" :patrolId="patrolId" @closeD="closeD"/>
    <WbNestDetail v-if="nestShow" :patrolId="patrolId" @closeN="closeN"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import API from "@/api";
import Drone from "./drone";
import Nest from "./nest";
import Device from "@/components/header-right/deviceadd.vue";
import WbList from "../main/list"
import WbDroneDetail from "../main/detailDrone"
import WbNestDetail from "../main/detailNest"

export default {
  components: {
    Drone,
    Nest,
    Device,
    WbList,
    WbDroneDetail,
    WbNestDetail
  },
  data() {
    return {
      roleId: JSON.parse(localStorage.getItem("user_info")).data.roles[0]
        .roleId,
      deviceVisible: false,
      dropdownList: [
        { name: "已报备", id: 1 },
        { name: "未报备", id: 0 },
        { name: "显示全部", id: 2 },
      ],
      active: 1,
      options: [],
      options1: [],
      pickTime: null,
      search: {
        pageSize: 10000,
        organId: null,
        startDate: null,
        endDate: null,
        isReporting: null,
      },
      btnName: "",
      choice: 4,
      form: null,
      wbData: null,
      wbShow: false,
      droneShow: false,
      nestShow: false,
      patrolId: null
    };
  },
  watch: {
    active() {
      this.search.organId = null;
      this.search.startDate = null;
      this.search.endDate = null;
      this.search.isReporting = null;
    },
  },
  async mounted() {
    this.search.organId = this.user_info.departmentId;
    await this.getOptions();
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  methods: {
    handleDetail(id){
      this.patrolId = id
      this.wbShow = false
      if(this.wbData.type_id == 3){
        this.nestShow = true
      }else{
        this.droneShow = true
      }
},
closeD(){
  this.wbShow = true
  this.droneShow = false
},
closeN(){
  this.wbShow = true
  this.nestShow = false
},
    uav_guanl() {
      this.active = 1;
      this.choice = 4;
    },
    yingchao() {
      this.choice = 5;
      this.active = 2;
    },
    dianBtn(num) {
      this.choice = 1;
      this.$parent.guanli(num);
    },
    guaBtn(num) {
      this.choice = 2;
      this.$parent.guanli(num);
    },
    jiBtn(num) {
      this.choice = 3;
      this.$parent.guanli(num);
    },
    addDevice() {
      const permissions = this.$store.state.user.user_info.permissions;
      const permissionList = permissions.map((item) => item.permissionSign);
      if (this.active == 1) {
        if (permissionList.indexOf("equipment:flight:battery:add") == -1) {
          this.$message.error("暂无权限操作");
        } else {
          this.deviceVisible = true;
        }
      }
      if (this.active == 2) {
        if (permissionList.indexOf("equipment:flight:mount:add") == -1) {
          this.$message.error("暂无权限操作");
        } else {
          this.deviceVisible = true;
        }
      }
      if (this.active == 3) {
        if (permissionList.indexOf("equipment:flight:baseStation:add") == -1) {
          this.$message.error("暂无权限操作");
        } else {
          this.deviceVisible = true;
        }
      }
      if (this.active == 4) {
        if (permissionList.indexOf("equipment:flight:uav:add") == -1) {
          this.$message.error("暂无权限操作");
        } else {
          this.deviceVisible = true;
        }
      }
      if (this.active == 5) {
        if (permissionList.indexOf("equipment:flight:nest:add") == -1) {
          this.$message.error("暂无权限操作");
        } else {
          this.deviceVisible = true;
        }
      }
    },
    handle_click(e) {
      // this.btnName = name;
      if (e == 2) {
        this.search.isReporting = null;
      } else {
        this.search.isReporting = e;
      }
    },
    getwb(data){
      this.wbShow = true
      this.wbData = data
    },
    getDetail(val) {
      this.form = val;
      this.deviceVisible = true;
    },
    shuaxin() {
      this.deviceVisible = false;
      this.form = null;
      if (this.active == 1) {
        this.$refs.uav.getDroneList();
      } else if (this.active == 2) {
        this.$refs.nest.getNestList();
      }
    },
    async handle_change(e) {
      if (e?.length) {
        this.search.startDate = e[0];
        this.search.endDate = e[1];
      } else {
        this.search.startDate = null;
        this.search.endDate = null;
      }
    },
    async getOptions() {
      let data = await API.DEPARTMENT.Drd();
      this.options = [data] || [];
      this.options1 = [data] || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dropdown{
  margin-left: 15px;
}
.cpt-command-device-drone-and-nest ::v-deep {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .tb-box .tb-bd-box {
    height: calc(100vh - 576px);
    overflow: auto;
  }

  .search-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    // padding-left: 80px;
    // margin-bottom: 20px;
    .search-box-left {
      span {
        display: inline-block;
        width: 153px;
        height: 40px;
        background: #000000;
        border: 1px solid #00aeff;
        font-family: PangMenZhengDao;
        font-size: 24px;
        color: #dce9ff;
        font-weight: 400;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        box-sizing: border-box;
        &:hover {
          background: rgba(0, 174, 255, 0.7);
        }
      }
      .active {
        background: rgba(0, 174, 255, 0.7);
        border: 1px solid #00aeff;
      }
    }
    .search-box-right {
      display: flex;
      justify-content: space-between;
      // width: 280px;
      // padding-right: 20px;
    }
    .iconfont {
      color: #00aeff;
      font-size: 24px;
      line-height: 40px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    
  }

  .tb-box .tb-bd-box .tb-tr .iconfont {
    color: #fff !important;
    font-size: 20px;

    &:hover {
      color: RGBA(0, 231, 255, 1) !important;
    }
  }
}
.flex {
  display: flex;
}
.waikuang {
   height: 32px;
  border: 1px rgba(49, 94, 199, 1) solid;
  border-radius: 3px;
  background-color: rgba(2, 23, 61, 1);
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #D2D9FF;
  cursor: pointer;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;

  &.active{
    background-image: url('~@/assets/images/monitoring/btn_active.png');
    background-size: 100% 100%;
  }

  &:hover {
    background-image: url('~@/assets/images/monitoring/btn_active.png');
    background-size: 100% 100%;
  }
}
.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}
.btn-start {
  background: rgba(20, 45, 92, 0.6);
  box-shadow: 1px 1px 2px 0px rgba(3, 16, 50, 50%),
    inset 0px 0px 20px 0px #00b6ff;
  border-radius: 6px;
  border: 1px solid #43deff;
  font-family: MicrosoftYaHei;
  color: #43deff;
  opacity: 1;
}
.xian {
  width: 100%;
  height: 1px;
  background: linear-gradient(
    207deg,
    rgba(36, 146, 252, 0) 0%,
    #2492fc 53%,
    rgba(36, 146, 252, 0) 100%
  );
  margin: 16px 0 0 0;
}
.distance-between {
  padding: 16px 24px 0 24px;
}

/deep/ .search-box-right .el-date-editor {
  width: 354px !important;
  height: 32px;
  cursor: pointer;
  background: #02173D;
  border-radius: 2px;
  border: 1px solid #315EC7;
  cursor: pointer;
  .el-range-input {
    width: 43%;
    background: rgba(0, 0, 0, 0);

    &::placeholder{
      color: RGBA(121, 132, 152, 1) !important;
    }
  }
  ::-webkit-input-placeholder {
    font-family: MicrosoftYaHeiUI !important;
    color: #00aeff !important;
  }
  .el-range-separator {
    font-family: MicrosoftYaHeiUI !important;
    color: RGBA(121, 132, 152, 1) !important;
    line-height: 24px;
  }
  .el-range__icon {
    line-height: 24px;
    color: RGBA(121, 132, 152, 1) !important;
  }
}

/deep/ .search-box-right .el-input--suffix {
  width: 170px;
  height: 32px;
  border-radius: 2px;

  .el-input__inner {
    background-color: #02173D;
    border: 1px solid #315EC7;
    color: RGBA(121, 132, 152, 1);

    &::placeholder {
      color: RGBA(121, 132, 152, 1) !important;
    }
  }
  .el-input__icon {
    color: #00aeff !important;
  }
  ::-webkit-input-placeholder {
    font-family: MicrosoftYaHeiUI !important;
    color: #00aeff !important;
  }
}
.el-cascader ::v-deep {
  width: auto;
  height: 32px;
  transform: translate(0, -4px);

  input {
    height: 32px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: RGBA(121, 132, 152, 1);
  }
}
.el-select ::v-deep{
  margin: 0 8px;
  width: auto;
  height: 32px;
  input {
    height: 32px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: RGBA(121, 132, 152, 1);
  }
}
.baobei {
  width: 170px;
  height: 32px;
  background: #02173D;
  border-radius: 2px;
  border: 1px solid #315EC7;
  box-sizing: border-box;
  padding-left: 12px;
  color: RGBA(121, 132, 152, 1);
  margin-right: 14px;
  :last-child {
    margin: 0 13px 0 0;
    line-height: 40px !important;
  }
}
.item-center {
  align-items: center;
}
/deep/ .el-input__inner {
  color: #00aeff !important;
  border-radius: 0;
}
/deep/ .el-range-input {
  color: #00aeff !important;
}
</style>
<style lang="scss">
.device-dronenest {
  border: none;
  .el-picker-panel__body-wrapper {
    background: #0d224f;
    border: 1px solid #00aeff;
    color: #fff;
    .el-picker-panel__content {
      border: 1px solid #00aeff;
    }
    .el-date-table th {
      border-bottom: solid 1px #00aeff;
      color: #fff;
    }
    .el-date-table td.in-range div {
      background-color: #00aeff;
    }
    .el-picker-panel__icon-btn {
      color: #fff;
    }
  }
}
</style>

<style lang="scss">
.el-dropdown-menu-special {
  margin-right: 40px;
  .el-dropdown-menu__item {
    background-color: #0d224f;
    padding: 0;
    & > span {
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 0 20px;
    }
  }
}
.imgs-active{
  width: 24px;
  height: 24px;
}
.imgs {
  width: 18px;
  height: 18px;
  margin-right: 3px;
  margin-left: 3px;
}
.el-dropdown-menu-special{
  background: #0d224f;
  border: 1px solid #178890;
}
</style>
