import API from '@/api'
import { Utils } from '@/lib/cesium'
let location_icon = null
let history_entity = null
let history_Line = null
let history_Name = null
let point_entity_began = null
let point_entity_over = null
let began = null
let over = null
let air_line_preview = []
let airline_entitys = []

export default {
  async handle_position(data) {
    console.log(data,'darta');
    if (data && data.incidentAddress) {
      if (data.incidentAddress == '盐城市') {
        return false
      }
      let res = await API.MAP.Geo({ keywords: data.incidentAddress })
      let rows = res.data.data.rows
      if (rows && rows.length) {
        let cesium_layer = this.g_cesium_layer()
        let viewer = cesium_layer.viewer()

        cesium_layer.fly_to_globe()

        let item = rows[0]
        let location = item.location.split(',')

        let potiion = Utils.transformWGS842Cartesian({
          lng: Number(location[0]),
          lat: Number(location[1]),
          alt: 1000,
        })
        viewer.camera.flyTo({
          destination: potiion,
        })
        this.flightTask_init_location_icon(potiion)
      }
      this.$emit('close')
    }
  },
  removeEntities(){
    console.log(window.location_icon,'window.location_icon');
    if(window.location_icon){

   window.viewer.entities.remove(window.location_icon)
   window.location_icon=null
   location_icon=null
    }
  },
    async handle_position_v2(data) {
    if (data && data.incidentAddress) {
      if (data.incidentAddress == '盐城市') {
        return false
      }
        let potiion = Utils.transformWGS842Cartesian({
          lng: Number(data.gisx),
          lat: Number(data.gisy),
          alt: 3000,
        })
        viewer.camera.flyTo({
          destination: potiion,
        })
        this.flightTask_init_location_icon(potiion)
    }
  },
  init_location_icon(position) {
    // let cesium_layer = this.g_cesium_layer()
    // let viewer = cesium_layer.viewer()
    let viewer = window.viewer
    viewer.entities.remove(location_icon)
    console.log(location_icon,"location_icon");
    // if (location_icon) {
    //   location_icon.position = position
    // } else {
      location_icon = viewer.entities.add({
        position,
        billboard: {
          width: 54,
          height: 54,
          image: require('@/assets/images/factor/jwys/1.png'),
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.Bottom,
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        //  disableDepthTestDistance: Number.POSITIVE_INFINITY,
        },
      })
    // }
    window.location_icon=location_icon
  },

  // 常态、临时任务定位
  async handleLocation(item, state) {
    console.log(state,"state");
    let viewer = window.viewer
    viewer.entities.remove(location_icon)
    let flightCourseJson = JSON.parse(item.flightCourseJson)
    if (flightCourseJson?.points?.length) {
      let position = Utils.transformWGS842Cartesian({
        lng: Number(flightCourseJson.points[0].lon),
        lat: Number(flightCourseJson.points[0].lat),
        alt: 1000,
      })
      location_icon = viewer.entities.add({
        position,
        billboard: {
          width: 54,
          height: 89,
          image: require(`@/assets/images/factor/jwys/${state}.png`),
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.Bottom,
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        },
      })
      this.$emit("close")
      viewer.camera.flyTo({
        destination: position,
      })
    } else {
      this.$el_message('暂无位置', () => {},'info')
    }
  },

  // 航线预览
  async handleAirLinePreview(item, state) {
    try {
      let viewer = window.viewer
      if (airline_entitys?.length) {
        airline_entitys.forEach((item) => {
          viewer.entities.remove(item)
        })
      }
      let res = {}
      air_line_preview.forEach((item) => {
        viewer.entities.remove(item)
      })
      if (state) {
        res.flightCourseJson = item.flightCourseJson
      } else {
        res = await API.TASK.JingQingTrack({
          taskId: item.taskId,
        })
      }
      // 有航点高度和距离的代码
      if (res?.flightCourseJson) {
        let positions = []
        let center = []
        let polyline = JSON.parse(res.flightCourseJson)
        console.log(polyline,"polyline");
        let label_arr = []
        let _this = this

        polyline.points.forEach((item, index) => {
          positions.push(item.lon, item.lat, item.alt)
          label_arr.push(
            Cesium.Cartesian3.fromDegrees(item.lon, item.lat, item.alt)
          )
          let point_entity = viewer.entities.add({
            position: Cesium.Cartesian3.fromDegrees(
              item.lon,
              item.lat,
              item.alt
            ),
            name: 'show_airline_point',
            point: {
              pixelSize: 20,
              color: Cesium.Color.RED,
              outlineWidth: 2,
              outlineColor: Cesium.Color.WHITE,
            },
            // label: {
            //   text: String(item.alt) + 'm',
            //   scale: 0.6,
            //   font: 'bold 28px Microsoft YaHei',
            //   // fillColor: Cesium.Color.BLUE,
            //   fillColor: Cesium.Color.fromCssColorString('#FF7F09'),
            //   horizontalOrigin: Cesium.VerticalOrigin.CENTER,
            //   verticalOrigin: Cesium.VerticalOrigin.CENTER,
            //   disableDepthTestDistance: Number.POSITIVE_INFINITY,
            //   showBackground: false,
            //   outlineWidth: 0,
            //   pixelOffset: new Cesium.Cartesian2(25, -10),
            // },
            label: {
              text: new Cesium.CallbackProperty(() => {
                         return String(index + 1);
                       }, false),
                       font: "bold 18px Microsoft YaHei",
                       fillColor: Cesium.Color.fromCssColorString("white"),
                       horizontalOrigin: Cesium.VerticalOrigin.CENTER,
                        verticalOrigin: Cesium.VerticalOrigin.CENTER,
                       disableDepthTestDistance: Number.POSITIVE_INFINITY,
                       showBackground: false,
                       outlineWidth: 0,
                       pixelOffset: new Cesium.Cartesian2(0, 0),
           },
          })
          airline_entitys.push(point_entity)
          if (label_arr.length > 1) {
            let before = label_arr[label_arr.length - 2]
            let after = label_arr[label_arr.length - 1]

            _this.flightTask_create_label(before, after)
          }
        })

        positions = Cesium.Cartesian3.fromDegreesArrayHeights(positions)

        let blueLine = viewer.entities.add({
          name: 'Red line on terrain',
          polyline: {
            positions: new Cesium.CallbackProperty(() => {
              return positions
            }, false),
            width: 4,
            // material: Cesium.Color.RED,
            material: Cesium.Color.fromCssColorString('#00A9A9'),
            // clampToGround: true,
          },
        })
        viewer.flyTo(blueLine)
        airline_entitys.push(blueLine)
        if(!window.$flightTask){
          window.$flightTask = {};
        }
        window.$flightTask.blueLine = blueLine;
        window.$flightTask.airline_entitys = airline_entitys;
      } else {
        this.$el_message('暂无航线', () => {},'info')
      }

      // 无航点高度和距离的代码
      // if (res?.flightCourseJson) {
      //   let polyline = JSON.parse(res.flightCourseJson)
      //   let positions = []
      //   polyline.points.forEach((item, index) => {
      //     positions.push(item.lon, item.lat, item.alt)
      //     let air_line_point = viewer.entities.add({
      //       position: Cesium.Cartesian3.fromDegrees(item.lon, item.lat),
      //       point: {
      //         pixelSize: 20,
      //         color: Cesium.Color.RED,
      //         fillColor: Cesium.Color.RED,
      //         heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
      //       },
      //       label: {
      //         text: String(index + 1),
      //         scale: 0.5,
      //         font: 'bold 24px Microsoft YaHei',
      //         fillColor: Cesium.Color.WHITE,
      //         horizontalOrigin: Cesium.VerticalOrigin.CENTER,
      //         verticalOrigin: Cesium.VerticalOrigin.CENTER,
      //         disableDepthTestDistance: Number.POSITIVE_INFINITY,
      //         showBackground: false,
      //         outlineWidth: 0,
      //       },
      //     })
      //     air_line_preview.push(air_line_point)
      //   })
      //   let redLine = viewer.entities.add({
      //     name: 'Red line on terrain',
      //     polyline: {
      //       positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
      //       width: 5,
      //       material: Cesium.Color.RED,
      //       clampToGround: true,
      //     },
      //   })
      //   air_line_preview.push(redLine)
      //   viewer.flyTo(redLine)
      // }
    } catch (error) {
      this.$el_message('暂无航线', () => {},'info')
    }
  },

  // 显示距离
  create_label(before, after) {
    let viewer = window.viewer
    let before_wgs84 = Utils.transformCartesian2WGS84(before)
    let after_wgs84 = Utils.transformCartesian2WGS84(after)

    let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2
    let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2
    let alt = (after_wgs84.alt + before_wgs84.alt) / 2

    let position = Utils.transformWGS842Cartesian({
      lng: center_lng,
      lat: cetner_lat,
      alt: alt + 10,
    })

    let text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`

    let label_entity = viewer.entities.add({
      id: `label_${before}`,
      position: position,
      label: {
        text: text,
        scale: 0.5,
        font: 'bold 30px Microsoft YaHei',
        fillColor: Cesium.Color.fromCssColorString('#fff'),
        horizontalOrigin: Cesium.VerticalOrigin.CENTER,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        showBackground: true,
        backgroundPadding: new Cesium.Cartesian2(10, 10),
      },
    })
    airline_entitys.push(label_entity)
  },

  // 历史视频
  // async handleVideoPlayer(item, state) {
  //   // try {
  //     let data = {
  //       taskId: item.id,
  //       pageNo: 1,
  //       pageSize: 10000,
  //     };
  //     let list = []
  //     let res = await API.TASK.photoAndvideo(data);
  //     res.data.forEach(val => {
  //       if (val.type == 2) {
  //         list.push(val)
  //       }
  //     })
  //   if (list && list.length) {
  //     this.flightTask_video = list[0]
  //     // this.flightTask_video.download_url = res1.object[0].replay[0].urlList[0]
  //     this.mediaIsShow = true
  //   } else {
  //     this.$el_message('无视频回放', () => {},'info')
  //   }
  //   // } catch (error) {
  //   //   console.log(error,'error');
  //   //   this.$el_message('无视频回放', () => {},'info')
  //   // }
  // },
  async handleVideoPlayer(item, state) {
    try {
      let res
      if (item.taskCateId == 1 || state) {
        res = await API.SITUATION.HistoryVideo({
          taskId: state == 1 ? item.taskId : item.id,
        })
      } else {
        res = await API.TASK.airtaskVideo(item.id)
      }

      let res1
      if (item.taskCateId == 1 || state) {
        res1 = await API.SITUATION.HistoryVideo({
          taskId: state == 1 ? item.taskId : item.id,
          type: 0
        })
      } else {
        res1 = await API.TASK.airtaskVideo(item.id,{type: 0})
      }
console.log(res,'res.object');
      this.flightTask_video = res.object[0].replay[0]
      this.flightTask_video.download_url = res1.object[0].replay[0].urlList[0]
      this.mediaIsShow = true
    } catch (error) {
      console.log(error,'error');
      this.$el_message('无视频回放', () => {},'info')
    }
  },

  // 历史图片
  async handleImage(item, state) {
    this.flightTask_imageList = []
    // try {
      let data = {
        taskId: item.id,
        pageNo: 1,
        pageSize: 10000,
      };
      let res = await API.TASK.photoAndvideo(data);
      // console.log("图片",res)
      res.data.forEach(val => {
        if (val.type == 1) {
          this.flightTask_imageList.push(val)
        }
      })
      if (this.flightTask_imageList && this.flightTask_imageList.length) {
        this.visible = 5
      } else {
        // console.log('jinlaimei----')
        this.$el_message('无历史图片', () => {},'info')
      }
    // } catch (error) {
    //   // this.$el_message('无历史图片', () => {},'info')
    // }
  },
  // async handleImage(item, state) {
  //   try {
  //     let res
  //     if (item.taskCateId == 1 || state) {
  //       res = await API.SITUATION.HistoryPhoto(
  //         state == 1 ? item.taskId : item.id
  //       )
  //     } else {
  //       res = await API.TASK.airtaskPhoto(item.id)
  //     }
  //     if (res && res.length) {
  //       this.flightTask_imageList = res
  //       this.visible = 5
  //     } else {
  //       this.$el_message('无历史图片', () => {},'info')
  //     }
  //   } catch (error) {
  //     this.$el_message('无历史图片', () => {},'info')
  //   }
  // },
  // 历史轨迹
  async handleHistotyTrack(item, state) {
    let viewer = window.viewer
    if (history_entity) {
      viewer.entities.remove(history_entity)
    }
    if (history_Line) {
      viewer.entities.remove(history_Line)
    }
    if(history_Name){
      viewer.entities.remove(history_Name)
    }
    if(point_entity_began){
      viewer.entities.remove(point_entity_began)
    }
    if(point_entity_over){
      viewer.entities.remove(point_entity_over)
    }
    try {
      let res
      if (item.taskCateId == 1 || state) {
        res = await API.SITUATION.HistoryTrack({
          taskId: state == 1 ? item.taskId : item.id,
        })
      } else {
        res = await API.TASK.airtaskTrack(item.id)
      }
      if (res[Object.keys(res)[0]]) {
        let polyline = res[Object.keys(res)[0]]
        let positions = []
        began = polyline[0]
        over = polyline[polyline.length - 1]
        polyline.forEach((item, index) => {
          positions.push(Number(item.longitude), Number(item.latitude), 100)
          history_entity = viewer.entities.add({
            position: Cesium.Cartesian3.fromDegrees(
              Number(item.longitude),
              Number(item.latitude)
            ),
          })
        })

        

        history_Line = viewer.entities.add({
          name: 'Red line on terrain',
          polyline: {
            positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
            width: 5,
            material: Cesium.Color.RED,
            // clampToGround: true,
          },
        })
        viewer.flyTo(history_Line)
        console.log(item);
        this.flightTask_create_labelName(item)
        this.flightTask_point_show()
        // this.visible=0
        //当前项目架构要实现清空航线，只能挂到全局对象上了
        if(!window.$flightTask){
          window.$flightTask = {};
        }
        window.$flightTask.history_entity = history_entity;
        window.$flightTask.history_Line = history_Line;
        window.$flightTask.history_Name = history_Name
        window.$flightTask.point_entity_began = point_entity_began
        window.$flightTask.point_entity_over = point_entity_over
        this.$emit('close')
      }
    } catch (error) {
      this.$el_message('无历史轨迹', () => {},'info')
    }
  },
  point_show(){
    let viewer = window.viewer
    console.log(began,over);
    point_entity_began = viewer.entities.add({
      name: "airline_point_began",
      // id: "began",
      position: Cesium.Cartesian3.fromDegrees(
         Number(began.longitude),
         Number(began.latitude),
        100
      ),
      point: {
        pixelSize: 20,
        color: Cesium.Color.red,
        color: Cesium.Color.fromCssColorString("red"),
      },
      label: {
        text: new Cesium.CallbackProperty(() => {
          return "起";
        }, false),
        font: "bold 14px Microsoft YaHei",
        // fillColor: Cesium.Color.BLUE,
        fillColor: Cesium.Color.fromCssColorString("white"),
        horizontalOrigin: Cesium.VerticalOrigin.CENTER,
        verticalOrigin: Cesium.VerticalOrigin.CENTER,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        showBackground: false,
        outlineWidth: 0,
        pixelOffset: new Cesium.Cartesian2(0, 0),
      },
    });
    point_entity_over = viewer.entities.add({
      name: "airline_point_over",
      // id: "over",
      position: Cesium.Cartesian3.fromDegrees(
         Number(over.longitude),
         Number(over.latitude),
         100
        // 0
      ),
      point: {
        pixelSize: 20,
        color: Cesium.Color.red,
        color: Cesium.Color.fromCssColorString("red"),
        // fillColor: Cesium.Color.red,
        // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND, // supermap版本会导致拖动显示错误
      },
      label: {
        text: new Cesium.CallbackProperty(() => {
          return "终";
        }, false),
        // text:index + 1,
        font: "bold 14px Microsoft YaHei",
        // fillColor: Cesium.Color.BLUE,
        fillColor: Cesium.Color.fromCssColorString("white"),
        horizontalOrigin: Cesium.VerticalOrigin.CENTER,
        verticalOrigin: Cesium.VerticalOrigin.CENTER,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        showBackground: false,
        outlineWidth: 0,
        pixelOffset: new Cesium.Cartesian2(0, 0),
      },
    });
  },
    // 显示航线名称
    create_labelName(item) {
      let viewer = window.viewer
      // let before_wgs84 = Utils.transformCartesian2WGS84(before)
      // let after_wgs84 = Utils.transformCartesian2WGS84(after)
      let flightCourseJson = item.flightCourseJson ? JSON.parse(item.flightCourseJson) : JSON.parse(item.lastData)
      console.log(flightCourseJson);
      // let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2
      // let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2
      // let alt = (after_wgs84.alt + before_wgs84.alt) / 2
  
      let position = Utils.transformWGS842Cartesian({
        lng: Number(flightCourseJson.points ? flightCourseJson.points[0].lon : flightCourseJson.uavInfo.longitude),
        lat: Number(flightCourseJson.points ? flightCourseJson.points[0].lat : flightCourseJson.uavInfo.latitude),
        alt: 0,
      })
  
      let text = item.airlineTitle
       history_Name = viewer.entities.add({
        id: `label_${item}`,
        position: position,
        label: {
          text: text,
          scale: 0.5,
          font: 'bold 30px Microsoft YaHei',
          fillColor: Cesium.Color.fromCssColorString('#fff'),
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: true,
          backgroundPadding: new Cesium.Cartesian2(10, 10),
        },
      })
      // airline_entitys.push(label_entity)
    },
}
