<template>
  <div class="cpt-command-airway-edit">
    <div class="header" v-interact>
      <div class="titles">
        <div
          class="hd-box title"
          :class="visibleAutoRoute == 1 ? 'active':''"
          style="cursor: pointer"
          @click="visibleAutoRoute = 1"
        >
          {{ "手动规划" }}
        </div>
        <!-- <div
          class="hd-box title"
          :class="visibleAutoRoute == 2 ? 'active':''"
          style="cursor: pointer"
          @click="visibleAutoRoute = 2"
        >
          {{ "自动规划" }}
        </div> -->
        <div
          class="hd-box title"
          :class="visibleAutoRoute == 3 ? 'active':''"
          style="cursor: pointer"
          @click="visibleAutoRoute = 3"
        >
          {{ "区域规划" }}
        </div>
      </div>
      <div class="hd-box cp mr20" @click="exit">关闭</div>
    </div>
    <autoPlan
      v-if="visibleAutoRoute == 2"
      :flag="flag"
      @add="testEmit($event, 'autoPlan')"
    ></autoPlan>
    <manual
      @change_airway="change_airway"
      @exitNest_list="
        flag = false;
        showNest_list(flag);
      "
      v-else-if="visibleAutoRoute == 1"
      :flag="flag"
      @add="testEmit($event, 'manual')"
    >
    </manual>
    <space v-if="visibleAutoRoute == 3" :flag="flag" @add="testEmit($event, 'autoPlan')"></space>
  </div>
</template>

<script>
import API from "@/api";
import autoPlan from "./autoPlan";
import manual from "./manual";
import space from "./space";
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  components: {
    autoPlan,
    manual,
    space
  },
  inject: [
    "g_cesium_layer",
    "change_page",
    "showNest_list",
    "change_flightTask_view",
  ],
  data() {
    return {
      visibleAutoRoute:1,
      flag: false,
      airSpaceDataSrouce: null, //空域数据源
    };
  },
  watch:{
    visibleAutoRoute:{
      handler(val){
        if(val == 3){
          this.flag = false
          this.showNest_list(this.flag)
        }
      }
    }
  },
  computed: {
    ...mapGetters(["is_from_situation_edit"]),
  },
  mounted() {
    this.initAirSpace();
  },
  destroyed() {
    this.clearAirSpace();
  },
  methods: {
    /**
     * 初始化渲染空域
     */
    async initAirSpace() {
      let res = await API.AIRSPACE.List({
        pageSize: 9999,
        pageNo: 1,
        belongTo: 0,
      });

      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();
      // 创建实体
      this.airSpaceDataSrouce = new Cesium.CustomDataSource("airSpace");
      res.data.forEach((item) => {
        let positions = item.regionList;
        if (item.regionType == "CIRCLE") {
          this.airSpaceDataSrouce.entities.add({
            position: Cesium.Cartesian3.fromDegrees(positions[0], positions[1]),
            ellipse: {
              semiMajorAxis: positions[2],
              semiMinorAxis: positions[2],
              material: Cesium.Color.fromCssColorString(
                item.color || "red"
              ).withAlpha(0.3),
              /* item.typeId == 2
                  ? Cesium.Color.GREEN.withAlpha(0.5)
                  : Cesium.Color.RED.withAlpha(0.5), */
            },
            show: true,
            id: item.id,
          });
        } else if (item.regionType == "POLYGON") {
          this.airSpaceDataSrouce.entities.add({
            // viewer.entities.add({
            polygon: {
              hierarchy: {
                positions: Cesium.Cartesian3.fromDegreesArray(positions),
              },
              outline: false,
              material: Cesium.Color.fromCssColorString(
                item.color || "red"
              ).withAlpha(0.3),
            },
            show: true,
            id: item.id,
          });
        }
      });

      viewer.dataSources.add(this.airSpaceDataSrouce);
    },
    clearAirSpace() {
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();
      this.airSpaceDataSrouce.entities.removeAll();
      viewer.dataSources.remove(this.airSpaceDataSrouce, true);
      this.airSpaceDataSrouce = null;
    },
    exit(item) {
      if (this.is_from_situation_edit) {
        this.change_flightTask_view();
      } else {
        this.flag = false;
        this.showNest_list(this.flag);
        this.change_page();
      }
    },
    testEmit(item, type) {
      this.$emit("add", item, type);
    },
    change_airway(flag) {
      this.visibleAutoRoute = 2;
      setTimeout(() => {
        this.visibleAutoRoute = 1;
      }, 0);
      this.flag = flag;
      this.showNest_list(this.flag);
    },
  },
  // computed: {
  //   cesium_layer() {
  //     return this.g_cesium_layer();
  //   },
  // },
  // mounted() {
  //   this.init_cesium_handler();
  //   this.get_air_space_list();
  //   this.get_electric_fence_list();
  //   this.get_institutional_fence_list();
  // },
  // destroyed() {
  //   this.destroy_cesium_handler();
  //   this.clear_entities();
  // },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";
@import "~@/styles/mixins.scss";

.cpt-command-airway-edit {
  position: absolute;
  left: 100px;
  top: 85px;
  width: 560px;
  box-sizing: border-box;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 44px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;

    border: 1px solid #427dff;
    .titles{
      display: flex;
      .title{
        font-family: YouSheBiaoTiHei;
        color: #C0C0C0;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2,32,56,0.2);
      }
      .active {
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        font-weight: 700;
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .hd-box {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 700;
    margin-left: 20px;
  }

  // .title-box {
  //   height: 56px;
  //   box-sizing: border-box;
  //   padding-left: 112px;
  //   position: relative;
  //   display: flex;
  //   justify-content: space-between;
  //   margin-right: 80px;
  //   .title {
  //     font-family: PangMenZhengDao;
  //     font-size: 22px;
  //     color: #dce9ff;
  //     font-weight: 400;
  //     line-height: 3.2;
  //     margin-right: 30px;
  //   }
  //   .select {
  //     font-family: PangMenZhengDao;
  //     font-size: 22px;
  //     color: #00ffff;
  //     text-align: center;
  //     font-weight: 400;
  //   }
  //   &::before {
  //     content: " ";
  //     display: block;
  //     position: absolute;
  //     height: 1px;
  //     left: 0;
  //     bottom: 0;
  //     right: 0;
  //     background-image: linear-gradient(
  //       270deg,
  //       rgba(0, 117, 221, 0) 0%,
  //       #3ea4d5 50%,
  //       rgba(0, 117, 221, 0) 100%
  //     );
  //   }
  //   .right-box {
  //     flex: 1;
  //     display: flex;
  //     font-family: PangMenZhengDao;
  //     font-size: 18px;
  //     color: #20f0f0;
  //     text-align: center;
  //     font-weight: 400;
  //     // line-height: 18px;
  //     margin-top: 10px;
  //     .before-point {
  //       margin-right: 12px;
  //     }
  //     .before-point,
  //     .after-point {
  //       cursor: pointer;
  //       user-select: none;
  //       margin-left: 15px;
  //       &.disabled {
  //         cursor: not-allowed;
  //       }
  //     }
  //     .no {
  //       margin: 0 10px;
  //     }
  //   }
  // }
  .ctx-box {
    height: calc(100% - 56px - 88px);
    box-sizing: border-box;
    padding: 25px 0px 0px 58px;
    overflow: auto;

    .form-box {
      ::v-deep .el-form {
        .divider {
          @include linear_gradient_border(0, auto, 0, 0);
          height: 22px;
        }

        .el-form-item {
          .item-group {
            display: flex;
            align-items: center;

            .unit {
              font-family: MicrosoftYaHeiUI;
              font-size: 18px;
              color: rgb(179, 201, 203);
              font-weight: 400;
              margin-left: 12px;
            }

            .iconfont {
              color: rgb(179, 201, 203);
              font-size: 30px;
              margin-left: 0.3em;
              cursor: pointer;
            }

            .el-icon-location-outline {
              color: rgb(179, 201, 203);
              font-size: 30px;
              margin-left: 0.3em;
              cursor: pointer;
            }
          }

          .el-form-item__label {
            font-family: Microsoft YaHei;
            font-size: 16px;
            color: #ccedff;
            text-align: right;
            font-weight: 400;
          }

          .el-input {
            width: 356px;
            height: 40px;
            border: 1px solid #08c2d1;

            .el-input__inner {
              width: 100%;
              border: none;
              border-radius: 0;
              background-color: #000000;
              color: #fff;
            }
          }

          .el-input-number {
            width: 356px;

            .el-input-number__decrease,
            .el-input-number__increase {
              bottom: 1px;
              background: #606266;
              color: #f5f7fa;
              border-radius: 0;
              border: none;
            }

            .el-input-number__decrease {
              left: 1px;
            }

            .el-input-number__increase {
              right: -1px;
            }
          }
        }
      }
    }
  }

  .bottom-box {
    box-sizing: border-box;
    padding-bottom: 20px;
    height: 88px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    @include linear_gradient_border(0, auto, 0, 0);

    .iconfont {
      cursor: pointer;
      font-size: 44px;
      color: #08c2d1;
    }
  }
}
</style>

<style lang="scss">
.actions-box {
  .title-box {
    display: flex;
    align-items: center;

    .title {
      font-size: 14px;
      width: 70px;
    }
  }

  .list-box {
    max-height: 210px;
    overflow: auto;
    margin-top: 12px;

    .action-item {
      background-color: #ececec;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #fff;
      box-sizing: border-box;
      padding: 12px;

      &:hover {
        background-color: #ccc;
      }

      .item-inner {
        display: flex;
        align-items: center;
      }

      .iconfont {
        color: red;
        cursor: pointer;
        font-size: 20px;
      }
    }
  }
}
</style>
