<template>
  <div class="cpt-command-airtask-edit">
    <div class="header" v-interact>
      <div class="hd-box">任务编辑</div>
      <div class="hd-box cp mr20" @click="flight_task_chage_view(1)">关闭</div>
    </div>
    <div class="form-box">
      <div class="form-inner-box">
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item label="任务名称" prop="taskTitle">
            <el-input
              v-model="form.taskTitle"
              placeholder="请输入任务名称"
              clearable
            />
          </el-form-item>
          <el-form-item label="航线名称" prop="flightLineId">
            <el-select
              v-model="form.flightLineId"
              placeholder="请选择航线名称"
              clearable
              @change="handle_change"
            >
              <el-option
                v-for="item in drd.airway"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              <span style="float: left">{{ item.title }}</span>
              <span style="float: left; color: #00b900; font-size: 13px">{{ item.issafe == 1 ? '（安全）' : '' }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="任务类别" prop="taskCateId">
            <el-select
              v-model="form.taskCateId"
              placeholder="请选择任务类别"
              clearable
            >
              <el-option
                v-for="cate in drd.cate"
                :key="cate.id"
                :label="cate.title"
                :value="cate.id"
              />
            </el-select>
          </el-form-item> -->
          <el-form-item label="任务类型" prop="taskTypeId">
            <el-select
              v-model="form.taskTypeId"
              placeholder="请选择任务类型"
              clearable
            >
              <el-option
                v-for="type in drd.type"
                :key="type.id"
                :label="type.title"
                :value="type.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="责任单位" prop="organizationId">
            <el-cascader
              placeholder="请选择所属机构"
              v-model="form.organizationId"
              :options="drd.department"
              :show-all-levels="false"
              :props="{
                children: 'children',
                label: 'orgName',
                value: 'id',
                checkStrictly: true,
                emitPath: false,
              }"
              clearable
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="飞手" prop="flyIds">
            <el-select
              v-model="form.flyIds"
              placeholder="请选择飞手"
              multiple
              clearable
            >
              <el-option
                v-for="team in drd.team"
                :key="team.id"
                :label="team.title"
                :value="team.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="电池编号" prop="batteryNumbers">
            <el-select
              v-model="form.batteryNumbers"
              clearable
              placeholder="请选择电池编号"
              :popper-append-to-body="false"
              multiple
              :multiple-limit="2"
            >
              <el-option
                v-for="item in drd.batteryList"
                :key="item.id"
                :label="item.title"
                :value="item.title"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="预计开始时间" prop="started_at">
            <el-date-picker
              v-model="form.started_at"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择预计开始时间"
              popper-class="task-resubmit"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="内容描述" prop="remark">
            <el-input
              v-model="form.remark"
              type="textarea"
              :rows="2"
              placeholder="请输入内容描述"
            >
            </el-input>
          </el-form-item>
          <el-form-item class="btns">
            <el-button @click="handle_edit">重新提交</el-button>
            <el-button @click="flight_task_chage_view(1)">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";
import dayjs from "dayjs";

export default {
  data() {
    return {
      drd: {
        airway: [],
        cate: [
          { title: "常态任务", id: 2 },
          { title: "临时紧急任务", id: 3 },
        ],
        type: [],
        department: [],
        team: [],
        batteryList: [],
      },
      form: {
        taskTitle: undefined,
        flightLineId: undefined,
        // taskCateId: undefined,
        taskTypeId: undefined,
        organizationId: undefined,
        flyIds: [],
        batteryNumbers: [],
        remark: undefined,
        started_at: null,
        applyStatus: null,
        papplyStatus: null,
        id: null,
        userId: null,
      },
    };
  },

  computed: {
    ...mapGetters(["flight_detail"]),
  },
    watch: {
    "form.organizationId": {
      handler(newValue, oldValue) {
        this.drd_batteryList(newValue);
      },
    },
  },

  methods: {
    getDetail() {
      const keys = Object.keys(this.form);
      this.flight_detail.batteryNumbers =
        this.flight_detail.batteryNumbers.split(",");
      this.flight_detail.taskTypeId = this.flight_detail.taskTypeTittleId;
      keys.forEach((item) => {
        this.form[item] = this.flight_detail[item];
      });
    },
    async drd_batteryList(id) {
      let data = await API.BATTERY.List({
        organId:id
      });
      this.drd.batteryList = data || [];
    },
    async drd_airway() {
      let res = await API.AIRWAY.Drd();
      this.drd.airway = res || [];
    },
    async drd_type() {
      let res = await API.TASK.DrdType();
      this.drd.type = res || [];
    },
    async drd_department() {
      let res = await API.DEPARTMENT.Drd();
      this.drd.department = [res] || [];
    },
    async drd_team() {
      let res = await API.USER.DrdPilot();
      this.drd.team = res || [];
    },

    async handle_edit() {
      try {
        const keys = Object.keys(this.form);
        const form = JSON.parse(JSON.stringify(this.form));
        form.batteryNumbers = form.batteryNumbers.join(",");
        keys.forEach((item) => {
          if (form[item] == this.flight_detail[item]) {
            if (
              item != "id" &&
              item != "applyStatus" &&
              item != "papplyStatus" &&
              item != "userId"
            ) {
              delete form[item];
            }
          }
        });
        await API.TASK.FlightEdit(form);
        this.flight_task_chage_view(1);
        this.$el_message("修改成功");
      } catch (error) {
        this.$el_message("修改失败", () => {}, "error");
      }
    },
    // 请选择航线名称
    handle_change(e) {
      // 提取路径点
      let a;
      this.drd.airway.forEach((item, index) => {
        if (item.id == e) {
          a = index;
        }
      });
      a = JSON.parse(this.drd.airway[a].flightCourseJson);

      let name = this.drd.airway.find((item) => item.id == e);
      if (name) {
        this.form.taskTitle = `${name.title}-${dayjs().format(
          "YYYYMMDDHHmmss"
        )}`;
      } else {
        this.form.taskTitle = null;
      }
    },
  },
  async mounted() {
    await this.drd_airway();
    await this.drd_type();
    await this.drd_department();
    await this.drd_team();
    // await this.drd_batteryList();
    this.getDetail();
  },

  inject: ["flight_task_chage_view"],
};
</script>

<style lang="scss" scoped>
// @import "~@/styles/form.scss";
@import "~@/styles/mixins.scss";
.cpt-command-airtask-edit {
  position: absolute;
  width: 540px;
  height: 700px;
  left: 100px;
  top: 170px;
  box-sizing: border-box;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 13px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 44px;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;
  }

  .hd-box {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 700;
    margin-left: 20px;
  }

  .form-box {
    height: calc(100% - 54px);
    box-sizing: border-box;
    padding: 20px 80px 20px 20px;
    .form-inner-box {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      ::v-deep .el-form {
        .el-form-item {
          .el-form-item__label {
            font-family: Microsoft YaHei;
            font-size: 16px;
            color: #ffffff;
            text-align: right;
            font-weight: 400;
          }
          .el-form-item__content {
            background: rgba(2, 31, 51, 0.7);
            border: 1px solid #06b6e0;
            .field-group {
              display: flex;
              align-items: center;
              .el-icon-location-outline {
                color: #08c2d1;
                font-size: 26px;
                cursor: pointer;
                width: 34px;
              }
            }
            .el-input {
              .el-input__inner {
                background: transparent;
                border: none;
                color: #08c2d1;
              }
            }
            .el-textarea {
              background: rgba(2, 31, 51, 0.7);
              border: 1px solid #06b6e0;
              .el-textarea__inner {
                background: transparent;
                border: none;
                color: #08c2d1;
              }
            }

            .el-button {
              border: 0;
              opacity: 0.8;
              font-family: PangMenZhengDao;
              font-size: 22px;
              color: #00ffff;
              text-align: center;
              font-weight: 400;
              width: 114px;
              height: 40px;
              padding: 10px 20px;
              background: rgba(0, 34, 140, 0.2);
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5),
                inset 0 0 15px 0 #00b6ff;
              border-radius: 4px;
              margin-left: 30px;
              margin-top: 10px;
            }
          }
          &.btns {
            display: flex;
            .el-form-item__content {
              background: none;
              border: none;
            }
          }
          .el-date-editor.el-input {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.task-resubmit {
  border: none;

  .el-picker-panel__body-wrapper {
    background: #0d224f;
    border: 1px solid #00aeff;
    color: #fff;

    .el-date-table th {
      // border-bottom: solid 1px #00aeff;
      color: #fff;
    }
    .el-date-table td.in-range div {
      background-color: #00aeff;
    }
    .el-input--small .el-input__inner {
      background-color: #0d224f;
    }
    .el-date-picker__header-label{
        color: #fff;
      }
  }
  .el-picker-panel__footer {
    background-color: #0d224f !important;
    .el-button  {
    background-color: transparent !important;
    border: none;
    color: #00aeff;
  }
  }
  
}
</style>