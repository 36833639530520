<template>
  <div class="w468 routeLabelMaxBox">
    <div class="header safety_heard">
      <div class="toubu">
        <div class="tiao">
          <img
            src="~@/assets/yingkong1027/jinye.png"
            style="width: 100%; height: 100%"
            alt=""
          />
        </div>
        <div class="hd-box">航线标签</div>
      </div>
      <div class="right">
        <div class="fontOne cp mr20" @click="$emit('close')">关闭</div>
      </div>
    </div>
    <div class="nei">
      <div class="routeLabelTop jcsb">
        <div class="routeLabelCreate" @click="showLabel">创建标签</div>
        <div class="jcsb" v-if="createFlag">
          <el-input
            clearable
            size="mini"
            maxlength="6"
            v-model="labelValue"
          ></el-input>
          <div class="routeLabelCreate btn_an" @click="createFn">保存</div>
        </div>
      </div>
      <div class="routeLabelSearch jcsb">
        <el-input
          size="mini"
          placeholder="请输入标签名称"
          suffix-icon="el-icon-search"
          v-model="search"
        >
        </el-input>
        <div class="preservation w76 h27 tc lh27 cp ml10" @click="searchBtn">
          搜索
        </div>
      </div>
      <div class="routeLabelList pt20 pl20 mt20 h321">
        <div class="h300 routeLabelListBox">
          <div
            class="routeLabelListItem mb10"
            v-for="(item, index) in listData"
            :key="index"
          >
            <el-checkbox v-model="item.status"
              >{{ item.labelName }}
            </el-checkbox>
            <span
              @click="delBtn(item.id)"
              class="cp mr20 fr iconfont icon-shanchu"
              style="#00a9ff"
            ></span>
          </div>
        </div>
      </div>
      <div class="jcsb mt20">
        <div class="preservation w76 h27 tc lh27 cp" @click="handClickFn">
          保存
        </div>
        <div class="preservation w76 h27 tc lh27 cp" @click="$emit('close')">
          取消
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api'
export default {
  data() {
    return {
      createFlag: false,
      labelValue: '',
      search: null,
      checked: '',
      listData: [],
    }
  },
  props: ['id'],
  watch: {
    id(newValue) {
      this.listFn()
    },
  },
  created() {
    this.listFn()
  },
  methods: {
    searchBtn() {
      this.listFn()
    },
    async handClickFn() {
      let newArr = []
      this.listData.forEach((element) => {
        if (element.status) {
          newArr.push(element.id)
        }
      })

      let res = await API.AIRWAY.updatatag({
        flightId: this.id,
        labelIds: newArr,
      })
      this.$message.success('创建成功')
      this.$emit('close')
      this.$emit('changeList')
    },
    async selectList() {
      let res = await API.AIRWAY.getLabelById({ id: this.id })
      let listData = JSON.parse(JSON.stringify(this.listData))
      for (let i = 0; i < res.length; i++) {
        for (let j = 0; j < listData.length; j++) {
          if (listData[j].id == res[i].labelId) {
            listData[j].status = true
          }
        }
      }
      this.listData = listData
    },
    async listFn() {
      let res = await API.AIRWAY.getFlightLineLabel({ labelName: this.search })
      if (res) {
        let newArr = []
        res.forEach((element) => {
          newArr.push({
            id: element.id,
            labelName: element.labelName,
            status: false,
          })
        })
        this.listData = newArr
        this.selectList()
      }
    },
    showLabel() {
      if (!this.createFlag) {
        this.labelValue = ''
        this.createFlag = true
      }
    },
    async createFn() {
      if (this.labelValue == '') {
        return this.$message.info('标签名称不能为空')
      } else {
        let res = await API.AIRWAY.insert({
          labelName: this.labelValue,
        })
        if (res.status == 1) {
          this.$message.success('创建成功')
          this.labelValue = ''
          this.createFlag = false
          this.listFn()
        } else {
          this.$message.error(res.message)
        }
      }
    },
    //
    async delBtn(id) {
      let res = await API.AIRWAY.update({
        id,
        lableStatus: 1,
      })
      if (res.status == 1) {
        this.listFn()
      }
    },
  },
}
</script>

<style lang='scss'  scoped>
::v-deep.el-input--mini {
  .el-input__inner {
    height: 32px;
  }
}
::v-deep.el-checkbox {
  color: #fff;
}

.routeLabelMaxBox {
  position: absolute;
  left: 50%;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
  .routeLabelTop {
    display: flex;
    justify-content: space-between;
    .routeLabelCreate {
      width: 92px;
      height: 32px;
      line-height: 32px;
      background-image: url('~@/assets/yingkong1027/btn_lan.png');
      background-size: 100% 100%;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      cursor: pointer;
      margin: 0 14px 0 0;
    }
  }
  .routeLabelList {
    background: rgba(2, 31, 51, 0.7);
    border: 1px solid rgba(0, 159, 255, 0.6);
    box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35);

    border-radius: 13px;
    .routeLabelListBox {
      overflow-y: auto;
      color: #fff;
    }
  }
  .routeLabelBottom {
    display: flex;
    justify-content: space-around;
  }

  .preservation {
    background: rgba(0, 34, 140, 0.2);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 15px 0 #00b6ff;
    border-radius: 6px;
    color: #00ffff;
  }
  .el-input {
    background: rgba(2, 31, 51, 0.7);
    border-radius: 6px;
  }
  .el-input__inner {
    background: rgba(2, 31, 51, 0.7);
    border: 1px solid rgba(6, 182, 224, 0.7);
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1820px;
  height: 32px;
  background: linear-gradient(
    180deg,
    #9198ff 0%,
    rgba(45, 81, 153, 0.45) 40%,
    #05091a 100%
  );
  box-shadow: inset 0px 0px 10px 2px #3f9dff;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #427dff;
  .toubu {
    display: flex;
    .tiao {
      width: 26px;
      height: 26px;
      margin: 0 5px 0 8px;
    }
    .hd-box {
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ffffff;
      line-height: 24px;
    }
  }
}
.nei {
  box-sizing: border-box;
  padding: 12px 12px 16px 12px;
}
.el-input--suffix {
  width: 262px;
  height: 32px;
  background: #02173d;
  border-radius: 2px;
  border: 1px solid #315ec7;
  .el-input__inner {
    border: 0px solid transparent;
  }
}
.btn_an {
  width: 64px !important;
  height: 32px !important;
  margin: 0 0 0 14px !important;
}
.routeLabelSearch {
  margin: 12px 0 0 0;
}
</style>