<template>
  <div class="monitoringMaxBox">
    <LeftList @changeType="changeType" ref="dingshi"></LeftList>
    <div class="monitoringMaxArea" v-if="title.id != 10">
      <RightTop
        style="margin-bottom: 16px"
        :status="status"
        :count="count"
        :sl="sl"
        :title="title"
        @changeFlag="changeFlag"
        @change="changeStatus"
      ></RightTop>
      <div key class="monitoringMaxArea-main">
        <div class="map" v-if="type == 'Map'">
          <Map ref="cesium_layer" @change-plate-mode="handle_change_plate_mode" />
        </div>
        <component
          v-if="type !== 'Map'"
          :is="type"
          :flag="flag"
          class="ml45 mr45 mt33"
          ref="cesium_layer"
          @changeFlag="changeFlag"
          @change="change"
        ></component>
      </div>
    </div>
    <div class="monitoringMaxArea" v-else>
      <Serve />
    </div>
    <div class="singleList" v-if="singleList.length > 0">
      <div
        class="item"
        :class="item.id == changeSingle.id ? 'change' : ''"
        v-for="item in singleList"
        :key="item.id"
        @click="getsinglespace(item)"
      >
        {{ item.location || "暂无" }}
        <img src="./pos.svg" title />
      </div>
    </div>
  </div>
</template>

<script>
import LeftList from "./components/leftList";
import LeftListData from "./components/leftList/data";
import Serve from "@/pages/server_details/index";
import RightTop from "./components/rightTop";
import Mount from "./components/mount";
import Map from "./components/map";
import Battery from "./components/battery";
import API from "@/api";
import Popup from "./methods/popup";
import PopupSingle from "./methods/popupSingle";
import PopupV2 from "./methods/popupV2";
import { Viewer, Utils } from "@/lib/cesium";
let single = null;
let collection = null;
export default {
  name: "monitoring",
  components: { LeftList, RightTop, Mount, Map, Battery, Serve },
  inject: ["g_cesium_layer"],
  data() {
    return {
      singleList: [], //异常区域列表
      changeSingle: {},
      component: "",
      flag: true,
      type: LeftListData.btnList[0].componentType,
      status: true,
      count: null,
      online: null,
      collection: {
        zysb: null,
        jwys: null,
        gzsb: null,
        jlys: null,
        kfq: null,
        zddq: null
      },
      title: {
        id: 10,
        icon: "icon-wurenji2",
        title: "服务器"
      },
      sl: 0, //数量
      zdys_id_arr: [],
      move_id_arr: [],
      popupList: [] //弹出框实例列表
    };
  },
  computed: {
    cesium_layer() {
      return this.$refs["cesium_layer"];
    }
  },
  watch: {
    type() {
      if (this.type === "Map") {
        this.$nextTick(() => {
          this.click_billboard();
        });
      }
    }
  },
  mounted() {
    this.getlist(1, Number(false), this.online, true);
  },
  methods: {
    async getSingle() {
      let viewer = this.$refs.cesium_layer.viewer;
      let move_id_arr = [];
      let _this = this;
      let data = {
        pageSize: 100,
        pageNo: 1
      };
      let res = await API.FCKERNEL.singleList(data);
      this.singleList = [];
      res.data.forEach(v => {
        if (!v.deleted) {
          this.singleList.push(v);
        }
      });
      let scene = viewer.scene;
      collection = new Cesium.BillboardCollection({
        /*   scene, */
        /*   show: true, */
      });
      if (!this.$refs.cesium_layer.is_plate_mode()) {
        if (!scene.primitives.contains(collection)) {
          scene.primitives.add(collection);
        }
        collection.show = true;
        // this.collection[cate_name] = collection;
        collection.add({});
      }
      this.singleList.forEach(item => {
        item.lon *= 1;
        item.lat *= 1;
        if (item.lat && item.lon) {
          collection.add({
            position: Utils.transformWGS842Cartesian({
              lng: item.lon,
              lat: item.lat,
              alt: 0
            }),
            // image: item.category === 4 ? require(`@/assets/images/factor/${cate_name}/${item.category}.png`) :  require(`@/assets/images/factor/${cate_name}/${item.category}${(item.category === 1 && item.status == 0) ? '-disable' : ''}.svg`),
            image: require(`@/assets/images/factor/sjq/${
              item.signalStrength > -115 && item.signalStrength < -110
                ? "single_y"
                : "single_r"
            }.svg`),
            scale: 1,
            width: 54,
            height: 89,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            horizontalOrigin: Cesium.VerticalOrigin.CENTER,
            id: JSON.stringify({
              ...item,
              entityType: "singlepoint"
            }),
            distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
              0,
              2.2e5
            ),
            scaleByDistance: new Cesium.NearFarScalar(2.5e4, 0.7, 2.2e7, 0),
            disableDepthTestDistance: Number.POSITIVE_INFINITY
          });
        }
      });
      single = new Cesium.CustomDataSource("single");
      console.log(single, "single");
      this.singleList.forEach(async item => {
        // let address = await API.MAP.AiRegeo({
        //   location: `${item.lon},${item.lat}`,
        // });
        // //   address.province.value + address.city.value + address.dist.value + address.road.roadname +
        // this.$set(item, "addr", address.poi);
        single.entities.add({
          position: Cesium.Cartesian3.fromDegrees(
            Number(item.lon),
            Number(item.lat)
          ),
          ellipse: {
            semiMajorAxis: item.radius,
            semiMinorAxis: item.radius,
            material: Cesium.Color.fromCssColorString(
              item.signalStrength > -115 && item.signalStrength < -110
                ? "yellow"
                : "red"
            ).withAlpha(0.3)
            //   material: Cesium.Color.fromCssColorString('red').withAlpha(0.3)
            /* item.typeId == 2
                          ? Cesium.Color.GREEN.withAlpha(0.5)
                          : Cesium.Color.RED.withAlpha(0.5), */
          },
          show: true,
          id: JSON.stringify(item),
          name: "single"
        });
      });
      viewer.dataSources.add(single);
      let handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      handler.setInputAction(async movement => {
        let pickedLabel = viewer.scene.pick(movement.position);
        console.log(pickedLabel, "pickedLabel123");
        var pick = new Cesium.Cartesian2(
          movement.position.x,
          movement.position.y
        );
        if (Cesium.defined(pickedLabel)) {
          // 获取点击位置坐标
          let cartesian = viewer.scene.globe.pick(
            viewer.camera.getPickRay(pick),
            viewer.scene
          );
          if (pickedLabel.id.name == "single") {
            try {
              let data = JSON.parse(pickedLabel.id.id);
              let arr = move_id_arr;
              let singleData = {
                arr: move_id_arr
              };
              if (arr.indexOf(data.id) < 0) {
                move_id_arr.push(data.id);
                // 调用弹窗方法
                let popup = new PopupSingle({
                  viewer: viewer,
                  geometry: cartesian,
                  data: data,
                  arr: singleData
                });
              }
            } catch (error) {}
          }
          // if(pickedLabel.collection){
          //   // _this.getsinglespace(JSON.parse(pickedLabel.id))
          // }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },
    getsinglespace(val) {
      let viewer = this.$refs.cesium_layer.viewer;
      this.changeSingle = val;
      let position = Utils.transformWGS842Cartesian({
        lng: Number(val.lon),
        lat: Number(val.lat),
        alt: Number(val.radius) * 2
      });
      viewer.camera.flyTo({
        destination: position
      });
    },
    change(val) {
      this.sl = val;
      // console.log(val, "change");
    },
    changeFlag(flag) {
      // console.log("changeFlag", flag);
      this.flag = flag;
      this.getlist(this.title.id, Number(this.flag), this.online);
    },
    changeType(type) {
      this.popupList.forEach(item => {
        item.close();
      });
      this.popupList = [];
      // console.log('changeType', type)
      this.flag = null;
      this.online = null;
      this.title = type;
      // console.log(this.title, " type.componentType");
      this.changeSingle = {};
      this.singleList = [];
      setTimeout(() => {
        let viewer = this.$refs.cesium_layer.viewer;
        if (single) {
          viewer.dataSources.remove(single, true);
          single = null;
        }
      }, 1500);
      if (collection) {
        collection.removeAll();
        collection = null;
      }
      if (this.title.id == 3) {
        setTimeout(() => {
          this.getSingle();
        }, 1500);
      } else {
        this.changeSingle = {};
        this.singleList = [];
        setTimeout(() => {
          let viewer = this.$refs.cesium_layer.viewer;
          if (single) {
            viewer.dataSources.remove(single, true);
            single = null;
          }
        }, 1500);
        if (collection) {
          collection.removeAll();
          collection = null;
        }
      }

      this.type = type.componentType;

      if (type.id < 5) {
        this.getlist(type.id, 1);
      } else {
        this.flag = false;
      }

      if (type.title == "无人机") {
        this.status = true;
      } else {
        this.status = false;
      }
    },
    changeStatus(val) {
      // console.log(val,'changeStatus', val);
      this.online = val;
      this.getlist(this.title.id, null, val);
    },
    async getlist(id, isAbnormal, status, flag) {
      if (id < 5) {
        let res = await API.MONITOR.getList({
          type: id,
          isAbnormal: isAbnormal,
          status: status
        });
        // console.log('getlist res', res)
        this.$nextTick(() => {
          this.sl = res.count;
          if (isAbnormal == 1 && id == 1) {
            this.count = {
              exception: res.exception.length,
              noException: res.noException.length
            };
          }

          // noException
          this.addMapFlag(res, id);
        });
      }
    },
    /**
     * 添加地图标记
     */
    addMapFlag(res, id) {
      // console.log('this.collection', this.collection)
      let cate_name = "zysb";
      let viewer = this.$refs.cesium_layer.viewer;
      console.log("addMapFlag viewer", viewer);
      // let viewer = window.viewer;
      let scene = viewer.scene;
      //先删除旧的标记, 再创建新的标记添加到scene实体中
      //判断当前scene中是否包含旧的标记实体, 有则删除
      // console.log('remove', this.collection[cate_name], scene.primitives.contains(this.collection[cate_name]))
      if (
        scene.primitives.contains(
          this.collection[cate_name]
        ) /* Cesium.defined(this.collection[cate]) */
      ) {
        let result = scene.primitives.remove(this.collection[cate_name]);
        // console.log('remove result:', result);
        // this.collection[cate].destroy();
      }
      //创建新的标记集合
      const collection = new Cesium.BillboardCollection({
        /*   scene, */
        /*   show: true, */
      });
      if (!this.$refs.cesium_layer.is_plate_mode()) {
        if (!scene.primitives.contains(collection)) {
          scene.primitives.add(collection);
        }
        collection.show = true;
        this.collection[cate_name] = collection;
        collection.add({});
      }

      if (res) {
        let list = [];
        if (id == 1) {
          list.push(...res.deviceList);
        } else if (id == 2) {
          list.push(...res.nestList);
        } else if (id == 3) {
          list.push(...res.baseList);
        } else if (id == 4) {
          list.push(...res.landList);
        } else if (id == 5) {
          list.push(...res.mountList);
        } else if (id == 6) {
          list.push(...res.batteryList);
        }
        this.sl = list.length;
        // console.log('addMapFlag', res, list, collection)
        list.forEach(item => {
          item.longitude *= 1;
          item.latitude *= 1;
          if (item.latitude && item.longitude) {
            collection.add({
              position: Utils.transformWGS842Cartesian({
                lng: item.longitude,
                lat: item.latitude,
                alt: 0
              }),
              // image: item.category === 4 ? require(`@/assets/images/factor/${cate_name}/${item.category}.png`) :  require(`@/assets/images/factor/${cate_name}/${item.category}${(item.category === 1 && item.status == 0) ? '-disable' : ''}.svg`),
              image: require(`@/assets/images/factor/sjq/${id *
                (item.reportStatus == 0
                  ? 100
                  : item.state == 1
                  ? 1
                  : 10 || item.landingStatus
                  ? 1
                  : 10)}.png`),
              scale: 1,
              width: 54,
              height: 89,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              horizontalOrigin: Cesium.VerticalOrigin.CENTER,
              id: JSON.stringify({
                ...item,
                entityType: "zdys"
              }),
              distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                0,
                2.2e5
              ),
              scaleByDistance: new Cesium.NearFarScalar(2.5e4, 0.7, 2.2e7, 0),
              disableDepthTestDistance: Number.POSITIVE_INFINITY
            });
          }
        });
      }
    },
    handle_change_plate_mode(val) {
      if (val) {
        // 板块模式
        Object.keys(this.collection).forEach(key => {
          this.factor_hide_collection(key);
        });
      } else {
        // 地图模式;
        Object.keys(this.collection).forEach(key => {
          this.factor_show_collection(key);
        });
      }
    },
    click_billboard() {
      console.log("click_billboard");
      let viewer = this.$refs.cesium_layer.viewer;
      const _this = this;

      let handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      console.log(handler, "handler");
      handler.setInputAction(async movement => {
        let pickedLabel = viewer.scene.pick(movement.position);
        var pick = new Cesium.Cartesian2(
          movement.position.x + 15,
          movement.position.y + 10
        );
        if (Cesium.defined(pickedLabel)) {
          // 获取点击位置坐标
          let cartesian = viewer.scene.globe.pick(
            viewer.camera.getPickRay(pick),
            viewer.scene
          );
          if (pickedLabel.id.name == "single") {
            try {
              let data = JSON.parse(pickedLabel.id.id);
              let arr = move_id_arr;
              let singleData = {
                arr: move_id_arr
              };
              if (arr.indexOf(data.id) < 0) {
                move_id_arr.push(data.id);
                // 调用弹窗方法
                let popup = new PopupSingle({
                  viewer: viewer,
                  geometry: cartesian,
                  data: data,
                  arr: singleData
                });
                _this.popupList.push(popup);
              }
            } catch (error) {}
          } else {
            let data = JSON.parse(pickedLabel.id);
            let arr = _this.move_id_arr;
            console.log("准备创建弹窗", data);
            try {
              if (
                arr.indexOf(data.id) < 0 &&
                data.entityType != "singlepoint"
              ) {
                _this.move_id_arr.push(data.id);
                // 调用弹窗方法
                let popup = new Popup({
                  viewer: viewer,
                  geometry: cartesian,
                  data: data,
                  arr: _this
                });
                // console.log('创建弹窗', popup)
                _this.popupList.push(popup);
              }
              if (data.entityType == "singlepoint") {
                _this.getsinglespace(data);
              }
            } catch (error) {
              console.log("创建弹窗异常:", error);
            }
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

      handler.setInputAction(movement => {
        // console.log('滚动', _this.popupList)
        //滚动关闭所有信息框
        _this.popupList.forEach(item => {
          item.close();
        });
        _this.popupList = [];
      }, Cesium.ScreenSpaceEventType.WHEEL);
    }
  }
};
</script>

<style scoped lang="scss">
.monitoringMaxBox {
  width: 100%;
  height: calc(100%);
  position: absolute;
  top: 1px;
  z-index: 9;
  // overflow: hidden;
  background-color: #000;
  padding: 104px 16px 16px 6px;
  box-sizing: border-box;
  display: flex;

  .monitoringMaxArea {
    display: flex;
    flex-direction: column;
    flex: 1;

    .monitoringRightTop {
      flex-shrink: 0;
    }
  }

  .monitoringMaxArea-main {
    background: rgba(12, 34, 73, 0.7);
    border-radius: 10px;
    border: 1px solid rgba(26, 92, 246, 0.5);
    backdrop-filter: blur(1px);
    padding: 16px;
    box-sizing: border-box;
    flex: 1;
    .map {
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }
}
.singleList {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: #fff;
  background: rgba(9, 32, 87, 0.7);
  padding: 10px;
  border-radius: 10px;
  line-height: 24px;
  .change {
    color: #14faff;
  }
}
</style>
