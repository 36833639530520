<template>
    <ImageVideo />
</template>

<script>
import ImageVideo from '@/components/command/imagevideo'
import data from './data'
import methods from './methods'

export default {
  components: { ImageVideo },
  data() {
    return data
  },
  methods: {
    ...methods,
  },
  inject: ['g_cesium_toolbar','g_cesium_layer'],
  mounted() {
    this.g_cesium_toolbar().moveToolbar('left')
  },
}
</script>
