<template>
  <div class="page">
    <div class="header" v-interact>
      <div class="flex">
        <img class="header__icon" src="@/assets/zong_lan_ye_img/frame/icon.png" alt />
        <div class="hd-box">勤务管理导出</div>
      </div>

      <div class="jcsb">
        <div class="header__close" @click="$emit('close')">关闭</div>
      </div>
    </div>
    <div class="form">
      <el-form :model="ruleForm" ref="ruleForm" :rules="ruleFormRule">
        <el-form-item label="开始日期:" prop="startDate">
          <el-date-picker
            v-model="ruleForm.startDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期:" prop="endDate">
          <el-date-picker
            v-model="ruleForm.endDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="勤务类型:" prop="holidayType">
          <el-select v-model="ruleForm.holidayType" clearable placeholder="请选择勤务类型">
            <el-option
              v-for="item in firmList"
              :key="item.id"
              :label="item.patrolType"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="btns">
        <div class="mr20" @click="exportTable">确认导出</div>
        <div @click="
          $emit('close');
          $refs.ruleForm.resetFields();
        ">取消</div>
      </div>
    </div>
  </div>
</template>
<script>
import API from "@/api";
import { mapGetters } from "vuex";
export default {
  data() {
    let validateFileUrl = async (rule, value, callback) => {
      if (!this.firmForm.fileUrl) {
        callback(new Error("请上传Excel文件"));
      } else {
        callback();
      }
    };
    let validEndTime = async (rule, value, callback) => {
      const { endDate, startDate } = this.ruleForm;
      if (startDate != null) {
        let startTmp = startDate.split("-");
        let endTmp = endDate.split("-");
        let stT = new Date(startTmp[0], startTmp[1], startTmp[2]);
        let edT = new Date(endTmp[0], endTmp[1], endTmp[2]);
        if (stT.getTime() > edT.getTime()) {
          callback(new Error("结束日期必须大于开始日期"));
        } else {
          callback();
        }
      }
    };
    let validStartTime = async (rule, value, callback) => {
      const { endDate, startDate } = this.ruleForm;
      if (endDate != null) {
        let startTmp = startDate.split("-");
        let endTmp = endDate.split("-");
        let stT = new Date(startTmp[0], startTmp[1], startTmp[2]);
        let edT = new Date(endTmp[0], endTmp[1], endTmp[2]);
        if (stT.getTime() > edT.getTime()) {
          callback(new Error("开始日期必须小于结束日期"));
        } else {
          callback();
        }
      }
    };
    return {
      ruleForm: {},
      firmList: [],
      ruleFormRule: {
        startDate: [
          {
            required: true,
            message: "请选择开始日期",
            trigger: "change",
          },
          { validator: validStartTime, trigger: "change" },
        ],
        endDate: [
          {
            required: true,
            message: "请选择结束日期",
            trigger: "change",
          },
          { validator: validEndTime, trigger: "change" },
        ],
        holidayType: [
          {
            required: true,
            message: "请选择勤务类型",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  methods: {
    async getFirmNameList() {
      let res = await API.AIRSPACE.getHolidayPatrolList();
      this.firmList = res;
    },
    exportTable() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let str = "";
          str = `${process.env.VUE_APP_BASE_URL}/hawksystemserver/flightMission/exportHolidayPatrolFile?FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}&startDate=${this.ruleForm.startDate}&endDate=${this.ruleForm.endDate}&holidayType=${this.ruleForm.holidayType}&lineLabelType=${this.ruleForm.lineLabelType}`;
          window.open(str);
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    this.getFirmNameList();
  },
};
</script>
<style lang="scss" scoped>
.page {
  position: absolute;
  left: 100px;
  top: 99px;
  width: 400px;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;

    .flex {
      display: flex;
      align-items: center;
    }
    .hd-box {
      font-size: 20px;
      font-family: YouSheBiaoTiHei;
      color: #fff;
      letter-spacing: 0;
      font-weight: 700;
      background-image: -webkit-linear-gradient(
        right,
        #e3aa77,
        #f5cda9,
        #f9ecd3,
        #fcdbb1,
        #edb07a
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .header__close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 16px;
      cursor: pointer;
    }
  }
  .form {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /deep/.el-form-item {
      display: flex;
      .el-form-item__label {
        color: #fff;
      }
    }
    .el-select {
      width: 220px;
    }
    .btns {
      display: flex;
      div {
        white-space: nowrap;
        padding: 10px;
        background: url("~@/assets/yingkong1027/btn_active.png") no-repeat;
        border-radius: 4px;
        font-size: 14px;
        color: #fff;
      }
    }
  }
}
</style>
