<!-- 人员统计 -->
<template>
  <div class="cpt-command-device-mount cpt-command-device-common">
    <div class="head pl8">
      <img src="~@/assets/images/mount/mount_head.png" />
      <div class="fontO">人员统计</div>
    </div>
    <div class="divSC">
      <div class="search-box-statistics">
        <el-cascader
          ref="cascaderRef"
          @change="CSshow"
          v-model="filter.organId"
          :options="options"
          clearable
          :show-all-levels="false"
          placeholder="请选择单位"
          :props="{
            children: 'children',
            label: 'orgName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
          }"
        ></el-cascader>
        <div @click="handle_search" class="btn1027">搜索</div>
      </div>
      <div class="bd-box-chart">
        <!-- <div id="person_container"></div> -->
        <div id="heng" style="height: 70px; width: 600px"></div>
      </div>
    </div>
    <el-table
      :data="tableData"
      :header-cell-style="{ background: 'transparent' }"
      row-key="id"
      :cell-style="cellStyle"
      :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
      @cell-click="cellClick"
      max-height="327"
    >
      <el-table-column
        prop="anotherName"
        label="单位名称"
        width="180"
      ></el-table-column>
      <el-table-column
        @click="hanle_click(1)"
        align="center"
        prop="count"
        label="人员总数"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="flyingHandCount"
        label="飞行助手"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="mingjingCount"
        label="民警"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="fujingCount"
        label="辅警"
      ></el-table-column>

    </el-table>
  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";
import { Chart } from "@antv/g2";
import echarts from "echarts";
export default {
  data() {
    return {
      tableData: [],
      list: [],
      filter: {
        organId: null,
      },
      options: [],
      dataPerson: [
        { name: "飞行助手", value: 0 },
        { name: "民警", value: 0 },
        { name: "辅警", value: 0 },
      ],
      chart: null,
    };
  },

  async mounted() {
    this.filter.organId = this.user_info.departmentId;
    await this.getOptions();
    await this.getStatistics();
    // this.init(this.dataPerson, "person_container");
    this.charts();
    var div = document.querySelectorAll(".el-table__row--level-0")
    div.forEach((v,i)=>{
      if(i % 2 !== 1){
        v.style.backgroundColor = "rgba(73,135,210,0.2)"
      }
    })
    console.log(div,"divvvvv");
  },

  computed: {
    ...mapGetters(["user_info"]),
  },

  methods: {
    rowClassName({ row, rowIndex }) {
      // 判断是否为偶数行
      if (rowIndex % 2 === 1) {
        return "zebra-row";
      }
      return "";
    },
    CSshow() {
      // console.log(this.$refs.cascaderRef.dropDownVisible,'refffffff')
      this.$nextTick(() => {
        this.$refs.cascaderRef.dropDownVisible = true;
      });
    },
    //表格行颜色
    cellStyle({ row, column, rowIndex, columnIndex }) {

      // console.log(row);
      // console.log(column);
      // console.log(rowIndex);
      // console.log(columnIndex);

      // if (rowIndex % 2 == 1) {
      //   return "background:#081A3A;";
      // } else {
      //   return "background:rgba(73,135,210,0.2);";
      // }

    },
    charts() {
      // 就算百分比
      var zong =
        this.dataPerson[0].value +
        this.dataPerson[1].value +
        this.dataPerson[2].value;
      var a = (this.dataPerson[0].value / zong) * 100;
      var b = (this.dataPerson[1].value / zong) * 100;
      var c = (this.dataPerson[2].value / zong) * 100;
      // 放进容器里
      let xData = [""],
        yData = [],
        barData = [];
      var barData2 = [];
      var barData3 = [];
      yData.push(100);
      barData.push(a);
      barData2.push(b);
      barData3.push(c);

      let myChart = this.$echarts.init(document.getElementById("heng"));
      let option = {
        backgroundColor: "transparent",
        tooltip: {
          trigger: "item",
          padding: [
            18, // 上
            30, // 右
            18, // 下
            21, // 左
          ],
          formatter: (name) => {
            // tooltip不支持rich属性,但是支持更自由html
            if (name.seriesName == "民警") {
              return (
                "<div>" +
                name.seriesName +
                "数量" +
                "</div>" +
                '<div style="display:flex;align-items: center;margin: 5px 0 0px 0;font-size:14px;"><div style="width:14px;height:14px;background:#02C979;border-radius:50%;margin:0 14px 0 0;"></div><div style="color:#02C979;">' +
                this.dataPerson[1].value +
                "</div><div>" +
                "/" +
                zong +
                "</div></div>"
              );
            } else if (name.seriesName == "辅警") {
              return (
                "<div>" +
                name.seriesName +
                "数量" +
                "</div>" +
                '<div style="display:flex;align-items: center;margin: 5px 0 0px 0;font-size:14px;"><div style="width:14px;height:14px;background:#EFA914;border-radius:50%;margin:0 14px 0 0;"></div><div style="color:#EFA914;">' +
                this.dataPerson[2].value +
                "</div><div>" +
                "/" +
                zong +
                "</div></div>"
              );
            } else if (name.seriesName == "飞行助手") {
              return (
                "<div>" +
                name.seriesName +
                "数量" +
                "</div>" +
                '<div style="display:flex;align-items: center;margin: 5px 0 0px 0;font-size:14px;"><div style="width:14px;height:14px;background:#1449f2;border-radius:50%;margin:0 14px 0 0;"></div><div style="color:#1449f2;">' +
                this.dataPerson[0].value +
                "</div><div>" +
                "/" +
                zong +
                "</div></div>"
              );
            }
          },
        },
        grid: {
          top: "20px",
          bottom: "-30px",
          right: "15%",
          left: "5%",
          containLabel: true, // gird 区域是否包含坐标轴的刻度标签
        },
        legend: {
          data: ["飞行助手", "民警", "辅警"],
          selectedMode: false, //取消图例上的点击事件
          right: "0%",
          top: "0px",
          x: "center",
          orient: "vertical",
          icon: "circle",
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
          itemGap: 15,
          orient: "horizontal",
        },
        animation: false,
        xAxis: [
          {
            show: false,
            type: "value",
          },
        ],
        yAxis: [
          {
            type: "category",
            data: xData,
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#ddd",
              },
              show: false,
            },
            interval: 1,
          },
        ],
        series: [
          {
            name: "内层波浪",
            type: "pictorialBar",
            silent: true,
            symbolSize: [0, 18],
            symbolOffset: [0, 12],
            z: 1,
            itemStyle: {
              normal: {
                color: "#363A76",
              },
            },
            data: yData,
          },
          {
            name: "飞行助手",
            type: "bar",
            stack: "person",
            barWidth: 30,
            z: 120,
            itemStyle: {
              //lenged文本
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                1,
                0,
                [
                  {
                    offset: 0,
                    color: "#1765FFFF", // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#1228E4FF", // 0% 处的颜色
                  },
                ],
                false
              ),
            },
            label: {
              show: true,
              position: "inside",
              formatter: () => {
                return this.dataPerson[0].value;
              },
            },
            data: barData,
          },
          {
            name: "民警",
            type: "bar",
            barWidth: 30,
            stack: "person",
            z: 120,
            itemStyle: {
              //lenged文本
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                1,
                0,
                [
                  {
                    offset: 0,
                    color: "#00E087FF", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#069257FF", // 100% 处的颜色
                  },
                ],
                false
              ),
            },
            label: {
              show: true,
              position: "inside",
              formatter: () => {
                return this.dataPerson[1].value;
              },
            },
            data: barData2,
          },
          {
            name: "辅警",
            type: "bar",
            barWidth: 30,
            stack: "person",
            z: 120,
            itemStyle: {
              //lenged文本
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                1,
                0,
                [
                  {
                    offset: 0,
                    color: "#FFB417FF", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#B9830CFF", // 100% 处的颜色
                  },
                ],
                false
              ),
            },
            label: {
              show: true,
              position: "inside",
              formatter: () => {
                return this.dataPerson[2].value;
              },
            },
            data: barData3,
          },
          {
            name: "最底层",
            type: "pictorialBar",
            silent: true,
            symbolSize: [8, 30],
            symbolOffset: [-4, 0],
            symbolPosition: "start",
            z: 13,
            color: "#1228E4FF",
            data: barData,
          },
          {
            name: "第二层",
            type: "pictorialBar",
            silent: true,
            symbolSize: [8, 30],
            symbolOffset: [4, 0],
            z: 13,
            color: "#1763FEFF",
            symbolPosition: "end",
            data: barData,
          },
          {
            name: "第三层",
            type: "pictorialBar",
            silent: true,
            symbolSize: [8, 30],
            symbolOffset: [4, 0],
            z: 13,
            color: "#00E087FF",
            symbolPosition: "end",
            data: [barData[0] + barData2[0]],
          },
          {
            name: "最顶层",
            type: "pictorialBar",
            silent: true,
            symbolSize: [8, 30],
            symbolOffset: [4, 0],
            z: 13,
            color: "#FFB417FF",
            symbolPosition: "end",
            data: [barData[0] + barData2[0] + barData3[0]],
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },

    cellClick(row, column, cell, event) {
      let { dataPerson } = this;
      switch (column.label) {
        case "单位名称":
          dataPerson[0].value = row.flyingHandCount;
          dataPerson[1].value = row.mingjingCount;
          dataPerson[2].value = row.fujingCount;
          this.chart.data(this.dataPerson);
          this.chart.render();
          break;
        case "飞行助手":
          this.$emit("handle_detail", row.id, 2);
          break;
        case "民警":
          this.$emit("handle_detail", row.id, 0);
          break;
        case "辅警":
          this.$emit("handle_detail", row.id, 1);
          break;
        default:
          break;
      }
      // if (column.label == '单位名称') {

      //   dataPerson[0].value = row.flyingHandCount
      //   dataPerson[1].value = row.mingjingCount
      //   dataPerson[2].value = row.fujingCount
      //   this.chart.data(this.dataPerson)
      //   this.chart.render()
      // }
    },
    async handle_click(e) {
      let { dataPerson } = this;
      dataPerson[0].value = e.flyingHandCount;
      dataPerson[1].value = e.mingjingCount;
      dataPerson[2].value = e.fujingCount;
      this.chart.data(this.dataPerson);
      this.chart.render();
    },
    async handle_search() {
      let { dataPerson } = this;
      dataPerson[0].value = 0;
      dataPerson[1].value = 0;
      dataPerson[2].value = 0;
      await this.getStatistics();
      // this.chart.data(this.dataPerson);

      // this.chart.render();
    },
    async getOptions() {
      let res = await API.DEPARTMENT.Drd();
      this.options = [res] || [];
    },
    async getStatistics() {
      let level;
      function eachTree(tree, depth = 0) {
        tree.forEach((item) => {
          item.depth = depth;
          level = depth + 1;
          if (item.child && item.child.length) {
            eachTree(item.child, depth + 1);
          }
        });
      }

      let { dataPerson } = this;
      let res = await API.PERSON.Statistics(this.filter);
      dataPerson[0].value = res[0].flyingHandCount;
      dataPerson[1].value = res[0].mingjingCount;
      dataPerson[2].value = res[0].fujingCount;

      eachTree(res);
      if (this.filter.organId == 1) {
        this.tableData = res[0].child;
      } else {
        this.tableData = res;
      }
      this.charts();
    },
    init(data, dataName) {
      const chart = (this.chart = new Chart({
        container: dataName,
        autoFit: true,
        height: 120,
      }));

      chart.data(data);

      chart.coordinate("theta", {
        radius: 0.75,
      });

      chart.tooltip({
        showTitle: false,
        showMarkers: false,
      });

      chart.legend({
        position: "left",
        itemName: {
          style: {
            fill: "#fff",
          },
        },
      });

      chart.interval().adjust("stack").position("value").color("name");

      chart.interaction("element-active");
      chart.render();
    },
  },
  beforeDestroy() {
    this.chart.destroy();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/person1027.scss";
.cpt-command-device-mount {
  // background: url("~@/assets/images/command/person2.png") no-repeat;
  // background-size: 100% 100%;
  width: 48.6% !important;
  // height: 486px;
  margin-top: 33px;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  // border: 1px solid rgba(26,92,246,0.5);
  backdrop-filter: blur(1px);
  border: 1px solid #315ec7;
  .head {
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;
    display: flex;
    .fontO {
      font-size: 20px;
      font-family: YouSheBiaoTiHei;
      color: #14faff;
      line-height: 26px;
      text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
      background: linear-gradient(
        135deg,
        #e3aa77 0%,
        #f5cda9 38%,
        #f9ecd3 58%,
        #fcdbb1 79%,
        #edb07a 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .divSC {
    display: flex;
    align-items: center;
    margin-left: 16px;
  }
  .search-box-statistics {
    display: flex;
  }
  .bd-box-chart {
    margin-left: 16px;
    width: 600px;
    div {
      width: 100%;
      // padding-top: 22px;
      box-sizing: border-box;
    }
  }
  .tb-bd-box {
    height: calc(100% - 206px) !important;
    .tb-tr {
      .td {
        width: calc(100% / 5);
      }
    }
  }
  .btn {
    background: url("~@/assets/images/command/fourbg.png") no-repeat;
    // background: #00e6fe;
    // border: 1px solid #fff;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #d2d9ff;
    line-height: 32px;
    text-align: center;
    width: 64px !important;
    height: 32px !important;
  }
  // .el-cascader {
  // width: auto;
  // }

  .search-box-statistics:hover .el-cascader {
    border-color: red !important;
  }

  ::v-deep {
    .el-table {
      margin-left: 16px;
      width: 96.5%;
      height: 320px;
      background: rgba(8, 26, 58, 0.8);
      border-radius: 4px;
      border: 1px solid #315ec7;
      thead {
        background: rgba(8, 26, 58, 0.8);
        border-radius: 4px;
        border: 1px solid #315ec7;
        .el-table__cell {
          background: transparent;
          font-size: 16px;
          font-family: YouSheBiaoTiHei;
          font-weight: normal !important;
          color: #ebf9ff;
          line-height: 22px;
          letter-spacing: 1px;
        }
      }
      // tbody tr:nth-child(odd){
      //   // background: rgba(8, 26, 58, 0.8);
      //   background-color: red;
      // }
      .tr {
        background-color: transparent;
      }
      // height: calc(100% - 217px);
      .el-table__header-wrapper {
        .el-table__header tr {
          z-index: 20;
          background-color: transparent !important;
        }
        .el-table__header tr th {
          // background-color: red;
          // font-size: 20px !important;
          // font-family: MicrosoftYaHei-Bold, MicrosoftYaHei !important;
          // font-weight: bold !important;
          // color: #00c6ff !important;
          // text-shadow: 0px 2px 4px #020118 !important;
          border: none;
        }
      }
      .el-table__body-wrapper {
        overflow-x: hidden;
        overflow-y: auto;
        // background: #263542;
        tr {
          background: transparent;
          font-size: 18px;
          // font-family: MicrosoftYaHei;
          color: #dce9ff;
          td {
            border: none;
          }
          &:hover {
            td {
              background: #263542;
            }
          }
        }
      }
    }
    el-table__row > td {
      border: none;
    }

    .el-table::before {
      height: 0px;
    }
  }
}
.cpt-command-device-common {
  padding: 0 !important;
  height: 439px;
  margin: 0px 0 24px 0;
}
.biaotou {
  margin: 16px 1% 0 0;
  width: 40%;
  height: 38px;
  white-space: nowrap;
}
.flex {
  display: flex;
  width: 100%;
}
.xbj {
  height: 1px;
  width: 100%;
  background: linear-gradient(270deg, rgba(0, 230, 254, 0) 0%, #00e6fe 100%);
}
.ti {
  // height: 100%;
  display: flex;
  margin: 5px 0 3px 0;
}
.tiao {
  width: 4px;
  height: 19px;
  background: #ffbf00;
  margin: 0 10px 0 0px;
}
.fontO {
  font-size: 20px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #00ffff;
  white-space: nowrap;
  overflow: hidden;
  // line-height: 26px;
}
/deep/ .el-button {
  font-family: MicrosoftYaHei !important;
  color: #dce9ff !important;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 4px !important;
}

.btn1027 {
  padding: 5px 20px;
  white-space: nowrap;
  margin-top: -3px;
  margin-left: 10px;
  height: 32px;
}




/deep/.zebra-row {
  background-color: #f8f8f8;
}
/deep/.el-popper{
  position: absolute;
  z-index: 99;
}


</style>
