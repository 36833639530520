<template>
  <div class="cpt-command-device-drone-popup">
    <div class="header" v-interact>
      <div class="hd-box">累计飞行里程</div>
      <el-cascader
        v-model="search.orgId"
        :options="options"
        :show-all-levels="false"
        placeholder="请选择单位"
        @change="get_list"
        clearable
        :props="{
          children: 'children',
          label: 'orgName',
          value: 'id',
          checkStrictly: true,
          emitPath: false,
        }"
      ></el-cascader>
      <div class="close_box" @click="() => $emit('handleClose')">关闭</div>
    </div>
    <div class="bd-box">
      <div class="videoBtn mt20 mb20 fr">
          <div
            v-for="(item,index) in videoBtnList"
            :class="search.sourceType == item.id ? 'videoBtn_active':'' "
            :key="index"
            @click="splitScreenFn(item)"
          >{{item.title}}</div>
        </div>
      <div class="tb-box">
        <div class="tb-hd-box">
          <div class="tb-hd">单位名称</div>
          <div class="tb-hd">无人机名称</div>
          <div class="tb-hd">任务名称</div>
          <div class="tb-hd">航线名称</div>
          <div class="tb-hd">飞行距离(km)</div>
        </div>
        <!-- <div class="tb-bd-box">
          <div class="tb-tr" v-for="item in list" :key="item.id">
            <div class="td">
              {{ item.orgName || '暂无' }}
            </div>
            <div class="td">
              {{ item.deviceName || '暂无' }}
            </div>
            <div class="td">
              {{ item.taskTitle || '暂无' }}
            </div>
            <div class="td">
              {{ item.flightName || '暂无' }}
            </div>
            <div class="td">
              {{ (item && (item.distance / 1000).toFixed(1)) || '暂无' }}
            </div>
          </div>
        </div> -->
        <ul
          class="tb-bd-box infinite-list"
          v-infinite-scroll="load"
          infinite-scroll-distance="80"
          infinite-scroll-disabled="infiniteDisabled"
          style="overflow: auto"
        >
          <li
            class="tb-tr infinite-list-item"
            v-for="item in list"
            :key="item.id"
          >
            <div class="td">
              {{ item.orgName || '暂无' }}
            </div>
            <div class="td">
              {{ item.deviceName || '暂无' }}
            </div>
            <div class="td">
              {{ item.taskTitle || '暂无' }}
            </div>
            <div class="td">
              {{ item.flightName || '暂无' }}
            </div>
            <div class="td">
              {{ (item && (item.distance / 1000).toFixed(1)) || '暂无' }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api'
import { mapGetters } from 'vuex'
export default {
  props: {
    orgId: {
      type: Number,
      default: () => 1,
    },
  },
  data() {
    return {
      list: [],
      options: [],
      infiniteDisabled: false,  //true结束滚动加载
      search: {
        sorted: 1,
        orgId: null,
        pageNum: 1,
        pageSize: 20,
        sourceType: 1
      },
      videoBtnList: [
        {
          title: "无人机",
          id: 1,
        },
        {
          title: "鹰巢",
          id: 2,
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['user_info']),
  },
  async mounted() {
    this.search.orgId = this.orgId
    await this.getOptions()
    // await this.get_list();
  },
  methods: {
    async getOptions() {
      let data = await API.DEPARTMENT.Drd()
      this.options = [data] || []
    },
    async load() {
      let res = await API.DEVICE.FlightStatisticsPopup(this.search)
      if (this.search.pageNum == 1) {
        this.list = res
      } else {
        res.forEach((item) => {
          this.list.push(item)
        })
      }
      if(res.length === 0){
        this.infiniteDisabled = true
      }
      this.search.pageNum++
    },
    splitScreenFn(item) {
      this.search.sourceType = item.id;
      this.get_list()
    },
    async get_list() {
      this.list = []
      this.infiniteDisabled = false
      this.search.pageNum = 1
      let res = await API.DEVICE.FlightStatisticsPopup(this.search)
      this.list = res || []
    },
  },
}
</script>

<style lang="scss" scoped>
.tdclass1 {
  color: #00b900 !important;
}
.tdclass3 {
  color: red !important;
}
.tdclass2 {
  color: yellow !important;
}
.tdclass5 {
  color: #b3bbc5 !important;
}
.cpt-command-device-drone-popup {
  width: 784px;
  height: 332px;
  box-sizing: border-box;
  position: absolute;
  top: 200px;
  left: 50%;
  margin-left: -392px;
  z-index: 10;
  background: rgba(16, 30, 62, 0.9);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 13px;
  .header {
    height: 44px;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;
  }
  .close_box {
    font-family: MicrosoftYaHei;
    font-size: 18px;
    color: #00ffff;
    position: absolute;
    right: 28px;
    top: 13px;
    opacity: 0.8;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
  ::v-deep {
    .el-cascader {
      width: 200px;
      position: absolute;
      right: 80px;
      top: 3px;
      .el-input__inner {
        // background: #000000;
        // border: 1px solid #00aeff;
        // font-family: MicrosoftYaHeiUI;
        // font-size: 16px;
        // color: #00aeff;
        background: transparent;
        height: 27px;

        border: 1px solid #00b6ff;
        box-shadow: inset 0 0 3px 0 #00b6ff;
        border-radius: 6px;

        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: rgba(0, 255, 255, 0.8);
      }
    }
  }
  .hd-box {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 700;
    line-height: 44px;
    padding-left: 20px;
  }
  .bd-box {
    height: calc(100% - 44px);
    box-sizing: border-box;
    padding: 0 10px 15px;
    display: flex;
    flex-direction: column;
    .videoBtn {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  background-color: #07173b;
  width: 30%;
  border-radius: 20px;
  border: 1px solid #2267cf;
  div {
    cursor: pointer;
    width: 100%;
    text-align: center;
    height: 30px;
    line-height: 30px;
    color: #fff;

    // border: 1px solid #999;
    border-radius: 20px;
    // margin-right: -1px;
  }
  .videoBtn_active {
    border-radius: 20px;
    color: #fff;
    // background-image: url("./img/videoBtn_active.png");
    background-image: url("../img/videoBtn_active.png");

    background-size: 100% 100%;
  }
}
    .tb-box {
      box-sizing: border-box;
      height: calc(100% - 50px);
      .tb-hd-box {
        display: flex;
        justify-content: space-between;
        height: 40px;
        .tb-hd {
          height: 100%;
          flex: 1;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          line-height: 52px;
          text-align: center;
          font-size: 16px;

          color: #00c6ff;
        }
      }
      .tb-bd-box {
        height: calc(100% - 40px);
        overflow: auto;
        .tb-tr {
          display: flex;
          box-sizing: border-box;
          &:hover {
            cursor: pointer;
            background: rgba(0, 34, 140, 0.2);
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 15px 0 #00b6ff;
            border-radius: 5px;
          }
          .td {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            padding: 10px 0;
            text-align: center;
            font-family: MicrosoftYaHei;
            word-break: break-word;
            box-sizing: border-box;
            font-size: 14px;
            color: #dce9ff;
            font-weight: 400;
          }
        }
      }
    }
  }
}
</style>
