<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
    >
      <el-form-item label="基站名称:" prop="name">
        <el-input
          v-model="ruleForm.name"
          clearable
          placeholder="请输入基站名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="基站型号:" prop="model">
        <el-input
            v-model="ruleForm.model"
            clearable
            placeholder="请输入基站型号"
          ></el-input>
      </el-form-item>
      <el-form-item label="基站位置:" prop="address">
        <el-input
          v-model="ruleForm.deviceHardId"
          clearable
          placeholder="请输入基站位置"
        ></el-input>
      </el-form-item>
      <el-form-item label="基站纬度" prop="latitude">
          <el-input
            v-model="ruleForm.latitude"
            clearable
            placeholder="请输入基站纬度"
          ></el-input>
        </el-form-item>
        <el-form-item label="基站经度:" prop="longitude">
          <el-input
            v-model="ruleForm.longitude"
            clearable
            placeholder="请输入基站经度"
          ></el-input>
        </el-form-item>
      <el-form-item label="基站入网ID:" prop="netId">
        <el-input
          v-model="ruleForm.netId"
          clearable
          placeholder="请输入基站入网ID"
          oninput="value=value.replace(/[^0-9]/g,'')"
        ></el-input>
      </el-form-item>
      <el-form-item label="基站状态:" prop="state">
        <el-radio-group v-model="ruleForm.state">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="3">维修</el-radio>
            <el-radio :label="2">损坏</el-radio>
          </el-radio-group>
      </el-form-item>
      <el-form-item label="异常信息:" prop="abnormalContent">
          <el-input
            v-model="ruleForm.abnormalContent"
            clearable
            placeholder="请输入异常信息"
          ></el-input>
        </el-form-item>
      <el-form-item label="配发状态:" prop="store">
        <el-radio-group v-model="ruleForm.store">
          <el-radio :label="2">入库</el-radio>
          <el-radio :label="1">出库</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="上传图片:" prop="image">
          <el-upload
            :action="url"
            :http-request="handle_http_request"
            :multiple="false"
            accept=".png, .jpg"
            :file-list="fileList"
            :on-change="changePic"
            :on-remove="onRemove"
          >
            <el-button size="small" type="primary" style="margin: 0"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip">图片支持jpg/png格式</div>
          </el-upload>
        </el-form-item>
      <el-form-item label="备注说明:" prop="comment">
        <el-input
          v-model="ruleForm.comment"
          clearable
          type="textarea"
          placeholder="请输入备注说明"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="btn-box1027">
      <div class="btn1027" @click="submit">提交</div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import formRules from '@/utils/formRules'
export default {
  props:['form'],
  data() {
    return {
      url: "",
      ruleForm: {
        state: 1,
        image: null,
        store: 2,
      },
      rules: {
        name: [
          {
            required: true,
            validator: formRules.validateBaseName,
            trigger: 'blur',
          },
        ],
        netId: [
          {
            required: true,
            message: '请输入基站入网ID',
            trigger: 'blur',
          },
        ],
      },
      options: [],
      fileList:[],
    };
  },
  methods: {
    /*机构 */
    async getOptions() {
      let res = await API.DEPARTMENT.Drd();
      // each(res);
      this.options = res || [];
    },
    async handle_http_request(e) {
      let file = e.file,
        fd = new FormData();
      fd.append("uploadFiles", file);
      let res = await API.MAPMANAGE.fileUpload(fd);
        this.ruleForm.image = res[0].url;
    },
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if(this.ruleForm.id){
            // if(res == 1){
              API.DEVICE.baseEdit().then(res => {
                this.$message.success("编辑成功!");
                this.$emit("closedevice");
              })
            // }
          }
          API.DEVICE.baseAdd(this.ruleForm).then((res) => {
            // if (res == 1) {
              this.$message.success("创建成功!");
              this.$emit("closedevice");
            // }
          });
        } else {
          return false;
        }
      });
    },
    changePic(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]; //这一步，是 展示最后一次选择文件
      }
    },
    onRemove() {
      this.ruleForm.image = null;
    },
  },
  mounted() {
    let baseUrl = process.env.VUE_APP_BASE_API;
    this.url = baseUrl + "/upload/v2_uploads";
    if(this.form != null){
      this.ruleForm = this.form
    }
    this.getOptions();
  },
};
</script>

<style lang="scss" scoped>
.btn {
  margin-left: 120px;
  width: 94px;
  height: 38px;
  text-align: center;
  line-height: 38px;
  background: rgba(23, 70, 216, 0.2);
  box-shadow: 0px 2px 4px 0px rgba(23, 33, 60, 0.5),
    inset 0px 0px 16px 0px rgba(33, 137, 255, 0.4),
    inset 0px 0px 4px 0px #00a7ff;
  border-radius: 6px;
  // border: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(138, 218, 255, 1),
      rgba(82, 179, 255, 0)
    )
    1 1;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #00d3ff;
}
.el-form-item {
  margin-bottom: 18px;
}
/deep/ .el-form-item__label {
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  color: #92d9ff;
  letter-spacing: 0;
  font-weight: 700;
}
/deep/ .el-select .el-input__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid #315EC7;
  width: 316px;
  height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}
/deep/ .el-cascader .el-input__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid #315EC7;
  width: 316px;
  height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}
/deep/ .el-textarea .el-textarea__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid #315EC7;
  width: 316px;
  // height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}
/deep/ .el-input .el-input__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid #315EC7;
  width: 316px;
  height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}
.el-select,
.el-input,
.el-cascader {
  width: 316px;
}
</style>
