<template>
  <div class="cpt-command-task-situation">
    <div class="search-box" :class="orgType == 1 ? '':'set'">
      <div class="flexs waikuang shijanSelect">
        <el-date-picker
          prefix-icon="ccc"
          v-model="startYear"
          type="year"
          :picker-options="StartPickerOptions"
          value-format="yyyy"
          placeholder="开始年度"
          popper-class="flight-log"
        >
        </el-date-picker>
        <el-date-picker
          prefix-icon="ccc"
          v-model="endYear"
          type="year"
          :picker-options="EndPickerOptions"
          value-format="yyyy"
          placeholder="结束年度"
          popper-class="flight-log"
        >
        </el-date-picker>
      </div>
      <div class="flexs waikuang shijanSelect">
        <el-date-picker
          prefix-icon="ccc"
          v-model="pickTime"
          type="daterange"
          range-separator="至"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          popper-class="flight-log"
        >
        </el-date-picker>
      </div>
      <div class="flexs waikuang" v-if="orgType == 1">
        <el-cascader
          class="input-orgnameMini"
          placeholder="请选择责任单位"
          v-model="search.orgId"
          :options="unitList"
          :show-all-levels="false"
          :props="{
            children: 'children',
            label: 'orgName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
          }"
          clearable
        ></el-cascader>
      </div>
      <!-- <div class="flexs waikuang">
        <el-select
          v-model="search.orgType"
          class="input-excute"
          placeholder="请选择单位类型"
          :popper-append-to-body="false"
        >
          <el-option label="派发单位" :value="0"></el-option>
          <el-option label="责任单位" :value="1"></el-option>
        </el-select>
      </div> -->
      <div @click="get_list(1)" class="btn1027">搜索</div>
      <div @click="handle_upload" v-if="orgType == 1" class="btn1027">导出</div>
      <div @click="changeorgType " v-if="orgType == 1" class="btn1027">返回</div>
    </div>
    <div class="tb-box" v-if="orgType == 0">
      <div class="tb-hd-box">
        <div class="tb-hd w150">排名</div>
        <div class="tb-hd">年度</div>
        <div class="tb-hd">单位名称</div>
        <div class="tb-hd">已派发警情数（件）</div>
        <div class="tb-hd">派发数（件）</div>
        <div class="tb-hd">无需派发数（件）</div>
        <div class="tb-hd last-tb-hd">派发率（%）</div>
        <!-- -->
      </div>
      <div v-if="!loading" class="tb-bd-box">
        <template v-if="list.length > 0">
          <div v-for="(item, index) in list" :key="index" class="hangse">
            <div class="tb-tr">
              <div class="td w150 ">{{ item.index || "暂无" }}</div>
              <div class="td">{{ item.yearTime || "暂无" }}</div>
              <div class="td">{{ item.orgName || "暂无" }}</div>
              <div class="td tdhover" @click="jumpRoute(item,null)">{{ item.total || 0 }}</div>
              <div class="td tdhover" @click="getChange">{{ item.dist || 0 }}</div>
              <div class="td tdhover" @click="jumpRoute(item,9)">{{ item.notDist || 0 }}</div>
              <div class="td tdhover jcsb last-td">{{ (item.distPercent*100).toFixed(2)+"%" || 0 }}</div>
            </div>
          </div>
        </template>
        <div v-else-if="!loading && list.length == 0" class="no-data">
          暂无数据
        </div>
      </div>
      <div v-else class="loading-data">
        <div>
          <i class="el-icon-loading"></i>
          <p>加载中...</p>
        </div>
      </div>
    </div>
    <div class="tb-box org" v-else>
      <div class="tb-hd-box">
        <!-- <div class="tb-hd-triangle"></div> -->
        <div class="tb-hd w150">排名</div>
        <div class="tb-hd">年度</div>
        <div class="tb-hd">单位名称</div>
        <div class="tb-hd">警情数（件）</div>
        <div class="tb-hd">已派发警情数（件）</div>
        <div class="tb-hd">已签收数（件）</div>
        <div class="tb-hd">拒签收数（件）</div>
        <div class="tb-hd">未签收数（件）</div>
        <div class="tb-hd">签收率（%）</div>
        <div class="tb-hd ">已完成数（件）</div>
        <div class="tb-hd last-tb-hd">完成率（%）</div>
      </div>
      <div v-if="!loading" class="tb-bd-box">
        <template v-if="list.length > 0">
          <div v-for="(item, index) in list" :key="index" class="hangse">
            <div class="tb-tr">
              <div class="td w150">{{ item.index || "暂无" }}</div>
              <div class="td">{{ item.yearTime || "暂无" }}</div>
              <div class="td">{{ item.orgName || "暂无" }}</div>
              <div class="td tdhover" @click="jumpRoute(item,null)">{{ item.total || 0 }}</div>
              <div class="td tdhover" @click="jumpRoute(item,6)">{{ item.dist || 0 }}</div>
              <div class="td tdhover" @click="jumpRoute(item,5)">{{ item.sign || 0 }}</div>
              <div class="td tdhover" @click="jumpRoute(item,7)">{{ item.rejection || 0 }}</div>
              <div class="td tdhover" @click="jumpRoute(item,8)">{{ item.noSign || 0 }}</div>
              <div class="td tdhover">{{ (item.signPercent*100).toFixed(2)+"%" || 0 }}</div>
              <div class="td jcsb tdhover" @click="jumpRoute(item,4)">{{ item.finish || 0 }}</div>
              <div class="td jcsb last-td tdhover">{{ (item.finishPercent*100).toFixed(2)+"%" || 0 }}</div>
            </div>
          </div>
        </template>
        <div v-else-if="!loading && list.length == 0" class="no-data">
          暂无数据
        </div>
      </div>
      <div v-else class="loading-data">
        <div>
          <i class="el-icon-loading"></i>
          <p>加载中...</p>
        </div>
      </div>
    </div>
    <div class="tb-pagination">
      <div class="zongji">
        <span>共</span>
        <span>{{ pagination.totalCount }}</span>
        <span>条</span>
      </div>
      <span class="douhao zongji">,</span>
      <div class="zongji dangqianye">
        <span>{{ pagination.pageNo }}</span>
        <span>/</span>
        <span>{{ zongye(pagination.totalCount) }}</span>
        <span>页</span>
      </div>
      <el-pagination
        small
        :page-sizes="[10, 20, 30, 40]"
        :current-page.sync="pagination.pageNo"
        :page-size="pagination.pageSize"
        layout="prev, pager, next"
        :total="pagination.totalCount"
        @size-change="handle_size_change"
        @current-change="handle_page_change"
      ></el-pagination>
      <div class="zhuan">
        <span>前往</span>
        <input type="text" v-model="qianVal" />
        <span>页</span>
      </div>
      <!-- 不知道page-change指向哪,只能直接拿过来用 -->
      <el-button class="btnqueding" @click="qunding">确定</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { downloadData } from "@/utils/download";

import API from "@/api";
export default {
  computed: {
    ...mapGetters(["user_info"]),
  },

  async mounted() {
    await this.get_list();
    await this.getUnitList();
  },
  beforeDestroy() {},
  data() {
    return {
      pickTime: null,
      startYear: "",
      endYear:"",
      StartPickerOptions:this.beginDate(),
      EndPickerOptions:this.processDate(),
      qianVal: "", //手动写的跳转,没有相应样式的组件
      orgType: 0,
      // 派发权限
      policeSituation: false,
      search: {
        orgType: 0,
        // orgId: null,
      },
      list: [],
      loading: false,
      unitList: [],
      pagination: {
        pageNo: null,
        pageSize: 10,
        totalCount: 0,
      },
    };
  },
  methods: {
    changeorgType(){
      this.orgType = 0
      this.search.orgType = 0
      this.get_list(1)
    },
    beginDate() {
      let _this = this
      return {
        disabledDate(time) {
          let fixedTime = new Date(time)
            let date = new Date()
          if (_this.endYear !== '' && _this.endYear !== null) {
            return fixedTime.getFullYear() > _this.endYear || date.getFullYear() < fixedTime.getFullYear()
          }else{
            return date.getFullYear() < fixedTime.getFullYear()
          }
        }
      }
    },
    // 提出结束时间必须大于提出开始时间
    processDate() {
      let _this = this
      return {
        disabledDate(time) {
          let date = new Date()
          let fixedTime = new Date(time)
          if(_this.startYear !== ''){
            return fixedTime.getFullYear() < _this.startYear || date.getFullYear() < fixedTime.getFullYear()
          }else{
            return date.getFullYear() < fixedTime.getFullYear()
          }
        }
      }
    },
    // 页码跳转
    qunding() {
      this.handle_page_change(this.qianVal);
    },
    // 计算总页码
    zongye(num) {
      let a = num / 10;
      var result = a.toString().indexOf(".");
      if (result != -1) {
        let b = parseInt(a);
        return b + 1;
      } else {
        return a;
      }
    },
    async getUnitList() {
      let data = await API.DEPARTMENT.Drd();
      this.unitList = [data] || [];
    },
    handle_upload() {
      let orgId = this.search.orgId ? "&orgId=" + this.search.orgId : "";
      let startTime = this.search.startTime
        ? "&startTime=" + this.search.startTime
        : "";
      let endTime = this.search.endTime
        ? "&endTime=" + this.search.endTime
        : "";
        let startYear = this.search.startYear ? "&startYear=" + this.search.startYear : ""
        let endYear = this.search.endYear ? "&endYear=" + this.search.endYear : ""
      downloadData(
        `/hawksystemserver/flightMission/statisticsTaskData/down?FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}&TaskType=1&orgType=${this.search.orgType}${orgId}${startTime}${endTime}${startYear}${endYear}`,
        "警情处置"
      );
    },
    getChange(){
      this.orgType = 1
      this.search.orgType = 1
      this.get_list(1)
    },
    async get_list(page) {
      this.orgType = this.search.orgType;
      if (this.pickTime) {
        this.search.startTime = this.pickTime[0];
        this.search.endTime = this.pickTime[1];
      } else {
        this.search.startTime = null;
        this.search.endTime = null;
      }
      if(this.startYear){
        this.search.startYear = this.startYear
        this.search.endYear = this.endYear
      }else{
        this.search.startYear = null
        this.search.endYear = null
      }
      if (page) {
        this.pagination.pageNo = page;
      }
      this.loading = true;
      let res = await API.EXAMINE.List({
        TaskType: 1,
        ...this.pagination,
        ...this.search,
      });

      console.log(res, "normal");
      this.list = res.policeTaskResponse.policeTaskDataResponses || [];
      this.list.forEach((val, i) => {
        val.index = (this.pagination.pageNo - 1) * 10 + i + 1;
      });
      // this.pagination.pageNo = pageNo;
      // this.pagination.pageSize = pageSize;
      if (this.orgType == 1) {
        this.pagination.totalCount = res.total;
      } else {
        this.pagination.totalCount = 1;
      }
      this.loading = false;
    },
    handle_size_change(size) {
      this.pagination.pageSize = size;
      this.get_list();
    },
    handle_page_change(page) {
      this.pagination.pageNo = page;
      this.get_list();
    },
    jumpRoute(item,val){
      let data = {
        ...item,
        status:val,
        orgType:this.orgType,
        active:1
      }
      this.$emit("jumpRoute",data)
    }
  },
};
</script>

<style lang="scss" scoped>
.cpt-command-task-situation {
  @import "~@/styles/flightTask.scss";
  height: calc(100% - 125px);

  .btn-submit {
    width: 64px !important;
    height: 32px !important;
    background-image: url("~@/assets/yingkong1027/btn_lan.png") !important;
    background-size: 100% 100% !important;
    font-size: 14px !important;
    font-family: MicrosoftYaHei !important;
    color: #d2d9ff !important;
    line-height: 32px !important;
    cursor: pointer;
  }

  .tb-box {
    padding: 0 !important;
    width: 1796px;
    height: 470px;
    background: rgba(8, 26, 58, 0.8);
    border-radius: 4px;
    border: 1px solid #315ec7;

    .tb-hd-box {
      width: 100%;
      height: 42px;
      background: #0c1f3d;

      .tb-hd {
        height: 100%;
        line-height: 42px;
        width: calc(100% / 5);
        white-space: nowrap;
        font-size: 16px;
        font-family: YouSheBiaoTiHei;
        color: #ebf9ff;
        letter-spacing: 1px;
        font-weight: 400;
      }
      .last-tb-hd {
        width: 220px !important;
      }
      // .tb-hd-triangle {
      //   width: 14px;
      //   background: #10181f;
      // }
    }
    .loading-data {
      font-size: 16px;
      width: 1790px;
      height: 245px;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      // top: 0;
      left: 20px;
      text-align: center;
      color: #fff;
      padding-top: 10%;
      // div{
      //   position: absolute;
      //   top: 50%;
      // }
    }
    .tb-bd-box {
      width: 100%;
      height: calc(100% - 42px);
      position: relative;
      .no-data {
        text-align: center;
        color: #fff;
        padding-top: 10%;
      }
      .hangse:nth-child(odd) {
        background: rgba(73, 135, 210, 0.2);
      }

      .hangse:nth-child(even) {
        background: #0c1f3d;
      }

      overflow-y: auto;

      .tb-tr {
        justify-content: space-between;
        .td {
          width: calc(100% / 5);
        }
        .tdhover{
          cursor: pointer;
          &:hover{
            color: #66e0ff;
          }
        }

        .last-td {
          width: 220px !important;

          .iconfont {
            margin-right: 7px;
          }
        }
      }

      // .child-box {
      //   .last-td {
      //     width: 220px !important;
      //   }
      //   .tb-hd-triangle {
      //     width: 20px;
      //   }
      // }
    }

    // .collapse {
    //   transform: rotate(90deg);
    //   transition: 0.5s;
    // }
  }
  .org {
    .tb-hd {
      width: calc(100% / 6);
    }
    .td {
      width: calc(100% / 6);
    }
  }
}

// ---------------------------------------------------------------------
.flexs {
  display: flex;
}

.waikuang {
  width: 170px;
  height: 32px;
  background: #02173d;
  border-radius: 2px;
  border: 1px solid #315ec7;
  align-items: center;
  margin: 0 16px 0 0;

  img {
    width: 20px;
    height: 20px;
    margin: 0 8px 0 8px;
  }
}
.shijanSelect {
  width: 370px;
}

.xian {
  width: 1px;
  height: 22px;
  border-left: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(67, 222, 255, 0),
      rgba(67, 222, 255, 1),
      rgba(67, 222, 255, 0)
    )
    1 1;
}

// 所有输入框样式
/deep/ .waikuang .input-name .el-input__inner {
  // height: 32px !important;
  background: rgba(13, 50, 92, 0) !important;
  border: 0px solid #43deff !important;
  align-items: center !important;
  padding-left: 15px !important;
}

/deep/ .waikuang .el-cascader .el-input__inner {
  height: 32px !important;
  background: rgba(13, 50, 92, 0) !important;
  border: 0px solid #43deff !important;
  align-items: center !important;
  padding-left: 15px !important;
}

/deep/ .waikuang .el-select .el-input__inner {
  // height: 32px !important;
  background: rgba(13, 50, 92, 0) !important;
  border: 0px solid #43deff !important;
  align-items: center !important;
  padding-left: 15px !important;
}

//时间选择器样式
/deep/ .waikuang .el-range-input {
  height: 32px !important;
}
/deep/.el-date-editor .el-range-input {
  background: rgba(2, 31, 51, 0.1);
  // width: 390px;
  color: #fff;
}

/deep/ .waikuang .el-date-editor {
  padding: 0;
  border: 0px solid;
  height: 32px;
  background: rgba(13, 50, 92, 0) !important;
}

// 页签样式
.zongji {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #b5e5ff;
  line-height: 16px;
}

.douhao {
  margin: 0 5px 0 5px;
}

.dangqianye {
  margin: 0 20px 0 0;
}

/deep/.el-pager .number:hover {
  border-radius: 2px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  background: rgba(38, 71, 238, 0.71) !important;
  width: 14px;
  height: 22px;
  line-height: 22px;
}

/deep/ .el-pagination--small .el-pager .number {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #889fb2;
}

/deep/.active {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  background: rgba(38, 71, 238, 0.71) !important;
}

.btnqueding {
  margin: 0 0 0 8px;
  width: 79px;
  // height: 32px;
  background-image: url("~@/assets/yingkong1027/btn_hei.png");
  background-size: 100% 100%;
  line-height: 5px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #43deff;
  border: 0px solid transparent;

  &:hover {
    opacity: 0.5 !important;
  }
}

.tb-pagination {
  margin: 24px 0 0px 0;
  display: flex;
  justify-content: end;
}

.zhuan {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #889fb2;
  line-height: 16px;

  input {
    width: 48px;
    min-width: 48px;
    max-width: auto;
    text-align: center;
    // height: 28px;
    background: rgba(12, 13, 20, 0.5);
    border-radius: 2px;
    border: 1px solid #b6d4ff;
    margin: 0 5px 0 5px;
    color: #fff;
    outline: 0px solid;
  }
}

// 顶部
.search-box {
  height: auto !important;
  margin: 12px 0 12px 0;
  margin-left: 0 !important;
  box-sizing: border-box;
  padding: 0 0 0 590px;
}
.set{
  padding-left: 860px;
}
.el-date-editor--year{
  /deep/.el-input__inner{
    border: none;
    height: 100%;
  }
}
</style>
