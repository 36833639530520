<template>
  <div class="cpt-command-device-mount cpt-command-device-common mb14">
    <div class="head pl8">
      <img src="~@/assets/images/mount/mount_head.png" />
      <div class="fontO">绩效排名</div>
    </div>
    <div class="command-header pr">
      <div class="search-box-rank">
        <div
          @click="handle_search(0)"
          class="btn1027 iconfont icon-danweipaiming"
          :class="isOrgan ? 'active' : ''"
        >
          单位排名
        </div>
        <div
          @click="handle_search(1)"
          :class="isOrgan ? '' : 'active'"
          class="btn1027 iconfont icon-gerenpaiming"
        >
          人员排名
        </div>
      </div>
    </div>
    <div class="bd-box">
      <div class="tb-box">
        <div class="tb-hd-box">
          <div class="tb-hd table">排名</div>
          <div class="tb-hd table">{{ isOrgan ? "单位名称" : "人员姓名" }}</div>
          <div class="tb-hd table">飞行总任务数</div>
          <div class="tb-hd table">飞行总时长</div>
          <div class="tb-hd table">飞行总里程</div>
          <div class="tb-hd table">最新飞行时间</div>
        </div>
        <div class="tb-bd-box">
          <div class="tb-tr" v-for="(item, index) in list" :key="index">
            <div class="td">
              {{ item.serial || "暂无" }}
            </div>
            <div class="td">
              {{ isOrgan ? item.anotherName : item.name || "暂无" }}
            </div>
            <div class="td" @click="$emit('handle_detail', item, isOrgan)">
              {{ item.totalCount || 0 }}
            </div>
            <div class="td">
              {{ formatSeconds(item.totalTime) || 0 }}
            </div>
            <div class="td">
              {{
                item.totalMileage == 0
                  ? 0
                  : (item.totalMileage / 1000).toFixed(1) + "km"
              }}
            </div>
            <div class="td">
              {{ item.endTime || "暂无" }}
            </div>
          </div>
        </div>
        <div class="tb-pagination">
          <el-pagination
            small
            :page-sizes="[10, 20, 30, 40]"
            :current-page.sync="filter.pageNo"
            :page-size="filter.pageSize"
            layout="total, prev, pager, next"
            :total="total"
            @current-change="handleSizeChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  data() {
    return {
      filter: {
        pageNo: 1,
        pageSize: 10,
        type: 2,
      },
      total: 0,
      list: [],
      isOrgan: true,
    };
  },

  mounted() {
    this.getRank();
  },

  methods: {
    open() {
      this.$emit("handle_detail");
    },
    handle_search(val) {
      this.list = [];
      if (val == 0) {
        if (!this.isOrgan) {
          this.filter.type = 2;
          this.filter.pageNo = 1;
          this.isOrgan = true;
        }
      } else {
        if (this.isOrgan) {
          this.filter.type = 1;
          this.filter.pageNo = 1;
          this.isOrgan = false;
        }
      }
      this.getRank();
    },
    async getRank() {
      let res = await API.PERSON.Rank(this.filter);
      this.list = res.data || [];
      this.list.forEach((item, n) => {
        item.serial = (this.filter.pageNo - 1) * this.filter.pageSize + n + 1;
        if (item.endTime) {
          item.endTime = item.endTime.split(" ")[0];
        }
      });
      this.total = res.totalCount;
    },
    handleSizeChange(pageNo) {
      this.getRank();
    },
    formatSeconds(value) {
      if (value == 0) {
        return 0;
      }
      let result = parseInt(value);
      // let h =
      //   Math.floor(result / 3600) < 10
      //     ? "0" + Math.floor(result / 3600)
      //     : Math.floor(result / 3600);
      // let m =
      //   Math.floor((result / 60) % 60) < 10
      //     ? "0" + Math.floor((result / 60) % 60)
      //     : Math.floor((result / 60) % 60);
      // let s =
      //   Math.floor(result % 60) < 10
      //     ? "0" + Math.floor(result % 60)
      //     : Math.floor(result % 60);
      let h = (result / 3600).toFixed(2);
      result = `${h}小时`;
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/person1027.scss";
.cpt-command-device-mount {
  height: 440px !important;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  backdrop-filter: blur(1px);
  width: 48.6% !important;
  border: 1px solid #315ec7;
  .head {
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;
    display: flex;
    .fontO {
      font-size: 20px;
      font-family: YouSheBiaoTiHei;
      color: #14faff;
      line-height: 26px;
      text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
      background: linear-gradient(
        135deg,
        #e3aa77 0%,
        #f5cda9 38%,
        #f9ecd3 58%,
        #fcdbb1 79%,
        #edb07a 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  // width: 707px;
  .command-header {
    display: flex;
    justify-content: space-between;
    margin: auto;
    .search-box-rank {
      display: flex;
      margin: 20px 0 20px 17px;
      .flagdw {
        background: rgba(20, 45, 92, 0.6);
        border: 0 solid #43deff;
        box-shadow: 1px 1px 2px 0 rgba(3, 16, 50, 0.5), inset 0 0 20px 0 #00b6ff;
        border-radius: 6px;
      }
    }
  }

  .td {
    width: calc(100% / 6);
  }
  .btn {
    background: url("~@/assets/images/command/person.png");
    background-size: 100%;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #d2d9ff;
    line-height: 33px;
    text-align: center;
    width: 92px !important;
    height: 33px !important;
    &:hover {
      background: url("~@/assets/images/command/twobg.png") no-repeat;
      background-size: 100% 100%;
    }
    span {
      margin-left: 2px;
    }
  }
  .act {
    background: url("~@/assets/images/command/twobg.png");
    background-size: 100%;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #d2d9ff;
    line-height: 33px;
    text-align: center;
    width: 92px !important;
    height: 33px !important;
  }
}
.cpt-command-device-mount .command-header .search-box-rank {
  overflow: hidden;
}
.table {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tb-hd {
  font-size: 16px !important;
  // font-family: MicrosoftYaHei-Bold, MicrosoftYaHei !important;
  font-weight: normal !important;
  color: #fff !important;
  text-shadow: 0px 2px 4px #020118 !important;
}
.cpt-command-device-common .bd-box .tb-box .tb-bd-box {
  height: 263px;
  overflow: auto;
}
.bd-box {
  margin-left: 16px;
  width: 96%;
  background: rgba(8, 26, 58, 0.8);
  border-radius: 4px;
  border: 1px solid #315ec7;
  box-sizing: border-box;
  .tb-hd-box{
    padding-top: 5px;
    box-sizing: border-box;
  }
}
.tb-bd-box::-webkit-scrollbar {
  width: 4px !important;
}

/deep/ .icon-danweipaiming {
  span {
    margin-left: 4px;
  }
}

.btn1027 {
  padding: 5px 8px;
}
</style>
