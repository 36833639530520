export function createPoint(viewer, { position, image, title }) {
  let point = viewer.entities.add({
    name: 'point',
    position,
    billboard: {
      image,
      width: 30,
      height: 30,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0),
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
    },
    label: {
      text: title,
      font: '14pt monospace',
      style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      outlineWidth: 2,
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      pixelOffset: new Cesium.Cartesian3(0.0, 32, 0.0),
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
    },
  })
  return point
}

export function createLine(
  viewer,
  { polyCenter, positions, width, color, title }
) {
  let line = viewer.entities.add({
    position: polyCenter,
    polyline: {
      positions,
      width,
      material: Cesium.Color.fromCssColorString(color),
    },
    label: {
      // @ts-ignore
      text: title,
      font: '14pt monospace',
      style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      outlineWidth: 2,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0),
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      pixelOffset: new Cesium.Cartesian2(0, 0),
    },
  })
  return line
}

export function createPolygon(
  viewer,
  { polyCenter, outlineColor, positions, title, color }
) {
  var polygon = viewer.entities.add({
    position: polyCenter,
    polygon: {
      hierarchy: positions,
      material: Cesium.Color.fromCssColorString(color),
      outline: true,
      outlineWidth: 1,
      outlineColor: Cesium.Color.fromCssColorString(outlineColor),
    },
    label: {
      text: title,
      font: '14pt monospace',
      style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      outlineWidth: 2,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      pixelOffset: new Cesium.Cartesian2(0, 0),
    },
  })
  return polygon
}
