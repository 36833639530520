<!-- 设备管理关闭cesium -->
<template>
  <div class="page-command-device">
    <Device />
  </div>
</template>

<script>
import data from "./data";
import methods from "./methods";
import Device from "@/components/command/device";

export default {
  data() {
    return data;
  },
  methods: {
    ...methods,
  },
  components: { Device },
  mounted() {
    // this.$store.commit("device/IS_SHOW", false);
    // this.$store.commit("device/TOOLBAR_IS_SHOW", false);
  },
  beforeDestroy() {
    // this.$store.commit("device/IS_SHOW", true);
    // this.$store.commit("device/TOOLBAR_IS_SHOW", true);
  },
};
</script>

<style lang="scss" scoped></style>
