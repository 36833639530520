<template>
  <div class="page-command-map-label-modal">
    <div class="title-box" v-interact>{{ title }}</div>
    <div class="bd">
      <div class="bd_t">
        <div class="bd_t_l">
          <img :src="img" alt="" class="img" />
        </div>
        <div class="bd_t_r">
          <div class="btn-wrap">
            <el-button
              type="primary"
              class="btn btn-update-position"
              @click="handle_update_position"
            ></el-button>
            <span>更新位置</span>
          </div>
          <div class="btn-wrap">
            <el-button
              type="primary"
              class="btn btn-screenshot"
              @click="handle_screenshot"
            ></el-button>
            <span>截图</span>
          </div>
          <div class="btn-wrap">
            <el-button
              type="primary"
              class="btn btn-fly"
              @click="handle_fly"
            ></el-button>
            <span>飞入</span>
          </div>
        </div>
      </div>
      <div class="bd_b">
        <el-form ref="form" :model="form" label-width="40px">
          <el-tabs v-model="activeName" class="tabs">
            <el-tab-pane label="基本信息" name="first">
              <el-form-item label="标题">
                <el-input v-model="form.name" @input="onChangeName"></el-input>
              </el-form-item>
              <el-form-item label="经度">
                <span>{{ lonlat.longitude }}</span>
              </el-form-item>
              <el-form-item label="纬度">
                <span>{{ lonlat.latitude }}</span>
              </el-form-item>
              <!-- <el-form-item :label="type === 'polyline' ? '长度' : '面积'" v-if="type !== 'point'">
                  <span>{{distance}} {{type === 'polyline' ? '米' : '平方米'}}</span>
                </el-form-item> -->
              <el-form-item label="长度" v-if="type === 'polyline'">
                <span>{{ distance }} 米</span>
              </el-form-item>
              <el-form-item label="面积" v-if="type === 'polygon'">
                <span>{{ area }} 平方米</span>
              </el-form-item>
              <el-form-item label="标签" v-if="tagData.length > 0">
                <el-select
                  v-model="form.tags"
                  multiple
                  filterable
                  placeholder="请选择标签"
                >
                  <el-option
                    v-for="item in tagData"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="地址">
                <el-input type="textarea" v-model="form.address"></el-input>
              </el-form-item>
              <!-- <el-form-item label="详情">
                <el-input type="textarea" v-model="form.desc"></el-input>
              </el-form-item> -->
            </el-tab-pane>
            <el-tab-pane label="样式设置" name="second">
              <el-form-item
                label="图标"
                v-if="type === 'point' && iconData.length > 0"
              >
                <el-select
                  v-model="form.icon"
                  placeholder="请选择图标"
                  @change="onChangeIcon"
                >
                  <el-option
                    v-for="item in iconData"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="线宽" v-if="type === 'polyline'">
                <el-select
                  v-model="form.width"
                  placeholder="请选择线宽"
                  @change="onChangeWidth"
                >
                  <el-option label="一" :value="1"></el-option>
                  <el-option label="二" :value="2"></el-option>
                  <el-option label="三" :value="3"></el-option>
                  <el-option label="四" :value="4"></el-option>
                  <el-option label="五" :value="5"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="颜色" v-if="type !== 'point'">
                <el-color-picker
                  v-model="form.color"
                  show-alpha
                  @change="onChangeColor"
                ></el-color-picker>
              </el-form-item>
              <!-- <el-form-item label="贴地" v-if="type !== 'point'">
                <el-select
                  v-model="form.isClampToGround"
                  placeholder="请选择是否贴地"
                  @change="onChangeClampToGround"
                >
                  <el-option label="否" :value="false"></el-option>
                  <el-option label="是" :value="true"></el-option>
                </el-select>
              </el-form-item> -->
            </el-tab-pane>
          </el-tabs>
          <el-form-item class="footer-btn">
            <el-button type="primary" @click="onSubmit">保存</el-button>
            <el-button @click="close">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
// import axios from 'axios'
import API from '@/api'
import Popup from '@/components/popup'
import PopupItem from '@/components/popup-item'
import {
  simpleCartesian3ToWGS84,
  positionToGeom,
  cartesian3ToWGS84,
} from '@/lib/cesium/utils'
import { labelEntities } from '../../common.js'
import * as turf from '@turf/turf'
import screenshot from '@/lib/cesium/Screenshot'
import dataURItoBlob from '@/lib/cesium/dataURItoBlob'
import DrawTool from '@/lib/cesium/drawTool'
import iconData from '@/lib/cesium/iconData'
import noPhoto from '@/assets/images/map-manage/no_photo.png'

let draw_tool

export default {
  components: {
    Popup,
    PopupItem,
  },
  data() {
    return {
      title: '标注',
      visible: false,
      closed: false,
      type: 'point',
      callback: null,
      tagData: [],
      iconData: iconData,
      activeName: 'first',
      form: {
        name: '',
        tags: [],
        desc: '',
        width: 3,
        // isClampToGround: 0,
        icon: 1,
        address: '',
        color: 'rgba(40, 126, 255, 0.7)',
      },
      lonlat: {},
      area: '',
      distance: '',
      color: 'rgba(40, 126, 255, 0.7)',
      positions: null,
      img: null,
      imgUrl: null,
    }
  },
  computed: {
    ...mapGetters(['current_label_marker_id', 'current_label_data']),
  },
  methods: {
    ...mapMutations({
      set_label_modal_visible(commit, bool) {
        commit('mapmanage/SET_LABEL_MODAL_VISIBLE', bool)
      },
      set_current_label_marker_id(commit, id) {
        commit('mapmanage/SET_CURRENT_LABEL_MARKER_ID', id)
      },
    }),
    init_draw() {
      let cesium_layer = this.g_cesium_layer()
      let viewer = cesium_layer.viewer()
      draw_tool = new DrawTool({
        viewer: viewer,
        hasEdit: true,
      })
    },
    async get_tag_list() {
      let res = await API.MAPMANAGE.GetTags()
      this.tagData = res
    },
    init_form() {
      const {
        title,
        drawType,
        positions,
        style,
        img,
        icon,
        tagList = [],
      } = this.current_label_data

      let tags = tagList.map((item) => {
        return item.id
      })
      this.img = this.get_full_url(img)
      this.form.name = title
      this.form.icon = parseInt(icon) || 1
      this.form.tags = tags

      if (style) {
        let styleObj = JSON.parse(style)
        this.form.color = styleObj.color || 'rgba(40, 126, 255, 0.7)'
        this.form.width = styleObj.width || 5
      }

      this.type = drawType

      if (positions) {
        this.position_to_form_info(positions)
      }
    },
    get_full_url(url) {
      // return url ? process.env.VUE_APP_BASE_URL + url : noPhoto
      return url
        ? process.env.VUE_APP_BASE_URL.replace(/[:][0-9]+$/g, '') + url
        : noPhoto
    },
    async position_to_form_info(positions) {
      let cesium_layer = this.g_cesium_layer()
      let viewer = cesium_layer.viewer()
      const center = Cesium.BoundingSphere.fromPoints(positions).center
      this.lonlat = simpleCartesian3ToWGS84(center, viewer)

      if (!this.form.address) {
        // const url = `https://restapi.amap.com/v3/geocode/regeo?location=${this.lonlat.longitude},${this.lonlat.latitude}&key=945c084635e272e8067d2082c3b6554c&radius=1000&extensions=all`
        // axios.get(url).then(res => {
        //   console.log(res.data.regeocode.formatted_address.length)
        //   if(res.data.regeocode.formatted_address.length > 0) {
        //     this.form.address = res.data.regeocode.formatted_address
        //     console.log(this.form)
        //   }
        // })

        let address = await API.MAP.Regeo({
          location: `${this.lonlat.longitude},${this.lonlat.latitude}`,
        })
        this.form.address = address
      }

      if (this.type === 'polyline') {
        this.distance = this.getSpaceDistance(positions).toFixed(2)
      } else if (this.type === 'polygon') {
        this.area = this.getSpaceArea(positions).toFixed(2)
      }
    },
    getSpaceDistance(positions) {
      let distance = 0
      for (let i = 0; i < positions.length - 1; i++) {
        distance += Cesium.Cartesian3.distance(positions[i], positions[i + 1])
      }
      return distance
    },
    getSpaceArea(positions) {
      if (!positions || positions.length < 1) return
      let cartographics = []
      for (let i = 0; i < positions.length; i++) {
        let cartesian3 = positions[i]
        let cartographic = Cesium.Cartographic.fromCartesian(cartesian3)
        cartographics.push([
          Cesium.Math.toDegrees(cartographic.longitude),
          Cesium.Math.toDegrees(cartographic.latitude),
        ])
      }
      if (!cartographics.length) return
      cartographics = cartographics.concat([cartographics[0]])
      let polygon = turf.polygon([cartographics])
      let area = turf.area(polygon)
      return area
    },
    clear_entity() {
      let cesium_layer = this.g_cesium_layer()
      let viewer = cesium_layer.viewer()
      let entity = labelEntities.add && labelEntities.add.entity
      viewer.entities.remove(entity)
    },
    close() {
      this.set_label_modal_visible(false)
      this.clear_entity()
    },
    onChangeName(val) {
      labelEntities[this.current_label_marker_id].entity.label.text = val
    },
    onChangeIcon(val) {
      let res = this.iconData.filter((item) => {
        return item.id == val
      })
      this.form.icon = val
      labelEntities[this.current_label_marker_id].entity.billboard.image =
        res[0].image
    },
    onChangeWidth(val) {
      if (this.type === 'polyline') {
        labelEntities[this.current_label_marker_id].entity.polyline.width = val
      }
    },
    onChangeColor(color) {
      if (this.type === 'polyline') {
        labelEntities[
          this.current_label_marker_id
        ].entity.polyline.material = Cesium.Color.fromCssColorString(color)
      } else if (this.type === 'polygon') {
        labelEntities[
          this.current_label_marker_id
        ].entity.polygon.material = Cesium.Color.fromCssColorString(color)
      }
    },
    handle_update_position() {
      let cesium_layer = this.g_cesium_layer()
      let viewer = cesium_layer.viewer()

      let { drawType } = this.current_label_data

      let entity = labelEntities[this.current_label_marker_id].entity

      draw_tool.startDraw({
        type: drawType,
        style: {},
        success: (evt, positions) => {
          // 绘制完成回调
          viewer.entities.remove(evt)
          if (drawType === 'point') {
            entity.position = positions[0]
          } else if (drawType === 'polyline') {
            let polyCenter = Cesium.BoundingSphere.fromPoints(positions).center
            entity.polyline.positions = positions
            entity.position = polyCenter
          } else if (drawType === 'polygon') {
            let polyCenter = Cesium.BoundingSphere.fromPoints(positions).center
            entity.polygon.hierarchy = positions
            entity.position = polyCenter
          }
          this.position_to_form_info(positions)
        },
      })
    },
    handle_screenshot() {
      let cesium_layer = this.g_cesium_layer()
      let viewer = cesium_layer.viewer()
      screenshot(viewer, async (img) => {
        if (img) {
          this.img = img.src
          this.imgUrl = await this.getfileUpload(img.src)
          document.body.style.cursor = 'default'
        }
      })
    },
    async getfileUpload(src) {
      const params = new FormData()
      const blobFile = dataURItoBlob(src)
      params.append('uploadFiles', blobFile)
      let res = await API.MAPMANAGE.fileUpload(params)
      return res[0].url
    },
    handle_fly() {
      let cesium_layer = this.g_cesium_layer()
      let viewer = cesium_layer.viewer()
      let entity = labelEntities[this.current_label_marker_id].entity
      viewer.flyTo(entity)
    },
    onSubmit() {
      let cesium_layer = this.g_cesium_layer()
      let viewer = cesium_layer.viewer()

      let style = {},
        wktType
      let { type, positions } = this.current_label_data
      if (type === 1) {
        wktType = 'Point'
      } else if (type === 2) {
        style.color = this.form.color
        style.width = this.form.width
        wktType = 'LineString'
      } else if (type === 3) {
        wktType = 'Polygon'
        style.color = this.form.color
      }
      const wgs84 = cartesian3ToWGS84(viewer, {
        type: wktType,
        positions,
      })
      const geom = positionToGeom(wgs84)
      style = JSON.stringify(style)

      let options = {
        title: this.form.name,
        tags: this.form.tags,
        address: this.form.address,
        icon: this.form.icon,
        img: this.imgUrl,
        type,
        geom,
        style,
      }

      this.up_marker(options)
    },
    async up_marker(options) {
      let key
      if (this.current_label_marker_id === 'add') {
        key = 'AddMarker'
      } else {
        key = 'UpMarker'
        options.markerId = this.current_label_marker_id
      }
      let res = await API.MAPMANAGE[key](options)
      this.$message({
        message: '保存成功',
        type: 'success',
      })
      if (this.current_label_marker_id === 'add') {
        labelEntities[res.markerId] = labelEntities.add
        labelEntities.add = null
      }
      this.close()
      this.set_current_label_marker_id('update')
    },
  },
  created() {
    this.init_form()
    this.init_draw()
    this.get_tag_list()
  },
  mounted() {},
  inject: ['g_cesium_layer'],
}
</script>

<style lang="scss" scoped>
.page-command-map-label-modal {
  position: absolute;
  left: 500px;
  top: 170px;
  width: 383px;
  height: 710px;
  box-sizing: border-box;
  background: url('~@/assets/images/plate/label_bg.png') no-repeat;
  background-size: 100% 100%;
  padding: 0 25px;
}
.title-box {
  font-family: PangMenZhengDao;
  font-size: 22px;
  color: #dce9ff;
  font-weight: 400;
  height: 56px;
  box-sizing: border-box;
  display: flex;
  line-height: 3.2;
  padding-left: 132px;
}
.bd {
  padding: 8px 20px;
  .bd_t {
    display: flex;
    border: 1px solid #2a3243;
    height: 114px;
    .bd_t_l {
      flex: 1;
      padding: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .bd_t_r {
      width: 85px;
      color: #d9dbdd;
      font-size: 12px;
      .btn-wrap {
        margin: 10px 0;
        .btn-update-position {
          background: #287eff
            url('~@/assets/images/map-manage/update-position.svg') center
            no-repeat;
        }
        .btn-screenshot {
          background: #287eff url('~@/assets/images/map-manage/screenshot.svg')
            center no-repeat;
        }
        .btn-fly {
          background: #287eff url('~@/assets/images/map-manage/fly.svg') center
            no-repeat;
        }
      }
      .btn {
        width: 24px;
        height: 24px;
        line-height: 24px;
        padding: 0;
        margin-right: 5px;
        vertical-align: middle;
      }
    }
  }
  // .bd_b {
  //   height: 480px;
  //   overflow-y: auto;
  // }
  ::v-deep {
    .el-color-picker {
      width: 100%;
    }
    .el-color-picker__trigger {
      width: 100%;
    }
    .el-tabs__content {
      max-height: 390px;
      overflow-y: auto;
    }
  }
}
.footer-btn {
  padding: 5px 0;
  background: #161d2d;
}
.tabs {
  margin: 0 20px;
  padding-top: 20px;
  color: #e0e1e5;
}
::v-deep {
  .el-tabs__nav-scroll {
    padding: 0 20px;
  }
  .el-tabs__nav {
    background: #242e43;
  }
  .el-tabs__item {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #e0e1e5;
    text-align: center;
  }
  .el-tabs__item.is-top:nth-child(2) {
    padding-left: 20px;
  }
  .el-tabs--top .el-tabs__item.is-top:last-child {
    padding-right: 20px;
  }
  .el-tabs__item.is-active {
    color: #ccedff;
    background: #287eff;
  }
  .el-tabs__nav-wrap::after {
    display: none;
  }
  .el-tabs__active-bar {
    display: none;
  }
  .el-form-item__label {
    color: #ccedff;
  }
  .el-input__inner {
    color: #ccedff;
    background: #000000;
    border: 1px solid #08c2d1;
  }
  .el-textarea__inner {
    color: #ccedff;
    background: #000000;
    border: 1px solid #08c2d1;
  }
}
</style>
