<template>
  <div class="cpt-command-situation-detail dialog1027">
    <div class="dialog-header" v-interact>
      <div class="dialog-header__icon" />
      <div class="dialog-header__title">任务详情</div>
      <div class="dialog-header__close" @click="flight_task_chage_view(1)">关闭</div>
    </div>
    <div class="dialog-content">
      <div class="dialog-content-item textarea">
        <span class="dialog-content__label">任务名称：</span>
        <div class="dialog-content__text">{{ flight_detail.taskTitle || '暂无' }}</div>
      </div>
      <div class="dialog-content-item">
        <span class="dialog-content__label">添加时间：</span>
        <div class="dialog-content__text">{{ flight_detail.addtime || '暂无' }}</div>
      </div>
      <div class="dialog-content-item">
        <span class="dialog-content__label">航线名称：</span>
        <div class="dialog-content__text">{{ flight_detail.airlineTitle || '暂无' }}</div>
      </div>
      <div class="dialog-content-item">
        <span class="dialog-content__label">飞手：</span>
        <div class="dialog-content__text">
          {{
            flight_detail.team && flight_detail.team.length
              ? flight_detail.team.join('、')
              : '暂无'
          }}
        </div>
      </div>

      <div class="dialog-content-item">
        <span class="dialog-content__label">警情执行状态：</span>
        <div class="dialog-content__text">{{ get_status(flight_detail) }}</div>
      </div>

      <div class="dialog-content-item">
        <span class="dialog-content__label">预计开始时间：</span>
        <div class="dialog-content__text">{{ flight_detail.startedAt || '暂无' }}</div>
      </div>
      <div class="dialog-content-item">
        <span class="dialog-content__label">责任单位：</span>
        <div class="dialog-content__text">{{ flight_detail.organizationTitle || '暂无' }}</div>
      </div>
      <div class="dialog-content-item">
        <span class="dialog-content__label">任务类型：</span>
        <div class="dialog-content__text">{{ flight_detail.taskTypeTitle || '暂无' }}</div>
      </div>
      <div class="dialog-content-item">
        <span class="dialog-content__label">任务场景：</span>
        <div class="dialog-content__text">{{ flight_detail.cenarioName || '暂无' }}</div>
      </div>
      <div class="dialog-content-item textarea">
        <span class="dialog-content__label">内容描述：</span>
        <div class="dialog-content__text">{{ flight_detail.remark || '暂无' }}</div>
      </div>
      <div class="dialog-content-item textarea">
        <span class="dialog-content__label">审批意见：</span>
        <div class="dialog-content__text">{{ flight_detail.approvalRemark || '暂无' }}</div>
      </div>
      <div class="btn-box1027">
        <div class="btn1027" @click="flight_task_chage_view(1)">关闭</div>
      </div>  
      
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {}
  },
  inject: ['flight_task_chage_view'],
  computed: {
    ...mapGetters([ 'flight_detail']),
  },
  mounted() {},
  methods: {
    get_status(item) {
      let val = item
      let text = ''
      if (val.applyStatus == 1 && val.papplyStatus == 1) {
        text = '审批通过'

        if (val.executeStatus == 0) {
          text = '待执行'
        } else if (val.executeStatus == 1) {
          text = '已完成'
        } else {
          text = '执行中'
        }
      } else if (val.applyStatus == 2 || val.papplyStatus == 2) {
        text = '审批不通过'
      } else {
        text = '待审批'
      }
      return text
    },
  },
}
</script>

<style lang="scss" scoped>
.cpt-command-situation-detail {
  position: absolute;
  width: 760px;
  top: 170px;
  left: 100px;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 44px;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;
  }

  .dialog-content{
    background: rgba(2, 16, 56, 0.7);
  }

  .hd-box {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 700;
    margin-left: 20px;
  }
.el-button {
  width: 114px;
  height: 40px;
  padding: 10px 20px;
 border: 0;
    opacity: 0.8;
    font-family: PangMenZhengDao;
    font-size: 22px;
    color: #00FFFF;
    text-align: center;
    font-weight: 400;
    width: 114px;
    height: 40px;
    padding: 10px 20px;
    background: rgba(0, 34, 140, 0.20);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.50),
      inset 0 0 15px 0 #00B6FF;
    border-radius: 4px;
  margin-left: 530px;
  margin-top: 20px;
}
.flight_detail_box {
  box-sizing: border-box;
  margin: auto;
  padding-top: 35px;
  font-family: MicrosoftYaHeiUI;
  font-size: 16px;
  color: #fff;
  width: 1050px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;


  .dialog-content-item {
    width: 46%;
    display: flex;
    line-height: 40px;
    margin-bottom: 23px;
    > span {
      margin-right: 16px;
      display: inline-block;
      width: 105px;
      text-align: right;
    }
    > div {
      box-sizing: border-box;
      width: 396px;
      // height: 40px;
      background: rgba(2,31,51,0.70);
border: 1px solid #06B6E0;
      padding: 0 15px;
    }
  }
  .textarea {
    div {
      padding-top: 10px;
      padding-bottom: 10px;
      height: 80px;
      line-height: normal;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
</style>
