<template>
  <div class="cpt-app-float-player">
    <div class="title-box" v-interact>
      <!-- <div class="title">{{ videoUrl && videoUrl.flightSortieName }}</div> -->
      <div class="iconfont icon-a-xiazai2px" @click="handle_click"></div>
      <div class="el-icon-close" @click="change_media_is_show(false)"></div>
    </div>
    <div class="video-box">
      <div class="cpt-player-webrtc">
        <!-- <Livenvrlayer :data="videoUrl" /> -->
        <video ref="videoRef" width="100%" height="100%" controls preload="auto">
          <source :src="video" type="video/mp4" />
          <!-- <source :src="video" type="video/ogg" /> -->
          暂不支持video标签
        </video>
      </div>
    </div>
  </div>
</template>

<script>
  import Livenvrlayer from '@/components/player/livenvr'



  export default {
    components: {
      Livenvrlayer,
    },
    data() {
      return {
        imgApi: process.env.VUE_APP_IMG_URL,
        videoUrl: { vUrl: "" },
        video: null
      }
    },
    inject: ['flight_task_chage_view', 'change_media_is_show'],
    props: ['historyVideo'],
    mounted() {
      this.video = this.imgApi + '/uploads'+this.historyImage.fileKey
      this.videoUrl = {
        ...this.historyVideo,
        vUrl: this.historyVideo.download_url
      }
      // let Media = this.$refs["videoRef"]
     
      // Media.play()
    },
    methods: {
      handle_click() {
        let url = this.video

        // 替换为livenvr的下载格式
        url = url.replace(/play/, 'download')

        const a = document.createElement('a')
        document.body.appendChild(a)
        a.style.display = 'none'
        a.href = url
        a.download = 'video.mp4'
        a.click()

        // fetch(url)
        //   .then((res) => res.blob())
        //   .then((blob) => {
        //     const a = document.createElement('a')
        //     document.body.appendChild(a)
        //     a.style.display = 'none'
        //     const url = window.URL.createObjectURL(blob)
        //     a.href = url
        //     a.download = '视频.mp4'
        //     a.click()
        //     document.body.removeChild(a)
        //     window.URL.revokeObjectURL(url)
        //   })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .cpt-app-float-player {
    width: 362px;
    height: 242px;
    box-sizing: border-box;
    position: absolute;
    top: 100px;
    background: #161d2d;
    border: 1px solid #178890;
    box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    overflow: hidden;
    z-index: 9999;

    .iconfont {
      color: #00aeff;
      font-size: 20px;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }

    .title-box {
      height: 40px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      position: relative;
      padding: 0 12px;

      .title {
        font-family: Microsoft YaHei;
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 400;
        width: calc(100% - 60px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .el-icon-close {
        color: #8aabd4;
        font-size: 16px;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        &:hover {
          color: #fff;
        }
      }
    }

    .video-box {
      height: calc(100% - 40px);
      box-sizing: border-box;
    }
  }

  .cpt-player-webrtc {
    height: 100%;
    width: 100%;
    background-color: #000;

    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
</style>