<template>
  <div class="cpt-command-device">
    <div class="dcbj"></div>
    <div class="bjk">
      <div class="box top">
        <div style="width: 49.635%">
          <div v-if="statistics == true">
            <Flight @clickChart="click_chart" />
          </div>
          <div v-if="status == true">
            <Status @clickChart="click_chartV2" />
          </div>
        </div>
           <!-- <Status @clickChart="click_chart" /> -->
        <div style="width: 49.5%">
          <div v-if="storageOfEquipment">
            <Library @handleChart="handle_chart" />
          </div>
          <div v-if="stateStatistics">
            <DeviceStatus @handleChart="handle_chart" />
          </div>
        </div>
      </div>
        <!-- <Library @handleChart="handle_chart" /> -->
        <!-- <DeviceStatus @handleChart="handle_chart" /> -->
      <div class="box center">
        <Common v-show="zhuangtai" ref="_zhuangtai" />
        <DroneAndNest v-show="uav" ref="_uav" />
      </div>
      <div class="box bottom">
        <div></div>
      </div>
      <LeftDronePopup
        :organId="organId"
        @handleClose="active = 0"
        v-if="active == 2"
        @handleClick="handle_click"
      />
      <DroneMountPopup
        @handleClose="isShow = false"
        v-if="isShow"
        :deviceId="deviceId"
      />
      <LeftNestPopup
        :organId="organId"
        @handleClose="active = 0"
        v-if="active == 1"
      />
      <LeftMountPopup
        :organId="organId"
        @handleClose="active = 0"
        v-if="active == 3"
      />
      <RightDronePopup
        :organId="organId"
        :state="state"
        @handleClose="active = 0"
        v-if="active == 4"
      />
      <RightNestPopup
        :organId="organId"
        :state="state"
        @handleClose="active = 0"
        v-if="active == 5"
      />
      <RightMountPopup
        :organId="organId"
        :state="state"
        @handleClose="active = 0"
        v-if="active == 6"
      />
      <RightBatteryPopup
        :organId="organId"
        :state="state"
        @handleClose="active = 0"
        v-if="active == 7"
      />

      <FlightCountPopup @handleClose="active = 0" :orgId="changeorganId" v-if="active == 8" />
      <FlightLengthPopup @handleClose="active = 0" :orgId="changeorganId" v-if="active == 9" />
      <FlightTimePopup @handleClose="active = 0" :orgId="changeorganId" v-if="active == 10" />

      <DroneLine
        :organId="changeorganId"
        :status_info="status_info"
        @handleClose="active = 0"
        v-if="active == 11"
      />
      <NestLine
        :status_info="status_info"
        @handleClose="active = 0"
        v-if="active == 12"
      />
      <FlightTask
        :organId="changeorganId"
        :status_info="status_info"
        @handleClose="active = 0"
        v-if="active == 13"
      />
    </div>
  </div>
</template>

<script>
import Flight from "./flight";
import Status from "./status";
import Library from "./library";
import DeviceStatus from "./device-status";
import Common from "./common";
import DroneAndNest from "./drone-and-nest";

// 无人机设备详情
import LeftDronePopup from "./library/dronePopup";
// 鹰巢设备详情
import LeftNestPopup from "./library/nestPopup";
// 挂载设备详情
import LeftMountPopup from "./library/mountPopup";


import DroneMountPopup from "./library/dronePopup/drone-mount-popup";

import RightDronePopup from "./device-status/dronePopup";
import RightNestPopup from "./device-status/nestPopup";
import RightMountPopup from "./device-status/mountPopup";
import RightBatteryPopup from "./device-status/batteryPopup";

import FlightCountPopup from "./flight/flight-count";
import FlightLengthPopup from "./flight/flight-length";
import FlightTimePopup from "./flight/flight-time";

import DroneLine from "./status/drone-line";
import NestLine from "./status/nest-line";
import FlightTask from "./status/flight-task";

export default {
  components: {
    Flight,
    Status,
    Library,
    DeviceStatus,
    Common,
    DroneAndNest,

    LeftDronePopup,
    LeftNestPopup,
    LeftMountPopup,

    DroneMountPopup,

    RightDronePopup,
    RightNestPopup,
    RightMountPopup,
    RightBatteryPopup,

    FlightCountPopup,
    FlightLengthPopup,
    FlightTimePopup,

    DroneLine,
    NestLine,
    FlightTask,
  },
  data() {
    return {
      active: 0,
      organId: null,
      state: null,
      isShow: false,
      deviceId: null,

      status_info: null,
      flight_info: null,
      //状态栏切换
      statistics: true,
      status: false,
      storageOfEquipment: true,
      stateStatistics: false,
      zhuangtai: true,
      uav: false,
      changeorganId: null,
    };
  },

  methods: {
    //无人机飞行统计和设备工作管理切换
    circle_diagram_status() {
      this.statistics = false;
      this.status = true;
    },
    circle_diagram_statistics() {
      this.statistics = true;
      this.status = false;
    },
    Equipment() {
      this.stateStatistics = true;
      this.storageOfEquipment = false;
    },
    stateTong() {
      this.stateStatistics = false;
      this.storageOfEquipment = true;
    },
    uav_zhuangtai(num) {
      this.zhuangtai = false;
      this.uav = true;
      if (num == "1") {
        this.$refs._uav.uav_guanl();
      } else {
        this.$refs._uav.yingchao();
      }
    },
    guanli(num) {
      this.zhuangtai = true;
      this.uav = false;
      if (num == "1") {
        this.$refs._zhuangtai.dianchi();
      } else if (num == "2") {
        this.$refs._zhuangtai.guazai();
      } else {
        this.$refs._zhuangtai.jizhan();
      }
    },
    click_chart(active, data) {
      this.active = active;
      this.changeorganId = data;
    },
    click_chartV2(active, data,orgId) {
      this.active = active;
      this.status_info = data;
      this.changeorganId = orgId
    },
    handle_chart(id, status, state) {
      // console.log(id,status,state) 1无人机 2鹰巢 3挂载
      this.organId = id;
      this.active = status;
      this.state = state;
    },
    handle_click(e) {
      this.deviceId = e;
      this.isShow = true;
    },
  },

  mounted() {},

};
</script>

<style lang="scss" scoped>
.dcbj{
  position: absolute;
  top:-90px;
  left: -15px;
  width: 102vw;
  height: 90vh;
  z-index: -99;
}
.cpt-command-device {
  background: url("~@/assets/images/command/device_bk.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 73px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  z-index: 9;

  .box {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  .center {
    box-sizing: border-box;
    width: 100%;
    margin: 14px 0 0 0;
    display: flex;
    // justify-content: space-between;
    padding: 16px;
    background: rgba(12,34,73,0.7);
    border-radius: 10px;
    border: 1px solid rgba(26,92,246,0.5);
    backdrop-filter: blur(1px);
  }
  // .bottom {
  //   background: url("~@/assets/images/device/new/drone.png") no-repeat;
  //   background-size: 100% 100%;
  //   width: 100%;
  //   height: 585px;
  //   padding: 35px 15px 35px 60px;
  //   box-sizing: border-box;
  // }
  .bjk {
    padding: 14px 10px 14px;
    display: flex;
    flex-direction: column;
  }
}
</style>

<style lang="scss">
.el-image-viewer__canvas {
  z-index: 999;
}
</style>
