<!-- 飞行任务1027 -->
<!-- 头部 -->
<template>
  <div class="cpt-command-flight-task">
    <div class="header" v-interact>
      <div class="flex">
        <div class="xian">
          <img
            src="~@/assets/yingkong1027/jinye.png"
            style="width: 100%; height: 100%"
            alt=""
          />
        </div>
        <div class="hd-box center">勤务考核</div>
      </div>
      <div class="fontOne cp mr20" @click="change_page">关闭</div>
    </div>
    <div class="task_nav">
      <div
        v-for="(item, index) in nav_list"
        :key="item.id"
        @click="(active = item.id)"
        class="task_nav_box"
        :class="active == item.id ? 'active' : ''"
      >
        <div>{{ item.name }}</div>
      </div>
    </div>
    <Normal
      @jumpRoute="(e)=>$emit('jumpRoute',e)"
      v-if="active == 1"
      class="nb16"
    />
    <Instance v-if="active == 2" class="nb16" @jumpRoute="(e)=>$emit('jumpRoute',e)" />
    <DutyAssessment v-if="active == 3" class="nb16" />
  </div>
</template>

<script>
import Normal from "./normal";
// 警情处置
import Instance from "./instance";
import DutyAssessment from "./duty-assessment";


export default {
  inject: ["change_page"],
  components: {
    Instance,
    Normal,
    DutyAssessment,
  },
  props: {
    examineActive:{
      type: Number,
      default: () => {}
    }
  },
  data() {
    return {
      active:1,
      nav_list:[
        {
        id: 1,
        name: "常态飞行"
        },
        {
        id: 2,
        name: "警情处置"
        },
        {
        id: 3,
        name: "假日勤务"
        }
      ]
    };
  },
  mounted() {
    this.active = this.examineActive
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.cpt-command-flight-task::v-deep {
  position: absolute;
  left: 10px;
  top: 99px;
  z-index: 0;

  width: 1820px;
  height: 630px;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
  
  .btn1027{
    padding: 7px 18px;
  }

  .tb-box .tb-bd-box .tb-tr .iconfont {
    color: rgba(0, 231, 255, 1);
    cursor: pointer;
  }

  .tb-box .tb-bd-box .tb-tr{
    cursor: initial;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;
  }

  .task_nav {
    position: absolute;
    left: 12px;
    top: 48px;
    display: flex;

    .task_nav_box {
      box-sizing: border-box;
      padding: 7px 8px;
      height: 32px;
      cursor: pointer;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2d9ff;
      color: #c1c1c1;
      display: flex;
      justify-content: center;
      align-content: center;
      background-image: url("~@/assets/yingkong1027/btn_hei.png");
      background-size: 100% 100%;
      margin: 0 14px 0 0;
      line-height: 20px;
      &:hover {
        background-image: url("~@/assets/yingkong1027/btn_lan.png");
        color: #fff;
      }
    }

    .active {
      background-image: url("~@/assets/yingkong1027/btn_lan.png");
      color: #fff;
    }
  }

  .hd-box {
    font-size: 20px;
    font-family: YouSheBiaoTiHei;
    color: #14faff;
    line-height: 26px;
    text-shadow:none !important;
    background: linear-gradient(
      135deg,
      #e3aa77 0%,
      #f5cda9 38%,
      #f9ecd3 58%,
      #fcdbb1 79%,
      #edb07a 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
  }
}
// ------------------------------------------
.flex {
  display: flex;
}
.xian {
  width: 26px;
  height: 26px;
  margin: 0px 0 0 0;
}
.center {
  margin-left: 5px !important;
  color: #ffffff !important;
  text-shadow: 0px 2px 4px #136791;
}
.img {
  width: 20px;
  height: 20px;
  margin: 0px 4px 0 0;
}
.nb16 {
  padding: 0 16px 0 16px;
}
.fontOne {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #d2dfff;
}
</style>