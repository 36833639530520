<!-- 飞行统计 -->
<template>
  <div class="cpt-command-device-mount cpt-command-device-common">
    <div class="head pl8">
      <img src="~@/assets/images/mount/mount_head.png" />
      <div class="fontO">飞行统计</div>
    </div>
    <div class="divSC">
      <div class="search-box-statistics">
        <el-cascader
          ref="cascaderRef"
          @change="CSshow"
          v-model="filter.organId"
          :options="options"
          clearable
          :show-all-levels="false"
          placeholder="请选择单位"
          :props="{
            children: 'children',
            label: 'orgName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
          }"
        ></el-cascader>
        <el-select class="h40 ml20" @change="onChangeType" clearable v-model="filter.groupType" placeholder="按需查询">
          <el-option :value="0" label="按单位查询"></el-option>
          <el-option :value="1" label="按分局查询"></el-option>
        </el-select>
        <el-date-picker  size="mini" prefix-icon="ccc" v-model="pickTime" type="datetimerange" range-separator="至"
            value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" popper-class="flight-log">
          </el-date-picker>
      </div>
      <div class="btn">
        <div @click="handle_search" class="btn1027">搜索</div>
        <div @click="handle_export" class="btn1027">导出</div>
      </div>
    </div>
    <div class="show">
      <div class="mile">
        <div class="num">{{flyData ? flyData.flyDistanceTotal : 0}}</div>
        <div class="type">飞行里程（km）</div>
      </div>
      <div class="mile">
        <div class="num">{{flyData ? flyData.flyDurationTotal : 0}}</div>
        <div class="type">飞行时长（h）</div>
      </div>
      <div class="mile">
        <div class="num">{{flyData ? flyData.flySortiesTotal : 0}}</div>
        <div class="type">飞行架次（次）</div>
      </div>
      <div class="mile">
        <div class="num">{{flyData ? flyData.taskTotal : 0}}</div>
        <div class="type">任务量</div>
      </div>
    </div>
    <div  class="flex ml16 mt8" v-if="groupType">
      <div class="item" :class="parentOrgName == item.parentOrgName? 'active':''" v-for="(item,i) in list" :key="item.parentOrgName" @click="getPorgname(i)">{{ item.parentOrgName }}</div>
    </div>
    <div class="bd-box mt8" v-if="!groupType">
      <div class="tb-box">
        <div class="tb-hd-box">
          <div class="tb-hd table serial">序号</div>
          <div class="tb-hd table serial">单位名称</div>
          <div class="tb-hd table serial last-th">里程<div>
              <span class="el-icon-caret-top" @click="score(0)"></span
              ><span class="el-icon-caret-bottom" @click="desc(0)"></span>
            </div></div>
          <div class="tb-hd table serial last-th">时长<div>
              <span class="el-icon-caret-top" @click="score(1)"></span
              ><span class="el-icon-caret-bottom" @click="desc(1)"></span>
            </div></div>
          <div class="tb-hd table serial last-th">架次<div>
              <span class="el-icon-caret-top" @click="score(2)"></span
              ><span class="el-icon-caret-bottom" @click="desc(2)"></span>
            </div></div>
          <div class="tb-hd table serial last-th">任务量<div>
              <span class="el-icon-caret-top" @click="score(3)"></span
              ><span class="el-icon-caret-bottom" @click="desc(3)"></span>
            </div></div>
        </div>
        <div class="tb-bd-box">
          <div class="tb-tr" v-for="(item, index) in tableData" :key="index">
            <div class="td w80">
              {{ item.serial || "暂无" }}
            </div>
            <div class="td w300">
              {{ item.orgName || "暂无" }}
            </div>
            <div class="td w120">
              {{ item.flyDistance || 0 }}
            </div>
            <div class="td w120">
              {{ item.flyDuration || 0 }}
            </div>
            <div class="td w120">
              {{
                item.flySorties || 0
              }}
            </div>
            <div class="td w120">
              {{
                item.taskTotal || 0
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-table
      v-else
      :data="tableData"
      :header-cell-style="{ background: 'transparent' }"
      row-key="id"
      :cell-style="rowClassName"
      :span-method="objectSpanMethod"
      max-height="327"
    >
      <!-- <el-table-column
        align="center"
        prop="serial"
        label="序号"
        width="80"
      ></el-table-column> -->
      <el-table-column
        align="center"
        prop="prefName"
        label="所属分局"
        width="120"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="orgName"
        label="单位名称"
        width="180"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="flyDistance"
        label="里程"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="flyDuration"
        label="时长"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="flySorties"
        label="架次"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="taskTotal"
        label="任务量"
      ></el-table-column>
    </el-table>
    <div class="tb-pagination">
          <el-pagination
            small
            :page-sizes="[10, 20, 30, 40]"
            :current-page.sync="filter.pageNo"
            :page-size="filter.pageSize"
            layout="total, prev, pager, next"
            :total="total"
            @current-change="handleSizeChange"
          >
          </el-pagination>
        </div>
  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tableData: [],
      list: [],
      pickTime: null,
      filter: {
        organId: null,
        groupType: 0,
        queryType: 1,
        pageNo: 1,
        pageSize:10
      },
      groupType:0,
      options: [],
      flyData: null,
      total: 0,
      list:[],
      parentOrgName:null
    };
  },

  async mounted() {
    this.filter.organId = this.user_info.departmentId;
    await this.getOptions();
    await this.getStatistics();
    var div = document.querySelectorAll(".el-table__row--level-0")
    div.forEach((v,i)=>{
      if(i % 2 !== 1){
        v.style.backgroundColor = "rgba(73,135,210,0.2)"
      }
    })
  },

  computed: {
    ...mapGetters(["user_info"]),
  },

  methods: {
    handleSizeChange(pageNo) {
      this.getStatistics(pageNo);
    },
    onChangeType(e){
      console.log(e,"eeeeeeee");
    },
    rowClassName({ row, rowIndex }) {
      // 判断是否为偶数行
      if (rowIndex % 2 === 1) {
        return "zebra-row";
      }
      return "";
    },
    CSshow() {
      // console.log(this.$refs.cascaderRef.dropDownVisible,'refffffff')
      this.$nextTick(() => {
        this.$refs.cascaderRef.dropDownVisible = false;
      });
    },
    //表格行颜色
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex % 2 == 1) {
        return "background:#081A3A;";
      } else {
        return "background:rgba(73,135,210,0.2);";
      }

    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) {
          if (rowIndex % 6 === 0) {
            return {
              rowspan: this.tableData.length,
              colspan: 1
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
    },
    getPorgname(i){
      this.parentOrgName = this.list[i].parentOrgName;
      this.tableData = this.list[i].childUnitFlyDataList
      this.tableData.forEach((item, n) => {
        item.serial = (this.filter.pageNo - 1) * this.filter.pageSize + n + 1;
      });
    },
    async handle_search() {
      await this.getStatistics();
    },
    async handle_export() {
      let groupType = this.filter.groupType == 0 || this.filter.groupType ? "&groupType="+this.filter.groupType : ""
      let organId = this.filter.organId ? "&organId="+this.filter.organId : ""
      let time =  this.pickTime ? "&startDate="+this.pickTime[0]+"&endDate="+this.pickTime[1] : ""
      let url = `${process.env.VUE_APP_BASE_URL}/hawksystemserver/bigScreen/exportDataTotal?FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}${organId}${groupType}${time}`;
      window.open(url);
    },
    async getOptions() {
      let res = await API.DEPARTMENT.Drd();
      this.options = [res] || [];
    },
    score(val){
      this.filter.sortKey = val
      this.filter.sortType = 1
      this.getStatistics()
    },
    desc(val){
      this.filter.sortKey = val
      this.filter.sortType = 0
      this.getStatistics()
    },
    async getStatistics(pageNo) {
      this.groupType = this.filter.groupType
      if (this.pickTime) {
        this.filter.startDate = this.pickTime[0];
        this.filter.endDate = this.pickTime[1];
      } else {
        this.filter.startDate = null;
        this.filter.endDate = null;
      }
      if(this.filter.groupType){
        delete this.filter.pageNo
        delete this.filter.pageSize
        // delete this.filter.organId
      }else{
        this.filter.pageNo = pageNo || 1
        this.filter.pageSize = 10
        this.filter.organId = this.user_info.departmentId;
      }
      let res = await API.TABLEEXPORT.dataTotal(this.filter)
      this.flyData = res.data
      if (this.filter.groupType) {
        this.list = JSON.parse(JSON.stringify(res.data.groupUnitDataList))
        this.tableData = this.list[0].childUnitFlyDataList      
        this.total = this.tableData.length
        this.parentOrgName = this.list[0].parentOrgName
      } else {
        this.tableData = res.data.unitDataTotalResponseList
        this.total = res.totalCount
      }
      this.tableData.forEach((item, n) => {
        item.serial = (this.filter.pageNo - 1) * this.filter.pageSize + n + 1;
      });
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
@import "~@/styles/person1027.scss";
.cpt-command-device-mount {
  width: 48.6% !important;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  backdrop-filter: blur(1px);
  border: 1px solid #315ec7;
  .head {
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;
    display: flex;
    .fontO {
      font-size: 20px;
      font-family: YouSheBiaoTiHei;
      color: #14faff;
      line-height: 26px;
      text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
      background: linear-gradient(
        135deg,
        #e3aa77 0%,
        #f5cda9 38%,
        #f9ecd3 58%,
        #fcdbb1 79%,
        #edb07a 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .divSC {
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 16px;
  }
  .search-box-statistics {
    display: flex;
    align-items: center;
  }
  .btn {
    display: flex;
    align-items: center;
  }
  .el-cascader {
  width: auto;
  height: 40px;
  margin-top: 0px;
  }
  .el-select{
    display: flex;
    align-items: center;
  }

  .search-box-statistics:hover .el-cascader {
    border-color: red !important;
  }
  .show{
    display: flex;
    justify-content: space-around;
    align-items: center;
    .mile{
      display: flex;
      flex-direction: column;
      align-items: center;
      .num{
        color: crimson;
        font-size: 18px;
        line-height: 36px;
      }
      .type{
        color: #fff;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
  .td {
    // width: calc(100% / 6);
  }
  .item{
    padding: 5px;
    color: #fff;
    
  }
  .active{
  background-image: url("~@/assets/yingkong1027/btn_active.png");
  background-size: 100% 100%;
  border-color: RGBA(38, 60, 136, 1);
  }
  .bd-box {
  margin-left: 16px;
  width: 96%;
  background: rgba(8, 26, 58, 0.8);
  border-radius: 4px;
  border: 1px solid #315ec7;
  box-sizing: border-box;
  .tb-box{
    height: 327px !important;
  }
  .tb-hd-box {
    padding-top: 5px;
    box-sizing: border-box;
  }
  .tb-bd-box{
    height: calc(100% - 48px) !important;
  }
}
.tb-bd-box::-webkit-scrollbar {
  width: 4px !important;
}
.serial{
  flex: none !important;
  width: 125px;
  justify-self: center;
}
  .serial:nth-of-type(1){
    width: 80px;
  }
  .serial:nth-of-type(2){
    width: 310px;
  }
  .serial:nth-of-type(3){
    // width: 180px;
  }
  .last-th {
  display: flex;
  justify-content: center;
}


  ::v-deep {
    .el-table {
      margin-top: 16px;
      margin-left: 16px;
      width: 96.5%;
      height: 285px;
      background: rgba(8, 26, 58, 0.8);
      border-radius: 4px;
      border: 1px solid #315ec7;
      thead {
        background: rgba(8, 26, 58, 0.8);
        border-radius: 4px;
        border: 1px solid #315ec7;
        .el-table__cell {
          background: transparent;
          font-size: 16px;
          font-family: YouSheBiaoTiHei;
          font-weight: normal !important;
          color: #ebf9ff;
          line-height: 22px;
          letter-spacing: 1px;
        }
      }
      // tbody tr:nth-child(odd){
      //   // background: rgba(8, 26, 58, 0.8);
      //   background-color: red;
      // }
      .tr {
        background-color: transparent;
      }
      // height: calc(100% - 217px);
      .el-table__header-wrapper {
        .el-table__header tr {
          z-index: 20;
          background-color: transparent !important;
        }
        .el-table__header tr th {
          // background-color: red;
          // font-size: 20px !important;
          // font-family: MicrosoftYaHei-Bold, MicrosoftYaHei !important;
          // font-weight: bold !important;
          // color: #00c6ff !important;
          // text-shadow: 0px 2px 4px #020118 !important;
          border: none;
        }
      }
      .el-table__body-wrapper {
        overflow-x: hidden;
        overflow-y: auto;
        // background: #263542;
        tr {
          background: transparent;
          font-size: 18px;
          // font-family: MicrosoftYaHei;
          color: #dce9ff;
          td {
            border: none;
          }
          &:hover {
            td {
              background: #263542;
            }
          }
        }
      }
    }
    el-table__row > td {
      border: none;
    }

    .el-table::before {
      height: 0px;
    }
  }
}
.cpt-command-device-common {
  padding: 0 !important;
  height: 509px;
}
.flex {
  display: flex;
  width: 100%;
}
.fontO {
  font-size: 20px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #00ffff;
  white-space: nowrap;
  overflow: hidden;
  // line-height: 26px;
}
/deep/ .el-button {
  font-family: MicrosoftYaHei !important;
  color: #dce9ff !important;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 4px !important;
}

.btn1027 {
  padding: 5px 20px;
  white-space: nowrap;
  margin-left: 10px;
  height: 32px;
  margin-right: 0 !important;
}
/deep/.zebra-row {
  background-color: #f8f8f8;
}
/deep/.el-popper{
  position: absolute;
  z-index: 99;
}
/deep/ .el-date-editor {
  margin-left: 16px;
  padding: 0;
  border: 0px solid;
  background: rgba(13, 50, 92, 0) !important;
}
/deep/ .el-date-editor .el-range-input {
  background: rgba(2, 31, 51, 0.1);
  width: 390px;
  color: #fff;
}
</style>
