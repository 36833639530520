<template>
  <div class="image">
    <div class="list" v-for="item in type" :key="item.id">
      <div class="address" @click="abc(item, item.id)">{{ item.name }}</div>
      <div class="kuang" :class="{ active: item.id == numId }">
        <div class="xian"></div>
        <!-- <Item
        v-for="item2 in item.serviceMaps"
        :key="item2.id"
        :data="item2"
      /> -->
        <el-tree
          ref="refTree"
          :data="permissionData"
          :props="{ label: 'name' }"
          :default-expand-all="true"
          :check-strictly="true"
          icon-class="el-icon-arrow-down"
          row-key="id"
          :expand-on-click-node="false"
          node-key="id"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label, }}</span>
            <span v-if="data.pid == 8">
              <span class="times">{{ data.addtime }}</span>
              <el-button
                type="text"
                size="mini"
                @click="() => append(node,data)"
                class="el-icon-view icons"
              >
              </el-button>
            </span>
          </span>
        </el-tree>
      </div>
    </div>
  </div>
</template>

<script>
import Item from "./item";
import API from "@/api";

export default {
  components: {
    Item,
  },
  data() {
    return {
      organizationId: null,
      type: [],
      numId: "", //控制那个打开
      permissionData: [], //递归数组
    };
  },
  methods: {
    // 小眼睛点击功能
    append(a,obj){
      console.log(a,'999');
    },
    abc(obj, id) {
      this.permissionData = obj.serviceMaps;
      console.log(obj, "???");
      this.numId = id;
    },
    async getMapData() {
      let res = await API.SERVE.List();
      this.type = res;
      console.log(res, "resres");
    },
  },
  mounted() {
    this.getMapData();
  },
};
</script>

<style lang="scss" scoped>
.image {
  // height: 100%;
  padding-bottom: 10px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  .list {
    // height: 68px;
    margin: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    background-image: linear-gradient(
      270deg,
      rgba(6, 37, 83, 0) 0%,
      rgba(1, 20, 49, 0.7) 51%,
      rgba(6, 37, 83, 0) 100%
    );
    box-shadow: inset 0 3px 10px 0 rgba(0, 182, 255, 0.37);
    .address {
      width: 424px;
      height: 68px;
      line-height: 68px;
      margin-left: 30px;
      font-family: MicrosoftYaHei-Bold;
      font-size: 20px;
      color: #11bce6;
      letter-spacing: 0;
      font-weight: 700;
      cursor: pointer;
    }
    .right {
      background: rgba(6, 23, 56, 0.6);
      border: 1px solid #00ffff;
      box-shadow: 1px 1px 2px 0 rgba(3, 16, 50, 0.5);
      border-radius: 4px;
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.5;
      span {
        color: #00ffff;
      }
    }
  }
}
.kuang {
  width: 424px;
  height: 255px;
  // background-image: linear-gradient(270deg, rgba(7, 110, 184, 0) 1%);
  // background-image: linear-gradient(
  //   270deg,
  //   rgba(6, 37, 83, 0) 0%,
  //   rgba(1, 20, 49, 0.7) 51%,
  //   rgba(6, 37, 83, 0) 100%
  // );
  // box-shadow: inset 0 3px 10px 0 rgba(0, 182, 255, 0.37);
  display: none;
  overflow-y: auto;
}
.xian {
  width: 100%;
  height: 2px;
  background-image: linear-gradient(
    270deg,
    rgba(7, 110, 184, 0) 1%,
    rgba(37, 154, 237, 0.5) 49%,
    rgba(7, 110, 184, 0) 100%
  );
}
.active {
  display: block;
}
.el-tree {
  background-color: transparent;
  color: #fff;
}
/deep/ .el-tree-node__content:hover {
  box-shadow: inset 0 0 20px 0 rgba(0, 182, 255, 0.81);
  background-color: transparent;
}
/deep/ .el-tree-node__content {
  margin: 2px 5px;
  padding: 5px 0;
}
/deep/ .el-tree-node:focus > .el-tree-node__content {
  box-shadow: inset 0 0 20px 0 rgba(0, 182, 255, 0.81);
  background-color: transparent;
}
.icons {
  font-size: 20px;
}
/deep/ .custom-tree-node {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/ .el-button--mini{
  margin: 0 0 0 24px;
}
.times{
  font-size: 12px;
  color: #f8f8f8;
}
</style>