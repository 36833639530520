<template>
  <div class="cpt-nest-list-item">
    <div class="nest-group_box">
      <div class="group-title_box">
        <div class="title-box">
          <span
            @click="data.collapse = !data.collapse"
            class="iconfont el-icon-caret-right"
            :class="{ collapse: data.collapse }"
          ></span>
          <span :title="data.name" class="title">{{ data.name }}</span>
        </div>
        <div class="line-box">
          （ <span class="online">{{ data.online }}</span
          >/<span class="offline">{{ data.offline }}</span> ）
        </div>
      </div>
    </div>
    <div class="nest-children" :class="{ collapse: data.collapse }">
      <template v-if="data && data.nestList && data.nestList.length">
        <div class="nest-device-list">
          <div
            v-for="device in data.nestList"
            :key="`device_${device.id}`"
            class="nest-device-item"
            :class="{ online: device.online === 1 }"
          >
            <div class="title-box">
              <el-tooltip
                :content="device.online === 1 ? '在线' : '离线'"
                placement="top"
              >
                <el-checkbox
                  :disabled="device.online !== 1"
                  :value="curr && curr.code && curr.code === device.code"
                  @change="(e) => itemClick(e, device)"
                >
                </el-checkbox>
              </el-tooltip>
              <span :title="device.name" class="title">{{ device.name }}</span>
            </div>
            <div class="icon-box">
              <el-popover
                v-if="device.warnList && device.warnList.length > 0"
                placement="right"
                width="400"
                trigger="hover"
              >
                <div style="display: flex; flex-direction: column">
                  <div
                    style="line-height: 20px; color: #fff; padding: 12px"
                    v-for="(warn, index) in device.warnList"
                    :key="index"
                  >
                    {{ warn.content }}
                  </div>
                </div>
                <span
                  slot="reference"
                  style="margin-right: 12px"
                  class="iconfont icon-jiankang"
                ></span>
              </el-popover>
              <span
                @click="itemLocation(device)"
                class="iconfont icon-dingwei1"
              ></span>
            </div>
          </div>
        </div>
      </template>
      <div class="nest-child_group_box" v-if="data.child && data.child.length">
        <Item
          v-for="item in data.child"
          :data="item"
          :key="`device_child_${item.id}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Item",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    curr() {
      return this.current();
    },
  },
  inject: ["current", "itemClick", "itemLocation"],
};
</script>

<style lang="scss" scoped>
.cpt-nest-list-item {
  .nest-group_box {
    .group-title_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      height: 40px;
      .title-box {
        width: calc(100% - 80px);
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        .iconfont {
          color: #ccedff;
          transform: rotate(90deg);
          transition: 0.3s;
          cursor: pointer;
          &.collapse {
            transform: rotate(0deg);
          }
        }
        .title {
          font-family: Microsoft YaHei;
          font-size: 16px;
          color: #ccedff;
          font-weight: 400;
          margin-left: 5px;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .line-box {
        width: 80px;
        flex-shrink: 0;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        color: #ccedff;
        line-height: 19px;
        font-weight: 400;
        text-align: right;
        .online {
          color: #00ffff;
        }
        .offline {
          color: #ccedff;
        }
      }
    }
  }
  .nest-children {
    overflow: hidden;
    &.collapse {
      height: 0;
    }
    .nest-device-list {
      .nest-device-item {
        height: 30px;
        line-height: 30px;
        box-sizing: border-box;
        padding: 0 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .iconfont {
          font-size: 22px;
          color: #ccedff;
        }
        &.online {
          .title-box {
            .iconfont {
              color: #00ffff;
            }
            .title {
              color: #00ffff;
            }
          }
        }
        .title-box {
          height: 100%;
          ::v-deep .el-checkbox {
            &.is-checked {
              .el-checkbox__input {
                .el-checkbox__inner {
                  border-color: #08c2d1;
                  &::after {
                    border-color: #08c2d1;
                  }
                }
              }
            }
            .el-checkbox__input {
              .el-checkbox__inner {
                border-radius: 0;
                width: 18px;
                height: 18px;
                background: #000000;
                // border-color: #08c2d1;
                border-color: #fff;
                box-sizing: border-box;
                &::after {
                  height: 10px;
                  left: 5px;
                  top: 1px;
                  width: 5px;
                }
              }
            }
          }
          .iconfont {
            font-size: 20px;
            color: #ccedff;
          }
          .title {
            font-family: MicrosoftYaHei;
            font-size: 16px;
            color: #ccedff;
            font-weight: 400;
            margin-left: 8px;
          }
        }
        .icon-box {
          .icon-jiankang {
            font-weight: bold;
            color: #d54a15;
            &.healthy {
              color: #15d589;
            }
          }
        }
      }
    }
    .nest-child_group_box {
      box-sizing: border-box;
      padding-left: 20px;
    }
  }
}
</style>

<style>
.el-popover .popper__arrow::after {
  border-bottom-color: transparent !important;
}
</style>