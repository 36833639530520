<template>
  <div>
    <div class="search w250 jcsb">
    <div class="cf fw700 mt14">
        挂载类型：
    </div>
      <el-select
        v-model="mountType"
        clearable
        placeholder="请选择挂载类型"
        @change="cameraChangeFn"
      >
       <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
      </el-select>
      <!-- <div class="cp monitoringRightTop-status__btn w90">搜索</div> -->
    </div>
    <div class="monitoringAreaBox">
      <div class="monitoringAreaItem" v-if="flag" v-infinite-scroll="loadList">
        <div
          class="itemA ml30 mr60 mb10"
          v-for="(item, index) in mountList"
          :key="index"
        >
          <div class="jcsb">
            <span class="title">{{ item.describe }}</span
            ><span style="color: green">正常</span>
          </div>
          <div class="mt8 mb8" style="border: 1px solid #00a2e6"></div>
          <div class="itemList">
            <div class="dib w143 h154">
              <img
                class="dib wih100 ht100"
                :src="baseUrl + item.image"
                alt=""
              />
            </div>
            <div class="dib text ml20 mt30" style="vertical-align: top">
              <div>型号：{{ item.model }}</div>
              <div>单位名称：{{ item.orgName }}</div>
              <!-- <div>编号：MMC-SFA2523</div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="monitoringAreaItem" v-infinite-scroll="loadList" v-else>
        <div class="itemB ml30 mr60 mb10" v-for="(item, index) in mountList" :key="index">
          <div class="jcsb">
            <span class="title">{{ item.describe }}</span
            ><span style="color: #ff0000">异常</span>
          </div>
          <div class="mt8 mb8" style="border: 1px solid #fff"></div>
          <div class="itemList">
            <div class="dib w143 h154">
              <img class="dib w100 h100" :src="baseUrl + item.image" alt="" />
            </div>
            <div class="dib text" style="vertical-align: top">
              <div>型号：{{ item.model }}</div>
              <div>单位名称：{{ item.orgName }}</div>
              <div>
                异常信息：<span style="color: #ff7a00">{{
                  item.abnormalContent
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  data() {
    return {
      baseUrl: "",
      status: "正常",
      mountList: [],
      mountType:null,
      typeList:[],
      pageNo:1
    };
  },
  props: {
    flag: {
      Type: Boolean,
      default: false,
    },
  },
  watch: {
    flag(val) {
      this.pageNo = 1
      this.mountList = [];
      this.getlist();
    },
  },
  mounted() {
    this.getMountType()
    this.baseUrl = process.env.VUE_APP_IMG_URL;
    this.getlist(true);
  },
  methods: {
      async getMountType(){
      let res = await API.DEVICE.listAll()
      this.typeList = res
    },
    cameraChangeFn(){
      this.mountList = [];
      this.getlist()
    },
    loadList(){
      this.pageNo++
      this.getlist()
    },
    async getlist(flag) {
      let res = await API.MONITOR.getList({
        type: 5,
        isAbnormal: Number(this.flag),
        mountType:this.mountType,
        pageNo:this.pageNo,
        pageSize:15
      });
      res.mountList.forEach(val => {
        this.mountList.push(val)
      });
      // this.mountList = res.mountList;
      if(res.mountList.length==0 &&flag){
        this.$emit('changeFlag',1)
      }
      this.$emit("change", res.count);
    },
  },
};
</script>

<style scoped lang='scss'>
::v-deep {
  .el-select {
    width: 165px;
    margin-right: 5px;

    .el-input {
      .el-input__inner {
        background: #000000;
        border: 1px solid #08c2d1;
        color: #dce9ff;
      }
    }
  }

  .el-input {
    width: 165px;
    margin-right: 5px;

    .el-input__inner {
      background: #000000;
      border: 1px solid #08c2d1;
      color: #dce9ff;
    }
  }

  .el-radio-group {
    .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #08c2d1;
            background: #08c2d1;
          }
        }
      }

      .el-radio__label {
        color: #cbd0eb;
      }
    }
  }

  .el-button {
    background: #2aefed;
    color: #000;
    border: none;
  }
}
.search {
  position: fixed;
  top: -67px;
}
.monitoringRightTop-status__btn {
  height: 32px;
  border: 1px solid rgba(49, 94, 199, 1);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #d2d9ff;
  margin-left: 14px;
  background-color: rgba(2, 23, 61, 1);

  &.active {
    background-image: url("~@/assets/images/monitoring/btn_active.png");
    background-size: 100% 100%;
  }

  &:hover {
    background-image: url("~@/assets/images/monitoring/btn_active.png");
    background-size: 100% 100%;
  }
}
.monitoringAreaBox {
  .monitoringAreaItem {
    display: flex;
    align-content: flex-start;
    // justify-content: space-between;
    flex-wrap: wrap;
    height: 70vh;
    overflow-y: auto;
    .itemList {
      display: flex;

      .text {
        font-family: MicrosoftYaHei;
        font-size: 18px;
        color: #ccf5ff;
        letter-spacing: 1.93px;
        font-weight: 400;
        word-wrap: break-word;
      }
    }

    .itemA {
      padding: 20px;
      width: 400px;
      height: 180px;
      background: rgba(11, 57, 117, 0.6);
      border: 1px solid #00a2e6;
      box-shadow: 0 2px 10px 0 rgba(4, 21, 69, 0.5),
        inset 0 0 38px 0 rgba(12, 149, 178, 0.5);
      border-radius: 18px;

      .title {
        font-family: MicrosoftYaHei-Bold;
        font-size: 18px;
        color: #00ffff;
        letter-spacing: 0;
        font-weight: 700;
      }
    }

    .itemB {
      padding: 20px;
      width: 400px;
      height: 144px;
      background: rgba(117, 11, 11, 0.2);
      border: 1px solid rgba(230, 0, 0, 0.5);
      box-shadow: 0 2px 10px 0 rgba(4, 21, 69, 0.5),
        inset 0 0 38px 0 rgba(186, 25, 25, 0.55);
      border-radius: 18px;

      .title {
        font-family: MicrosoftYaHei-Bold;
        font-size: 18px;
        color: #ccf5ff;
        letter-spacing: 0;
        font-weight: 700;
      }
    }
  }
}
</style>