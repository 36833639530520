<template>
  <div class="page-command-situation pr">
    <div class="header" v-interact>
      <div class="hd-box select">影像管理</div>
      <!-- <div class="hd-box" :class="active == '倾斜摄影' ? 'select' : ''" @click="handleClick('倾斜摄影')">倾斜摄影</div> -->
    </div>

    <div class="bd-box">
      <!-- <Oblique v-if="active == '倾斜摄影'"/> -->
      <Just/>
    </div>
  </div>
</template>

<script>
import API from "@/api";
// import Oblique from './oblique.vue'
import Just from './just.vue'
export default {
  data() {
    return {
      // active: '正射影像' ,
    };
  },
  components:{
    // Oblique,
    Just
  },
  methods: {
  },

  mounted() {

  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";
.page-command-situation {
  position: absolute;
  left: 10px;
  top: 82px;
  width: 456px;
  // padding: 16px 0;
  // height: 692px;
  box-sizing: border-box;
  // padding: 0 30px 50px 41px;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 13px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 44px;
    line-height: 44px;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;
  }
  .hd-box {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 700;
    // margin-left: 20px;
  }
  .select{
    background: rgba(16,65,215,0.20);
box-shadow: inset 0 0 20px 0 rgba(0,182,255,0.60);
border-radius: 10px 0 0 0;
  }
  .bd-box {
    height: calc(100% - 56px);
    box-sizing: border-box;
    
  }
}
</style>
