<template>
  <div class="cpt-command-device-mount cpt-command-device-common">
    <div class="containright">
      <div class="search-box mt16">
        <div :class="Highlight ? 'active' : ''" class="btn1027">
          <img
            src="~@/assets/images/command/警察(1).svg"
            alt=""
            class="images"
          />
          警员报备
        </div>
        <div :class="!Highlight ? 'active' : ''" @click="btn" class="btn1027">
          <img
            src="~@/assets/images/command/飞行漫游(1).svg"
            alt=""
            class="images"
          />
          飞行助手报备
        </div>

        <div v-if="visible" @click="userShow" class="btn1027 active">
          人员登记
        </div>

        <el-cascader
          class="w170 h32 el_cas"
          style="margin-left: 52.7vw"
          v-model="filter.orgId"
          :options="options"
          clearable
          :show-all-levels="false"
          placeholder="请选择"
          :props="{
            children: 'children',
            label: 'orgName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
          }"
        ></el-cascader>
        <el-select
          class="w110 ml20"
          v-model="filter.type"
          placeholder="请选择"
          clearable
          @change="handle_search"
        >
          <el-option label="民警" :value="0"> </el-option>
          <el-option label="辅警" :value="1"> </el-option>
        </el-select>
        <el-select
          class="w110 ml20"
          v-model="filter.status"
          clearable
          placeholder="请选择"
          @change="handle_search"
        >
          <el-option label="已上线" value="1"> </el-option>
          <el-option label="未上线" value="0"> </el-option>
        </el-select>
        <div
          @click="handle_search"
          class="btn1027 ml16"
          style="padding: 5px 20px"
        >
          搜索
        </div>
      </div>
      <div class="bd-box">
        <div class="tb-box">
          <div class="tb-hd-box">
            <div class="tb-hd">警员姓名</div>
            <div v-if="filter.type == 0" class="tb-hd">警员编号</div>
            <div class="tb-hd">身份</div>
            <div class="tb-hd">报备状态</div>
            <div class="tb-hd">手机号</div>
            <div class="tb-hd">所属单位</div>
            <div class="tb-hd">报备时间</div>
            <div class="tb-hd" style="padding: 0 13px 0 0" v-if="visible2">
              操作
            </div>
          </div>
          <div class="tb-bd-box">
            <div class="tb-tr" v-for="(item, index) in list" :key="index">
              <div class="td">
                {{ item.name || "暂无" }}
              </div>
              <div v-if="filter.type == 0" class="td">
                {{ item.siren || "暂无" }}
              </div>
              <div class="td">
                {{ item.type == 0 ? "民警" : "辅警" }}
              </div>
              <div class="td baobei" :class="{ weibaobei: item.status == 1 }">
                {{ item.status == 1 ? "已上线" : "未上线" || "暂无" }}
              </div>
              <div class="td">
                {{ item.phone || "暂无" }}
              </div>
              <div class="td">
                {{ item.anotherName || "暂无" }}
              </div>
              <div class="td" style="padding: 0 0 0 25px">
                {{ item.addtime || "暂无" }}
              </div>
              <div class="td" v-if="visible2">
                <!-- <div
                  class="td-btn"
                  v-if="!Boolean(item.status)"
                  @click="handle_inform(item)"
                >
                  {{ item.noticeStatus == 1 ? "已通知" : "一键通知" }}
                </div> -->
                <!-- <div style=""> -->
                <!-- <el-tooltip
                    effect="dark"
                    :content="item.noticeStatus == 1 ? '已报备' : '一键通知'"
                    placement="top"
                  >
                    <div
                      v-if="!Boolean(item.status)"
                      @click="handle_inform(item)"
                    >
                      <div
                        class="yijian_baobei"
                        :class="item.noticeStatus == 1 ? 'gay' : ''"
                      >
                        <span class="iconfont icon-yijianbaobei"></span>
                      </div>
                    </div>
                  </el-tooltip> -->
                <el-tooltip effect="dark" content="编辑" placement="top">
                  <div
                    style="flex: 0.3; display: flex"
                    @click="handle_edit(item.id)"
                  >
                    <div class="yijian_baobei">
                      <span class="iconfont icon-a-bianji2px"></span>
                      <!-- 一键报备 -->
                    </div>
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="tb-pagination">
            <el-pagination
              small
              :page-sizes="[10, 20, 30, 40]"
              :current-page.sync="filter.pageNo"
              :page-size="filter.pageSize"
              layout="total, prev, pager, next"
              :total="total"
              @current-change="handleSizeChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="containleft ml16 mt58">
      <!-- <div class="hd-box"></div> -->
      <!-- <div class="search-box ml30"></div> -->
      <!-- <div class="wih100 h355" id="command_echart"></div> -->
      <div id="echart" style="height: 355px; width: 100%"></div>
      <div class="tuli">
        <div class="li" v-for="(item, index) in tuli" :key="index">
          <div class="yuan" :style="{ background: item.color }"></div>
          <div class="fontO">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <!-- <UserInfo v-if="userVisible" @closeuser="shuaxin" :form="form" /> -->
  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";
import { Chart } from "@antv/g2";
import UserInfo from "@/components/header-right/userinfo.vue";

export default {
  components: {
    UserInfo,
  },
  data() {
    return {
      form: null,
      Highlight: true,
      userVisible: false,
      list: [],
      filter: {
        pageNo: 1,
        pageSize: 10,
        type: null,
        name: null,
        phone: null,
        status: null,
        orgId: null,
      },
      total: 0, //表格数据总数
      options: [],
      dataFlight: [
        { name: "盐城高新区", value: 32 },
        { name: "盐都区辖区", value: 10 },
      ],
      chart: null,
      echartFilter: {
        type: null,
        status: null,
        orgId: null,
      },
      visible: false,
      xzhou: [], //x周数据
      yzhou: [], //y轴数据
      userVisible: false,
      form: null,
      visible2: false,
      tuli: [],
      yanse: [
        "#1228E4FF",
        "#17ACFFFF",
        "#6DFFCBFF",
        "#2107BFFF",
        "#FAFF23FF",
        "#FFB036FF",
        "#FF9A00FF",
        "#00ff66",
        "#ffff33",
        "#66CC66",
        "#990033",
        "#99CCFF",
        "#CC9933",
        "#009933",
        "#FF9966",
        "#6699CC",
        "#66CC66",
        "#CCCCFF",
      ], //颜色库
    };
  },

  computed: {
    ...mapGetters(["user_info"]),
  },
  created() {
    const permissions = this.$store.state.user.user_info.permissions;
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("system:user:add") > -1) {
      this.visible = true;
    }
    if (permissionList.indexOf("system:user:edit") > -1) {
      this.visible2 = true;
    }
  },
  async mounted() {
    this.getCommanderCheck();
    this.filter.orgId = this.user_info.departmentId;
    this.echartFilter.orgId = this.user_info.departmentId;
    await this.getechartData();

    // this.init(this.dataFlight, "command_echart");
    // this.drawChart()
    this.getOptions();
  },

  methods: {
    userShow() {
      this.$emit("user", this.form);
    },
    async handle_edit(id) {
      let res = await API.DEVICE.userDetail(id);
      this.form = res;
      this.$emit("user", this.form);
    },
    shuaxin() {
      this.userVisible = false;
      this.form = null;
      this.getCommanderCheck();
    },

    drawChart() {
      // 提炼数据
      // let a = this.dataFlight;
      // console.log(a, "下面的值");
      this.dataFlight.forEach((item) => {
        this.xzhou.push(item.name);
        // if (item.value == 0) {
        //   this.yzhou.push("-");
        // } else {
        this.yzhou.push(item.value);
        // }
      });
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      // 颜色
      let color1 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "transparent" },
          { offset: 0.1, color: "#1228E4FF" },
          { offset: 1, color: "#1765FFFF" },
        ],
        false
      );
      let color2 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "transparent" },
          { offset: 0.1, color: "#17ACFFFF" },
          { offset: 1, color: "#4FE1FFFF" },
        ],
        false
      );
      let color3 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "transparent" },
          { offset: 0.1, color: "#6DFFCBFF" },
          { offset: 1, color: "#18DD9BFF" },
        ],
        false
      );
      let color4 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "transparent" },
          { offset: 0.1, color: "#2107BFFF" },
          { offset: 1, color: "#A278FCFF" },
        ],
        false
      );
      let color5 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "transparent" },
          { offset: 0.1, color: "#FAFF23FF" },
          { offset: 1, color: "#DE8D28FF" },
        ],
        false
      );
      let color6 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "transparent" },
          { offset: 0.1, color: "#FFB036FF" },
          { offset: 1, color: "#FF7917FF" },
        ],
        false
      );
      let color7 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "transparent" },
          { offset: 0.1, color: "#FF9A00FF" },
          { offset: 1, color: "#FF0000" },
        ],
        false
      );
      let color8 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "transparent" },
          { offset: 0.1, color: "#00ff66" },
          { offset: 1, color: "#00ffcc" },
        ],
        false
      );
      let color9 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "transparent" },
          { offset: 0.1, color: "#ffff33" },
          { offset: 1, color: "#ffff99" },
        ],
        false
      );
      let color10 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "transparent" },
          { offset: 0.1, color: "#66CC66" },
          { offset: 1, color: "#99CC66" },
        ],
        false
      );
      let color11 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "transparent" },
          { offset: 0.1, color: "#990033" },
          { offset: 1, color: "#CC3366" },
        ],
        false
      );
      let color12 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "transparent" },
          { offset: 0.1, color: "#FFFF66" },
          { offset: 1, color: "#FFFF00" },
        ],
        false
      );
      let color13 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "transparent" },
          { offset: 0.1, color: "#99CCFF" },
          { offset: 1, color: "#6699FF" },
        ],
        false
      );
      let color14 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "transparent" },
          { offset: 0.1, color: "#CC9933" },
          { offset: 1, color: "#996633" },
        ],
        false
      );
      let color15 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "transparent" },
          { offset: 0.1, color: "#009933" },
          { offset: 1, color: "#006633" },
        ],
        false
      );
      let color16 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "transparent" },
          { offset: 0.1, color: "#6699CC" },
          { offset: 1, color: "#666699" },
        ],
        false
      );
      let color17 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "transparent" },
          { offset: 0.1, color: "#FF9966" },
          { offset: 1, color: "#CC6600" },
        ],
        false
      );
      let color18 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "transparent" },
          { offset: 0.1, color: "#66CC66" },
          { offset: 1, color: "#006600" },
        ],
        false
      );
      let color19 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "transparent" },
          { offset: 0.1, color: "#CCCCFF" },
          { offset: 1, color: "#9966CC" },
        ],
        false
      );
      let barTopColor = [
        "#1228E4FF", //1
        "#17ACFFFF", //2
        "#6DFFCBFF", //3
        "#2107BFFF", //4
        "#FAFF23FF", //5
        "#FFB036FF", //6
        "#FF9A00FF", //7
        "#00ff66", //8
        "#ffff33", //9
        "#66CC66", //10
        "#990033", //11
        "#FFFF66", //12
        "#99CCFF", //13
        "#CC9933", //14
        "#009933", //15
        "#6699CC", //17
        "#FF9966", //16
        "#66CC66", //18
        "#CCCCFF", //19
      ];
      let barBottomColor = [
        "#1765FFFF", //1
        "#4FE1FFFF", //2
        "#18DD9BFF", //3
        "#A278FCFF", //4
        "#DE8D28FF", //5
        "#FF7917FF", //6
        "#FF0000FF", //7
        "#00ffcc", //8
        "#ffff99", //9
        "#99CC66", //10
        "#CC3366", //11
        "#FFFF00", //12
        "#6699FF", //13
        "#996633", //14
        "#006633", //15
        "#666699", //17
        "#CC6600", //16
        "#006600", //18
        "#9966CC", //19
      ];
      const myChart = this.$echarts.init(document.getElementById("echart"));
      myChart.on("click", (params) => {
        // console.log(this.dataFlight, "现在什么值");
        let obj = this.dataFlight.find((o) => o.name == params.name);
        this.echartFilter.orgId = obj.orgId;
        this.filter.orgId = this.echartFilter.orgId;
        this.getechartData();
        this.getCommanderCheck();
      });

      let option = {
        color: [
          color1,
          color2,
          color3,
          color4,
          color5,
          color6,
          color7,
          color8,
          color9,
          color10,
          color11,
          color12,
          color13,
          color14,
          color15,
          color16,
          color17,
          color18,
          color19,
        ],
        grid: {
          top: "20%",
          left: "5%",
          right: "20%",
          bottom: "11%",
          containLabel: true,
        },
        backgroundColor: "transparent",
        tooltip: {
          trigger: "item",
        },
        xAxis: {
          data: this.xzhou,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            // symbol: ["none", "arrow"], // 是否显示轴线箭头
            lineStyle: {
              color: "#FFFFFF",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            interval: 0,
            rotate: -30, //倾斜的程度
            textStyle: {
              color: "#FFFFFF",
            },
            margin: 10, //刻度标签与轴线之间的距离。
            formatter: function (value) {
              if (value.length > 4) {
                return value.slice(0, 4) + "...";
              }
              return value;
            },
          },
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: "#888",
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#FFFFFF",
            },
          },
        },
        series: [
          {
            type: "pictorialBar",
            symbolSize: [25, 10],
            symbolOffset: [0, -5],
            z: 12,
            symbolPosition: "end",
            itemStyle: {
              normal: {
                color: function (params) {
                  return barBottomColor[params.dataIndex];
                },
              },
            },
            data: this.yzhou,
          },
          {
            type: "pictorialBar",
            symbolSize: [25, 10],
            symbolOffset: [0, -5],
            z: 12,
            itemStyle: {
              normal: {
                color: function (params) {
                  return barTopColor[params.dataIndex];
                },
              },
            },
            data: this.yzhou,
          },
          {
            type: "bar",
            itemStyle: {
              normal: {
                color: function (params) {
                  var colorList = [
                    color1,
                    color2,
                    color3,
                    color4,
                    color5,
                    color6,
                    color7,
                    color8,
                    color9,
                    color10,
                    color11,
                    color12,
                    color13,
                    color14,
                    color15,
                    color16,
                    color17,
                    color18,
                    color19,
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
            barWidth: 25,
            data: this.yzhou,
            label: {
              normal: {
                show: true,
                position: "top",
                formatter: "{c}",
                textStyle: {
                  fontSize: 18,
                  color: "#fff",
                  fontWeight: "400",
                  fontFamuly: "MicrosoftYaHei",
                },
              },
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    btn() {
      this.$emit("cut");
      this.Highlight = !this.Highlight;
    },
    // 更新图表数据
    async getechartData() {
      let res = await API.PERSON.getechartData(this.echartFilter);

      this.dataFlight = res.map(
        (item) =>
          (item = {
            value: item.count,
            name: item.name,
            orgId: item.organizationId,
          })
      );
      this.xzhou = [];
      this.yzhou = [];
      this.tuli = [];
      res.forEach((item, index) => {
        this.tuli.push({ name: item.name, color: this.yanse[index] });
      });
      this.drawChart();
    },
    init(data, dataName) {
      const chart = (this.chart = new Chart({
        container: dataName,
        autoFit: true,
        height: 280,
      }));

      chart.data(data);

      chart.coordinate("theta", {
        radius: 0.75,
      });

      chart.tooltip({
        showTitle: false,
        showMarkers: false,
      });

      chart.legend({
        position: "left",
        pageNavigator: {
          marker: {
            style: {
              // 非激活，不可点击态时的填充色设置
              inactiveFill: "#fff",
              inactiveOpacity: 0.45,
              // 默认填充色设置
              fill: "#fff",
              opacity: 0.8,
              size: 16,
            },
          },
        },
        itemName: {
          style: {
            fill: " #DCE9FF",
            fontSize: 16,
          },
        },
      });

      chart.interval().adjust("stack").position("value").color("name");
      // 点击饼图
      chart.on("interval:click", async (ev) => {
        this.echartFilter.orgId = ev.data.data.orgId;
        this.filter.orgId = this.echartFilter.orgId;

        await this.getechartData();
        this.getCommanderCheck();

        this.$nextTick(() => {
          this.chart.data(this.dataFlight);
          this.chart.render();
        });
        //
      });
      chart.interaction("element-active");
      chart.render();
    },

    async getOptions() {
      let res = await API.DEPARTMENT.Drd();

      this.options = [res] || [];
    },

    async handle_search() {
      this.getCommanderCheck();
      this.echartFilter.type = this.filter.type;
      this.echartFilter.orgId = this.filter.orgId;
      this.echartFilter.status = this.filter.status;
      await this.getechartData();
      this.chart.data(this.dataFlight);
      this.chart.render();
    },
    async handle_inform(item) {
      if (item.noticeStatus == 1) {
        return false;
      }
      let sendId = this.user_info.uid;
      let receiveId = item.id;
      await API.PERSON.Inform({ sendId, receiveId });
      this.$el_message("通知成功");
      this.getCommanderCheck();
    },
    async getCommanderCheck() {
      let res = await API.PERSON.Check(this.filter);
      this.list = res.data || [];
      this.total = res.totalCount;
    },
    handleSizeChange(pageNo) {
      this.getCommanderCheck();
    },
  },
};
</script>


<style lang="scss" scoped>
@import "~@/styles/person1027.scss";

::v-deep .el-select {
  position: relative !important;
  .el-popper {
    position: absolute !important;
    top: 30px !important;
    left: 0 !important;
    z-index: 10 !important;
  }
}

.cpt-command-device-mount {
  width: 50%;
  display: flex;
  padding: 0 10px;
  .containleft {
    width: 100%;
    height: 388.5px;
    background: rgba(8, 26, 58, 0.8);
    border-radius: 4px;
    border: 1px solid #315ec7;
    position: relative;
    display: flex;
    z-index: 9;
    .tuli {
      height: 355px;
      position: absolute;
      right: 0%;
      top: 0%;
      width: 20%;
      // border: 1px solid red;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 30px 0 30px 10px;
      box-sizing: border-box;
      overflow-y: auto;
      .li {
        display: flex;
        align-items: center;
        .yuan {
          width: 14px;
          height: 14px;
          border-radius: 50%;
        }
        .fontO {
          font-size: 16px;
          font-family: MicrosoftYaHeiUI;
          color: #dce9ff;
          margin: 0 0 0 11px;
        }
      }
    }
  }
  // .divider {
  //   width: 0;
  //   border: 1px solid #004869;
  //   height: 357px;
  //   margin-top: 93px;
  // }
  .containright {
    width: 100%;
    // margin-top: -260px;
    .td {
      width: calc(100% / 7);
    }
  }
}
.search-box {
  display: flex;
  position: fixed;
  align-items: center;
}




.bd-box {
  height: 390px;
  margin-top: 58px;
  background: rgba(8, 26, 58, 0.8);
  border-radius: 4px;
  border: 1px solid #315ec7;
}
.light1 {
  width: 92px;
  height: 32px !important;
  text-align: center;
  color: #43deff;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 32px !important;
  background: url("~@/assets/images/command/YL.png");
}
.light {
  width: 120px;
  height: 32px !important;
  text-align: center;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #d2d9ff;
  line-height: 32px !important;
  background: url("~@/assets/images/command/personN.png");
  background-size: 100%;
}
.buhuan {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: url("~@/assets/images/command/YL.png");
  }
}
.images {
  // width: 24px;
  // height: 24px;
}
.echarts {
  width: 100% !important;
  height: 100% !important;
}
/deep/ .el-button {
  font-family: MicrosoftYaHei !important;
  color: #dce9ff !important;
}
.tb-hd {
  font-size: 16px;
  font-family: YouSheBiaoTiHei;
  color: #ebf9ff;
  letter-spacing: 1px;
}
.td {
  font-size: 18px !important;
  font-family: MicrosoftYaHei !important;
  color: #dce9ff !important;
}
.baobei {
  color: #ff8700 !important;
}
.weibaobei {
  color: #04cc0f !important;
}
.btn {
  background: url("~@/assets/images/command/personN.png") no-repeat;
  background-size: 100% 100%;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #d2d9ff;
  line-height: 33px;
  text-align: center;
  width: 64px !important;
  height: 33px !important;
  &:hover {
    background: url("~@/assets/images/command/twobg.png");
  }
}
.cpt-command-device-common .bd-box .tb-box .tb-bd-box {
  height: calc(100% - 60px) !important;
}
.tb-box {
  padding: 17px 0 0 0;
}
.gudingdaxiao {
  width: 92px;
  height: 32px;
  background: url("~@/assets/images/command/YL.png");
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
}
.el_cas {
  // padding: 4px 0 0 0;
}
.btn1027 {
  padding: 5px 8px;
  white-space: nowrap;
}

/deep/ .icon-a-bianji2px{
  cursor: pointer;
}

</style>
