<template>
  <div class="cpt-command-device-mount cpt-command-device-common">
    <div class="header" v-interact>
      <div class="head_left ml16">
        <img src="~@/assets/images/mount/mount_head.png" />
      <div class="fontO center">{{ type ? "单位排名" : "人员排名" }}</div>
      </div>
      <div class="close cp mr20 center" @click="() => $emit('close')">
        关闭
      </div>
    </div>
    <div class="bd-box">
      <div class="tb-box">
        <div class="tb-hd-box2">
          <div class="tb-hd w200">任务名称</div>
          <div class="tb-hd w200">航线名称</div>
          <div class="tb-hd w120">任务类型</div>
          <div class="tb-hd w100">执行状态</div>
          <div class="tb-hd w100">单位名称</div>
          <div class="tb-hd w120">时间</div>
        </div>
        <div class="tb-bd-box h280">
          <div class="tb-tr" v-for="(item, index) in data" :key="index">
            <div class="td w200">
              {{ item.taskTitle || "暂无" }}
            </div>
            <div class="td w200">
              {{ item.flightName || "暂无" }}
            </div>
            <div class="td w120">
              {{ item.typeName || "暂无" }}
            </div>
            <div class="td w100">
              {{ getStatus(item.taskStatus) || "暂无" }}
            </div>
            <div class="td w100">
              {{ item.personName || "暂无" }}
            </div>
            <div class="td w120">
              {{ item.endTime || "暂无" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  props: ["data", "type"],
  data() {
    return {
      list: [],
      isOrgan: true,
    };
  },
  computed: {
    name: function () {
      switch (this.type) {
        case 0:
          return "民警人员";
        case 1:
          return "辅警人员";
        case 2:
          return "飞行助手人员";
        default:
          break;
      }
      return;
    },
  },
  mounted() {
    console.log("data", this.data);
  },
  /* watch: {
    data: {
      handle(value) {
        this.data = value;
      },
      immediate: true,
      deep: true,
    },
  }, */
  methods: {
    getStatus(value) {
      switch (value) {
        case 0:
          return "待派发";
        case 3:
          return "待执行";
        case 1:
          return "已完成";
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/person.scss";
.cpt-command-device-mount {
  background: rgba(16, 30, 62, 0.9);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 13px;
  position: absolute;
  left: 497px;
  top: 220px;
  z-index: 20;
  width: 925px;
  padding: 0 !important;
  height: 388px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    line-height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;
    .head_left {
      display: flex;
      .fontO {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
    }
  }

  .hd-box {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 700;
    padding-left: 0 !important;
    margin-left: 20px;
  }
  .search-box-rank {
    margin-bottom: 10px;
    margin-left: 20px;
  }
  .td {
    // flex: 1;
  }
  .tb-hd-box {
    justify-content: space-around;
  }
  .tb-hd-box2 {
    display: flex;
    height: 36px;
    justify-content: space-around;
    .tb-hd {
      height: 100%;
      // background-color: #10181f;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #00c6ff;
      font-weight: 400;
      line-height: 36px;
      text-align: center;
    }
  }

  .td2 {
    width: calc(100% / 4);
  }
  .btn {
    border: 1px solid #00aeff;
    font-family: MicrosoftYaHeiUI;
    font-size: 16px;
    color: #00aeff;
    background: transparent;
  }
}
</style>
