<template>
  <div class="cpt-command-device-base-station">
    <div class="tb-box">
      <div class="tb-hd-box">
        <div class="tb-hd">基站名称</div>
        <div class="tb-hd">基站型号</div>
        <div class="tb-hd">基站图片</div>
        <div class="tb-hd">基站位置</div>
        <div class="tb-hd">基站状态</div>
        <div class="tb-hd">操作</div>
      </div>
      <div class="tb-bd-box">
        <div class="tb-tr" :class="{'table-light': (i % 2)}" v-for="(item, i) in list" :key="item.id">
          <div class="td">
            {{ item.name || "暂无" }}
          </div>
          <div class="td">
            {{ item.model || "暂无" }}
          </div>
          <div class="td">
            <el-image
              :src="baseUrl + item.image"
              :preview-src-list="[baseUrl + item.image]"
            >
            </el-image>
          </div>
          <div class="td">
            {{ item.address || "暂无" }}
          </div>
          <div class="td" :class="`tdclass${item.state}`">
            {{ changeState(item.state) || "暂无" }}
          </div>
          <div class="td">
            <div style="flex: 0.5; display: flex; justify-content: center">
              <el-tooltip effect="dark" content="维保" placement="top">
                <div
                  @click="handle_wb(item.id)"
                >
                  <div class="wbjl">
                  </div>
                </div>
              </el-tooltip>
              <el-tooltip effect="dark" content="编辑" placement="top">
                <div
                  style="flex: 0.5; display: flex; justify-content: center"
                  @click="handle_edit(item.id)"
                >
                  <div class="yijian_baobei">
                    <span class="iconfont icon-a-bianji2px"></span>
                    <!-- 一键报备 -->
                  </div>
                </div>
              </el-tooltip>
              <el-tooltip effect="dark" content="删除" placement="top">
                <div @click="handle_del(item.id)">
                  <div class="yijian_baobei">
                    <span class="iconfont icon-shanchu"></span>
                    <!-- 一键报备 -->
                  </div>
                </div>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";

export default {
  data() {
    return {
      list: [],
      baseUrl: process.env.VUE_APP_IMG_URL,
    };
  },
  async mounted() {
    await this.getBaseStationList();
  },
  methods: {
    handle_wb(id){
      let data = {
        type_id: 4,
        device_id: id
      }
      this.$emit("getwb",data)
    },
    async handle_edit(id) {
      const permissions = this.$store.state.user.user_info.permissions;
      const permissionList = permissions.map((item) => item.permissionSign);
      if (permissionList.indexOf("equipment:flight:baseStation:edit") == -1) {
        this.$message.error("暂无权限操作");
      } else {
        let res = await API.DEVICE.baseDetail(id);
        this.$emit("getDetail", res);
      }
    },
    async handle_del(id) {
      const permissions = this.$store.state.user.user_info.permissions;
      const permissionList = permissions.map((item) => item.permissionSign);
      if (permissionList.indexOf("equipment:flight:baseStation:remove") == -1) {
        this.$message.error("暂无权限操作");
      } else {
        this.$el_confirm("确定要删除设备吗？", () => {
          API.DEVICE.baseEditStatus({ id: id, status: 99 }).then((res) => {
            this.$el_message("删除成功");
            this.getBaseStationList();
          });
        });
      }
    },
    async getBaseStationList() {
      let res = await API.DEVICE.ListBaseStation();
      this.list = res.data || [];
    },
    changeState(val) {
      const obj = { 1: "正常", 2: "损坏", 3: "维修" };
      return obj[val];
    },
  },
};
</script>

<style lang="scss" scoped>
.tdclass1 {
  color: #00b900 !important;
}
.tdclass2 {
  color: red !important;
}
.tdclass3 {
  color: yellow !important;
}
.cpt-command-device-base-station {
  background: rgba(8,26,58,0.8);
  border-radius: 4px;
  border: 1px solid #315EC7;
  .tb-box {
    box-sizing: border-box;
    height: 100%;
    .tb-hd-box {
      display: flex;
      justify-content: space-between;
      line-height: 42px;
      .tb-hd {
        height: 100%;
        flex: 1;
        font-size: 16px;
        font-family: YouSheBiaoTiHei;
        color: #EBF9FF;
        line-height: 42px;
        letter-spacing: 1px;
        text-align: center;
      }
      .special {
        flex: 1.2;
      }
    }
    .tb-bd-box {
      height: calc(100% - 52px);
      overflow: auto;
      .tb-tr {
        display: flex;
        box-sizing: border-box;
        margin-bottom: 0 !important;

        &.table-light{
          background: rgba(73,135,210,0.2);
        }
        
        &:hover {
          cursor: pointer;
          background: #394d5f;
          .yijian_baobei {
            background: transparent;
          }
        }
        .td {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          padding: 10px 1px;
          text-align: center;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #dce9ff;
          font-weight: 400;
          word-break: break-word;
          box-sizing: border-box;
        }
        .special {
          flex: 1.2;
        }
      }
    }
  }
  /deep/.el-image__inner {
    width: 70px;
    height: 70px;
  }
  /deep/.el-image__error {
    width: 70px;
    height: 70px;
  }
  .yijian_baobei {
    // background: rgba(28, 164, 195, 0.5);
    // padding: 9px 8px 9px 8px;
    // height: 19px;
    // width: 70px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #dce9ff;
    text-align: center;
    .iconfont {
      color: rgba(28, 164, 195, 0.5) !important;
    }
  }
}
.wbjl{
  margin-top: 3px;
  width: 16px;
  height: 18px;
  background: red;
  background: url("~@/assets/images/device/wbd.png") no-repeat;
  background-position: 50%;
  &:hover{
  background: url("~@/assets/images/device/wb.png") no-repeat;
  background-position: 50%;
  }
}
</style>
