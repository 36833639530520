<template>
  <div class="cpt-app-uav-list">
    <div class="uav-item-box">
      <div class="uav-item-inner" :class="{ collapse: !data.collapse }" @click="data.collapse = !data.collapse">
        <span
          class="el-icon-caret-right"
          :class="{ collapse: data.collapse }"
        ></span>
        <div class="org-name" :title="data.name">{{ data.name }}</div>
      </div>
      <div class="uav-item-child-box" :class="{ collapse: data.collapse }">
        <template v-for="children in data.children">
          <Item  :key="children.id" :data="children" />
        </template>
        <template v-for="children in data.children">
          <div class="device-item-box">
            <div class="device-name pl10" :title="children.name">
              <div>
                {{ children.nmae }}
              </div>
            </div>
            <div class="device-fns">
              <div
                class="iconfont icon-chakan1"
                title="查看"
              ></div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Item",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.color0F {
  color: #00ffff;
}
.cpt-app-uav-list {
  
  .el-switch__core{
    border: #01ffff;
  }
  // height: calc(100% - 120px);
  overflow: auto;
  .uav-item-box {
    font-family: Microsoft YaHei;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    .uav-item-inner {
      height: 30px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .el-icon-caret-right {
        cursor: pointer;
        transition: 0.3s;
        transform-origin: center;
        transform: rotate(90deg);
        &.collapse {
          transform: rotate(0deg);
        }
      }
      .online-info {
        white-space: nowrap;
        .online {
          color: #00ffff;
        }
      }
      .org-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &.collapse{
        margin-bottom: 8px;
        padding-left: 8px;
        box-shadow: inset 0 0 20px 0 rgba(0,182,255,0.81);
        color: #00B6FF;
        .el-icon-caret-right{
          color: #fff;
        }
      }
    }
    .uav-item-child-box {
      padding-left: 20px;

      max-height: 20000px;
      transition: 0.3s;
      overflow: hidden;
      position: relative;
      &::before {
        content: " ";
        display: block;
        height: 100%;
        width: 1px;
        background-color: #129c9c;
        position: absolute;
        left: 7px;
        top: 0;
      }
      &.collapse {
        max-height: 0;
      }
      .device-item-box {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        transition: 0.2s;
        .device-name {
          width: calc(100% - 150px);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        &.online {
          .device-name {
            color: #00ffff;
          }
        }
        .device-fns {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .iconfont {
            font-size: 24px;
            margin-right: 8px;
            cursor: pointer;
            &.videoStatus {
              color: #4fbc6a;
            }
            &.active {
              color: #00ffff;
            }
          }
        }
      }
    }
  }
}
</style>
