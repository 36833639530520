<template>
  <div class="cpt-command-device-mount cpt-command-device-common">
    <div class="containright">
      <div class="search-box mt16">
        <div :class="!Highlight ? 'active' : ''" @click="btn" class="btn1027">
          <img src="~@/assets/images/command/警察.svg" alt="" class="images" />
          警员报备
        </div>
        <div :class="Highlight ? 'active' : ''" class="btn1027">
          <img
            src="~@/assets/images/command/飞行漫游.svg"
            alt=""
            class="images"
          />
          飞行助手报备
        </div>
        <div v-if="visible" @click="userShow" class="btn1027 active">
          人员登记
        </div>

          <el-cascader
          class="w170 h32 el_cas"
          style="margin-left: 57vw"
          v-model="filter.orgId"
          :options="options"
          clearable
          placeholder="请选择"
          :props="{
            children: 'children',
            label: 'orgName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
          }"
        ></el-cascader>

          <el-select
          :popper-append-to-body="false"
          class="ml14 w170 h32 el_cas"
          v-model="filter.status"
          clearable
          placeholder="请选择"
          @change="handle_search"
        >
          <el-option label="已上线" :value="1"> </el-option>
          <el-option label="未上线" :value="0"> </el-option>
        </el-select>
        <div
          @click="handle_search"
          class="btn1027 ml16"
          style="padding: 5px 20px"
        >
          搜索
        </div>
      </div>
      <div class="bd-box">
        <div class="tb-box">
          <div class="tb-hd-box">
            <div class="tb-hd">飞行员姓名</div>
            <!-- <div class="tb-hd">警员编号</div> -->
            <div class="tb-hd">报备位置</div>
            <div class="tb-hd">报备状态</div>
            <div class="tb-hd">手机号</div>
            <div class="tb-hd">所属单位</div>
            <div class="tb-hd">报备时间</div>
            <div class="tb-hd" v-if="visible2">操作</div>
          </div>
          <div class="tb-bd-box">
            <div class="tb-tr" v-for="(item, index) in list" :key="index">
              <div class="td">
                {{ item.name || '暂无' }}
              </div>
              <!-- <div class="td">
              {{ item.siren || '暂无' }}
            </div> -->
              <div class="td">
                {{ item.position || '暂无' }}
              </div>
              <div class="td baobei" :class="{ weibaobei: item.status == 1 }">
                {{ item.status == 1 ? '已上线' : '未上线' || '暂无' }}
              </div>
              <div class="td">
                {{ item.phone || '暂无' }}
              </div>
              <div class="td">
                {{ item.anotherName || '暂无' }}
              </div>
              <div class="td" style="padding: 0 0 0 25px">
                {{ item.addtime || '暂无' }}
              </div>
              <div class="td" v-if="visible2">
                <!-- <div
                  class="td-btn"
                  v-if="!Boolean(item.status)"
                  @click="handle_inform(item)"
                >
                  {{ item.noticeStatus == 1 ? "已通知" : "一键通知" }}
                </div> -->
                <el-tooltip effect="dark" content="编辑" placement="top">
                  <div
                    style="flex: 0.5; display: flex; justify-content: center"
                    @click="handle_edit(item.id)"
                  >
                    <div class="yijian_baobei">
                      <span class="iconfont icon-a-bianji2px"></span>
                      <!-- 一键报备 -->
                    </div>
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="tb-pagination">
            <el-pagination
              small
              :page-sizes="[10, 20, 30, 40]"
              :current-page.sync="filter.pageNo"
              :page-size="filter.pageSize"
              layout="total, prev, pager, next"
              :total="total"
              @current-change="handleSizeChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <div class="containleft ml16 mt58">
      <!-- <div class="hd-box"></div> -->
      <!-- <div class="search-box ml30"></div> -->
      <!-- <div class="wih100 h355" id="flight_echart"></div> -->
      <div id="echart" style="height: 355px; width: 100%"></div>
      <div class="tuli">
        <div class="li" v-for="(item, index) in tuli" :key="index">
          <div class="yuan" :style="{ background: item.color }"></div>
          <div class="fontO">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <!-- <UserInfo v-if="userVisible" @closeuser="shuaxin" :form="form" /> -->
  </div>
</template>
<script>
import API from '@/api'
import UserInfo from '../userinfo.vue'
import { Chart } from '@antv/g2'
import { mapGetters } from 'vuex'
export default {
  components: {
    UserInfo,
  },
  data() {
    return {
      Highlight: true,
      list: [],
      filter: {
        pageNo: 1,
        pageSize: 10,
        name: null,
        phone: null,
        status: null,
        type: 2,
        orgId: null,
      },
      total: 0,       //表格数据总数
      options: [],
      dataFlight: [],
      chart: null,
      echartFilter: {
        type: 2,
        status: null,
        orgId: null,
      },
      xzhou: [], //x周数据
      yzhou: [], //y轴数据
      userVisible: false,
      form: null,
      visible: false,
      visible2: false,
      tuli: [],
      yanse: [
        '#1228E4FF',
        '#17ACFFFF',
        '#6DFFCBFF',
        '#2107BFFF',
        '#FAFF23FF',
        '#FFB036FF',
        '#FF9A00FF',
        '#00ff66',
        '#ffff33',
        '#66CC66',
        '#990033',
        '#99CCFF',
        '#CC9933',
        '#009933',
        '#FF9966',
        '#6699CC',
        '#66CC66',
        '#CCCCFF',
      ], //颜色库
      guoxiao: [],
    }
  },
  computed: {
    ...mapGetters(['user_info']),
  },
  created() {
    const permissions = this.$store.state.user.user_info.permissions
    const permissionList = permissions.map((item) => item.permissionSign)
    if (permissionList.indexOf('system:user:add') > -1) {
      this.visible = true
    }
    if (permissionList.indexOf('system:user:edit') > -1) {
      this.visible2 = true
    }
  },
  async mounted() {
    this.filter.orgId = this.user_info.departmentId
    this.echartFilter.orgId = this.user_info.departmentId
    await this.getechartData()
    // this.init(this.dataFlight, "flight_echart");
    await this.getOptions()
    await this.getFlightCheck()
    // this.drawChart();
  },
  methods: {
    shuaxin() {
      this.userVisible = false
      this.form = null
      this.getFlightCheck()
    },
    userShow() {
      this.$emit('user', this.form)
    },
    async handle_edit(id) {
      let res = await API.DEVICE.userDetail(id)
      this.form = res
      this.$emit('user', this.form)
      this.userVisible = true
    },
    extension(chart) {
      // 注意这里，是以X轴显示内容过长为例，如果是y轴的话，需要把params.componentType == 'xAxis'改为yAxis
      // 判断是否创建过div框,如果创建过就不再创建了
      // 该div用来盛放文本显示内容的，方便对其悬浮位置进行处理
      var elementDiv = document.getElementById('extension')
      if (!elementDiv) {
        var div = document.createElement('div')
        div.setAttribute('id', 'extension')
        div.style.display = 'block'
        document.querySelector('html').appendChild(div)
      }
      chart.on('mouseover', function (params) {
        if (params.componentType == 'xAxis') {
          var elementDiv = document.querySelector('#extension')
          //设置悬浮文本的位置以及样式
          var elementStyle =
            'position: absolute;z-index: 99999;color: #fff;font-size: 12px;padding: 5px;display: inline;border-radius: 4px;background-color: #303133;box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 8px'
          elementDiv.style.cssText = elementStyle
          elementDiv.innerHTML = params.value
          document.querySelector('html').onmousemove = function (event) {
            var elementDiv = document.querySelector('#extension')
            var xx = event.pageX + 10
            var yy = event.pageY - 30
            elementDiv.style.top = yy + 'px'
            elementDiv.style.left = xx + 'px'
          }
        }
      })
      chart.on('mouseout', function (params) {
        //注意这里，我是以X轴显示内容过长为例，如果是y轴的话，需要改为yAxis
        if (params.componentType == 'xAxis') {
          var elementDiv = document.querySelector('#extension')
          elementDiv.style.cssText = 'display:none'
        }
      })
    },

    // ecahrts
    drawChart() {
      // 提炼数据
      // let a = this.dataFlight;
      // console.log(a, "下面的值");t
      this.dataFlight.forEach((item, index) => {
        this.xzhou.push(item.name)
        // if (item.value == 0) {
        //   this.yzhou.push('-')
        // } else {
          this.yzhou.push(item.value)
        // }
      })
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      // 颜色
      let color1 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: 'transparent' },
          { offset: 0.1, color: '#1228E4FF' },
          { offset: 1, color: '#1765FFFF' },
        ],
        false
      )
      let color2 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: 'transparent' },
          { offset: 0.1, color: '#17ACFFFF' },
          { offset: 1, color: '#4FE1FFFF' },
        ],
        false
      )
      let color3 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: 'transparent' },
          { offset: 0.1, color: '#6DFFCBFF' },
          { offset: 1, color: '#18DD9BFF' },
        ],
        false
      )
      let color4 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: 'transparent' },
          { offset: 0.1, color: '#2107BFFF' },
          { offset: 1, color: '#A278FCFF' },
        ],
        false
      )
      let color5 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: 'transparent' },
          { offset: 0.1, color: '#FAFF23FF' },
          { offset: 1, color: '#DE8D28FF' },
        ],
        false
      )
      let color6 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: 'transparent' },
          { offset: 0.1, color: '#FFB036FF' },
          { offset: 1, color: '#FF7917FF' },
        ],
        false
      )
      let color7 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: 'transparent' },
          { offset: 0.1, color: '#FF9A00FF' },
          { offset: 1, color: '#FF0000' },
        ],
        false
      )
      let color8 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: 'transparent' },
          { offset: 0.1, color: '#00ff66' },
          { offset: 1, color: '#00ffcc' },
        ],
        false
      )
      let color9 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: 'transparent' },
          { offset: 0.1, color: '#ffff33' },
          { offset: 1, color: '#ffff99' },
        ],
        false
      )
      let color10 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: 'transparent' },
          { offset: 0.1, color: '#66CC66' },
          { offset: 1, color: '#99CC66' },
        ],
        false
      )
      let color11 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: 'transparent' },
          { offset: 0.1, color: '#990033' },
          { offset: 1, color: '#CC3366' },
        ],
        false
      )
      let color12 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: 'transparent' },
          { offset: 0.1, color: '#990033' },
          { offset: 1, color: '#CC3366' },
        ],
        false
      )
      let color13 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: 'transparent' },
          { offset: 0.1, color: '#99CCFF' },
          { offset: 1, color: '#6699FF' },
        ],
        false
      )
      let color14 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: 'transparent' },
          { offset: 0.1, color: '#CC9933' },
          { offset: 1, color: '#996633' },
        ],
        false
      )
      let color15 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: 'transparent' },
          { offset: 0.1, color: '#009933' },
          { offset: 1, color: '#006633' },
        ],
        false
      )
      let color16 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: 'transparent' },
          { offset: 0.1, color: '#6699CC' },
          { offset: 1, color: '#666699' },
        ],
        false
      )
      let color17 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: 'transparent' },
          { offset: 0.1, color: '#FF9966' },
          { offset: 1, color: '#CC6600' },
        ],
        false
      )
      let color18 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: 'transparent' },
          { offset: 0.1, color: '#66CC66' },
          { offset: 1, color: '#006600' },
        ],
        false
      )
      let color19 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: 'transparent' },
          { offset: 0.1, color: '#CCCCFF' },
          { offset: 1, color: '#9966CC' },
        ],
        false
      )
      let barTopColor = [
        '#1228E4FF',
        '#17ACFFFF',
        '#6DFFCBFF',
        '#2107BFFF',
        '#FAFF23FF',
        '#FFB036FF',
        '#FF9A00FF',
        '#00ff66',
        '#ffff33',
        '#66CC66',
        '#990033',
        '#99CCFF',
        '#CC9933',
        '#009933',
        '#FF9966',
        '#6699CC',
        '#66CC66',
        '#CCCCFF',
      ]
      let barBottomColor = [
        '#1765FFFF',
        '#4FE1FFFF',
        '#18DD9BFF',
        '#A278FCFF',
        '#DE8D28FF',
        '#FF7917FF',
        '#FF0000FF',
        '#00ffcc',
        '#ffff99',
        '#99CC66',
        '#CC3366',
        '#6699FF',
        '#996633',
        '#006633',
        '#CC6600',
        '#666699',
        '#006600',
        '#9966CC',
      ]
      const myChart = this.$echarts.init(document.getElementById('echart'))
      myChart.on('click', (params) => {
        let obj = this.dataFlight.find((o) => o.name == params.name)
        this.echartFilter.orgId = obj.orgId
        this.filter.orgId = this.echartFilter.orgId
        this.getechartData()
        this.getFlightCheck()
      })
      // this.extension(myChart)
      let option = {
        color: [
          color1,
          color2,
          color3,
          color4,
          color5,
          color6,
          color7,
          color8,
          color9,
          color10,
          color11,
          color12,
          color13,
          color14,
          color15,
          color16,
          color17,
          color18,
          color19,
        ],
        grid: {
          top: '20%',
          left: '5%',
          right: '20%',
          bottom: '11%',
          containLabel: true,
        },
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'item',
        },
        xAxis: {
          data: this.xzhou,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            // symbol: ["none", "arrow"], // 是否显示轴线箭头
            lineStyle: {
              color: '#FFFFFF',
              width: 1,
              type: 'solid',
            },
          },
          triggrEvent: true,
          axisLabel: {
            interval: 0,
            rotate: -30, //倾斜的程度
            textStyle: {
              color: '#FFFFFF',
            },
            margin: 10, //刻度标签与轴线之间的距离。
            formatter: function (value) {
              if (value.length > 4) {
                return value.slice(0, 4) + '...'
              }
              return value
            },
          },
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: '#888',
              type: 'dashed',
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: '#FFFFFF',
            },
          },
        },
        series: [
          {
            type: 'pictorialBar',
            symbolSize: [25, 10],
            symbolOffset: [0, -5],
            z: 12,
            symbolPosition: 'end',
            itemStyle: {
              normal: {
                color: function (params) {
                  return barBottomColor[params.dataIndex]
                },
              },
            },
            data: this.yzhou,
          },
          {
            type: 'pictorialBar',
            symbolSize: [25, 10],
            symbolOffset: [0, -5],
            z: 12,
            itemStyle: {
              normal: {
                color: function (params) {
                  return barTopColor[params.dataIndex]
                },
              },
            },
            data: this.yzhou,
          },
          {
            type: 'bar',
            itemStyle: {
              normal: {
                color: function (params) {
                  var colorList = [
                    color1,
                    color2,
                    color3,
                    color4,
                    color5,
                    color6,
                    color7,
                    color8,
                    color9,
                    color10,
                    color11,
                    color12,
                    color13,
                    color14,
                    color15,
                    color16,
                    color17,
                    color18,
                    color19,
                  ]
                  return colorList[params.dataIndex]
                },
              },
            },
            barWidth: 25,
            data: this.yzhou,
            label: {
              normal: {
                show: true,
                position: 'top',
                formatter: '{c}',
                textStyle: {
                  fontSize: 18,
                  color: '#fff',
                  fontWeight: '400',
                  fontFamuly: 'MicrosoftYaHei',
                },
              },
            },
          },
        ],
      }
      // let option = {
      //   color: [color1, color2, color3, color4, color5],
      //   backgroundColor: "transparent",
      //   grid: {
      //     top: "20%",
      //     left: "5%",
      //     right: "20%",
      //     bottom: "11%",
      //     containLabel: true,
      //   },
      //   xAxis: {
      //     data: this.xzhou,

      //     axisTick: {
      //       show: false,
      //     },
      //     axisLine: {
      //       show: true,
      //       lineStyle: {
      //         color: "#545278",
      //       },
      //     },
      //     axisLabel: {
      //       show: true,
      //       margin: 20,
      //       textStyle: {
      //         fontSize: 14,
      //         color: "#ffffff",
      //       },
      //     },
      //     interval: 0,
      //   },
      //   yAxis: {
      //     splitLine: {
      //       show: false,
      //     },
      //     axisTick: {
      //       show: false,
      //     },
      //     axisLine: {
      //       show: true,
      //       lineStyle: {
      //         color: "#545278",
      //       },
      //     },
      //     axisLabel: {
      //       show: true,
      //       margin: 10,
      //       textStyle: {
      //         fontSize: 14,
      //         color: "#ffffff",
      //       },
      //     },
      //   },
      //   series: [
      //     {
      //       name: "柱顶部",
      //       type: "pictorialBar",
      //       symbolSize: [34, 10],
      //       symbolOffset: [0, -5],

      //       z: 12,
      //       itemStyle: {
      //         normal: {
      //           color: function (params) {
      //             return barBottomColor[params.dataIndex];
      //           },
      //         },
      //       },
      //       label: {
      //         show: true,
      //         position: "top",
      //         fontSize: 16,
      //       },
      //       symbolPosition: "end",
      //       data: this.yzhou,
      //     },
      //     {
      //       name: "柱底部",
      //       type: "pictorialBar",
      //       symbolSize: [34, 10],
      //       symbolOffset: [0, 5],
      //       z: 12,
      //       itemStyle: {
      //         normal: {
      //           color: function (params) {
      //             return barTopColor[params.dataIndex];
      //           },
      //         },
      //       },
      //       data: this.yzhou,
      //     },
      //     {
      //       type: "bar",
      //       itemStyle: {
      //         normal: {
      //           color: function (params) {
      //             var colorList = [color1, color2, color3, color4, color5];
      //             return colorList[params.dataIndex];
      //           },
      //         },
      //       },
      //       z: 16,
      //       silent: true,
      //       barWidth: 34,
      //       barGap: "-100%",
      //       data: this.yzhou,
      //     },
      //   ],
      // };
      /// 不同
      // let abc = {
      //   grid: {
      //     top: "20%",
      //     left: "5%",
      //     right: "20%",
      //     bottom: "11%",
      //     containLabel: true,
      //   },
      //   xAxis: {
      //     show: true,
      //     type: "category",
      //     data: this.xzhou,
      //     axisLabel: {
      //       color: "#AAB7C3",
      //       fontSize: "13",
      //       interval: 0,
      //     },
      //     axisLine: {
      //       lineStyle: {
      //         type: "dashed", // 坐标轴线线的类型（'solid'，实线类型；'dashed'，虚线类型；'dotted',点状类型）
      //       },
      //     },
      //     axisTick: {
      //       alignWithLabel: true,
      //     },
      //   },
      //   yAxis: {
      //     type: "value",
      //     axisLabel: {
      //       //y轴文字的配置
      //       textStyle: {
      //         color: "#AAB7C3", //Y轴内容文字颜色
      //         fontSize: "12",
      //       },
      //     },
      //     axisLine: {
      //       show: false,
      //     },
      //     splitLine: {
      //       show: true,
      //       lineStyle: {
      //         type: "dashed",
      //         color: ["#AAB7C3"],
      //       },
      //     },
      //   },

      //   color: [color1, color2, color3, color4, color5],
      //   series: [
      //     {
      //       // name:,
      //       data: this.yzhou,
      //       type: "bar",
      //       backgroundStyle: {
      //         //   color: [color1, color2, color3, color4, color5],
      //       },
      //       itemStyle: {
      //         normal: {
      //           color: function (params) {
      //             var colorList = [color1, color2, color3, color4, color5];
      //             return colorList[params.dataIndex];
      //           },
      //         },
      //       },
      //       barWidth: 20,
      //     },
      //   ],
      // };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
    // echarts

    btn() {
      this.$emit('cut')
      this.Highlight = !this.Highlight
    },
    init(data, dataName) {
      const chart = (this.chart = new Chart({
        container: dataName,
        autoFit: true,
        height: 280,
      }))
      chart.data(data)
      chart.coordinate('theta', {
        radius: 0.75,
      })
      chart.tooltip({
        showTitle: false,
        showMarkers: false,
      })
      chart.legend({
        pageNavigator: {
          marker: {
            style: {
              // 非激活，不可点击态时的填充色设置
              inactiveFill: '#fff',
              inactiveOpacity: 0.45,
              // 默认填充色设置
              fill: '#fff',
              opacity: 0.8,
              size: 16,
            },
          },
        },
        position: 'left',
        itemName: {
          style: {
            fill: '#fff',
            fontSize: 16,
          },
        },
        itemWidth: 120,
      })
      chart.interval().adjust('stack').position('value').color('name')
      // 点击饼图
      chart.on('interval:click', async (ev) => {
        this.echartFilter.orgId = ev.data.data.orgId
        this.filter.orgId = this.echartFilter.orgId
        await this.getechartData()
        this.getFlightCheck()
        this.$nextTick(() => {
          this.chart.data(this.dataFlight)
          this.chart.render()
        })
        //
      })
      chart.interaction('element-active')
      chart.render()
    },
    // 更新图表数据
    async getechartData() {
      let res = await API.PERSON.getechartData(this.echartFilter)
      this.dataFlight = res.map(
        (item) =>
          (item = {
            value: item.count,
            name: item.name,
            orgId: item.organizationId,
          })
      )
      this.xzhou = []
      this.yzhou = []
      this.tuli = []
      res.forEach((item, index) => {
        this.tuli.push({ name: item.name, color: this.yanse[index] })
      })
      this.drawChart()
    },
    async getOptions() {
      let res = await API.DEPARTMENT.Drd()
      this.options = [res] || []
      let a = res
      // 获取x轴数据
    },
    async handle_inform(item) {
      if (item.noticeStatus == 1) {
        return false
      }
      let sendId = this.user_info.uid
      let receiveId = item.id
      await API.PERSON.Inform({ sendId, receiveId })
      this.$el_message('通知成功')
      this.getFlightCheck()
    },
    async handle_search() {
      this.getFlightCheck()
      this.echartFilter.orgId = this.filter.orgId
      this.echartFilter.status = this.filter.status
      await this.getechartData()
      this.chart.data(this.dataFlight)
      this.chart.render()
    },
    async getFlightCheck() {
      let res = await API.PERSON.Check(this.filter)
      this.list = res.data || []
      this.total = res.totalCount;
      console.log('飞行',res)
    },
    handleSizeChange(pageNo){
      this.getFlightCheck();
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~@/styles/person1027.scss';

::v-deep .el-select {
  position: relative !important;
  .el-popper {
    position: absolute !important;
    top: 30px !important;
    left: 0 !important;
    z-index: 1 !important;
  }
}

.cpt-command-device-mount {
  width: 50%;
  display: flex;
  .containleft {
    width: 100%;
          height: 388.5px;
    background: rgba(8, 26, 58, 0.8);
    border-radius: 4px;
    border: 1px solid #315ec7;
    position: relative;
    display: flex;

    .tuli {
      height: 355px;
      position: absolute;
      right: 0%;
      top: 0%;
      width: 20%;
      // border: 1px solid red;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 30px 0 30px 10px;
      box-sizing: border-box;
      overflow-y: auto;
      .li {
        display: flex;
        align-items: center;
        .yuan {
          width: 14px;
          height: 14px;
          border-radius: 50%;
        }
        .fontO {
          font-size: 16px;
          font-family: MicrosoftYaHeiUI;
          color: #dce9ff;
          margin: 0 0 0 11px;
        }
      }
    }
  }
  // .divider {
  //   width: 0;
  //   border: 1px solid #004869;
  //   height: 357px;
  //   margin-top: 93px;
  // }
  .containright {
    width: 100%;
    // margin-top: -260px;
    .td {
      width: calc(100% / 7);
    }
  }
}
.search-box {
  display: flex;
  position: fixed;
  align-items: center;
  z-index: 1;
}
.bd-box {
  height: 390px;
  margin-top: 58px;
  background: rgba(8, 26, 58, 0.8);
  border-radius: 4px;
  border: 1px solid #315ec7;
}
.light1 {
  width: 120px;
  height: 32px !important;
  text-align: center;
  color: #43deff;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 32px !important;
  background: url('~@/assets/images/command/YL.png');
}
.light {
  width: 92px;
  height: 32px !important;
  text-align: center;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #d2d9ff;
  line-height: 32px !important;
  background: url('~@/assets/images/command/personN.png');
}
.buhuan {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: url('~@/assets/images/command/YL.png');
  }
}
.images {
  // width: 24px;
  // height: 24px;
}
.echarts {
  width: 100% !important;
  height: 100% !important;
}
/deep/ .el-button {
  font-family: MicrosoftYaHei !important;
  color: #dce9ff !important;
}
.tb-hd {
  font-size: 16px;
  font-family: YouSheBiaoTiHei;
  color: #ebf9ff;
  letter-spacing: 1px;
}
.td {
  font-size: 18px !important;
  font-family: MicrosoftYaHei !important;
  color: #dce9ff !important;
}
.baobei {
  color: #ff8700 !important;
}
.weibaobei {
  color: #04cc0f !important;
}
.btn {
  background: url('~@/assets/images/command/personN.png') no-repeat;
  background-size: 100% 100%;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #d2d9ff;
  line-height: 33px;
  text-align: center;
  width: 64px !important;
  height: 33px !important;
  &:hover {
    background: url('~@/assets/images/command/twobg.png');
  }
}
.cpt-command-device-common .bd-box .tb-box .tb-bd-box {
  height: calc(100% - 60px) !important;
}
.tb-box {
  padding: 17px 0 0 0;
}
.gudingdaxiao {
  width: 92px;
  height: 32px;
  background: url('~@/assets/images/command/YL.png');
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
}
.el_cas {
  // padding: 4px 0 0 0;
}
.btn1027 {
  padding: 5px 8px;
  white-space: nowrap;
}


</style>
