<template>
  <div class="cpt-popup-item">
    <p>{{ title }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => '',
    },
  },
}
</script>

<style lang="scss" scoped>
.cpt-popup-item {
  p {
    position: absolute;
    top: 20px;
    left: 130px;
    font-family: PangMenZhengDao;
    font-size: 22px;
    color: #dce9ff;
    font-weight: 400;
  }
}
</style>
