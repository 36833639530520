<template>
  <div class="nestListBox">
    <div class="  wih100 ht100" v-interact>
      <div class="jcsb nestListBoxTitle h50 lh50 pl10 pr10">
        鹰巢列表
        <div class="healthy" slot="nav__right">
          <div class="healthy" slot="nav__right" v-if="list && list.length">
            (<span class="healthy--un">{{ list[0].onLineCount }}</span>
            <span>/</span>
            <span class="healthy--total">{{
              list[0].onLineCount + list[0].offLineCount
              }}</span>)
          </div>
        </div>
      </div>
      <div class="cpt-observe-nest-list">
        <!-- <div class="search-box">
        <el-input v-model="name" placeholder="请输入设备名称" clearable />
        <div class="search-icon-box">
          <span class="iconfont icon-sousuo"></span>
        </div>
      </div> -->
        <div class="ctx-box">
          <div class="list-box">
            <template v-if="list && list.length">
              <Item v-for="item in list" :key="item.id" :data="item" />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Dialog from "@/components/observe/fckernel/common/dialog";
  import Item from "./item";

  export default {
    props: {
      value: {
        type: Object,
        default: () => ({}),
      },
      asyncList: {
        type: Function,
        default: () => () => { },
      },
      list: {
        type: Array,
        default: () => [],
      },
    },
    components: { Dialog, Item },
    data() {
      return {
        name: null,
        curr_value: null,
      };
    },
    mounted() {
      this.asyncList();
    },
    watch: {
      value: {
        handler(value) {
          this.curr_value = value;
        },
        immediate: true,
        deep: true,
      },
    },
    computed: {
      healthy_total() {
        return this.list.reduce(
          (total, item) => total + item.healthy + item.unhealthy,
          0
        );
      },
      unhealthy_total() {
        return this.list.reduce((total, item) => total + item.unhealthy, 0);
      },
    },
    provide() {
      return {
        itemClick: (select, item) => {
          let value = select ? item : null;
          this.curr_value = value;
          this.$emit("change", this.curr_value);
          // this.$emit("input", this.curr_value);
        },
        current: () => this.curr_value,
        itemLocation: (device) => {
          this.$emit("item-location", device);
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  .nestListBox {
    position: fixed;
    right: 10%;
    width: 400px;
    height: 563px;
    top: 150px;
    z-index: 10;
    border: 1px solid #3dcdff;
    background: rgba(0, 23, 79, 0.7);
    box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
      inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
    border-radius: 10px;

    .nestListBoxTitle {
      color: #00ffff;
      letter-spacing: 0;
      font-weight: 700;
      background: rgba(16, 65, 215, 0.2);
      box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
      border-radius: 10px 10px 0 0;
    }
  }

  .healthy {
    box-sizing: border-box;
    padding: 0 5px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    height: 100%;

    .healthy--total {}

    .healthy--un {
      color: #d54a15;
    }
  }

  .cpt-observe-nest-list {
    display: flex;
    flex-direction: column;
    height: 510px;
    overflow-y: auto;
    .search-box {
      flex-shrink: 0;
      display: flex;
      height: 36px;
      padding: 0 10px;
      background: #000000;
      border: 1px solid #3dcdff;
      border-radius: 2px;
      box-sizing: border-box;

      ::v-deep .el-input {
        height: 36px;

        input {
          height: 36px;
          background-color: transparent;
          border: none;
          padding: 0;

          font-family: Microsoft YaHei;
          font-size: 16px;
          color: #08c2d1;
          font-weight: 400;
        }

        .el-input__suffix {
          display: flex;
          align-items: center;
        }
      }

      .search-icon-box {
        display: flex;
        align-items: center;
        cursor: pointer;

        .iconfont {
          font-size: 24px;
          color: #08ffff;
        }

        &:hover {
          .iconfont {
            opacity: 0.8;
          }
        }
      }
    }

    .ctx-box {
      flex: 1;
      overflow: auto;
      margin-bottom: 10px;
    }
  }
</style>