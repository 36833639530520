<template>
  <div class="cpt-command-situation-detail dialog1027">
    <div class="dialog-header" v-interact>
      <div class="dialog-header__icon"/>
      <div class="dialog-header__title">警情详情 </div>
      <div class="dialog-header__close" @click="$emit('close',1)">关闭</div>
    </div>

    <div class="flight_detail_box dialog-content">
      <div class=" dialog-content-item  textarea">
        <span class="dialog-content__label">警情事件内容：</span>
        <div class="dialog-content__text w598">{{ situation_detail.content || '暂无' }}</div>
      </div>
      <div class=" dialog-content-item">
        <span class="dialog-content__label">报警时间：</span>
        <div class="dialog-content__text">{{ situation_detail.bjdhsj || '暂无' }}</div>
      </div>
      <div class=" dialog-content-item">
        <span class="dialog-content__label">推送时间：</span>
        <div class="dialog-content__text">{{ situation_detail.addTime || '暂无' }}</div>
      </div>
      <div class=" dialog-content-item">
        <span class="dialog-content__label">派发时间：</span>
        <div class="dialog-content__text">{{  situation_detail.addtime||'暂无' }}</div>
      </div>
      <div class=" dialog-content-item">
        <span class="dialog-content__label">审批时间：</span>
        <div class="dialog-content__text">
          {{ situation_detail.edittime || '暂无' }}
        </div>
      </div>
      <div class=" dialog-content-item">
        <span class="dialog-content__label">签收时间：</span>
        <div class="dialog-content__text">{{ situation_detail.responseTime || '暂无' }}</div>
      </div>
      <div class=" dialog-content-item">
        <span class="dialog-content__label">签收人：</span>
        <div class="dialog-content__text">{{ situation_detail.responseUserName || '暂无' }}</div>
      </div>
      <div class=" dialog-content-item">
        <span class="dialog-content__label">响应状态：</span>
        <div class="dialog-content__text">{{responseStatusTypeFn(situation_detail.responseStatus) }}</div>
      </div>
      <div class=" dialog-content-item">
        <span class="dialog-content__label">任务状态：</span>
        <div class="dialog-content__text">{{  get_status(situation_detail.taskSituation )}}</div>
      </div>
      <div class=" dialog-content-item">
        <span class="dialog-content__label">责任单位：</span>
        <div class="dialog-content__text w598">{{ situation_detail.policeUnitTitle || '暂无' }}</div>
      </div>
      <div class=" textarea dialog-content-item">
        <span class="dialog-content__label">备注：</span>
        <div class="dialog-content__text w598 h90" >{{ situation_detail.remark || '暂无' }}</div>
      </div>

      <div class="btn-box1027">
        <div class="btn1027" @click="$emit('close',1)">关闭</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
    
    }
  },
  inject: ['flight_task_chage_view'],
  computed: {
    ...mapGetters(['situation_detail']),
  },
  methods: {
    responseStatusTypeFn(item){
      if(item){
        const   responseStatusType={
        0:'未响应',
        1:'及时响应',
        2:'超时响应'
      }
        return responseStatusType[item]
      }else{
        return '暂无'
      }
    },
    get_status(item) {
      if(item){
       if (item == -1) {
        return "待派发";
      }
      const obj = {
        5: "已签收",
        // 5: "待规划航线",
        0: "待审批",
        1: "审批不通过",
        2: "待执行",
        3: "执行中",
        4: "已完成",
        6: "已派发",
        7: "拒收",
        8: "未签收",
        9: "无需派发",
      };
      return obj[item];
      }else{
       return '暂无'
      }
   
    },
  },
}
</script>

<style lang="scss" scoped>
.cpt-command-situation-detail {
  position: absolute;
  width: 760px;
  // height: 608px;
  box-sizing: border-box;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 13px;
  left: calc(50% - 380px);

  top: 170px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 44px;
  background: rgba(16, 65, 215, 0.2);
  box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
  border-radius: 10px 10px 0 0;
}

.hd-box {
  font-family: MicrosoftYaHei-Bold;
  font-size: 18px;
  color: #00ffff;
  letter-spacing: 0;
  font-weight: 700;
  margin-left: 20px;
}
.el-button {
  border: 0;
  opacity: 0.8;
  font-family: PangMenZhengDao;
  font-size: 22px;
  color: #00ffff;
  text-align: center;
  font-weight: 400;
  width: 114px;
  height: 40px;
  padding: 10px 20px;
  background: rgba(0, 34, 140, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 15px 0 #00b6ff;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  bottom: 20px;
}
.flight_detail_box {
  box-sizing: border-box;
  // width: 1050px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .textarea {
    div {
      height: 80px;
      line-height: normal;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
</style>
