var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"livenvr pr",class:_vm.className},[(_vm.data.list.length > 0)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.infoflag),expression:"infoflag"}],ref:"timeStr",staticClass:"timeStr",class:_vm.infoflag ? 'timeStrStyle' : ''},[_vm._v(" "+_vm._s(_vm.timeStr || "")+" ")]):_vm._e(),_c('LivePlayer',{ref:"livePlayer",class:{
      small: !_vm.videoFlag,
      'livenvr-player': _vm.videoFlag,
      vUrl: _vm.isStatus && !_vm.videoFlag,
      vUrlDeffault: !_vm.isStatus && !_vm.videoFlag,
    },attrs:{"dblclick-fullscreen":false,"aspect":"fulllscreen","videoUrl":_vm.data.vUrl}}),(_vm.fpvUrl.vUrl)?_c('LivePlayer',{ref:"fpvPlayer",class:{
      vUrl: _vm.isStatus,
      vUrlDeffault: !_vm.isStatus,
      small: _vm.videoFlag,
      'livenvr-player': !_vm.videoFlag,
    },attrs:{"dblclick-fullscreen":false,"controls":false,"videoUrl":_vm.fpvUrl.vUrl}}):_vm._e(),(_vm.data.list.length > 0 && _vm.data.deviceName == '图传')?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.infoflag),expression:"infoflag"}],ref:"wsDataleft",staticClass:"wsDataleft",class:_vm.infoflag ? 'leftStyle' : ''},[_c('div',[_vm._v("经度："+_vm._s(_vm.wsData.longitude || 0))]),_c('div',[_vm._v("纬度："+_vm._s(_vm.wsData.latitude || 0))]),_c('div',[_vm._v("高度："+_vm._s(_vm.wsData.height ? _vm.wsData.height.toFixed(2) : 0))]),_c('div',[_vm._v(" 速度："+_vm._s(_vm.wsData.groundSpeed ? _vm.wsData.groundSpeed.toFixed(2) : 0)+" ")]),_c('div',[_vm._v("方向："+_vm._s(_vm.wsData.yaw ? _vm.wsData.yaw.toFixed(2) : 0))])]):_vm._e(),(_vm.data.list.length > 0)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.infoflag),expression:"infoflag"}],ref:"wsDataright",staticClass:"wsDataright",class:_vm.infoflag ? 'rightStyle' : ''},[(_vm.device.customName && _vm.data.deviceName == '图传')?_c('div',[_vm._v(" "+_vm._s(_vm.org.ername || "")+" ")]):_c('div',[_vm._v(_vm._s(_vm.org.ername || ""))]),(_vm.wsData.sanname)?_c('div',[_vm._v(_vm._s(_vm.org.sanname || ""))]):_vm._e(),(_vm.data.deviceName != '图传')?_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.data.list[0].name)+" ")]):_vm._e(),(_vm.data.deviceName == '图传')?_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.data.name))]):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }