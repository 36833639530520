<template>
    <Airspace @hedgehop="airway_hedgehop" @handleShow="airspace_handle_show" @handleHide="airspace_handle_hide" />
</template>

<script>
import Airspace from '@/components/command/airspace'
import data from './data'
import methods from './methods'

export default {
  components: { Airspace },
  data() {
    return data
  },
  methods: {
    ...methods,
  },
  inject: ['g_cesium_toolbar','g_cesium_layer'],
  mounted() {
    this.g_cesium_toolbar().moveToolbar('left')
  },
}
</script>
