<!-- 新版 鹰视首页 -->
<template>
  <div class="pr">
    <div>
      <Header title="鹰控" :user="user_info" :flag="page" :type.sync="headerType" />
    </div>
    <div class="command-home">
      <div class="fierce_look_content">
        <div class="img_bg1" @click="tolink(5)"></div>
        <div class="img_bg2" @click=" tolink(6) "></div>
        <div class="img_bg3" @click="tolink(7)"></div>
        <div class="img_bg4" @click="tolink(11)"></div>
      </div>
      <div class="pa command-home_btn_v1 cp" @click="$emit('change')"></div>
    </div>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import Header from "@/components/command/header/index";
import API from "@/api";
export default {
  components: { Header },
  data() {
    return {
      page: 1,
      headerType: "首页",
      angle: 0, //旋转角度
      pauseTurn: false, //暂停旋转
      pauseTurnTimeId: null,
      timeId: null, //主旋转定时器
      turnTimeId: null, //手动旋转定时器
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  created() {
    // console.log("created");
    this.$store.commit("mapmanage/SET_VISIBLE_MAIN_MAP", false);
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    tolink(num) {
      this.$emit("changePage", num);
    },
  },
};
</script>
  
<style lang="scss" scoped>
$headerHegith: 72px; //顶部条高度

.command-home {
  position: fixed;
  top: $headerHegith;
  left: 0;
  width: 100vw;
  height: calc(100vh - 72px);
  background-size: 100% 100%;

  .fierce_look_content {
    display: flex;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    width: 88%;
    transform: translate(-50%, -50%);
    justify-content: space-around;

    .img_bg1 {
      width: 302px;
      height: 400px;
      background-image: url("./img/1.png");
      background-repeat: no-repeat;
      background-size: 100%;
      cursor: pointer;
    }

    .img_bg1:hover {
      background-image: url("./img/1_active.png");
    }

    .img_bg2 {
      width: 302px;
      height: 400px;
      background-image: url("./img/2.png");
      background-size: 100%;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    .img_bg2:hover {
      background-image: url("./img/2_active.png");
    }

    .img_bg3 {
      width: 302px;
      height: 400px;
      background-image: url("./img/3.png");
      background-repeat: no-repeat;

      background-size: 100%;
      cursor: pointer;
    }

    .img_bg3:hover {
      background-image: url("./img/3_active.png");
    }

    .img_bg4 {
      width: 302px;
      height: 400px;
      background-image: url("./img/4.png");
      background-repeat: no-repeat;

      background-size: 100%;
      cursor: pointer;
    }

    .img_bg4:hover {
      background-image: url("./img/4_active.png");
    }
  }
}
.command-home_btn_v1 {
  width: 150px;
  height: 50px;
  z-index: 90;
  bottom: 10px;
  right: 20px;
  background-image: url("~@/assets/img/homeChange1.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.command-home_btn_v1:hover {
  // background-image: url("~@/assets/img/homeChange2.png");
}
</style>
    