<template>
  <div class="cpt-command-device-mount cpt-command-device-common">
    <div class="header" v-interact>
      <div class="head_left ml16">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="fontO center">{{ name || "暂无" }}</div>
      </div>
      <div class="close cp mr20 center" @click="() => $emit('close')">关闭</div>
    </div>

    <div class="bd-box">
      <div class="tb-box">
        <div class="tb-hd-box">
          <div class="tb-hd">单位名称</div>
          <div class="tb-hd">用户姓名</div>
          <div class="tb-hd">身份</div>
          <div class="tb-hd" v-if="type != 2">警号</div>
          <div class="tb-hd">手机号</div>
        </div>
        <div class="tb-bd-box h280">
          <div class="tb-tr" v-for="(item, index) in data" :key="index">
            <div class="td">
              {{ item.anotherName || "暂无" }}
            </div>
            <div class="td">
              {{ item.name || "暂无" }}
            </div>
            <div class="td">
              {{ name.replace("人员", "") || "暂无" }}
            </div>
            <div class="td" v-if="type != 2">
              {{ item.policeId || "暂无" }}
            </div>
            <div class="td">
              {{ item.phone || "暂无" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  props: ["data", "type"],
  data() {
    return {
      list: [],
      isOrgan: true,
    };
  },
  computed: {
    name: function () {
      switch (this.type) {
        case 0:
          return "民警人员";
        case 1:
          return "辅警人员";
        case 2:
          return "飞行助手人员";
        default:
          break;
      }
      return;
    },
  },
  mounted() {
    console.log("data", this.data);
  },
  /* watch: {
    data: {
      handle(value) {
        this.data = value;
      },
      immediate: true,
      deep: true,
    },
  }, */
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "~@/styles/person.scss";
.cpt-command-device-mount {
  background: rgba(16, 30, 62, 0.9);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 13px;
  background-size: 100% 100%;
  position: absolute;
  left: 650px;
  top: 220px;
  z-index: 20;
  width: 609px;
  height: 388px;
  padding: 0 !important;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    line-height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;
    .head_left {
      display: flex;
      .fontO {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
    }
  }

  .hd-box {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 700;
    padding-left: 0 !important;
    margin-left: 20px;
  }
  .search-box-rank {
    margin-bottom: 10px;
    margin-left: 20px;
  }
  .td {
    flex: 1;
  }
  .td2 {
    width: calc(100% / 4);
  }
  .btn {
    border: 1px solid #00aeff;
    font-family: MicrosoftYaHeiUI;
    font-size: 16px;
    color: #00aeff;
    background: transparent;
  }
}
</style>
