import point_icon_01 from '@/assets/images/point-icon/point_icon_01.svg'
import point_icon_02 from '@/assets/images/point-icon/point_icon_02.svg'
import point_icon_03 from '@/assets/images/point-icon/point_icon_03.svg'
import point_icon_04 from '@/assets/images/point-icon/point_icon_04.svg'
import point_icon_05 from '@/assets/images/point-icon/point_icon_05.svg'
import point_icon_06 from '@/assets/images/point-icon/point_icon_06.svg'
import point_icon_07 from '@/assets/images/point-icon/point_icon_07.svg'
import point_icon_08 from '@/assets/images/point-icon/point_icon_08.svg'
import point_icon_09 from '@/assets/images/point-icon/point_icon_09.svg'
import point_icon_10 from '@/assets/images/point-icon/point_icon_10.svg'
import point_icon_11 from '@/assets/images/point-icon/point_icon_11.svg'
import point_icon_12 from '@/assets/images/point-icon/point_icon_12.svg'


const iconData = [
  {
    id: 1,
    label: '默认',
    image: point_icon_01
  },
  {
    id: 2,
    label: '排污',
    image: point_icon_02
  },
  {
    id: 3,
    label: '违建',
    image: point_icon_03
  },
  {
    id: 4,
    label: '垃圾',
    image: point_icon_04
  },
  {
    id: 5,
    label: '彩钢棚',
    image: point_icon_05
  },
  {
    id: 6,
    label: '水面垃圾',
    image: point_icon_06
  },
  {
    id: 7,
    label: '水闸',
    image: point_icon_07
  },
  {
    id: 8,
    label: '烟窗',
    image: point_icon_08
  },
  {
    id: 9,
    label: '秸秆焚烧点',
    image: point_icon_09
  },
  {
    id: 10,
    label: '政府',
    image: point_icon_10
  },
  {
    id: 11,
    label: '学校',
    image: point_icon_11
  },
  {
    id: 12,
    label: '医院',
    image: point_icon_12
  },
]

export default iconData 
