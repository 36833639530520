import { User, Flyer } from '@/api'

let phone =
  /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/

let username = /^[a-zA-Z0-9]{6,12}$/

let password = /^[a-zA-Z0-9]{6,18}$/

let email =
  /^[a-z0-9]+([._-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/

export default {
  validatePassword(rule, value, callback) {
    if (!value) {
      callback(new Error('请输入登录密码'))
    } else if (!password.test(value) && value != '******') {
      callback(new Error('登录密码需由6-18位数字字母组成'))
    } else {
      callback()
    }
  },
  validateName(rule, value, callback) {
    if (!value) {
      callback(new Error('请输入用户名称'))
    } else if (value.length > 10) {
      callback(new Error('长度限制在10位数以内'))
    } else {
      callback()
    }
  },
  validateFlyName(rule, value, callback) {
    if (!value) {
      callback(new Error('请输入飞手名称'))
    } else if (value.length > 10) {
      callback(new Error('长度限制在10位数以内'))
    } else {
      callback()
    }
  },
  validateRoleName(rule, value, callback) {
    if (!value) {
      callback(new Error('请输入角色名称'))
    } else if (value.length > 10) {
      callback(new Error('长度限制在10位数以内'))
    } else {
      callback()
    }
  },
  validateOrganName(rule, value, callback) {
    if (!value) {
      callback(new Error('请输入机构名称'))
    }
    //  else if (value.length > 20) {
    //   callback(new Error('长度限制在20位数以内'))
    // } 
    else {
      callback()
    }
  },
  validateDeviceName(rule, value, callback) {
    if (!value) {
      callback(new Error('请输入无人机名称'))
    } else if (value.length > 10) {
      callback(new Error('长度限制在10位数以内'))
    } else {
      callback()
    }
  },
  validateNestName(rule, value, callback) {
    if (!value) {
      callback(new Error('请输入鹰巢名称'))
    } else if (value.length > 10) {
      callback(new Error('长度限制在10位数以内'))
    } else {
      callback()
    }
  },
  validateCounterName(rule, value, callback) {
    if (!value) {
      callback(new Error('请输入反制名称'))
    } else if (value.length > 10) {
      callback(new Error('长度限制在10位数以内'))
    } else {
      callback()
    }
  },
  validateBaseName(rule, value, callback) {
    if (!value) {
      callback(new Error('请输入基站名称'))
    } else if (value.length > 10) {
      callback(new Error('长度限制在10位数以内'))
    } else {
      callback()
    }
  },
  validateTaskTypeName(rule, value, callback) {
    if (!value) {
      callback(new Error('请输入任务类型'))
    } else if (value.length > 10) {
      callback(new Error('长度限制在10位数以内'))
    } else {
      callback()
    }
  },
  validateServiceName(rule, value, callback) {
    if (!value) {
      callback(new Error('请输入服务名称'))
    } else if (value.length > 10) {
      callback(new Error('长度限制在10位数以内'))
    } else {
      callback()
    }
  },
  validateAirName(rule, value, callback) {
    if (!value) {
      callback(new Error('请输入空域名称'))
    } else if (value.length > 15) {
      callback(new Error('长度限制在15位数以内'))
    } else {
      callback()
    }
  },
  validateBatteryNumber(rule, value, callback) {
    let reg = /^[a-zA-Z0-9.-]+$/
    if (!reg.test(value) && value !== '') {
      callback(new Error('电池料号由数字字母符号.-组成'))
    } else {
      callback()
    }
  },
  validateBatteryType(rule, value, callback) {
    let reg = /^[a-zA-Z0-9]+$/
    if (!reg.test(value) && value !== '') {
      callback(new Error('电池型号由数字字母组成'))
    } else {
      callback()
    }
  },
}
