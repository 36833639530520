<template>
  <div class="wrap">
    <div class="hd">
      <!-- <el-tooltip class="item" effect="dark" content="筛选" placement="bottom">
        <div class="btn btn-search" @click="drawGeometry"></div>
      </el-tooltip> -->
      <el-tooltip class="item" effect="dark" content="删除" placement="bottom">
        <el-button circle :disabled="deleted_disabled" class="btn btn-deleted" @click="handle_deleted_all"></el-button>
      </el-tooltip>
      <el-dropdown  @command="handle_command">
        <span class="el-dropdown-link">
          <div class="btn btn-add"></div>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="point">添加点标注</el-dropdown-item>
          <el-dropdown-item command="polyline">添加线标注</el-dropdown-item>
          <el-dropdown-item command="polygon">添加面标注</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-tooltip class="item" effect="dark" content="全选" placement="bottom">
        <div class="btn btn-check-all" @click="handle_check_all"></div>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="导出报告" placement="bottom">
        <div class="btn btn-report" @click="handle_export"></div>
      </el-tooltip>
    </div>
    <div class="search">
      <el-input placeholder="请输入筛选的标注名称" v-model="search_value" class="input-with-select" clearable>
        <el-button slot="append" icon="el-icon-search" @click="handle_search"></el-button>
      </el-input>
    </div>
    <div class="bd">
      <div class="list">
        <div
          v-for="item in labelList"
          :key="item.markerId"
          class="item"
          :class="{active: currertMarkerId === item.markerId}"
          @click="handle_item_click(item)"
        >
          <div @click.stop>
            <el-checkbox :value="checked(item.markerId)" @change="(val) => on_change_checked(val, item)" class="checkbox"></el-checkbox>
          </div>
          <div class="item-img">
            <img :src="get_full_url(item.img)" alt="" />
          </div>
          <div class="item-body">
            <div class="item-text">
              <p class="item-title">{{ item.title }}</p>
              <!-- <p>{{ item.code }}</p> -->
              <!-- <p style="color: #dce5ed">等级：{{ item.level }}</p> -->
              <p>{{ item.address }}</p>
            </div>
            <div class="item-tags">
              <el-tag class="tag" size="mini" type="info" closable v-for="tag in item.tagList" :key="tag.id">{{tag.name}}</el-tag>
            </div>
            <div class="item-operation" v-show="currertMarkerId === item.markerId">
              <el-button class="btn" type="primary" size="mini" icon="el-icon-edit" @click.stop="handle_edit(item)"></el-button>
              <el-button class="btn" type="primary" size="mini" icon="el-icon-delete" @click.stop="handle_deleted(item)"></el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <el-pagination
          small
          background
          :pager-count="5"
          @current-change="handle_current_change"
          layout="prev, pager, next, jumper"
          :page-size="pageSize"
          :total="totalCount">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Lockr from 'lockr'
import { mapGetters, mapMutations } from 'vuex'
import API from "@/api";
// import marker from '@/components/marker'
// import iconData from '@/lib/cesium/iconData'
// import { createEntities } from '@/lib/cesium/iconData'
import { geomToCartesian3, addZero } from '@/lib/cesium/utils'
import { createPoint, createLine, createPolygon } from '@/lib/cesium/CreateLabel'
import { labelEntities } from '../../common.js'
import DrawTool from '@/lib/cesium/drawTool'
import noPhoto from '@/assets/images/map-manage/no_photo.png'

let draw_tool

export default {
  data() {
    return {
      labelList: [],
      currertMarkerId: null,
      pageSize: 10,
      pageNo: 1,
      totalCount: 0,
      checkList: [],
      deleted_disabled: false,
      handler: null,
      positions: [], //leftclick记录的点
      moveposition: [],// mouse move记录的点，总是比leftclick多一个点
      GeometryShape: null,
      search_value: '',
    }
  },
  computed: {
    ...mapGetters(["current_label_marker_id"]),
  },
  watch: {
    current_label_marker_id: {
      handler(val){
        if(val === 'update') {
          this.pageNo = 1
          this.get_label_list()
        }
      },
      // deep:true 
    },
  },
  methods: {
    ...mapMutations({
      set_label_modal_visible(commit, bool) {
        commit('mapmanage/SET_LABEL_MODAL_VISIBLE', bool)
      },
      set_current_label_marker_id(commit, id) {
        commit('mapmanage/SET_CURRENT_LABEL_MARKER_ID', id)
      },
      set_current_label_data(commit, id) {
        commit('mapmanage/SET_CURRENT_LABEL_DATA', id)
      }
    }),
    get_full_url(url) {
      // console.log(process.env.VUE_APP_BASE_URL.replace(/[:][0-9]+\/$/g, ""))
      return url ? process.env.VUE_APP_BASE_URL.replace(/[:][0-9]+$/g, "") + url : noPhoto
    },
    checked(markerId) {
      return this.checkList.indexOf(markerId) > -1
    },
    init_draw() {
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();
      draw_tool = new DrawTool({
        viewer: viewer,
        hasEdit: true
      });
    },
    async get_label_list() {
      let res = await API.MAPMANAGE.GetLabelList({
        title: this.search_value,
        pageSize: this.pageSize,
        pageNo: this.pageNo
      });
			this.labelList = res.data
      this.totalCount = res.totalCount
    },
    handle_search() {
      this.pageNo = 1
      this.get_label_list()
    },
    on_change_checked(val, item) {
      let index = this.checkList.indexOf(item.markerId)
      if(index > -1) {
        this.checkList.splice(index, 1);
      }else{
        this.checkList.push(item.markerId)
      }
    },
    handle_check_all() {
      if(this.checkList.length > 0) {
        this.checkList = []
      } else {
        this.labelList.forEach(item => {
          if(this.checkList.indexOf(item.markerId) == -1) {
            this.checkList.push(item.markerId)
          }
        })
      }
    },
    async handle_export() {
      let markerIds = this.checkList.join(',')
      window.open(`${process.env.VUE_APP_BASE_URL}/hawksystemserver/account/exportword?markerIds=${markerIds}&FLYINGSESSIONID=${Lockr.get("FLYINGSESSIONID")}&mmc-identity=${Lockr.get("IDENTITY")}`)
    },
    handle_item_click(item) {
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();
      
      if (item.markerId == this.currertMarkerId) {
        this.currertMarkerId = null
        // viewer.entities.remove(labelEntities[item.markerId].entity)
        // labelEntities[item.markerId].entity = null
        labelEntities[item.markerId] && labelEntities[item.markerId].entity && (labelEntities[item.markerId].entity.show = false)
        return
      }

      this.currertMarkerId = item.markerId
      if(labelEntities[item.markerId]) {
        labelEntities[item.markerId] && labelEntities[item.markerId].entity && (labelEntities[item.markerId].entity.show = true) && (viewer.flyTo(labelEntities[item.markerId].entity))
        
      } else {
        this.create_entities(item)
      }
    },
    addZero(num) {
      if (parseInt(num) < 10) {
        num = '0' + num
      }
      return num
    },
    create_entities(item) {
			let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();

      let { markerId, type, style, geom, title, icon } = item,
        { positions } = geomToCartesian3(geom)
      let icon_str = addZero(icon || 1)
      let image

      style = JSON.parse(style)

      let data = {
        positions,
        title,
        color: style.color || "rgba(255, 255, 0, 0.6)",
        methodState: 'update',
        width: style.width || 5,
        polyCenter: Cesium.BoundingSphere.fromPoints(positions).center,
        outlineColor: '#238ff3',
        item,
      }

      let entity
      if(type === 1) {
        image = require(`@/assets/images/point-icon/point_icon_${icon_str}.svg`)
        entity = createPoint(viewer, {
          position: data.positions[0],
          image,
          title: data.title,
        })
      } else if (type === 2) {
        entity = createLine(viewer, {
          polyCenter: data.polyCenter,
          positions: data.positions,
          width: data.width,
          color: data.color,
          title: data.title,
        })
      } else {
        entity = createPolygon(viewer, {
          polyCenter: data.polyCenter,
          positions: data.positions,
          color: data.color,
          title: data.title,
          outlineColor: data.outlineColor,
        })
      }

      viewer.flyTo(entity)

      labelEntities[markerId] = {
        entity: entity
      }
    },
    handle_edit(item) {
      let { markerId, geom} = item
      const { positions } = geomToCartesian3(geom)
      item.positions = positions
      if(item.type == 1) {
        item.drawType = 'point'
      }else if(item.type == 2) {
        item.drawType = 'polyline'
      }else if(item.type == 3) {
        item.drawType = 'polygon'
      }
      this.set_label_modal_visible(true)
      this.set_current_label_marker_id(markerId)
      this.set_current_label_data(item)
    },
    async deleted_label(ids) {
      let res = await API.MAPMANAGE.DropMarker({
        ids
      });
      if(res >= 1) {
        this.$message({
          message: '删除成功',
          type: 'success'
        });
        this.get_label_list()

        ids = String(ids)
        let idsArr = ids.split(',')
        idsArr.forEach(item => {
          let index = this.checkList.indexOf(parseInt(item))
          if(index > -1) {
            this.checkList.splice(index, 1);
          }
        })
      } else {
        this.$message.error('删除失败');
      }
      this.deleted_disabled = false
    },
    handle_deleted(item) {
      this.deleted_label(item.markerId)
    },
    handle_deleted_all() {
      this.deleted_disabled = true
      let ids = this.checkList.join(',')
      if(!ids) {
        this.$message('请选择要删除的标注');
        this.deleted_disabled = false
      } else {
        this.deleted_label(ids)
      }
    },
    handle_current_change(val) {
      this.pageNo = val
      this.get_label_list()
    },
    handle_command(drawType) {
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();
      draw_tool.startDraw({
        type: drawType,
        style: {},
        success: (evt, positions) => { // 绘制完成回调
          // viewer.entities.remove(evt)

          let title, type
          if(drawType === 'point') {
            type = 1
            title = '默认点标注'
          }else if(drawType === 'polyline') {
            type = 2
            title = '默认线标注'
          }else if(drawType === 'polygon') {
            type = 3
            title = '默认面标注'
          }

          // 为绘制 entity 添加默认 labelEntity
          // let pixelOffsetY = type === 'point' ? -32 : -18 
          const center = Cesium.BoundingSphere.fromPoints(positions).center 
          evt.position = center
          evt.label = new Cesium.LabelGraphics({
            text: title,
            font: "18px Helvetica",
            fillColor: Cesium.Color.WHITE,
            outlineColor: Cesium.Color.BLACK,
            outlineWidth: 2,
            style: Cesium.LabelStyle.FILL_AND_OUTLINE,
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            pixelOffset: new Cesium.Cartesian2(0.0, 32),
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND // 开启后点贴地形
          })

          labelEntities.add = {
            entity: evt
          }

          this.set_label_modal_visible(true)
          this.set_current_label_marker_id('add')
          this.set_current_label_data({
            type,
            drawType,
            title,
            positions,
            icon: 1
          })
        }
      });
    },
    drawGeometry(drawMode) {
      drawMode = 'polygon'
      let self = this;
      let viewer = window.viewer
      /*
      * 鼠标左键单击事件发生，用两个数组来存点坐标
      * 鼠标移动事件，利用两个数组长度比较，左键单击事件发生时，两个长度一样，moveposition总添加第一个移动的点坐标；
      * 当鼠标移动事件发生时，moveposition长度总比positions多1，moveposition[moveposition.length-1]更新为最后一个动点
      * 鼠标左键双击发生时，结束鼠标交互事件
      */
      self.handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      var activePosition;
      viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
      self.handler.setInputAction(function (e) {
          self.moveposition.pop();
          self.drawshape(self.moveposition, {
              drawMode: drawMode,
              color: Cesium.Color.RED,
              width: 3,
          });
          viewer.entities.remove(self.GeometryShape);
          self.GeometryShape = undefined;
          self.moveposition = [];
          self.positions = []
          self.handler.destroy();
      }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
      self.handler.setInputAction(function (e) {
          //此获取包含地形坐标的高度
          var positions = viewer.scene.pickPosition(e.position);
          // let ray = viewer.camera.getPickRay(e.position);
          // let leftclickcartesian = viewer.scene.globe.pick(ray, viewer.scene);
          //此获取的是无高度的空间直角坐标系
          // let positions= viewer.camera.pickEllipsoid(e.position, viewer.scene.globe.ellipsoid);
          //动态加面改进
          if (self.positions.length == 0) {
              activePosition = new Cesium.CallbackProperty(function () {
                  if (drawMode == 'polygon') {
                      return new Cesium.PolygonHierarchy(self.moveposition)
                  }
                  return self.moveposition;
              }, false)
              self.GeometryShape =
                  self.drawshape(activePosition, {
                      drawMode: drawMode,
                      color: Cesium.Color.RED,
                      width: 3,
                  });
          }
          self.positions.push(positions);
          self.moveposition.push(positions);
          //动态添加点
          viewer.entities.add({
              position: positions,
              point: {
                  pixelSize: 5,
                  color: Cesium.Color.BLUE,
                  outlineColor: Cesium.Color.WHITE,
                  outlineWidth: 1,
              },
          });
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

      self.handler.setInputAction(function (e) {
          // let ray = viewer.camera.getPickRay(e.endPosition);
          // let mousemovecartesian = viewer.scene.globe.pick(ray, viewer.scene);
          var mousemovecartesian = viewer.scene.pickPosition(e.endPosition);
          // let mousemovecartesian= viewer.camera.pickEllipsoid(e.endPosition, viewer.scene.globe.ellipsoid);
          //两个if条件控制，保证当moveposition的长度与positions的长度一致时，moveposition push 鼠标移动的点
          if (self.moveposition.length === self.positions.length + 1) {
              //当moveposition的长度比positions大1时，一直更新最新的动点
              self.moveposition[self.moveposition.length - 1] = mousemovecartesian;
              // console.log("this.moveposition",self.moveposition);
          }
          else if (self.moveposition.length === self.positions.length) {
              //长度一样添加鼠标动点坐标
              self.moveposition.push(mousemovecartesian);
          }

      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)
      // this.measure.measureAreaSpace(this.viewer,this.handler);
  },
  //绘制图形的函数
  drawshape(positions, config) {
    let viewer = window.viewer
      var config = config ? config : {};
      var shape
      if (config.drawMode == "polygon") {
          shape = viewer.entities.add({
              polygon: {
                  hierarchy: positions,//new Cesium.PolygonHierarchy(positions),                     
                  material: config.color ? config.color : new Cesium.ColorMaterialProperty(Cesium.Color.WHITE.withAlpha(0.7)),
                  width: config.width ? config.width : 2,
              }
          });
      } else if (config.drawMode == "polyline") {
          shape = viewer.entities.add({
              polyline: {
                  clampToGround: true,
                  positions: positions,
                  material: config.color ? config.color : new Cesium.Color.fromCssColorString("#FFD700").withAlpha(.2),
                  width: config.width ? config.width : 3,
              }
          });
      }
      return shape;
  },
  },
  created() {
    this.get_label_list()
    this.init_draw()
  },
  inject: ["g_cesium_layer"],
};
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.search {
  margin: 15px 0 10px;
  ::v-deep {
    .el-input__inner {
      color: #fff;
      background: #000000;
      border: 1px solid #08C2D1;
    }
    .el-input-group__append {
      font-size: 24px;
      font-weight: 700;
      color: #08C2D1;
      background: #000000;
      border-right: 1px solid #08C2D1;
      border-top: 1px solid #08C2D1;
      border-bottom: 1px solid #08C2D1;
      border-left: none;
    }
  }
}
.hd {
  display: flex;
}
.btn {
  margin: 0 20px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  border: none !important;
  &.btn-search {
    background: url("~@/assets/images/map-manage/search.svg") center no-repeat;
  }
  &.btn-deleted {
    background: url("~@/assets/images/map-manage/deleted.svg") center no-repeat;
  }
  &.btn-add {
    background: url("~@/assets/images/map-manage/add.svg") center no-repeat;
  }
  &.btn-check-all {
    background: url("~@/assets/images/map-manage/check-all.svg") center
      no-repeat;
  }
  &.btn-report {
    background: url("~@/assets/images/map-manage/report.svg") center no-repeat;
  }
}

.list {
  height: calc(100% - 120px);
  padding-top: 20px;
  overflow-y: scroll;
}

.item {
  position: relative;
  display: flex;
  width: 100%;
  padding: 10px 5px;
  position: relative;
  box-sizing: border-box;
  border: 1px solid transparent;
  cursor: pointer;
  &.active {
    border: 1px solid #00ffff;
  }
  .checkbox {
    position: absolute;
    top: 10px;
    right: 10px;
    ::v-deep {
      .el-checkbox__inner {
        border: 2px solid #979797;
        background: transparent;
      }
      .el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #20f0f0;
        border-color: #20f0f0;
      }
    }
  }
  &-img {
    width: 100px;
    height: 80px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-body {
    width: calc(100% - 100px);
    padding-left: 5px;
    p {
      width: 200px;
      margin: 0;
    }

    font-size: 12px;
    color: #7c8187;
    line-height: 16px;
    .item-tags {
      margin-top: 5px;
      .tag {
        margin-right: 5px;
        margin-bottom: 5px;
        background-color: transparent;
        border: 1px solid #E0E1E5;
        color: #7c8187;
      }
    }
    .item-operation {
      margin-top: 10px;
      .btn {
        margin-left: 0;
        width: 48px;
        height: 28px;
      }
    }
  }
  &-title {
    color: #dce5ed;
    font-size: 14px;
    margin-bottom: 3px !important;
  }
  &-check {
    z-index: 99999;
  }
}
.bd {
  height: 100%;
}
.page {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 0 20px;
}
// /deep/.el-checkbox__inner {
//   background: rgba(#000000, 0) !important; /* 透明 */
//   /* background:red 其他颜色 */
// }
</style>
