<template>
  <div class="page-command-home">
    <MapList/>
    <LabelModal v-if="label_modal_visible"/>
    <TagModal v-if="tag_modal_visible"/>
  </div>
</template>

<script>
import MapList from './components/manage-list'
import LabelModal from './components/label-modal'
import TagModal from './components/tag-modal'
import { mapGetters } from 'vuex'

export default {
  components: {
    MapList,
    LabelModal,
    TagModal
  },
  computed: {
    ...mapGetters(["label_modal_visible", "tag_modal_visible"])
  },
  inject: ['g_cesium_toolbar'],
  mounted() {
    this.g_cesium_toolbar().moveToolbar('center')
  },
};
</script>

<style lang="scss" scoped>

</style>