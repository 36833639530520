<template>
  <div class="cpt-command-device">
    <div class="box">
      <!-- <FlightInfo /> -->
      <div v-if="show"><FlightCheck class="width" @cut="cut" @user="userInfo" /></div>
      <div v-if="!show"><CommanderCheck class="width" @cut="cut" @user="userInfo" /></div>
    </div>
    <div class="box1">
      <!-- <CommanderInfo /> -->
      <Statistics @handle_detail="person_detail" class="width2" />
      <personDetail
        :data="list"
        :type="status"
        v-show="visible"
        @close="visible = false"
      />
      <Rank @handle_detail="task_detail" class="width2 ml16" />
      <taskDetail
        :data="taskList"
        :type="identity"
        v-show="visible2"
        @close="visible2 = false"
      />
    <UserInfo v-if="userVisible" @closeuser="shuaxin" :form="form" />
    </div>
  </div>
</template>

<script>
import API from "@/api";
import FlightInfo from "./flight-info";
import FlightCheck from "./flight-check";
import Statistics from "./statistics";
import CommanderInfo from "./commander-info";
import CommanderCheck from "./commander-check";
import Rank from "./rank";
import personDetail from "./person-detail";
import taskDetail from "./task-detail";
import UserInfo from "./userinfo.vue";

export default {
  components: {
    FlightInfo,
    FlightCheck,
    Statistics,
    CommanderInfo,
    CommanderCheck,
    Rank,
    personDetail,
    taskDetail,
    UserInfo,
  },
  data() {
    return {
      form:{},
      userVisible: false,
      list: [],
      status: null,
      taskList: [],
      visible: false,
      visible2: false,
      identity: null,
      show: false,
    };
  },
  methods: {
    userInfo(data){
      this.userVisible = true
      this.form = data
    },
    shuaxin() {
      this.userVisible = false;
      this.form = null;
      this.getFlightCheck();
    },
    cut() {
      this.show = !this.show;
    },
    person_detail(id, type) {
      this.visible2 = false;
      this.visible = true;
      this.status = type;
      this.getFlightCheck({
        pageSize: 10000,
        type: type,
        orgId: id,
      });
    },
    task_detail(item, type) {
      this.visible = false;
      this.visible2 = true;
      this.identity = type;
      this.getTaskdetail({
        type: type ? 2 : 1,
        id: item.id,
      });
    },
    async getFlightCheck(params) {
      let res = await API.PERSON.Check(params);
      this.list = res.data || [];
    },
    async getTaskdetail(params) {
      let res = await API.PERSON.getTaskdetail(params);
      this.taskList = res || [];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";

.dcbj {
  position: fixed;
  top: -90px;
  left: -15px;
  width: 102vw;
  height: 130vh;
  z-index: -99;
  background: url("~@/assets/images/背景.png") no-repeat;
  background-size: 100% 100%;
}
.cpt-command-device {
  background: url("~@/assets/images/command/device_bk.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 90px;
  overflow-y: auto;
  overflow-x: hidden;
  // overflow: auto;
  width: 100vw;
  z-index: 9;
  height: calc(100% - 90px);

  .box {
    background: rgba(12, 34, 73, 0.7);
    border-radius: 10px;
    border: 1px solid rgba(26, 92, 246, 0.5);
    backdrop-filter: blur(1px);
    margin: 0px 15px 14px 24px;
    // padding: 16px;
  }
  .box1 {
    width: 100%;
    display: flex;
    // background: rgba(12, 34, 73, 0.7);
    // border-radius: 10px;
    // border: 1px solid rgba(26, 92, 246, 0.5);
    // backdrop-filter: blur(1px);
    margin: 0px 15px 24px 24px;
    // padding: 16px;
  }
  .width {
    width: 100%;
  }
  .width2{
    width: 50%;
  }
}

/deep/.tb-pagination {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: right;
  
  .el-pagination {
    height: 30px;
    display: flex;
    align-items: center;
    button {
      background: transparent;
      font-size: 12px;
      color: #889fb2;
      text-align: center;
      font-weight: 400;
      &:disabled {
        background: transparent;
        color: #889fb2;
        opacity: 0.3;
      }
    }
    .el-pagination__total {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #b5e5ff;
      line-height: 22px;
    }
    .el-pagination__sizes {
      height: 30px;
      line-height: 30px;
      margin: 0px;
      .el-select {
        .el-input {
          width: 60px;
          padding-right: 20px;
          margin: 0;
          .el-input__inner {
            background: transparent;
            border: none;
            line-height: 30px;
            font-size: 12px;
            color: #b5e5ff;
            text-align: center;
            font-weight: 400;
            padding: 0px;
          }
        }
      }
    }
    .el-pager {
      .number {
        background: transparent;
        font-size: 12px;
        color: #fff;
        text-align: center;
        font-weight: 400;
        &.active {
          background: rgba(38, 71, 238, 0.71);
          border-radius: 2px;
          color: #ffffff;
        }
      }
      .more {
        background: transparent;
        color: #889fb2;
      }
    }
    .el-pagination__jump {
      font-size: 12px;
      color: #889fb2;
      text-align: center;
      font-weight: 400;
      height: 30px;
      line-height: 30px;
      margin-left: 6px;
      .el-input {
        .el-input__inner {
          border: 1px solid rgba(36, 146, 252, 0.3);
          border-radius: 2px;
          background: rgba(12, 13, 20, 0.5);
          color: #889fb2;
        }
      }
    }
  }
  ._jumper {
    font-size: 12px;
    color: #889fb2;
    text-align: center;
    font-weight: 400;
    display: flex;
    align-items: center;
    white-space: nowrap;
    ::v-deep .el-input {
      .el-input__inner {
        background: rgba(12, 13, 20, 0.5);
        border: 1px solid rgba(36, 146, 252, 0.3);
        border-radius: 2px;
        width: 33px;
        height: 24px;
        color: #889fb2;
        padding: 0;
        text-align: center;
      }
    }
    ._jumper_btn {
      width: 51px;
      height: 22px;
      background: #333c57;
      border: 1px solid $primary-border-color;
      border-radius: 2px;
      padding: 0px;
      font-size: 14px;
      color: $primary-color;
      letter-spacing: -0.08px;
      text-align: center;
      font-weight: 400;
      margin-left: 4px;
    }
  }

  .el-pager .number:hover {
    border-radius: 2px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    background: rgba(38, 71, 238, 0.71) !important;
    width: 14px;
    height: 22px;
    line-height: 22px;
  }
  .el-pagination--small .el-pager .number {
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #889fb2;
  }
}
</style>
