

export default {
    selected_list: [],
    current_log_id: null,
    // current_log_id: 2669,
    list: [
        // {
        //     "id": 99,
        //     "flightName": "盐城市公安局特警实战训练基地",
        //     "flightCourseJson": "{\"filename\":\"盐城市公安局特警实战训练基地\",\"line\":{\"baseSpeed\":\"6\"},\"points\":[{\"lon\":120.27903350712974,\"lat\":33.323467226707685,\"alt\":80,\"pitch\":0,\"gimbalPitch\":0,\"actions\":[],\"uuid\":\"hpq3pTSR6CRkwlEx3dwsr\"},{\"lon\":120.28063740223645,\"lat\":33.324380981016766,\"alt\":80,\"pitch\":0,\"gimbalPitch\":0,\"actions\":[],\"uuid\":\"CJcu9xU4nco6pRkJCIzQe\"},{\"lon\":120.28169028150577,\"lat\":33.325095712793285,\"alt\":80,\"pitch\":0,\"gimbalPitch\":0,\"actions\":[],\"uuid\":\"dRFm5KQXXIncgTl9M02vv\"},{\"lon\":120.28275554653278,\"lat\":33.32565686479966,\"alt\":80,\"pitch\":0,\"gimbalPitch\":0,\"actions\":[],\"uuid\":\"StIOnf4MDjXkurGkvhC6L\"},{\"lon\":120.28384398459185,\"lat\":33.326254292825865,\"alt\":80,\"pitch\":0,\"gimbalPitch\":0,\"actions\":[],\"uuid\":\"suxAFGL_Vbk1zWQUvPfez\"},{\"lon\":120.28488513506213,\"lat\":33.32676423458521,\"alt\":80,\"pitch\":0,\"gimbalPitch\":0,\"actions\":[],\"uuid\":\"jdjrZHYqBi-Ufgyqo90MG\"},{\"lon\":120.28553668055176,\"lat\":33.326496520824406,\"alt\":80,\"pitch\":0,\"gimbalPitch\":0,\"actions\":[],\"uuid\":\"bCjE1SWcbmCGjSt83bRsK\"}]}",
        //     "deviceHardId": null,
        //     "taskCateId": 1,
        //     "taskStatus": 0,
        //     "addtime": "2021-09-13 02:36:16",
        //     "executeTime": null,
        //     "currentDay": false
        // },
        // {
        //     "id": 98,
        //     "flightName": "盐城市公安局特警实战训练基地",
        //     "flightCourseJson": "{\"filename\":\"盐城市公安局特警实战训练基地\",\"line\":{\"baseSpeed\":\"6\"},\"points\":[{\"lon\":120.2846452453565,\"lat\":33.32579262499931,\"alt\":100,\"pitch\":0,\"gimbalPitch\":0,\"actions\":[],\"uuid\":\"ia-3uCJet3qAXkrmM2lmt\"},{\"lon\":120.28483567755458,\"lat\":33.32545550413584,\"alt\":100,\"pitch\":0,\"gimbalPitch\":0,\"actions\":[],\"uuid\":\"KJOgnzg0UW13-a75KZNBW\"},{\"lon\":120.28524647510014,\"lat\":33.32498131866131,\"alt\":100,\"pitch\":0,\"gimbalPitch\":0,\"actions\":[],\"uuid\":\"VmcaJrVY9W16BgSaCTh2t\"},{\"lon\":120.28564107033996,\"lat\":33.32484982259727,\"alt\":100,\"pitch\":0,\"gimbalPitch\":0,\"actions\":[],\"uuid\":\"tPU8XBgDlmJDdCzNymgpD\"},{\"lon\":120.28584210993095,\"lat\":33.32515402279038,\"alt\":100,\"pitch\":0,\"gimbalPitch\":0,\"actions\":[{\"label\":\"拍照\",\"actionType\":\"START_TAKE_PHOTO\",\"actionParam\":0}],\"uuid\":\"tQFq9UpH-mS3q2RLlakqg\"},{\"lon\":120.28576334302895,\"lat\":33.325455318724835,\"alt\":100,\"pitch\":0,\"gimbalPitch\":0,\"actions\":[{\"label\":\"开始录像\",\"actionType\":\"START_RECORD\",\"actionParam\":0}],\"uuid\":\"hliS8lvHKHoxMw6Fqg-uh\"},{\"lon\":120.28527224996371,\"lat\":33.325847907101874,\"alt\":100,\"pitch\":0,\"gimbalPitch\":0,\"actions\":[{\"label\":\"停止录像\",\"actionType\":\"STOP_RECORD\",\"actionParam\":0}],\"uuid\":\"3V6OYU0BNDZKk3b4ryCec\"},{\"lon\":120.28518770450093,\"lat\":33.32605026708761,\"alt\":100,\"pitch\":0,\"gimbalPitch\":0,\"actions\":[{\"label\":\"悬停\",\"input\":\"el-input-number\",\"min\":0,\"max\":32000,\"actionType\":\"STAY\",\"actionParam\":10}],\"uuid\":\"gi9y0KKByIDmKGp8V_NxG\"}]}",
        //     "deviceHardId": null,
        //     "taskCateId": 2,
        //     "taskStatus": 0,
        //     "addtime": "2021-09-16 10:56:54",
        //     "executeTime": null,
        //     "currentDay": false
        // },
    ],
    status: {
        status: 1
    },
    search: {
        name: null
    },
    loading: false,
    is_show_warning: false,
    warning_cont: '',
    warning_num: 1,
    is_show_hedgehop: false,
    pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0
    }
}