import point_icon_01 from '@/assets/images/point-icon/point_icon_01.svg'

export const CreatePoint = function(viewer, style) {
	this.objId = Number((new Date()).getTime() + "" + Number(Math.random() * 1000).toFixed(0)); 
	this.viewer = viewer;
	this.handler = new Cesium.ScreenSpaceEventHandler(this.viewer.scene.canvas);
	this.modifyHandler = new Cesium.ScreenSpaceEventHandler(this.viewer.scene.canvas);
	this.polyline = null;
	this.positions = [];
	this.style = style;
	this.floatPoint = null;
	this.linePointArr = [];
	this.modifyPoint = null;
	this.state = 0; //1为新增 2为编辑 0为清除
	this.centerPoint = null
}

CreatePoint.prototype = {
	startCreate: function(callBack) {
		document.documentElement.style.cursor = 'crosshair';

		var $this = this;
		this.handler.setInputAction(function(evt) { //单机开始绘制
			var cartesian = $this.getCatesian3FromPX(evt.position,$this.viewer,[$this.polyline]);
			if ($this.positions.length == 0) {
				$this.positions.push(cartesian.clone());
				$this.floatPoint = $this.createPoint(cartesian.clone());
			}
			$this.positions.push(cartesian);
			// var point = $this.createPoint(cartesian);
			var point = $this.createBillboard(cartesian);
			// point.wz = $this.linePointArr.length;
			// $this.linePointArr.push(point);
			$this.handler.destroy();
			document.documentElement.style.cursor = 'default';
			callBack(point, $this.positions);
		}, Cesium.ScreenSpaceEventType.LEFT_CLICK);
		this.handler.setInputAction(function(evt) { //移动时绘制线
			if ($this.positions.length < 1) return;
			var cartesian = $this.getCatesian3FromPX(evt.endPosition,$this.viewer,[$this.polyline]);
			if($this.floatPoint) $this.floatPoint.position.setValue(cartesian);
			if ($this.positions.length == 2) {
				if (!Cesium.defined($this.polyline)) {
					$this.polyline = $this.createPolyline($this.style);
				}
			} 
			if ($this.polyline) {
				$this.positions.pop();
				$this.positions.push(cartesian);
			}
		}, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
		this.handler.setInputAction(function(evt) { //单机开始绘制
			if(!$this.polyline) return ;
			var cartesian = $this.getCatesian3FromPX(evt.position,$this.viewer,[$this.polyline]);
			$this.state = 1;
			$this.handler.destroy();
			if($this.floatPoint){
				if($this.floatPoint) $this.floatPoint.show = false;
				$this.floatPoint = null;
			}
			
			$this.positions.pop();
			$this.positions.push(cartesian);
			var point = $this.createPoint(cartesian);
			point.wz = $this.linePointArr.length;
			$this.linePointArr.push(point);
			
			// 获取中心点
			const polyCenter = Cesium.BoundingSphere.fromPoints($this.positions).center
			// $this.centerPoint = $this.createPoint(polyCenter)
			const centerPoint = $this.createPoint(polyCenter);
			centerPoint.wz = $this.linePointArr.length;
			centerPoint.zx = 1;
			$this.linePointArr.push(centerPoint); 

			document.documentElement.style.cursor = 'default';
			callBack($this.polyline, $this.positions);
		}, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
	},
	createByPositions:function(lnglatArr,callBack){ //通过传入坐标数组创建面
		if(!lnglatArr) return ;
		var positions = this.lnglatArrToCartesianArr(lnglatArr);
		if(!positions) return ;
		this.polyline = this.createPolyline(this.style);
		this.positions = positions;
		callBack(this.polyline);
		for(var i=0;i<positions.length;i++){
			var point = this.createPoint(positions[i]);
			point.wz = this.linePointArr.length;
			this.linePointArr.push(point);
		}
		this.state = 1;
	},
	
	startModify: function() {
		if(this.state!=2 && this.state !=1) return ; //表示还没绘制完成
		if(!this.modifyHandler) this.modifyHandler = new Cesium.ScreenSpaceEventHandler(this.viewer.scene.canvas); 
		var $this = this;
		for(var i=0;i<$this.linePointArr.length;i++){
			var point = $this.linePointArr[i];
			if(point) point.show = true;
		}
		this.modifyHandler.setInputAction(function(evt) { 
			document.documentElement.style.cursor = 'move';
			var pick = $this.viewer.scene.pick(evt.position);
			if (Cesium.defined(pick) && pick.id){
				if(!pick.id.objId)
					$this.modifyPoint = pick.id;
				$this.forbidDrawWorld(true);	
			}else{
				for(var i=0;i<$this.linePointArr.length;i++){
					var point = $this.linePointArr[i];
					if(point) point.show = false;
				}
				if($this.modifyHandler){
					$this.modifyHandler.destroy();
					$this.modifyHandler = null;
				} 
			}
			$this.state = 2;
		}, Cesium.ScreenSpaceEventType.LEFT_DOWN);
		this.modifyHandler.setInputAction(function(evt) { //移动时绘制线
			if ($this.positions.length < 1 || !$this.modifyPoint) return;
			//记录尾随的坐标
			let startPosition = $this.getCatesian3FromPX(evt.startPosition, $this.viewer,[$this.polyline,$this.modifyPoint]);
			let endPosition = $this.getCatesian3FromPX(evt.endPosition, $this.viewer,[$this.polyline,$this.modifyPoint]);
			if(endPosition){
				if($this.modifyPoint.zx) {
					//计算每次的偏差
					if(startPosition) {
						let changed_x = endPosition.x-startPosition.x;
						let changed_y = endPosition.y-startPosition.y;
						let changed_z = endPosition.z-startPosition.z;
						$this.modifyPoint.position.setValue(endPosition);
						for(let i=0; i<$this.positions.length; i++){
							//与之前的算差 替换掉
							$this.positions[i].x = $this.positions[i].x + changed_x;
							$this.positions[i].y = $this.positions[i].y + changed_y;
							$this.positions[i].z = $this.positions[i].z + changed_z;
						}
					}
				} else {
					$this.modifyPoint.position.setValue(endPosition);
					$this.positions[$this.modifyPoint.wz] = endPosition;
				}
			}
		}, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
		
		this.modifyHandler.setInputAction(function(evt) { 
			if(!$this.modifyPoint) return;
			// var cartesian = $this.getCatesian3FromPX(evt.position, $this.viewer,[$this.polyline,$this.modifyPoint]);
			// $this.modifyPoint.position.setValue(cartesian);
			// $this.positions[$this.modifyPoint.wz] = cartesian;
			$this.modifyPoint = null;
			$this.forbidDrawWorld(false);
			document.documentElement.style.cursor = 'default';
		}, Cesium.ScreenSpaceEventType.LEFT_UP);
	},
	createBillboard: function(position) {
		return this.viewer.entities.add({
			position: position,
			billboard: {
			  image: point_icon_01,
			  width: 32,
			  height: 32,
			  disableDepthTestDistance: Number.POSITIVE_INFINITY,
			},
		});
	},
	createPoint: function(position) {
		if (!position) return;
		return this.viewer.entities.add({
			position: position,
			point: {
				pixelSize:5,
				color:Cesium.Color.YELLOW,
				outlineWidth:2,
				outlineColor:Cesium.Color.DARKRED,
				// heightReference:Cesium.HeightReference.CLAMP_TO_GROUND,
				disableDepthTestDistance : Number.POSITIVE_INFINITY
			},
			show : false
		});
	},
	createPolyline: function(obj) {
		if(!obj) obj = {};
		var $this = this;
		var polyline =  this.viewer.entities.add({
			polyline: {
				positions: new Cesium.CallbackProperty(function() {
					return $this.positions
				}, false),
				clampToGround: obj.clampToGround ,
				show: true,
				fill: obj.fill || true ,
				// material:  obj.material || Cesium.Color.WHITE,
				material:  obj.material || Cesium.Color.fromCssColorString('rgba(255, 255, 0, 0.6)'),
				width: obj.width || 3
			}
		});
		polyline.objId = this.objId;
		return polyline;
	},
	getPositions: function() {
		return this.positions;
	},
	setStyle: function(obj) {},
	remove: function() {
		if (this.polyline) {
			this.state = 0;
			this.viewer.entities.remove(this.polyline);
			this.polyline = null;
		}
	},
	setVisible: function(vis) {
		this.polyline.show = vis;
	},
	forbidDrawWorld:function(isForbid){
		this.viewer.scene.screenSpaceCameraController.enableRotate = !isForbid;
        this.viewer.scene.screenSpaceCameraController.enableTilt = !isForbid;
        this.viewer.scene.screenSpaceCameraController.enableTranslate = !isForbid;
        this.viewer.scene.screenSpaceCameraController.enableInputs = !isForbid;
	},
	destroy:function(){
		this.linePointArr = [];
		
		if(this.handler){
			this.handler.destroy();
			this.handler = null;
		}
		if(this.modifyHandler){
			this.modifyHandler.destroy();
			this.modifyHandler = null;
		}
		if(this.polyline){
			this.viewer.entities.remove(this.polyline);
			this.polyline = null;
		}
		if(this.floatPoint){
			this.viewer.entities.remove(this.floatPoint);
			this.floatPoint = null;
		}
		this.positions = [];
		this.style = null;
		for(var i=0;i<this.linePointArr.length;i++){
			var point = this.linePointArr[i];
			this.viewer.entities.remove(point);
		}
		this.linePointArr = [];
		this.modifyPoint = null;
	},
	getCatesian3FromPX:function(px, viewer, entitys) {
		var pick = viewer.scene.pick(px);
		var cartesian;
		var drillPick = viewer.scene.drillPick(px);
		var truePick = null;
		// if (entitys) {
		// 	for (var i = 0; i < drillPick.length; i++) {
		// 		if (drillPick[i].id._id != entitys[0].id && drillPick[i].id._id != entitys[1].id) {
		// 			truePick = drillPick[i].id;
		// 			break;
		// 		}
		// 	}
		// } else {
		// 	truePick = pick;
		// }
		// if (viewer.scene.pickPositionSupported && Cesium.defined(truePick)) {
		// 	console.log('truePick')
		// 	cartesian = viewer.scene.pickPosition(px);
		// } else {
		// 	var ray = viewer.camera.getPickRay(px);
		// 	if (!ray) return;
		// 	cartesian = viewer.scene.globe.pick(ray, viewer.scene);
		// }
		// return cartesian;
		viewer.render();
		let bool = drillPick.some(item => {
			return item.content
		})
		if (bool > 0) {
			cartesian = viewer.scene.pickPosition(px);
			// console.log('truePick', cartesian)
		} else {
			var ray = viewer.camera.getPickRay(px);
			if (!ray) return;
			cartesian = viewer.scene.globe.pick(ray, viewer.scene);
		}
		return cartesian;
	},
	lnglatArrToCartesianArr:function(lnglatArr){
		if (!lnglatArr) return [];
		var arr = [];
		for (var i = 0; i < lnglatArr.length; i++) {
			arr.push(Cesium.Cartesian3.fromDegrees(lnglatArr[i]));
		}
		return arr;
	}
}


