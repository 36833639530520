<!-- 飞行任务1027 -->
<!-- 头部 -->
<template>
  <div class="cpt-command-flight-task">
    <div class="header" v-interact>
      <div class="flex">
        <div class="xian">
          <img
            src="~@/assets/yingkong1027/jinye.png"
            style="width: 100%; height: 100%"
            alt=""
          />
        </div>
        <div class="hd-box center">飞行任务</div>
      </div>
      <div class="fontOne cp mr20" @click="change_page">关闭</div>
    </div>
    <div class="task_nav">
      <div
        v-for="(item, index) in nav_list"
        :key="item.id"
        @click="abc(index,item.id)"
        class="task_nav_box"
        :class="active == item.id ? 'active' : ''"
      >
        <img :src="item.img" alt="" class="img" />
        <div>{{ item.name }}</div>
      </div>
    </div>
    <SituationList
      @returnEX="(e)=>$emit('returnEX',e)"
      :examineData="examineData"
      v-if="active == 1"
      @handleLocation="handlePosition"
      class="nb16"
    />
    <NormalList :examineData="examineData" v-if="active == 2" class="nb16" :numId="num" @returnEX="(e)=>$emit('returnEX',e)" />
    <UrgentList v-if="active == 3" class="nb16" :numId="num" />
    <FlightLogList v-if="active == 4" class="nb16" />
  </div>
</template>

<script>
import SituationList from "./situation";
import NormalList from "./normal";
import UrgentList from "./urgent";
import FlightLogList from "./flight-log";
// 切换图片
import jingBai from "@/assets/newImage/jingdengbai.png";
import jingHui from "@/assets/newImage/jingdenghui.png";
import renwuHui from "@/assets/newImage/renwuhui.png";
import rewnuBai from "@/assets/newImage/renwubai.png";
import jinjiHui from "@/assets/newImage/jinjihui.png";
import jinjiBai from "@/assets/newImage/jinjibai.png";
import feixingHui from "@/assets/newImage/feixinghui.png";
import feixingBai from "@/assets/newImage/feixingbai.png";

let explain_box = [
  { name: "审批", icon: "iconfont icon-shenhe" },
  { name: "派发", icon: "iconfont icon-a-bianji2px" },
  { name: "下载", icon: "iconfont icon-a-xiazai2px" },
  { name: "详情", icon: "iconfont icon-xiangqing" },
  { name: "定位", icon: "iconfont icon-dingwei" },
  { name: "航线预览", icon: "iconfont icon-guiji" },
  { name: "重新提交", icon: "iconfont icon-zhongxintijiao" },
  { name: "历史视频", icon: "iconfont icon-lishishipin" },
  { name: "历史图片", icon: "iconfont icon-lishizhaopian" },
  { name: "历史轨迹", icon: "iconfont icon-lishiguiji" },
  { name: "删除", icon: "iconfont icon-shanchu" },

  // { name: "AI配置", icon: "iconfont icon-AIpeizhi" },
];

export default {
  inject: ["change_page", "change_media_is_show"],
  components: {
    SituationList,
    NormalList,
    UrgentList,
    FlightLogList,
  },
  props: {
    num: {
      type: Number,
    },
    active:{
      type:Number,
      default:2
    },
    examineData:{
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      jingdeng: jingHui,
      renwu: renwuHui,
      jinji: jinjiHui,
      feixing: feixingHui,
      nav_list: [
        { name: "常态任务", id: 2, img: renwuHui },
        { name: "临时紧急任务", id: 3, img: jinjiHui },
        { name: "警情任务", id: 1, img: jingHui },
        { name: "飞行日志", id: 4, img: feixingHui },
      ],
      ws: "",
      explain_box: explain_box,
    };
  },
  watch:{
    examineData(val){
      // console.log(val,"command");
    }
  },
  mounted() {
    this.abc(0);
  },
  methods: {
    // 重新给予图片路径
    abc(index,id) {
      this.$emit("changeActive",id)
      if (index == 2) {
        // 警情任务
        this.nav_list[0].img = jingBai;
        this.nav_list[1].img = renwuHui;
        this.nav_list[2].img = jinjiHui;
        this.nav_list[3].img = feixingHui;
      } else if (index == 0) {
        // 常态任务
        this.nav_list[0].img = jingHui;
        this.nav_list[1].img = rewnuBai;
        this.nav_list[2].img = jinjiHui;
        this.nav_list[3].img = feixingHui;
      } else if (index == 1) {
        // 临时紧急任务
        this.nav_list[0].img = jingHui;
        this.nav_list[1].img = renwuHui;
        this.nav_list[2].img = jinjiBai;
        this.nav_list[3].img = feixingHui;
      } else if (index == 3) {
        //飞行记录
        this.nav_list[0].img = jingHui;
        this.nav_list[1].img = renwuHui;
        this.nav_list[2].img = jinjiHui;
        this.nav_list[3].img = feixingBai;
      }
    },
    handlePosition(item) {
      this.$emit("handleLocation", item);
    },
  },
  destroyed() {
    this.change_media_is_show(false);
  },
};
</script>

<style lang="scss" scoped>
.cpt-command-flight-task::v-deep {
  position: absolute;
  left: 10px;
  top: 99px;
  z-index: 0;

  width: 1820px;
  height: 630px;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
  
  .btn1027{
    padding: 7px 18px;
  }

  .tb-box .tb-bd-box .tb-tr .iconfont {
    color: rgba(0, 231, 255, 1);
    cursor: pointer;
  }

  .tb-box .tb-bd-box .tb-tr{
    cursor: initial;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;
  }

  .task_nav {
    position: absolute;
    left: 12px;
    top: 48px;
    display: flex;

    .task_nav_box {
      box-sizing: border-box;
      padding: 7px 8px;
      height: 32px;
      cursor: pointer;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2d9ff;
      color: #c1c1c1;
      display: flex;
      justify-content: center;
      align-content: center;
      background-image: url("~@/assets/yingkong1027/btn_hei.png");
      background-size: 100% 100%;
      margin: 0 14px 0 0;
      line-height: 20px;
      &:hover {
        background-image: url("~@/assets/yingkong1027/btn_lan.png");
        color: #fff;
      }
    }

    .active {
      background-image: url("~@/assets/yingkong1027/btn_lan.png");
      color: #fff;
    }
  }

  .hd-box {
    font-size: 20px;
    font-family: YouSheBiaoTiHei;
    color: #14faff;
    line-height: 26px;
    text-shadow:none !important;
    background: linear-gradient(
      135deg,
      #e3aa77 0%,
      #f5cda9 38%,
      #f9ecd3 58%,
      #fcdbb1 79%,
      #edb07a 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
  }
}
// ------------------------------------------
.flex {
  display: flex;
}
.xian {
  width: 26px;
  height: 26px;
  margin: 0px 0 0 0;
}
.center {
  margin-left: 5px !important;
  color: #ffffff !important;
  text-shadow: 0px 2px 4px #136791;
}
.img {
  width: 20px;
  height: 20px;
  margin: 0px 4px 0 0;
}
.nb16 {
  padding: 0 16px 0 16px;
}
.fontOne {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #d2dfff;
}
</style>