<template>
  <div class="cpt-command-task-normal">
    <div class="search-box">
      <div class="flexs waikuang shijanSelect">
        <el-date-picker
          prefix-icon="ccc"
          v-model="startYear"
          type="year"
          :picker-options="StartPickerOptions"
          value-format="yyyy"
          placeholder="开始年度"
          popper-class="flight-log"
        >
        </el-date-picker>
        <el-date-picker
          prefix-icon="ccc"
          v-model="endYear"
          type="year"
          :picker-options="EndPickerOptions"
          value-format="yyyy"
          placeholder="结束年度"
          popper-class="flight-log"
        >
        </el-date-picker>
      </div>
      <div class="flexs waikuang shijanSelect">
        <el-date-picker
          prefix-icon="ccc"
          v-model="pickTime"
          type="daterange"
          range-separator="至"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          popper-class="flight-log"
        >
        </el-date-picker>
      </div>
      <div class="flexs waikuang">
        <el-cascader
          class="input-orgname"
          placeholder="请选择责任单位"
          v-model="search.orgId"
          :options="unitList"
          :show-all-levels="false"
          :props="{
            children: 'children',
            label: 'orgName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
          }"
          clearable
        >
        </el-cascader>
      </div>

      <div @click="get_list" class="btn1027">搜索</div>
      <div @click="handle_upload" class="btn1027">导出</div>
    </div>
    <div class="tb-box">
      <div class="tb-hd-box">
        <div class="tb-hd w150">排名</div>
        <div class="tb-hd">年度</div>
        <div class="tb-hd">单位名称</div>
        <div class="tb-hd">任务合计（件）</div>
        <div class="tb-hd sort">已完成数（件）</div>
        <div class="tb-hd sort">未完成数（件）</div>
        <div class="tb-hd sort">完成率（%）</div>
      </div>
      <div v-if="!loading" class="tb-bd-box">
        <template v-if="list.length > 0">
          <div class="tb-tr" v-for="item in list" :key="item.id">
            <!-- 结构:最外层为td层,第二层为element的标签提示,判定此格子里的字符有没有超过9个 -->
            <!-- 超过9个就开启提示标签,没有就关闭 -->
            <!-- 第三层的button是和第二层一起的 -->
            <!-- 第四层的div为动画层,超过9个字符就赋予动画效果 -->
            <!-- 第一排 -->
            <div class="td w170">
              {{ item.index || "暂无" }}
            </div>
            <div class="td">
              {{ item.yearTime || "暂无" }}
            </div>
            <!-- 第一排 -->
            <!-- 第二排 -->
            <div class="td">
              {{ item.orgName || "暂无" }}
            </div>
            <div class="td">
              {{ item.taskTotal || "暂无" }}
            </div>
            <!-- 第二排 -->
            <!-- 第三排 -->
            <div class="td tdhover" @click="jumpRoute(item,4)">{{ item.finishCount || "0" }}</div>
            <!-- 第三排 -->
            <!-- 第四排 -->
            <div class="td tdhover" @click="jumpRoute(item,2)">
              {{ item.noFinishCount || "0" }}
            </div>
            <!-- 第四排 -->
            <!-- 第五排 -->
            <div class="td">
              {{ item.percent || "0" }}%
            </div>
            <!-- 第五排 -->
          </div>
        </template>
        <div v-else-if="!loading && list.length == 0" class="no-data">
          暂无数据
        </div>
      </div>
      <div v-else class="loading-data">
        <div>
          <i class="el-icon-loading"></i>
          <p>加载中...</p>
        </div>
      </div>
    </div>
    <div class="tb-pagination">
      <div class="zongji">
        <span>共</span>
        <span>{{ pagination.totalCount }}</span>
        <span>条</span>
      </div>
      <span class="douhao zongji">,</span>
      <div class="zongji dangqianye">
        <span>{{ pagination.pageNo }}</span>
        <span>/</span>
        <span>{{ zongye(pagination.totalCount) }}</span>
        <span>页</span>
      </div>
      <el-pagination
        small
        :page-sizes="[10, 20, 30, 40]"
        :current-page.sync="pagination.pageNo"
        :page-size="pagination.pageSize"
        layout="prev, pager, next"
        :total="pagination.totalCount"
        @size-change="handle_size_change"
        @current-change="handle_page_change"
      >
      </el-pagination>
      <div class="zhuan">
        <span>前往</span>
        <input type="text" v-model="qianVal" />
        <span>页</span>
      </div>
      <el-button class="btnqueding" @click="queding">确定</el-button>
    </div>
  </div>
</template>

<script>
let baseUrl = process.env.VUE_APP_BASE_URL
import { mapGetters } from "vuex";
import API from "@/api";
export default {
  computed: {
    ...mapGetters(["user_info"]),
  },
  watch: {
  },
  data() {
    return {
      pickTime: null,
      startYear: "",
      endYear:"",
      StartPickerOptions:this.beginDate(),
      EndPickerOptions:this.processDate(),
      qianVal: "", //跳转页码标签的值
      search: {
        orgId: null,
        startTime: null,
        endTime: null,
      },
      list: [],
      loading: false,
      unitList: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
      },
    };
  },
  async mounted() {
    await this.get_list();
    await this.getUnitList();
  },
  destroyed() {},
  methods: {
    beginDate() {
      let _this = this
      return {
        disabledDate(time) {
          let date = new Date()
          let fixedTime = new Date(time)
          if (_this.endYear !== '' && _this.endYear !== null) {
            return fixedTime.getFullYear() > _this.endYear || date.getFullYear() < fixedTime.getFullYear()
          }else{
            return date.getFullYear() < fixedTime.getFullYear()
          }
        }
      }
    },
    // 提出结束时间必须大于提出开始时间
    processDate() {
      let _this = this
      return {
        disabledDate(time) {
          let date = new Date()
          let fixedTime = new Date(time)
          if(_this.startYear !== ''){
            return fixedTime.getFullYear() < _this.startYear || date.getFullYear() < fixedTime.getFullYear()
          }else{
            return date.getFullYear() < fixedTime.getFullYear()
          }
        }
      }
    },
    // 手动页码跳转
    queding() {
      this.handle_page_change(this.qianVal);
    },
    // 计算总页码
    zongye(num) {
      let a = num / 10;
      var result = a.toString().indexOf(".");
      if (result != -1) {
        let b = parseInt(a);
        return b + 1;
      } else {
        return a;
      }
    },
    up(val) {
      this.search.orderBy = val
      this.search.orderType = 0
      this.get_list()
     },
    down(val) {
      this.search.orderBy = val
      this.search.orderType = 1
      this.get_list()
    },
    handle_upload() {
    this.$el_message("导出中...", () => {}, "info", 0);
      let orgId = this.search.orgId ? "&orgId=" + this.search.orgId : "";
      let startTime = this.search.startTime
        ? "&startTime=" + this.search.startTime
        : "";
      let endTime = this.search.endTime
        ? "&endTime=" + this.search.endTime
        : "";
      // downloadDataExcel(
      //   `/hawksystemserver/flightMission/statisticsTaskData/down?FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}&TaskType=0${orgId}${startTime}${endTime}`,
      //   "常态考核"
      // );
      let _this = this
      let fileURL=baseUrl+`/hawksystemserver/flightMission/statisticsTaskData/down?FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}&TaskType=0${orgId}${startTime}${endTime}`
      fetch(fileURL).then(response => {
  if (!response.ok) {
    throw new Error('下载文件失败');
  }

  const reader = response.body.getReader();

  return new Response(new ReadableStream({
    start(controller) {
      function read() {
        reader.read().then(({ done, value }) => {
          console.log(done,value);
          if (done) {
            controller.close();
            return;
          }

      _this.$el_message("导出完成", () => {}, "success");
          controller.enqueue(value);
          read();
        });
      }

      read();
    }
  }));
}).then(response => {
  // 将下载完成的文件进行保存
  response.blob().then(blob => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = "常态飞行任务考核.xls";
    link.click();
    URL.revokeObjectURL(url); // 释放对象 URL
  });
}).catch(error => {
  console.error('下载文件出错：', error);
});
    },
    async getUnitList() {
      let data = await API.DEPARTMENT.Drd();
      this.unitList = [data] || [];
    },
    async get_list() {
      if (this.pickTime) {
        this.search.startTime = this.pickTime[0];
        this.search.endTime = this.pickTime[1];
      } else {
        this.search.startTime = null;
        this.search.endTime = null;
      }
      if(this.startYear){
        this.search.startYear = this.startYear
        this.search.endYear = this.endYear
      }else{
        this.search.startYear = null
        this.search.endYear = null
      }
      this.loading = true;
      let res = await API.EXAMINE.List({
        TaskType: 0,
        ...this.pagination,
        ...this.search,
      });

      this.list = res.taskFlyDataResponses || [];
      this.list.forEach((val, i) => {
        val.index = (this.pagination.pageNo - 1) * 10 + i + 1;
      });
      // this.pagination.pageNo = pageNo;
      // this.pagination.pageSize = pageSize;
      this.pagination.totalCount = res.total;
      this.loading = false;
    },
    get_status(item) {
      let val = item;
      let text = "";
      if (val.applyStatus == 1 && val.papplyStatus == 1) {
        text = "审批通过";
        if (val.executeStatus == 0) {
          text = "待执行";
        } else if (val.executeStatus == 1) {
          text = "已完成";
        } else {
          text = "执行中";
        }
      } else if (val.applyStatus == 2 || val.papplyStatus == 2) {
        text = "审批不通过";
      } else {
        text = "待审批";
      }
      return text;
    },
    handle_size_change(size) {
      this.pagination.pageSize = size;
      this.get_list();
    },
    handle_page_change(page) {
      this.pagination.pageNo = page;
      this.get_list();
    },
    jumpRoute(item,val){
      let data = {
        ...item,
        status:val,
        active:2
      }
      this.$emit("jumpRoute",data)
    }
  },
};
</script>

<style lang="scss" scoped>
.cpt-command-task-normal {
  @import "~@/styles/flightTask.scss";
  height: calc(100% - 125px);

  .btn-add {
    cursor: pointer;
    width: 170px !important;
    background-image: url("~@/assets/yingkong1027/btn_hei.png") !important;
    background-size: 100% 100% !important;
    box-shadow: none !important;
    font-size: 14px !important;
    font-family: MicrosoftYaHei !important;
    color: #d2d9ff !important;
  }

  .btn-submit {
    // margin-left: 40px;
    cursor: pointer;
    margin-right: 16px;
  }

  .tb-box {
    padding: 0 !important;
    width: 1796px;
    height: 470px;
    background: rgba(8, 26, 58, 0.8);
    border-radius: 4px;
    border: 1px solid #315ec7;
    .no-data {
      text-align: center;
      color: #fff;
      padding-top: 10%;
    }
    .loading-data {
      font-size: 16px;
      width: 1790px;
      height: 245px;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      // top: 0;
      left: 20px;
      text-align: center;
      color: #fff;
      padding-top: 10%;
      // div{
      //   position: absolute;
      //   top: 50%;
      // }
    }
    .tb-hd-box {
      width: 100%;
      height: 42px;
      background: #0c1f3d;
      .tb-hd {
        height: 100%;
        line-height: 42px;
        width: calc(100% / 7);
        white-space: nowrap;
        font-size: 16px;
        font-family: YouSheBiaoTiHei;
        color: #ebf9ff;
        letter-spacing: 1px;
        font-weight: 400;
      }

      .last-tb-hd {
        width: 220px !important;
      }
      .sort{
        display: flex;
        justify-content: center;
        align-items: center;
        div{
          margin-left: 4px;
          display: inline-block;
          span{
            display: block;
            font-size: 12px;
          }
        }
      }
    }

    .tb-bd-box {
      width: 100%;
      height: calc(100% - 42px);
      position: relative;

      .tb-tr:nth-child(odd) {
        background: rgba(73, 135, 210, 0.2);
      }
      .tb-tr:nth-child(even) {
        background: #0c1f3d;
      }
      overflow-y: auto;
      .tb-tr {
        margin: 0 0 4px 0px;
        cursor: pointer;
        &:hover {
          background: rgba(2, 19, 96, 0.2);
          box-shadow: inset 0px 0px 10px 2px #3fcbff;
          border: 0px solid transparent;
        }
        .td {
          width: calc(100% / 6);
          overflow: hidden;
        }
        .tdhover{
          cursor: pointer;
          &:hover{
            color: #66e0ff;
          }
        }

        .last-td {
          width: 220px !important;

          .iconfont {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
.tb-bd-box::-webkit-scrollbar {
  width: 0 !important;
}
// ---------------------------------------------------------------
.search-box {
  height: auto !important;
  margin: 12px 0 12px 0;
  margin-left: 0 !important;
  box-sizing: border-box;
  padding: 0 0 0 675px;
}
// 输入框样式
.flexs {
  display: flex;
}
.waikuang {
  width: 170px;
  height: 32px;
  background: #02173d;
  border-radius: 2px;
  border: 1px solid #315ec7;
  align-items: center;
  margin: 0 16px 0 0;
  img {
    width: 20px;
    height: 20px;
    margin: 0 8px 0 8px;
  }
}
.shijanSelect {
  width: 370px;
}
.xian {
  width: 1px;
  height: 22px;
  border-left: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(67, 222, 255, 0),
      rgba(67, 222, 255, 1),
      rgba(67, 222, 255, 0)
    )
    1 1;
}
// 所有输入框样式
/deep/ .waikuang .input-name .el-input__inner {
  width: 150px !important;
  background: rgba(13, 50, 92, 0) !important;
  border: 0px solid #43deff !important;
  align-items: center !important;
  padding-left: 15px !important;
}
/deep/ .waikuang .el-cascader .el-input__inner {
  width: 150px !important;
  background: rgba(13, 50, 92, 0) !important;
  border: 0px solid #43deff !important;
  align-items: center !important;
  padding-left: 15px !important;
}
/deep/ .waikuang .el-select .el-input__inner {
  width: 150px !important;
  background: rgba(13, 50, 92, 0) !important;
  border: 0px solid #43deff !important;
  align-items: center !important;
  padding-left: 15px !important;
}

//时间选择器样式
/deep/ .waikuang .el-range-input {
  height: 32px !important;
}
/deep/.el-date-editor .el-range-input {
  background: rgba(2, 31, 51, 0.1);
  // width: 390px;
  color: #fff;
}

/deep/ .waikuang .el-date-editor {
  padding: 0;
  border: 0px solid;
  height: 32px;
  background: rgba(13, 50, 92, 0) !important;
}
/deep/ .el-tooltip {
  background-color: transparent;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  border: 0px solid;
}

// 页签样式
.zongji {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #b5e5ff;
  line-height: 16px;
}
.douhao {
  margin: 0 5px 0 5px;
}
.dangqianye {
  margin: 0 20px 0 0;
}
/deep/.el-pager .number:hover {
  border-radius: 2px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  background: rgba(38, 71, 238, 0.71) !important;
  width: 14px;
  height: 22px;
  line-height: 22px;
}
/deep/ .el-pagination--small .el-pager .number {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #889fb2;
}
/deep/.active {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  background: rgba(38, 71, 238, 0.71) !important;
}
.btnqueding {
  width: 79px;
  height: 32px;
  background-image: url("~@/assets/yingkong1027/btn_hei.png");
  background-size: 100% 100%;
  border: 0px solid transparent !important;
  margin: 0 0 0 24px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #43deff;
  line-height: 9px;
}
.tb-pagination {
  margin: 24px 0 19px 0;
  display: flex;
  justify-content: end;
}
.zhuan {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #889fb2;
  line-height: 16px;
  input {
    width: 60px;
    border-radius: 2px;
    border: 1px solid #b6d4ff;
    text-align: center;
    background: rgba(12, 13, 20, 0.5);
    margin: 0 5px 0 5px;
    color: #fff;
    outline: 0px solid;
  }
}
.wu {
  opacity: 0.7;
}
.el-date-editor--year{
  /deep/.el-input__inner{
    border: none;
    height: 100%;
  }
}
</style>
<style lang="scss">
.xiala .el-scrollbar {
  background: rgba(0, 3, 36, 0.9) !important;
}
.xiala .el-scrollbar li {
  color: #00aeff !important;
}
.xiala .el-scrollbar ul li:hover {
  background: #34466e !important;
}
</style>
