<!-- 飞行日志页面1027 -->
<template>
  <div class="cpt-command-task-flight-log">
    <div class="duanheng">
      <div class="chang">
        <div class="search-box">
          <div class="flexs waikuang">
            <el-select
              v-model="search.patrolSpaceTypeId"
              class="input-excute mr5"
              placeholder="请选择类型"
              clearable
            >
              <el-option
                v-for="item in firmList"
                :key="item.id"
                :label="item.patrolType"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="flexs waikuang shijanSelect">
            <el-date-picker
              prefix-icon="ccc"
              v-model="pickTime"
              type="datetimerange"
              range-separator="至"
              value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              popper-class="flight-log"
            >
            </el-date-picker>
          </div>
          <div class="yuan">
            <div @click="get_list" class="btn1027">搜索</div>
          </div>
        </div>
        <div class="tb-box">
          <div class="tb-hd-box">
            <div class="tb-hd">排名</div>
            <div class="tb-hd">年度</div>
            <div class="tb-hd">巡护类型</div>
            <div class="tb-hd">总计</div>
            <div class="tb-hd">完成</div>
            <div class="tb-hd">未完成</div>
            <div class="tb-hd">完成率(%)</div>
            <div class="tb-hd">操作</div>
          </div>
          <div v-if="!loading" class="tb-bd-box">
            <template v-if="list.length > 0">
              <div class="tb-tr" v-for="item in list" :key="item.id">
                <div class="td">{{ item.index || "暂无" }}</div>
                <div class="td times">{{ item.year || "暂无" }}</div>
                <div class="td times">{{ item.airSpaceType || "暂无" }}</div>
                <div class="td">
                  {{ item.total || "暂无" }}
                </div>
                <div class="td">{{ item.finish || "暂无" }}</div>
                <div class="td tdhover" @click="show(item)">
                  {{ item.notFinish || "暂无" }}
                </div>
                <div class="td">{{ (item.finishRate*100).toFixed(2)+"%" || "暂无" }}</div>
                
                <div class="td last-td">
                  <el-tooltip effect="dark" content="下载" placement="top">
                    <div
                      @click="handle_upload(item)"
                      class="iconfont icon-a-xiazai2px"
                    ></div>
                  </el-tooltip>
                </div>
              </div>
            </template>
            <div v-else-if="!loading && list.length == 0" class="no-data">
              暂无数据
            </div>
          </div>
          <div v-else class="loading-data">
            <div>
              <i class="el-icon-loading"></i>
              <p>加载中...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tb-pagination">
      <div class="zongji">
        <span>共</span>
        <span>{{ pagination.totalCount }}</span>
        <span>条</span>
      </div>
      <span class="douhao zongji">,</span>
      <div class="zongji dangqianye">
        <span>{{ pagination.pageNo }}</span>
        <span>/</span>
        <span>{{ zongye(pagination.totalCount) }}</span>
        <span>页</span>
      </div>
      <el-pagination
        small
        :page-sizes="[10, 20, 30, 40]"
        :current-page.sync="pagination.pageNo"
        :page-size="pagination.pageSize"
        layout="prev, pager, next"
        :total="pagination.totalCount"
        @size-change="handle_size_change"
        @current-change="handle_page_change"
      >
      </el-pagination>
      <div class="zhuan">
        <span>前往</span>
        <input type="text" v-model="qianVal" />
        <span>页</span>
      </div>
      <el-button class="btnqueding" @click="queding">确定</el-button>
    </div>
    <Start ref="Start" v-if="startShow" :data="notFinishData" @close="closeStart" />
  </div>
</template>

<script>
import API from "@/api";
import Start from "./start";
import { mapGetters } from "vuex";
import { downloadData } from "@/utils/download";
export default {
  inject: ["change_page"],
  components: {
    Start
  },
  data() {
    return {
      notFinishData:[],
      startShow:false,
      qianVal: "",
      pickTime: null,
      search: {
        patrolSpaceTypeId: null,
        startTime: null,
        endTime: null,
      },
      list: [],
      loading: false,
      firmList: [],
      unitList: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  methods: {
    show(item) {
      this.notFinishData = item.patrolOrgVoList ? item.patrolOrgVoList : []
      this.notFinishData.forEach((val,i)=>{
        val.index = i+1
      })
      this.startShow = true
    },
    closeStart() {
      this.startShow = false
    },
    // 手动页码跳转
    queding() {
      let page = 1;
      if (this.qianVal != "") {
        page = this.qianVal;
      }
      this.handle_page_change(page);
    },
    // 计算总页码
    zongye(num) {
      let a = num / 10;
      var result = a.toString().indexOf(".");
      if (result != -1) {
        let b = parseInt(a);
        return b + 1;
      } else {
        return a;
      }
    },
    async getFirmNameList() {
      let res = await API.EXAMINE.getHolidayPatrolList();
      this.firmList = res;
    },
    handle_upload(item) {
      downloadData(
        `/hawksystemserver/flightMission/exportHolidayPatrolFile?FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}&startDate=${item.startDate}&endDate=${item.endDate}&holidayType=${item.patrolTypeId}`,
        "假日勤务考核"
      );
    },
    async getUnitList() {
      let data = await API.DEPARTMENT.Drd();
      this.unitList = [data] || [];
    },
    async get_list() {
      if (this.pickTime) {
        this.search.startTime = this.pickTime[0];
        this.search.endTime = this.pickTime[1];
      } else {
        this.search.startTime = null;
        this.search.endTime = null;
      }
      this.loading = true;
      let res = await API.EXAMINE.List({
        TaskType: 2,
        ...this.pagination,
        ...this.search,
      });
      this.list = res.patrolTaskDataResponses || [];
      console.log("this.list: ", this.list);
      this.list.forEach((val, i) => {
        val.index = (this.pagination.pageNo - 1) * 10 + i + 1;
      });
      // this.pagination.pageNo = current;
      // this.pagination.pageSize = size;
      this.pagination.totalCount = res.total;
      this.loading = false;
    },

    handle_size_change(size) {
      this.pagination.pageSize = size;
      this.get_list();
    },
    handle_page_change(page) {
      this.pagination.pageNo = page;
      this.get_list();
    },
  },
  mounted() {
    this.getUnitList();
    this.getFirmNameList();
    this.get_list();
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.duanheng {
  // width: 1132px;
  // min-width: 1132px;
  // overflow-x: scroll;
}

.cpt-command-task-flight-log {
  @import "~@/styles/flightTask.scss";
  height: calc(100% - 125px);

  .chang {
    // width: 1468px;
    // min-width: 1468px;
    // padding: 0 20px 0 20px;
    // margin: 0 0 24px 0;
    font-size: 12px !important;
  }

  .btn-submit {
    cursor: pointer;
  }

  .tb-box {
    padding: 0 !important;
    width: 1796px;
    height: 470px;
    background: rgba(8, 26, 58, 0.8);
    border-radius: 4px;
    border: 1px solid #315ec7;
    .tb-hd-box {
      position: relative;
      width: 100%;
      height: 42px;
      background: #0c1f3d;
      .tb-hd {
        height: 100%;
        line-height: 42px;
        width: calc(100% / 7);
        white-space: nowrap;
        font-size: 16px;
        font-family: YouSheBiaoTiHei;
        color: #ebf9ff;
        letter-spacing: 1px;
        font-weight: 400;
      }
    }
    .loading-data {
      font-size: 16px;
      width: 1790px;
      height: 245px;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      // top: 0;
      left: 20px;
      text-align: center;
      color: #fff;
      padding-top: 10%;
      // div{
      //   position: absolute;
      //   top: 50%;
      // }
    }

    .tb-bd-box {
      width: 100%;
      height: calc(100% - 42px);
      position: relative;
      .no-data {
        font-size: 16px;
        text-align: center;
        color: #fff;
        padding-top: 10%;
      }

      .tb-tr:nth-child(odd) {
        background: rgba(73, 135, 210, 0.2);
      }
      .tb-tr:nth-child(even) {
        background: #0c1f3d;
      }
      overflow-y: auto;

      .tb-tr {
        .td {
          width: calc(100% / 10);
        }
        .tdhover{
          cursor: pointer;
          &:hover{
            color: #66e0ff;
          }
        }

        .last-td {
          .iconfont {
            margin-right: 7px;
          }
        }
      }
    }
  }
}

::v-deep {
  .el-input__inner {
    background: rgba(2, 31, 51, 0.7);
    border: 1px solid #06b6e0;
    border-radius: 4px;
  }

  .el-date-editor .el-range-input {
    background: rgba(2, 31, 51, 0.1);
    // width: 390px;
    color: #fff;
  }

  .el-date-editor .el-range-separator {
    color: #ffffff;
  }
}

.cpt-command-task-flight-log .tb-box {
  padding: 0;
}

// --------------------------------------------------

.search-box {
  height: auto !important;
  margin: 13px 0 12px 0;
  margin-left: 0 !important;
  display: flex;
  box-sizing: border-box;
  padding: 0 0 0 1150px;
  .btn-add {
    width: 126px !important;
    height: 32px !important;
    background: rgba(23, 70, 216, 0.2) !important;
    box-shadow: 0px 2px 4px 0px rgba(23, 33, 60, 0.5),
      inset 0px 0px 16px 0px rgba(33, 137, 255, 0.4),
      inset 0px 0px 4px 0px #00a7ff !important;
    border-radius: 6px !important;
    border: 1px solid;
    border-image: linear-gradient(
        180deg,
        rgba(138, 218, 255, 1),
        rgba(82, 179, 255, 0)
      )
      1 1 !important;
    font-size: 12px !important;
    font-family: MicrosoftYaHei !important;
    color: #43deff !important;
    line-height: 32px !important;
  }

  .btn-submit {
    width: 64px !important;
    height: 32px !important;
    background-image: url("~@/assets/yingkong1027/btn_lan.png") !important;
    background-size: 100% 100%;
    font-size: 14px !important;
    font-family: MicrosoftYaHei !important;
    color: #d2d9ff !important;
    line-height: 32px !important;
    box-shadow: none !important;
    margin: 0 14px 0 0;
  }
  .daochu {
    margin-left: 14px;
  }
}

.flexs {
  display: flex;
}

.waikuang {
  width: 170px;
  height: 32px;
  background: #02173d;
  border-radius: 2px;
  border: 1px solid #315ec7;
  align-items: center;
  margin: 0 14px 0 0;
}

.shijanSelect {
  width: 370px;
}

.xian {
  width: 1px;
  height: 22px;
  border-left: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(67, 222, 255, 0),
      rgba(67, 222, 255, 1),
      rgba(67, 222, 255, 0)
    )
    1 1;
}

// 所有输入框样式
/deep/ .waikuang .input-name .el-input__inner {
  background: rgba(13, 50, 92, 0) !important;
  border: 0px solid #43deff !important;
  align-items: center !important;
  padding-left: 5px !important;
}

/deep/ .waikuang .el-cascader .el-input__inner {
  background: rgba(13, 50, 92, 0) !important;
  border: 0px solid #43deff !important;
  align-items: center !important;
  padding-left: 5px !important;
}

/deep/ .waikuang .el-select .el-input__inner {
  background: rgba(13, 50, 92, 0) !important;
  border: 0px solid #43deff !important;
  align-items: center !important;
  padding-left: 5px !important;
}

//时间选择器样式
/deep/ .waikuang .el-range-input {
  height: 32px !important;
}

/deep/ .waikuang .el-date-editor {
  padding: 0;
  border: 0px solid;
  height: 32px;
  background: rgba(13, 50, 92, 0) !important;
}

// 表格样式
.tb-hd-box {
  height: 38px !important;
  background: rgba(0, 39, 121, 0.2);
  border: 1px solid rgba(207, 234, 255, 0.33);

  .tb-hd {
    height: 38px !important;
    // font-size: 16px !important;
    // font-family: MicrosoftYaHei-Bold, MicrosoftYaHei !important;
    font-weight: bold !important;
    color: #e5f9ff !important;
    line-height: 38px !important;
  }
}

.tb-bd-box {
  height: 430px;

  .tb-tr {
    // height: 38px !important;
    background-image: url("~@/assets/newImage/tiaokaung.png");
    background-size: 100% 100%;
    margin: 4px 0 0 0;
    cursor: pointer;
    justify-content: space-around;

    .td {
      overflow: hidden;
    }

    &:hover {
      // background: rgba(2, 19, 96, 0.2);
      box-shadow: inset 0px 0px 10px 2px #3fcbff;
      // border: 1px solid #70f6f9;
    }
  }
}

.tb-bd-box::-webkit-scrollbar {
  width: 0 !important;
}

// 滚动动画
.animate {
  padding-left: 0px;
  // font-size: 12px;
  // color: #000;
  display: inline-block;
  white-space: nowrap;
  animation: 5s wordsLoop linear infinite normal;
}

@keyframes wordsLoop {
  0% {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.times {
  // white-space: nowrap;
  // width: 6% !important;
}

/deep/ .el-tooltip {
  background-color: transparent;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  border: 0px solid;
}

.tb-pagination {
  margin: 27px 0 0 0;
  display: flex;
  justify-content: end;
}

// 页签样式
.zongji {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #b5e5ff;
  line-height: 16px;
}

.douhao {
  margin: 0 5px 0 5px;
}

.dangqianye {
  margin: 0 20px 0 0;
}

/deep/.el-pager .number:hover {
  border-radius: 2px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  background: rgba(38, 71, 238, 0.71) !important;
  width: 14px;
  height: 22px;
  line-height: 22px;
}

/deep/ .el-pagination--small .el-pager .number {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #889fb2;
}

/deep/.active {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  background: rgba(38, 71, 238, 0.71) !important;
}

.btnqueding {
  margin: 0 0 0 24px;
  width: 79px;
  height: 32px;
  background-image: url("../../../../assets/yingkong1027/btn_hei.png");
  background-size: 100% 100%;
  line-height: 4px;
  border: 0px solid;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #43deff;

  &:hover {
    opacity: 0.5 !important;
  }
}

.tb-pagination {
  margin: 19px 0 0px 20px;
}

.zhuan {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #889fb2;
  line-height: 16px;

  input {
    width: 48px;
    min-width: 48px;
    max-width: auto;
    text-align: center;
    height: 28px;
    background: rgba(12, 13, 20, 0.5);
    border-radius: 2px;
    border: 1px solid #b6d4ff;
    margin: 0 5px 0 5px;
    color: #fff;
    outline: 0px solid;
  }
}
</style>
<style lang="scss">
.flight-log {
  border: none;

  .el-picker-panel__body-wrapper {
    background: #0d224f;
    border: 1px solid #00aeff;
    color: #fff;

    .el-input__inner {
      color: #fff;
      border: 1px solid #00aeff;
    }

    .el-date-table th {
      // border-bottom: solid 1px #00aeff;
      color: #fff;
    }

    .el-date-table td.in-range div {
      background-color: #00aeff;
    }

    .el-input--small .el-input__inner {
      background-color: #0d224f;
    }

    .el-picker-panel__icon-btn {
      color: #fff;
    }
  }

  .el-picker-panel__footer {
    background-color: #0d224f !important;
  }

  .el-button.el-button--mini.is-plain,
  .el-button.is-disabled.is-plain {
    background-color: transparent !important;
    border: none;
    color: #00aeff;
  }
}

.cpt-command-task-flight-log .tb-box .tb-bd-box .tb-tr .td {
  font-size: 12px !important;
}
</style>
