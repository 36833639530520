<template>
  <div class="cpt-box-sidebar-item" :style="containerStyle">
    <div class="box-sidebar-item-title">
      <div class="title_left_decorate">
        <img src="~@/assets/images/webhome/pos.png" alt="" />
        {{ title }}
        <span class="today" v-if="titleToday == 0"></span>
        <span class="today" v-if="titleToday == 1">（今日）</span>
        <span class="today" v-if="titleToday == 2">（本月）</span>
        <span class="today" v-if="titleToday == 3">（总数）</span>
        <!-- <span class="today" v-if="titleToday  == 1">（今日）</span> -->
      </div>
      <div class="icon-box cp">
        <div :class="
            find_active == 0 ? 'title_right_find' : 'title_right_find_active'
          " v-if="titleFind" class="search">
          <el-input v-model="form_data" class="find_input" v-if="find_active == 1" autofocus="autofocus"
            :placeholder="请输入搜索内容" clearable style="outline: none"></el-input>
          <img src="~@/assets/images/new_home/title-find.png" alt="" @click="handleFind" />
        </div>

        <div class="title_right_filter" v-if="titleFilter">
          <img src="~@/assets/images/webhome/change3.png" alt="" @click="open" />
          <div class="filter_box" v-if="isFliter" tabindex="0" @blur="hide" ref="filter" style="outline: none">
            <div :class="change_data == 1 ? 'filter_item' : 'filter_item_active'" @click="handle_change(1)">
              今日
            </div>
            <div :class="change_data == 2 ? 'filter_item' : 'filter_item_active'" @click="handle_change(2)">
              本月
            </div>
            <div :class="change_data == 3 ? 'filter_item' : 'filter_item_active'" @click="handle_change(3)">
              总数
            </div>
          </div>
        </div>

        <div v-if="rightButton == 1" class="title_more cp" @click="handle_more">
          详情&gt;&gt;
        </div>
        <div v-if="rightButton == 2" class="title_more cp" @click="handle_more">
          更多&gt;&gt;
        </div>
        <div v-if="rightButton == 3" class="title_more cp" @click="handle_more">
          订阅&gt;&gt;
        </div>
      </div>
    </div>
    <div class="box-sidebar-item-bd">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import dayjs from "dayjs";
  export default {
    props: {
      title: {
        type: String,
        default: () => "标题",
      },
      rightButton: {
        type: Number,
        default: () => 0,
      },
      // 1：今天 2、本月 3、总数 4、时间段
      titleToday: {
        type: Number,
        default: 1,
      },
      titleFind: {
        type: Boolean,
        default: () => false,
      },
      titleFilter: {
        type: Boolean,
        default: () => false,
      },
      containerStyle: {
        type: Object | String,
        default: () => ({}),
      },
      placeholderData: {
        type: String,
        default: () => "请输入搜索内容",
      },
    },
    data() {
      return {
        find_active: 0,
        form_data: "",
        change_data: 1,
        isFliter: false,
      };
    },
    methods: {
      open() {
        if (this.isFliter) {
          this.isFliter = false;
        } else {
          this.isFliter = true;
        }
        this.$nextTick(() => {
          this.$refs.filter.focus();
        });
      },
      hide() {
        this.isFliter = false;
      },
      handleFind() {
        if (this.find_active == 0) {
          console.log("active1:", this.find_active);
          this.find_active = 1;
        } else if (this.find_active == 1) {
          if (this.form_data == '') { this.find_active = 0; }
          this.$emit("handle_find_btn", this.form_data);
        }
        // else if (this.find_active == 1 && this.form_data == "") {
        //   console.log("active3:", this.find_active);
        //   this.find_active = 0;
        // }
      },
      handle_change(item) {
        this.change_data = item;
        let day = dayjs();
        const today = day.format("YYYY-MM-DD");
        const mothe = day.format("YYYY-MM");
        if (item == 1) {
          // this.$emit("handle_change_btn", today, item);
          this.$emit("handle_change_btn", item);
        } else if (item == 2) {
          // this.$emit("handle_change_btn", mothe, item);
          this.$emit("handle_change_btn", item);
        } else {
          // this.$emit("handle_change_btn", "", item);
          this.$emit("handle_change_btn", item);
        }
        this.isFliter = false;
      },
      handle_more() {
        this.$emit("handle_more_btn");
      },
      handle_onBlur(e) {
        this.find_active = 0;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @keyframes yes {
    0% {
      opacity: 0.3;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.3;
    }
  }

  .cpt-box-sidebar-item {
    width: 100%;
    height: 100%;
    background: transparent;

    .box-sidebar-item-title {
      height: 38px;
      width: 100%;
      margin: 0 auto;
      // background: left url("~@/assets/images/new_home/title-bg_v3.png") no-repeat;
      background: url("~@/assets/images/webhome/titlebg.png") no-repeat;
      background-size: 100% 100%;
      // text-align: center;
      text-indent: 30px;
      font-family: MicrosoftYaHei-Bold;
      font-size: 18px;
      color: #00ffff;
      letter-spacing: 0;
      line-height: 38px;
      font-weight: 700;
      display: inline-block;
      position: relative;

      .icon-box {
        position: absolute;
        width: 50%;
        top: 0;
        // left: 20%;
        right: 122px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-top: 8px;
        box-sizing: border-box;

        .search {
          z-index: 11;
        }

        .title_right_filter {
          display: inline-block;
          width: 18.05px;
          height: 18.15px;
          margin-right: 20px;
          position: relative;

          img {
            left: 5px;
            top: -10px;
          }

          .filter_box {
            position: absolute;
            right: 10px;
            top: 20px;
            width: 97px;
            height: 96px;
            // padding-top: 11px;
            box-sizing: border-box;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: url("~@/assets/images/home/shaixuan_bg.png") no-repeat;

            .filter_item {
              height: 30%;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #92d9ff;
            }

            .filter_item_active {
              height: 30%;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #00efff;
              background: #3c4c70;
              border-radius: 0px 0px 0px 0px;
              opacity: 1;
            }
          }

          .filter_box {
            position: absolute;
            z-index: 10;
            right: -245%;
            top: 137%;
            width: 97px;
            height: 96px;
            // padding-top: 11px;
            box-sizing: border-box;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: url("~@/assets/images/home/shaixuan_bg.png") no-repeat;

            .filter_item {
              height: 30%;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #92d9ff;
            }

            .filter_item_active {
              height: 30%;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #00efff;
              background: #3c4c70;
              border-radius: 0px 0px 0px 0px;
              opacity: 1;
            }
          }
        }

        .title_right_find {
          display: inline-block;
          // position: absolute;
          // right: 22%;
          // top: 18%;
          margin-right: 10px;
          position: relative;
          top: -10px;

          ::v-deep.el-input__inner {
            width: 154px;
            height: 24px;
            background: rgba(12, 50, 145, 0.5);
            box-shadow: 0px 0px 10px 1px rgba(0, 245, 253, 0.47999998927116394),
              0px 4px 4px 1px rgba(0, 0, 0, 0.25);
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #0084ff;
          }

          img {
            z-index: 100;
            top: 0;
          }
        }

        .title_right_find_active {
          position: relative;
          right: -120px;

          ::v-deep.el-input__suffix {
            right: 50px;
            transition: all 0.3s;
            pointer-events: none;
            top: -43px;
          }

          ::v-deep.el-input__inner {
            position: absolute;
            right: 30px;
            top: -34px;
            width: 145px;
            height: 24px;
            background: rgba(12, 50, 145, 0.5);
            box-shadow: 0px 0px 10px 1px rgba(0, 245, 253, 0.47999998927116394),
              0px 4px 4px 1px rgba(0, 0, 0, 0.25);
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #0084ff;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #92d9ff;
            line-height: 14px;
            padding-left: 10px;
            box-sizing: border-box;
          }

          img {
            position: absolute;
            left: 32px;
            top: -9px;
            z-index: 100;
          }
        }

        .title_left_decorate {
          display: inline-block;
        }

        .title_more {
          position: relative;
          top: -10px;
          right: -120px;
          font-family: PingFangSC;
          font-size: 16px;
          color: #00ffff;
          line-height: 22px;
          font-weight: 400;
        }

        // .icon-box {
        //   width: 300px;
        //   height: 100px;
        //   padding-right: 20px;
        //   box-sizing: border-box;
        // }
      }

      .today {
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #6aff64;
        letter-spacing: 0;
        line-height: 24px;
        font-weight: 400;
      }

      .title_left_decorate {
        // background-image: -webkit-linear-gradient(bottom, #FFF7E3, #FFD774 );
        background-image: linear-gradient(180deg, #FFF7E3 30%, #FFD774 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        img {
          position: absolute;
          left: 128px;
          top: -15px;
          animation: yes 1.5s infinite;
        }

      }

      img {
        position: absolute;
        left: 121px;
        top: -15px;
      }
    }

    .box-sidebar-item-bd {
      height: calc(100% - 34px);
      flex: 1;
    }
  }
</style>