<!-- 新版 鹰视首页 -->
<template>
  <div class="command-home pr">
    <ul class="surround" :style="surroundStyle">
      <img class="earth" :style="earthStyle" src="@/assets/new_yingshi/shouye_diqiu.png" />
      <li
        v-for="(item, i) in list"
        :key="i"
        class="surround-item"
        :style="item.style"
        @mousemove="onMousemove"
        @mouseout="onMouseout"
        @click="$emit('changePage',item.type)"
      >
        <img class="surround-item__img" :src="item.img" />
      </li>
    </ul>
    <div class="turn turn--left" @click="onTurn('left')">
      <div>
        <img src="@/assets/new_yingshi/leftBtn.png" />
      </div>
    </div>
    <div class="turn turn--right" @click="onTurn('right')">
      <div>
        <img src="@/assets/new_yingshi/leftBtn.png" />
      </div>
    </div>
    <div class="pa command-home_btn cp" @click="$emit('change')"></div>
  </div>
</template>
  
<script>
import API from "@/api";

const list = [
  {
    img: require("@/assets/new_yingkong/ddzx.png"),
    href: "",
    style: {},
    type: 5,
  },
  {
    img: require("@/assets/new_yingkong/rygl.png"),
    href: "",
    style: {},
    type: 6,
  },
  {
    img: require("@/assets/new_yingkong/sbgl.png"),
    href: "",
    style: {},
    type: 7,
  },
  {
    img: require("@/assets/new_yingkong/sbjc.png"),
    href: "",
    style: {},
    type: 11,
  },
];
export default {
  components: {},
  data() {
    return {
      list: list, //环绕项数组
      angle: 0, //旋转角度
      pauseTurn: false, //暂停旋转
      pauseTurnTimeId: null,
      timeId: null, //主旋转定时器
      turnTimeId: null, //手动旋转定时器
    };
  },

  computed: {
    surroundStyle() {
      return {
        transform: `perspective(800px) rotateX(-5deg) rotateY(${this.angle}deg)`,
      };
    },
    earthStyle() {
      return {
        transform: `translate3d(-432px, -277px, 0px) rotateY(${-this
          .angle}deg)`,
      };
    },
  },
  created() {
    // console.log("created");
    this.$store.commit("mapmanage/SET_VISIBLE_MAIN_MAP", false);
  },
  mounted() {
    //计算所有环绕项
    var len = this.list.length;
    this.list.forEach((item, i) => {
      item.style = {
        transform:
          "rotateY(" +
          (360 / len) * i +
          "deg) translateZ(400px) translateY(30px)",
        transition: "1s all ease " + (len - i) * 200 + "ms",
      };
    });

    //定时器实现旋转
    this.timeId = setInterval(() => {
      //暂停旋转
      if (this.pauseTurn) {
        return;
      }
      this.angle -= 1;
    }, 50);
  },
  beforeDestroy() {
    clearInterval(this.timeId);
  },
  methods: {
    /**
     * 旋转事件
     * @param {string} 类型, left | right
     */
    onTurn(type) {
      if (this.turnTimeId) {
        return;
      }
      const len = this.list.length;
      const itemAngle = (360 / len) * (type === "left" ? -1 : 1); //每一项角度
      let remainder = Number((this.angle % itemAngle).toFixed(4)); //求得与最近一项还差多少度数, 注意, 当前项为正前方时, 得0, 这时需要直接转动到下一项
      console.log("remainder", remainder);
      if (remainder === 0 && type === "right") {
        remainder = itemAngle;
      }
      let sumAngle = (type === "left" ? itemAngle : 0) - remainder; //总共要旋转多少度
      let angleUnit = 1; //每N毫秒旋转多少度

      // console.log("unit", type);
      // console.log('this.angle', this.angle);
      // console.log('itemAngle', itemAngle);
      // console.log('sumAngle', sumAngle);

      //right情况下有特殊, 转角少于10度时, 不要回正第一项, 要回正第二项
      /* if(type === 'right' && sumAngle < 10){
        sumAngle += itemAngle;

        if(Math.abs(sumAngle) > itemAngle){
          sumAngle -= itemAngle;
        }
      } */

      //暂停旋转N秒
      if (this.pauseTurnTimeId) {
        clearTimeout(this.pauseTurnTimeId);
      }
      this.pauseTurn = true;
      this.pauseTurnTimeId = setTimeout(() => {
        this.pauseTurn = false;
      }, 2000);

      if (this.turnTimeId) {
        clearInterval(this.turnTimeId);
      }
      let angleCount = 0; // 旋转角度计数
      this.turnTimeId = setInterval(() => {
        if (angleCount >= Math.abs(sumAngle)) {
          clearInterval(this.turnTimeId);
          this.turnTimeId = null;
          angleUnit = Math.abs(sumAngle) - angleCount;
        }

        switch (type) {
          case "left":
            this.angle -= angleUnit;
            break;

          case "right":
            this.angle += angleUnit;
            break;
        }

        angleCount += angleUnit;

        // console.log('angleCount', sumAngle, angleCount, angleUnit, this.angle)
      }, 10);
    },

    /**
     * 鼠标悬停事件
     */
    onMousemove() {
      clearTimeout(this.pauseTurnTimeId);
      this.pauseTurn = true;
    },
    onMouseout() {
      this.pauseTurn = false;
    },
  },
};
</script>
  
<style lang="scss" scoped>
$headerHegith: 72px; //顶部条高度
.command-home {
  position: fixed;
  top: $headerHegith;
  left: 0;
  width: 100vw;
  height: calc(100vh - 72px);
  background-size: 100% 100%;
}
.command-home_btn {
  width: 150px;
  height: 50px;
  z-index: 10;
  bottom: 10px;
  right: 20px;
  background-image: url("~@/assets/img/homeChange1.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.command-home_btn:hover {
  // background-image: url("~@/assets/img/homeChange2.png") ;
}
.surround {
  width: 133px;
  height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -65px;
  margin-top: -200px;
  /*开启3D*/
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;

  .earth {
    width: 1000px;
  }

  .surround-item {
    cursor: pointer;
    list-style: none;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-size: 100% 100%;
    /*图片一圈加阴影*/
    box-shadow: 0 0 10px #fff;
    /*加圆角*/
    border-radius: 10px;
    /*给li加倒影*/
    -webkit-box-reflect: below 10px -webkit-linear-gradient(rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.3));

    .surround-item__img {
      width: 100%;
      height: 100%;
    }
  }
}

.turn {
  position: absolute;
  width: 35%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 156px;
    height: 182px;
    background: rgba(3, 10, 36, 0.5);
    img {
      opacity: 0.6;
    }
    &:hover {
      img {
        opacity: 1;
      }
    }
  }

  &.turn--left {
    left: 0;
  }

  &.turn--right {
    right: 0;
    justify-content: end;
    img {
      transform: rotate(180deg);
    }
  }
}
</style>
    