<template>
  <div class="cpt-command-airtask-edit dialog1027">
    <div class="dialog-header" v-interact>
      <div class="dialog-header__icon" />
      <div class="dialog-header__title">{{ type == 2 ? "创建常态任务" : "创建临时紧急任务" }}</div>
      <div class="dialog-header__close" @click="flight_task_chage_view(1)">关闭</div>
    </div>

    <div class="form-box dialog-content">
      <div class="switch-box">
        <div class="btn1027" :class="{active: !taskFlag}" @click="changeTask(false)">
          <i class="icon iconfont icon-wurenji2" style="font-size: 16px; margin-right: 4px;" />
          无人机
        </div>
        <div class="btn1027" :class="{active: taskFlag}" @click="changeTask(true)">
          <i class="icon iconfont icon-yingchao1" style="font-size: 14px; margin-right: 4px;" />
          鹰巢
        </div>
      </div>
      <div class="form-inner-box">
        <el-form ref="form" :model="form" label-width="120px" :rules="rules">
          <el-form-item label="任务名称" prop="taskTitle" class="dialog-content__input">
            <el-input
              v-model="form.taskTitle"
              placeholder
              :disabled="true"
              class="dialog-content__text"
            />
          </el-form-item>
          <el-form-item label="航线名称" prop="flightLineId">
            <el-select
              filterable
              v-model="form.flightLineId"
              placeholder="请选择航线名称"
              :popper-append-to-body="false"
              clearable
              @change="handle_change"
            >
              <el-option
                v-for="item in drd.airway"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
                <span style="float: left">{{ item.title }}</span>
                <span style="float: left; color: #00b900; font-size: 13px">
                  {{
                  item.issafe == 1 ? "（安全）" : ""
                  }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="任务类型" prop="taskTypeId">
            <el-select
              filterable
              v-model="form.taskTypeId"
              placeholder="请选择任务类型"
              :popper-append-to-body="false"
              clearable
            >
              <el-option
                v-for="type in drd.type"
                :key="type.id"
                :label="type.title"
                :value="type.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="任务场景" prop="cenarioName">
            <el-select
              filterable
              v-model="form.cenarioName"
              placeholder="请选择任务场景"
              :popper-append-to-body="false"
              clearable
            >
              <el-option
                v-for="(item, index) in cenarioList"
                :key="index"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="责任单位" prop="organizationId">
            <el-cascader
              placeholder="请选择所属机构"
              v-model="form.organizationId"
              :options="drd.department"
              :show-all-levels="false"
              :props="{
                children: 'children',
                label: 'orgName',
                value: 'id',
                checkStrictly: true,
                emitPath: false,
              }"
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="飞手" prop="userIds">
            <el-select
              filterable
              v-model="form.userIds"
              placeholder="请选择飞手"
              :popper-append-to-body="false"
              multiple
              clearable
            >
              <el-option
                v-for="team in drd.team"
                :key="team.id"
                :label="team.title"
                :value="team.id"
              />
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="电池编号" prop="batteryNumbers">
            <el-select
              filterable
              v-model="form.batteryNumbers"
              clearable
              placeholder="请选择电池编号"
              :popper-append-to-body="false"
              multiple
              :multiple-limit="2"
            >
              <el-option
                v-for="item in drd.batteryList"
                :key="item.id"
                :label="item.title"
                :value="item.title"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="预计开始时间" prop="startedAt">
            <el-date-picker
              :picker-options="pickerOptions"
              v-model="form.startedAt"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择预计开始时间"
              :append-to-body="true"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="内容描述" prop="remark">
            <el-input v-model="form.remark" type="textarea" :rows="2" placeholder="请输入内容描述"></el-input>
          </el-form-item>
        </el-form>
        <div class="btn-box1027">
          <div class="btn1027" @click="flight_task_chage_view(1)">取消</div>
          <div class="btn1027" @click="handle_create('form')">创建</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import { parseTime } from "./parseTime/index.js";
import { Utils } from "@/lib/cesium";
let airline_entitys = [];
let air_line_preview = [];
export default {
  data() {
    return {
      flag: true,
      // 无人机任务 or 鹰巢任务
      taskFlag: false,
      // 阻止点击事件
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000; // 设置选择今天以及今天之后的日
          // return time.getTime() > Date.now(); //设置选择今天以及今天以前的日期
          // return time.getTime() < Date.now();//设置选择今天之后的日期（不能选择当天时间）
          // return time.getTime() > Date.now() - 8.64e7 //设置选择今天之前的日期（不能选择当天）
          // 设置当天23：59：59可选
          // let currentTime = this.getNowMonthDay() + ` 23:59:59`
          // return time.getTime() > new Date(currentTime).getTime()
        },
        selectableRange: `${Date().split(" ")[4]} - 23:59:59`,
      },
      drd: {
        airway: [],
        type: [],
        department: [],
        team: [],
        batteryList: [],
      },
      cenarioList: [],
      timer: null,
      form: {
        taskTitle: null,
        destination: null,
        flightLineId: null,
        cenarioName: null, //任务场景名称
        cateId: undefined,
        taskTypeId: undefined,
        organizationId: undefined,
        userIds: [],
        remark: undefined,
        startedAt: null,
      },
      rules: {
        taskTitle: [{ required: true, message: "请选择名称", trigger: "blur" }],
        flightLineId: [
          { required: true, message: "请选择航线名称", trigger: "blur" },
        ], //选择航线名称
        taskTypeId: [
          { required: true, message: "请选择任务类型", trigger: "blur" },
        ],
        cenarioName: [
          { required: true, message: "请选择任务场景", trigger: "blur" },
        ], // 选择任务类型
        cenarioName: [
          { required: true, message: "请选择任务场景", trigger: "blur" },
        ], // 选择任务场景
        organizationId: [
          { required: true, message: "请选择责任单位", trigger: "blur" },
        ], // 选择责任单位
        userIds: [{ required: true, message: "请选择飞手", trigger: "blur" }], // 选择飞手
        // batteryNumbers: [
        //   { required: true, message: "请选择电池编号", trigger: "blur" },
        // ],
        startedAt: [
          { required: true, message: "请选择预计时间", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    "form.organizationId": {
      handler(newValue, oldValue) {
        this.drd_batteryList(newValue);
      },
    },
    "form.startedAt": {
      //需要对绑定的时间进行监听
      handler(newValue, oldValue) {
        if (newValue) {
          let date = new Date(); //当前时间
          let min = date.getMinutes(); //当前时间的分
          date.setMinutes(min);
          let nowDate = this.$moment(date).format("HH:mm:ss"); //当前时分秒
          let st = "";
          if (
            this.$moment(date).format("yyyy-MM-DD") ===
            this.$moment(newValue).format("yyyy-MM-DD")
          ) {
            let hh1 = this.$moment(newValue).format("HH:mm:ss");
            if (hh1 <= nowDate) {
              this.form.startedAt = this.$moment(date).format(
                "yyyy-MM-DD HH:mm:ss"
              );
            }
            st = nowDate;
            console.log(nowDate, "nowDate");
          } else {
            st = "00:00:00";
          }

          this.pickerOptions.selectableRange = st + "-23:59:59";
          this.pickerOptions = this.pickerOptions;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  computed: {
    ...mapGetters(["user_info"]),
  },
  props: ["type"],
  methods: {
    changeTask(type) {
      this.taskFlag = type;
      this.form.flightLineId = "";
      this.drd_airway();
    },
    // 请选择航线名称
    handle_change(e) {
      // 提取路径点
      let a;
      this.drd.airway.forEach((item, index) => {
        if (item.id == e) {
          a = index;
        }
      });
      a = JSON.parse(this.drd.airway[a].flightCourseJson);

      let name = this.drd.airway.find((item) => item.id == e);
      if (name) {
        this.form.taskTitle = `${name.title}-${dayjs().format(
          "YYYYMMDDHHmmss"
        )}`;
      } else {
        this.form.taskTitle = null;
      }
      // 调用预览
      this.handleAirLinePreview(a);
    },
    // 航线预览
    async handleAirLinePreview(item) {
      try {
        let viewer = window.viewer;
        if (airline_entitys?.length) {
          airline_entitys.forEach((item) => {
            viewer.entities.remove(item);
          });
        }
        let res = item;
        air_line_preview.forEach((item) => {
          viewer.entities.remove(item);
        });
        if (res != null) {
          let positions = [];
          let center = [];
          let polyline = res;
          let label_arr = [];
          let _this = this;
          polyline.forEach((item, index) => {
            positions.push(item.lon, item.lat, item.alt);
            label_arr.push(
              Cesium.Cartesian3.fromDegrees(item.lon, item.lat, item.alt)
            );
            let point_entity = viewer.entities.add({
              position: Cesium.Cartesian3.fromDegrees(
                item.lon,
                item.lat,
                item.alt
              ),
              name: "show_airline_point",
              point: {
                pixelSize: 20,
                color: Cesium.Color.red,
                color: Cesium.Color.fromCssColorString("red"),
              },
              label: {
                text: String(item.alt) + "m",
                scale: 0.6,
                font: "bold 28px Microsoft YaHei",
                // fillColor: Cesium.Color.BLUE,
                fillColor: Cesium.Color.fromCssColorString("#FF7F09"),
                horizontalOrigin: Cesium.VerticalOrigin.CENTER,
                verticalOrigin: Cesium.VerticalOrigin.CENTER,
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
                showBackground: false,
                outlineWidth: 0,
                pixelOffset: new Cesium.Cartesian2(25, -10),
              },
            });
            let point_entity_num = viewer.entities.add({
              name: "airline_point",
              // id: uuid,
              position: Cesium.Cartesian3.fromDegrees(
                item.lon,
                item.lat,
                item.alt
              ),
              point: {
                pixelSize: 10,
                color: Cesium.Color.red,
                color: Cesium.Color.fromCssColorString("red"),
                // fillColor: Cesium.Color.red,
                // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND, // supermap版本会导致拖动显示错误
              },
              label: {
                text: new Cesium.CallbackProperty(() => {
                  return String(index + 1);
                }, false),
                // text:index + 1,
                font: "bold 14px Microsoft YaHei",
                // fillColor: Cesium.Color.BLUE,
                fillColor: Cesium.Color.fromCssColorString("white"),
                horizontalOrigin: Cesium.VerticalOrigin.CENTER,
                verticalOrigin: Cesium.VerticalOrigin.CENTER,
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
                showBackground: false,
                outlineWidth: 0,
                pixelOffset: new Cesium.Cartesian2(0, 0),
              },
            });

            airline_entitys.push(point_entity);
            airline_entitys.push(point_entity_num);
            if (label_arr.length > 1) {
              let before = label_arr[label_arr.length - 2];
              let after = label_arr[label_arr.length - 1];

              _this.create_label(before, after);
            }
          });
          positions = Cesium.Cartesian3.fromDegreesArrayHeights(positions);
          let redLine = viewer.entities.add({
            name: "Red line on terrain",
            polyline: {
              positions,
              width: 4,
              material: Cesium.Color.fromCssColorString("#00A9A9"),
            },
          });

          viewer.flyTo(redLine);
          airline_entitys.push(redLine);
        } else {
          this.$el_message("暂无航线", () => {}, "error");
        }
      } catch (error) {
        this.$el_message("暂无航线", () => {}, "error");
      }
    },
    // 显示距离
    create_label(before, after) {
      let viewer = window.viewer;
      let before_wgs84 = Utils.transformCartesian2WGS84(before);
      let after_wgs84 = Utils.transformCartesian2WGS84(after);

      let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2;
      let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2;
      let alt = (after_wgs84.alt + before_wgs84.alt) / 2;

      let position = Utils.transformWGS842Cartesian({
        lng: center_lng,
        lat: cetner_lat,
        alt: alt + 10,
      });

      let text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`;

      let label_entity = viewer.entities.add({
        id: `label_${before}`,
        position: position,
        label: {
          text: text,
          scale: 0.5,
          font: "bold 30px Microsoft YaHei",
          fillColor: Cesium.Color.fromCssColorString("#fff"),
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: true,
          backgroundPadding: new Cesium.Cartesian2(10, 10),
        },
      });
      airline_entitys.push(label_entity);
    },
    // 任务场景数据
    async getAllTaskCenarioList() {
      let data = await API.BATTERY.getAllTaskCenarioList();
      this.cenarioList = data;
    },
    async drd_batteryList(id) {
      let data = await API.BATTERY.List({
        organId:id
      });
      this.drd.batteryList = data || [];
    },
    async drd_airway() {
      let res = await API.AIRWAY.Drd({
        sourceType: this.taskFlag ? 2 : 1,
      });
      this.drd.airway = res || [];
    },
    async drd_type() {
      let res = await API.TASK.DrdType();
      this.drd.type = res || [];
    },
    async drd_department() {
      let res = await API.DEPARTMENT.Drd();
      this.drd.department = [res] || [];
    },
    async drd_team() {
      let res = await API.USER.DrdPilot();
      this.drd.team = res || [];
    },

    throttle(fn, wait) {
      var context = this;
      var args = arguments;

      clearTimeout(this.timer);
      this.timer = null;
      this.timer = setTimeout(function () {
        fn.apply(context, args);
      }, wait);
    },

    handle_create(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 发送请求
          if (this.flag) {
            this.fn();
          }
          this.flag = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });

      // this.throttle(, 1000)
    },

    async fn() {
      this.flag = false;
      if (this.taskFlag) {
        this.form.sourceType = 2;
      } else {
        this.form.sourceType = 1;
      }
      let res = await API.TASK.Add(this.form);
      if (res.status == 1) {
        this.$el_message("创建成功", () => {
          if(this.type == 2){
            this.flight_task_chage_view(1);
          }else{
            this.flight_task_chage_view(10);
          }
          this.flag = true;
        });
      } else {
        this.flag = true;
        this.$message.warning(res.message);
      }
    },
  },
  async mounted() {
    await this.drd_airway();
    await this.drd_type();
    await this.drd_department();
    await this.drd_team();
    await this.getAllTaskCenarioList();

    this.user_info && (this.form.organizationId = this.user_info.departmentId == 1 ? 2 : this.user_info.departmentId);
  },
  inject: ["flight_task_chage_view"],
};
</script>
<style lang="scss">
.el-time-panel__footer {
  background: #0b1138 !important;
}

.el-time-spinner__item.active:not(.disabled) {
  color: #999;
}

.el-time-spinner__item:hover:not(.disabled):not(.active) {
  background: #0d224f;
}

.el-time-spinner__item:hover {
  background: #0b1138;
}

.el-time-panel__btn {
  color: #fff;
}

.el-scrollbar {
  background: #0d224f;
  border: 1px solid #00aeff;
}

.el-time-spinner__item {
  color: #fff;
}
</style>
<style lang="scss" scoped>
@import "~@/styles/mixins.scss";

.cpt-command-airtask-edit {
  position: absolute;
  width: 560px;
  // height: 690px;
  left: 100px;
  top: 170px;
  box-sizing: border-box;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 13px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 44px;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;
  }

  .hd-box {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 700;
    margin-left: 20px;
  }

  .form-box {
    .form-inner-box {
      width: 100%;
      height: 100%;
      box-sizing: border-box;

      ::v-deep .el-form {
        .el-form-item {
          .el-form-item__label {
            font-family: Microsoft YaHei;
            font-size: 16px;
            color: #fff;
            text-align: right;
            font-weight: 400;
          }

          .el-form-item__content {
            // background: rgba(2, 31, 51, 0.7);
            // border: 1px solid #06b6e0;
            .field-group {
              display: flex;
              align-items: center;

              .el-icon-location-outline {
                color: #08c2d1;
                font-size: 26px;
                cursor: pointer;
                width: 34px;
              }
            }

            .el-input {
              .el-input__inner {
                font-family: MicrosoftYaHeiUI;
                font-size: 15px;
                color: #08c2d1;

                background: rgba(2, 31, 51, 0.7);
                border: 1px solid #06b6e0;
              }
            }

            .el-textarea {
              background: rgba(2, 31, 51, 0.7);
              border: 1px solid #06b6e0;

              .el-textarea__inner {
                background: transparent;
                border: none;
                color: #08c2d1;
              }
            }

            .el-button {
              width: 114px;
              height: 40px;
              border: 0;
              opacity: 0.8;
              font-family: PangMenZhengDao;
              font-size: 22px;
              color: #00ffff;
              text-align: center;
              font-weight: 400;
              width: 114px;
              height: 40px;
              padding: 10px 20px;
              background: rgba(0, 34, 140, 0.2);
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5),
                inset 0 0 15px 0 #00b6ff;
              border-radius: 4px;
            }
          }

          &.btns {
            .el-form-item__content {
              background: none;
              border: none;
            }
          }

          .el-date-editor.el-input {
            width: 100%;
          }
        }
      }
    }
  }
}

// 阻止点击事件
.preventClick {
  pointer-events: none;
}

.switch-box {
  display: flex;
  margin-right: 14px;
  margin-bottom: 12px;
  .btn1027 {
    padding: 8px;
  }
}
</style>