<!-- 鹰控 -->
<!-- 空域管理 -->
<template>
  <div class="page-command-situation pr">
    <div class="header" v-interact>
      <div class="flex">
        <img
          class="header__icon"
          src="@/assets/zong_lan_ye_img/frame/icon.png"
          alt
        />
        <div
          class="hd-box"
          :class="airtypeId == 0 ? 'active' : ''"
          @click="airtypeId = 0"
        >
          空域管理
        </div>
        <div
          class="hd-box ml12"
          :class="airtypeId == 1 ? 'active' : ''"
          @click="airtypeId = 1"
        >
          巡护区管理
        </div>
      </div>

      <div class="jcsb">
        <!-- @click="airspaceFlag=true" -->
        <div class="mr16 cp" @click="$emit('hedgehop')" style="color: #fff">
          低空限制
        </div>
        <img
          class="header__add-icon"
          src="~@/assets/images/command/airspace_icon.png"
          @click="init_cesium_handler"
          title="新增空域"
        />
        <div class="header__close" @click="change_page">关闭</div>
      </div>
    </div>

    <div class="bd-box">
      <div class="search mt12 ml12 mr12" v-if="airtypeId == 1">
        <el-select
          v-model="ruleform.typeId"
          placeholder="请选择巡护区类型"
          @change="onChangeType"
          clearable
        >
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <div class="search__btn" @click="showMap">{{ titles }}</div>
        <!-- <div class="search__btn" @click="exportTable">导出</div> -->
      </div>
      <div class="tb-box">
        <div class="tb-hd-box">
          <div class="tb-hd">
            {{ airtypeId == 0 ? "空域名称" : "区域名称" }}
          </div>
          <div class="tb-hd leixing">
            {{ airtypeId == 0 ? "空域类型" : "区域类型" }}
          </div>
          <div class="tb-hd zhuangtai">
            {{ airtypeId == 0 ? "空域状态" : "区域状态" }}
          </div>
          <div class="tb-hd shijian">更新时间</div>
          <div class="tb-hd jj">操作</div>
        </div>
        <div class="tb-bd-box">
          <div
            class="tb-tr"
            :class="{ 'tb-tr--color': (i + 1) % 2 }"
            v-for="(item, i) in list"
            :key="item.id"
          >
            <div class="td">
              {{ item.name || "暂无" }}
              <!-- <div :class="{ animate: item.name.length > 9 }">
                {{ item.name || "暂无" }}
              </div>-->
            </div>
            <div class="td">{{ item.typeName || "暂无" }}</div>
            <div class="td biaozhun" :class="{ caizi: item.status != 1 }">
              {{ item.status == 1 ? "正常" : "禁用" }}
            </div>
            <div class="td shijian">{{ item.editTime || "暂无" }}</div>
            <div class="td">
              <div class="btn1027" @click="handleClick(item)">预览</div>
            </div>
          </div>
        </div>
        <Airspace
          v-show="airspaceFlag"
          :airtypeId="airtypeId"
          ref="AirspaceRef"
          @getList="getList"
          @close="airspaceFlag = false"
        ></Airspace>
        <!-- <Export></Export> -->
      </div>
      <div class="tb-pagination">
        <div class="zongji">
          <span>总计</span>
          <span class="tiaoshu">{{ pagination.totalCount }}</span>
          <span>条</span>
        </div>
        <span class="douhao zongji">,</span>
        <div class="zongji dangqianye">
          <span>{{ pagination.pageNo }}</span>
          <span>/</span>
          <span>{{ zongye(pagination.totalCount) }}</span>
          <span>页</span>
        </div>
        <el-pagination
          small
          :page-sizes="[10, 20, 30, 40]"
          :current-page.sync="pagination.pageNo"
          :page-size="pagination.pageSize"
          layout="prev, pager, next, jumper"
          :total="pagination.totalCount"
          @size-change="handle_size_change"
          @current-change="handle_page_change"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import Airspace from "./airspace.vue";
import Export from "./export.vue";
import { Utils } from "@/lib/cesium";
import { nanoid } from "nanoid";
let positions = [];
let handler = null;
let data_srouce = null;
let location_icon = null;
let noFlyEntities = [];
let electricFenceEntities = [];
let point_index = null;

let isGetAddress = false;
let point_uuid = null;
let point_uuid_next = null;
let isEditting = true;
export default {
  data() {
    return {
      titles: "显示巡护区",
      airtypeId: 0,
      qianVal: "", // 页码跳转输入框
      list: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
      },
      airline: {
        filename: null,

        line: {
          // baseSpeed: 10,
          baseSpeed: 2,
        },
        points: [],
      },
      form: {},
      visibleFlag: true,
      airspaceFlag: false,
      action: {
        visible: false,
        types: [
          {
            label: "悬停(s)",
            input: "el-input-number",
            min: 0,
            max: 32000,
            actionType: "STAY",
            actionParam: 10,
          },
          {
            label: "拍照",
            actionType: "START_TAKE_PHOTO",
            actionParam: 0,
          },
          {
            label: "开始录像",
            actionType: "START_RECORD",
            actionParam: 0,
          },
          {
            label: "停止录像",
            actionType: "STOP_RECORD",
            actionParam: 0,
          },
        ],
        list: [],
        curr_index: 0,
      },
      typeList: [],
      ruleform: {
        typeId: null,
      },
      mapFlag: false,
    };
  },
  inject: ["change_page", "g_cesium_layer"],
  components: { Airspace, Export },
  destroyed() {
    this.destroy_cesium_handler();
    this.clear_entities();
  },
  watch: {
    airtypeId: {
      handler(val) {
        console.log(val);
        this.pagination.pageNo = 1;
        this.getList();
        this.$refs.AirspaceRef.init(val);
      },
    },
  },
  methods: {
    onChangeType(e) {
      this.ruleform.typeId = e;
      this.getList();
      this.titles = "显示巡护区";
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();
      viewer.dataSources.remove(this.airSpaceDataSrouce);
    },
    getType() {
      API.AIRSPACE.selectType({ belongTo: 1 }).then((res) => {
        this.typeList = res;
      });
    },
    // 计算总页码
    zongye(num) {
      let a = num / 10;
      var result = a.toString().indexOf(".");
      if (result != -1) {
        let b = parseInt(a);
        return b + 1;
      } else {
        return a;
      }
    },
    async init_cesium_handler() {
      this.airline = {
        filename: null,
        line: {
          // baseSpeed: 10,
          baseSpeed: 2,
        },
        points: [],
      };
      this.destroy_cesium_handler();
      let viewer = window.viewer;
      let currentPoint;
      // 修复规划航线时
      viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
        Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
      );

      if (data_srouce) {
        data_srouce.entities.removeAll();
        viewer.dataSources.remove(data_srouce, true);
        data_srouce = null;
        positions = [];
      }
      // 创建实体
      data_srouce = new Cesium.CustomDataSource("airway_edit");

      await viewer.dataSources.add(data_srouce);

      let entities = data_srouce.entities;
      let polyline = entities.add({
        polyline: {
          material: Cesium.Color.RED,
          width: 3,
          positions: new Cesium.CallbackProperty(() => {
            return positions.map((item) => {
              let wgs84 = Utils.transformCartesian2WGS84(item);
              return Utils.transformWGS842Cartesian({
                lng: wgs84.lng,
                lat: wgs84.lat,
                alt: wgs84.alt + 10,
              });
            });
          }, false),
        },
      });

      // viewer.scene.globe.depthTestAgainstTerrain = true;
      handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      handler.setInputAction(async (movement) => {
        let windowPosition = movement.position;
        let pickedObject = viewer.scene.pick(windowPosition);
        if (pickedObject) {
        }
        if (
          Cesium.defined(pickedObject) &&
          pickedObject.id?.name === "airline_point"
        ) {
          // 航点编辑
          // let entity = pickedObject.id;
          // point_uuid = entity.id;
          // let points = this.airline.points;
          // let index = points.findIndex((item) => item.uuid === entity.id);
          // if (index > -1) {
          //   point_index = index;
          //   if (index + 1 < points.length) {
          //     point_uuid_next = points[index + 1].uuid;
          //   }
          //   this.handler_current_point(index);
          // }
          // currentPoint = entity;
          // isEditting = true;
          // viewer.scene.screenSpaceCameraController.enableRotate = false;
          // viewer.scene.screenSpaceCameraController.enableZoom = false;
        } else {
          // 航点添加
          let position = Utils.getCartesian3FromPX(viewer, movement.position);
          if (Cesium.defined(position) && this.visibleFlag) {
            // this.reset_form();
            if (positions.length + 1) {
              let points = this.airline.points;
              if (this.action.curr_index <= points.length + 1) {
                this.action.curr_index = points.length + 1;
              } else {
                this.action.curr_index += 1;
              }
            }

            positions.push(position);
            // let wgs84 = Utils.transformCartesian2WGS84(position);
            // this.form.lon = wgs84.lng;
            // this.form.lat = wgs84.lat;
            let uuid = (this.form.uuid = nanoid());
            // console.log(uuid, "uuid");
            this.handle_save_point();

            if (positions.length > 1) {
              let before = positions[positions.length - 2];
              let after = positions[positions.length - 1];

              this.create_label(uuid, before, after);
            }
            this.create_point(uuid, position);
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      let __this = this;
      handler.setInputAction(function (event) {
        let positionsArr = JSON.parse(JSON.stringify(positions));
        let arr = [];
        let str = "";
        if (positionsArr.length <= 2)
          return __this.$message.info("航点必须在三个以上!");
        for (let i = 0; i < positionsArr.length; i++) {
          str += __this.transformationCartesian3(positionsArr[i]) + " ";
          arr.push(...__this.transformationCartesian3(positionsArr[i]));
        }

        __this.$refs.AirspaceRef.form = {
          name: "", //空域名称
          color: "red", //颜色
          category: 15,
          pid: 246158,
          region: str,
          regionList: arr,
          regionType: "POLYGON",
          tier: 1, //层
          // typeId: null,
          noFlyStartTime: "", //开始时间
          noFlyEndTime: "", //结束时间
        };
        if (__this.airtypeId) {
          __this.$refs.AirspaceRef.form.types = [];
          __this.$refs.AirspaceRef.form.belongTo = 1;
          __this.$refs.AirspaceRef.typeId = [];
        } else {
          __this.$refs.AirspaceRef.form.typeId = 1;
        }
        __this.airspaceFlag = true;
        __this.airline = {
          filename: null,
          line: {
            // baseSpeed: 10,
            baseSpeed: 2,
          },
          points: [],
        };
        __this.destroy_cesium_handler();
      }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
      // 对鼠标移动事件的监听
      // handler.setInputAction((event) => {
      //   console.log('aaa',currentPoint)
      //   if (isEditting && currentPoint) {
      //     // let windowPosition = event.startPosition;
      //     // let ellipsoid = viewer.scene.globe.ellipsoid;
      //     // let cartesian = viewer.camera.pickEllipsoid(windowPosition, ellipsoid);
      //     let cartesian = Utils.getCartesian3FromPX(
      //       viewer,
      //       event.startPosition
      //     );

      //     if (!cartesian) {
      //       return;
      //     }
      //     currentPoint.position = cartesian;

      //     if (!(typeof point_index == undefined) && positions?.length > 1) {
      //       positions[point_index] = cartesian;
      //       let current_wgs84 = Utils.transformCartesian2WGS84(cartesian);
      //       this.airline.points[point_index].lon = current_wgs84.lng;
      //       this.airline.points[point_index].lat = current_wgs84.lat;
      //       if (point_index === this.action.curr_index) {
      //         this.form.lon = current_wgs84.lng;
      //         this.form.lat = current_wgs84.lat;
      //       }

      //       if (point_index > 0) {
      //         this.update_label(
      //           point_uuid,
      //           cartesian,
      //           positions[point_index - 1]
      //         );
      //       }
      //       if (point_uuid_next) {
      //         this.update_label(
      //           point_uuid_next,
      //           cartesian,
      //           positions[point_index + 1]
      //         );
      //       }
      //     }
      //   }
      // }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

      // // 对鼠标抬起事件的监听
      // handler.setInputAction((event) => {
      //   console.log(event,'321312312');
      //   point_index = null;
      //   point_uuid_next = null;
      //   isEditting = false;
      //   currentPoint = undefined;
      //   // viewer.render()
      //   setTimeout(() => {
      //     viewer.scene.screenSpaceCameraController.enableRotate = true;
      //     viewer.scene.screenSpaceCameraController.enableZoom = true;
      //   }, 300);
      // }, Cesium.ScreenSpaceEventType.LEFT_UP);
    },
    clear_entities() {
      noFlyEntities.forEach((item) => {
        viewer.entities.remove(item);
      });
      electricFenceEntities.forEach((item) => {
        viewer.entities.remove(item);
      });
      noFlyEntities = [];
      electricFenceEntities = [];
    },
    transformationCartesian3(positions) {
      let ellipsoid = viewer.scene.globe.ellipsoid;
      let cartesian3 = new Cesium.Cartesian3(
        positions.x,
        positions.y,
        positions.z
      );
      let cartographic = ellipsoid.cartesianToCartographic(cartesian3);
      let lng = Cesium.Math.toDegrees(cartographic.longitude);
      let lat = Cesium.Math.toDegrees(cartographic.latitude);
      return [lng, lat];
    },
    update_label(uuid, before, after) {
      let entities = data_srouce.entities;
      let id = `label_${uuid}`;
      let entity = entities.getById(id);

      let before_wgs84 = Utils.transformCartesian2WGS84(before);
      let after_wgs84 = Utils.transformCartesian2WGS84(after);

      let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2;
      let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2;
      let alt = (after_wgs84.alt + before_wgs84.alt) / 2;

      let position = Utils.transformWGS842Cartesian({
        lng: center_lng,
        lat: cetner_lat,
        alt: alt + 10,
      });
      let text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`;

      entity.position = position;
      entity.label.text = text;
    },
    create_label(uuid, before, after, alt) {
      if (data_srouce) {
        let entities = data_srouce.entities;
        let before_wgs84 = Utils.transformCartesian2WGS84(before);
        let after_wgs84 = Utils.transformCartesian2WGS84(after);

        let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2;
        let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2;
        let alt = (after_wgs84.alt + before_wgs84.alt) / 2;

        let position = Utils.transformWGS842Cartesian({
          lng: center_lng,
          lat: cetner_lat,
          alt: alt + 10,
        });

        let text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`;

        entities.add({
          id: `label_${uuid}`,
          position: position,
          label: {
            text: text,
            scale: 0.5,
            font: "bold 30px Microsoft YaHei",
            fillColor: Cesium.Color.fromCssColorString("#fff"),
            horizontalOrigin: Cesium.VerticalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            showBackground: true,
            backgroundPadding: new Cesium.Cartesian2(10, 10),
          },
        });
      }
    },

    destroy_cesium_handler() {
      let viewer = window.viewer;
      viewer.scene.globe.depthTestAgainstTerrain = false;

      if (handler) {
        // handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
        handler && handler.destroy() && (handler = null);
        handler = null;
      }

      if (data_srouce) {
        data_srouce.entities.removeAll();
        viewer.dataSources.remove(data_srouce, true);
        data_srouce = null;
        positions = [];
      }
    },
    create_point(uuid, position) {
      let wgs84 = Utils.transformCartesian2WGS84(position);
      let points = this.airline.points;
      if (data_srouce && this.visibleFlag) {
        let entities = data_srouce.entities;
        entities.add({
          name: "airline_point",
          id: uuid,
          position: Utils.transformWGS842Cartesian({
            lng: wgs84.lng,
            lat: wgs84.lat,
            alt: wgs84.alt + 10,
          }),
          point: {
            pixelSize: 20,
            color: Cesium.Color.RED,
            fillColor: Cesium.Color.RED,
            // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND, // supermap版本会导致拖动显示错误
          },
          label: {
            text: new Cesium.CallbackProperty(() => {
              let index = points.findIndex((item) => item.uuid === uuid);
              return String(index + 1);
            }, false),
            scale: 0.5,
            font: "bold 24px Microsoft YaHei",
            fillColor: Cesium.Color.WHITE,
            horizontalOrigin: Cesium.VerticalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.CENTER,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            showBackground: false,
            outlineWidth: 0,
          },
        });
      }
    },
    handle_size_change(size) {
      this.pagination.pageSize = size;
      this.getList();
    },
    handler_current_point(index) {
      let points = this.airline.points;
      this.action.curr_index = index;
      this.init_point(points[this.action.curr_index]);
    },
    handle_page_change(page) {
      this.pagination.pageNo = page;
      this.getList();
    },
    init_point(data) {
      Object.keys(data).map((key) => {
        if (this.form.hasOwnProperty(key)) {
          this.form[key] = data[key];
        }
      });
    },
    exportTable() {},
    //显示巡护区可视化
    async showMap() {
      this.mapFlag = !this.mapFlag;
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();
      if (this.mapFlag) {
        this.titles = "隐藏巡护区";
        let res = await API.AIRSPACE.List({
          pageSize: 9999,
          pageNo: 1,
          belongTo: 1,
          typeId: this.ruleform.typeId,
        });

        // 创建实体
        this.airSpaceDataSrouce = new Cesium.CustomDataSource("airSpace");
        res.data.forEach((item) => {
          let positions = item.regionList;
          if (item.regionType == "CIRCLE") {
            this.airSpaceDataSrouce.entities.add({
              position: Cesium.Cartesian3.fromDegrees(
                positions[0],
                positions[1]
              ),
              ellipse: {
                semiMajorAxis: positions[2],
                semiMinorAxis: positions[2],
                material: Cesium.Color.fromCssColorString(
                  item.color || "red"
                ).withAlpha(0.3),
                /* item.typeId == 2
                    ? Cesium.Color.GREEN.withAlpha(0.5)
                    : Cesium.Color.RED.withAlpha(0.5), */
              },
              show: true,
              id: item.id,
            });
          } else if (item.regionType == "POLYGON") {
            this.airSpaceDataSrouce.entities.add({
              // viewer.entities.add({
              polygon: {
                hierarchy: {
                  positions: Cesium.Cartesian3.fromDegreesArray(positions),
                },
                outline: false,
                material: Cesium.Color.fromCssColorString(
                  item.color || "red"
                ).withAlpha(0.3),
              },
              show: true,
              id: item.id,
            });
          }
        });

        viewer.dataSources.add(this.airSpaceDataSrouce);
      } else {
        this.titles = "显示巡护区";
        viewer.dataSources.remove(this.airSpaceDataSrouce);
      }
    },
    handleClick(item) {
      console.log("handleClick", item);
      item.visible = !item.visible;
      if (item.visible) {
        this.$emit("handleHide", item);
      } else {
        this.$emit("handleShow", item);
      }
    },
    handle_save_point() {
      this.airline.points.push({ ...this.form });
    },
    handleHide(item) {
      item.visible = true;
      this.$emit("handleHide", item);
    },
    async getList() {
      let { data, pageNo, pageSize, totalCount } = await API.AIRSPACE.List({
        ...this.pagination,
        ...this.ruleform,
        belongTo: this.airtypeId,
      });
      this.list =
        data.map(
          (item) =>
            (item = {
              ...item,
              visible: true,
            })
        ) || [];
      this.pagination.pageNo = pageNo;
      this.pagination.pageSize = pageSize;
      this.pagination.totalCount = totalCount;
    },
  },

  mounted() {
    this.getType();
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";
.page-command-situation {
  position: absolute;
  left: 10px;
  top: 99px;
  width: 594px;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;

    .icon-linshijinfeiqu {
      font-size: 18px;
      color: rgba(151, 151, 151, 1);
    }
  }
  .hd-box {
    font-size: 15px;
    font-family: YouSheBiaoTiHei;
    color: #fff;
    letter-spacing: 0;
    margin-top: 5px;
    cursor: pointer;
  }

  .active {
    font-weight: 700;
    font-size: 20px;
    margin-top: 0;
    background-image: -webkit-linear-gradient(
      right,
      #e3aa77,
      #f5cda9,
      #f9ecd3,
      #fcdbb1,
      #edb07a
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .bd-box {
    height: calc(100% - 56px);
    box-sizing: border-box;
    .search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .el-select {
        width: 300px;
        /deep/.el-input {
          width: 300px;
          input {
            height: 40px;
          }
        }
      }
      .search__btn {
        white-space: nowrap;
        // padding: 25px;
        padding: 10px;
        // background: rgba(38, 71, 238, 0.71);
        background: url("~@/assets/yingkong1027/btn_active.png") no-repeat;
        border-radius: 4px;
        font-size: 14px;
        color: #fff;
      }
    }
    .tb-box {
      box-sizing: border-box;
      height: calc(100% - 20px);
      margin: 12px 12px 12px 12px;
      background: rgba(8, 26, 58, 0.8);
      border-radius: 4px;
      border: 1px solid #315ec7;

      .tb-hd-box {
        display: flex;
        justify-content: space-between;
        height: 42px;
        font-size: 16px;
        font-family: YouSheBiaoTiHei;
        color: #ebf9ff;

        .tb-hd {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .leixing {
          margin-left: -20px;
        }
        .zhuangtai {
          margin-left: -12px;
        }
      }
      .tb-bd-box {
        height: 385px;
        overflow: auto;
        .tb-tr {
          display: flex;
          box-sizing: border-box;

          &.tb-tr--color {
            background: rgba(73, 135, 210, 0.2);
          }

          .td {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% / 4);
            padding: 9px 0;
            text-align: center;

            word-break: break-word;
            box-sizing: border-box;

            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #ebf9ff;
            .iconfont {
              font-size: 20px;
              cursor: pointer;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .tb-pagination {
    height: 30px;
    display: flex;
    align-items: center;
    margin: 16px 0 11px 16px;
    ::v-deep .el-pagination {
      height: 30px;
      display: flex;
      align-items: center;
      button {
        background: transparent;
        font-size: 12px;
        color: #889fb2;
        text-align: center;
        font-weight: 400;
        &:disabled {
          background: transparent;
          color: #889fb2;
          opacity: 0.3;
        }
      }
      .el-pagination__total {
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #00ffff;
        letter-spacing: 0;
        line-height: 16px;
        font-weight: 400;

        text-align: center;
        height: 30px;
        line-height: 30px;
        display: inline-block;
        margin: 0px;
      }
      .el-pagination__sizes {
        height: 30px;
        line-height: 30px;
        margin: 0px;
        .el-select {
          .el-input {
            width: 60px;
            padding-right: 20px;
            margin: 0;
            .el-input__inner {
              background: transparent;
              border: none;
              line-height: 30px;
              font-size: 12px;
              color: #b5e5ff;
              text-align: center;
              font-weight: 400;
              padding: 0px;
            }
          }
        }
      }
      .el-pager {
        .number {
          background: transparent;
          font-size: 12px;
          color: #889fb2;
          text-align: center;
          font-weight: 400;
          &.active {
            background: #00b6ff;
            border-radius: 2px;
            color: #ffffff;
          }
        }
        .more {
          background: transparent;
          color: #889fb2;
        }
      }
      .el-pagination__jump {
        font-size: 12px;
        color: #889fb2;
        text-align: center;
        font-weight: 400;
        height: 30px;
        line-height: 30px;
        margin-left: 6px;
        .el-input {
          .el-input__inner {
            border: 1px solid rgba(36, 146, 252, 0.3);
            border-radius: 2px;
            background: rgba(12, 13, 20, 0.5);
            color: #889fb2;
          }
        }
      }
    }
    ._jumper {
      font-size: 12px;
      color: #889fb2;
      text-align: center;
      font-weight: 400;
      display: flex;
      align-items: center;
      white-space: nowrap;
      ::v-deep .el-input {
        .el-input__inner {
          background: rgba(12, 13, 20, 0.5);
          border: 1px solid rgba(36, 146, 252, 0.3);
          border-radius: 2px;
          width: 33px;
          height: 24px;
          color: #889fb2;
          padding: 0;
          text-align: center;
        }
      }
      ._jumper_btn {
        width: 51px;
        height: 22px;
        background: #333c57;
        border: 1px solid $primary-border-color;
        border-radius: 2px;
        padding: 0px;
        font-size: 14px;
        color: $primary-color;
        letter-spacing: -0.08px;
        text-align: center;
        font-weight: 400;
        margin-left: 4px;
      }
    }
  }
}
// ------------------------------------------------
.tb-bd-box::-webkit-scrollbar {
  width: 0 !important;
}
.flex {
  display: flex;
}
.xian {
  width: 3px;
  height: 15px;
  background: #ffbd36;
  margin: 3px 0 0 0;
}
.header__close {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #d2dfff;
  margin-right: 16px;
  cursor: pointer;
}
.jcsb {
  display: flex;
  align-items: center;
}
.iconfont {
  margin: 0 40px 0 0px;
}
.tb-tr:hover {
  background: rgba(2, 19, 96, 0.2);
  box-shadow: inset 0px 0px 10px 2px #3fcbff;
  border: 1px solid #70f6f9;
}
.caizi {
  color: #ffbd36 !important;
}
.biaozhun {
  font-size: 14px !important;
  color: #00d45c !important;
  line-height: 19px;
}
.shijian {
  width: 34%;
  white-space: nowrap;
}
.jj {
  margin-right: -5px;
}
// 动画
.animate {
  padding-left: 20px;
  // font-size: 12px;
  // color: #000;
  display: inline-block;
  white-space: nowrap;
  animation: 5s wordsLoop linear infinite normal;
}

@keyframes wordsLoop {
  0% {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
    // -webkit-transform: translateX(-100%);
  }
}
// 页签样式
.zongji {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #b5e5ff;
  line-height: 16px;
  .tiaoshu {
    color: #43deff;
  }
}
.douhao {
  margin: 0 5px 0 5px;
}
.dangqianye {
  margin: 0 20px 0 0;
}
/deep/.el-pager .number:hover {
  background: #00b6ff !important;
  border-radius: 2px;
  color: #000 !important;
  width: 14px;
  height: 22px;
  line-height: 22px;
}
/deep/ .el-pagination--small .el-pager .number {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #889fb2;
}
/deep/.active {
  color: #000 !important;
}
.btnqueding {
  margin: 0 0 0 8px;
  width: 79px;
  height: 32px;
  background: rgba(23, 70, 216, 0.2);
  box-shadow: 0px 2px 4px 0px rgba(23, 33, 60, 0.5),
    inset 0px 0px 16px 0px rgba(33, 137, 255, 0.4),
    inset 0px 0px 4px 0px #00a7ff;
  border-radius: 6px;
  border: 0px solid;
  border-image: linear-gradient(
      180deg,
      rgba(138, 218, 255, 1),
      rgba(82, 179, 255, 0)
    )
    1 1;
  line-height: 1px;
}
.tb-pagination {
  margin: 0 0 19px 0;
}
.zhuan {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #889fb2;
  line-height: 16px;
  input {
    width: 48px;
    min-width: 48px;
    max-width: auto;
    text-align: center;
    height: 28px;
    background: rgba(12, 13, 20, 0.5);
    border-radius: 2px;
    border: 1px solid rgba(36, 146, 252, 0.3);
    margin: 0 5px 0 5px;
    color: #fff;
    outline: 0px solid;
  }
}

.header__icon {
  width: 19px;
  height: 25px;
  margin-right: 4px;
}

.btn1027 {
  padding-left: 16px;
  padding-right: 16px;
}

.header__add-icon {
  width: 20px;
  height: 14px;
  margin-right: 39px;
  cursor: pointer;
}
</style>
