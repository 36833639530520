<template>
  <div class="content">
    <div class="content_head" v-interact>
      <div class="left">
        <div class="line"></div>
        {{form ? "人员编辑" : "人员登记"}}
      </div>
      <div class="right" @click="() => $emit('closeuser')">关闭</div>
    </div>
    <div class="content_box">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
      >
        <el-form-item label="用户名称:" prop="name">
          <el-input
            v-model="ruleForm.name"
            clearable
            placeholder="请输入用户名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户身份类型:">
            <el-radio-group v-model="ruleForm.type">
              <el-radio :label="0">民警</el-radio>
              <el-radio :label="1">辅警</el-radio>
              <el-radio :label="2">飞行助手</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="警员编号:" prop="USER_POLICE_ID" v-if="ruleForm.type == 0">
          <el-input
            v-model="ruleForm.USER_POLICE_ID"
            clearable
            placeholder="请输入警员编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号码:" prop="USER_CODE">
          <el-input
            v-model="ruleForm.USER_CODE"
            clearable
            placeholder="请输入身份证号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户账号:" prop="username">
          <el-input
            v-model="ruleForm.username"
            clearable
            placeholder="请输入用户账号"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码:" prop="phone">
          <el-input
            v-model="ruleForm.phone"
            clearable
            placeholder="请输入手机号码"
            oninput="value=value.replace(/[^0-9]/g,'')"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属机构:" prop="organizationId">
          <el-cascader
            v-model="ruleForm.organizationId"
            :options="options"
            clearable
            :showAllLevels="false"
            placeholder="请选择所属机构"
            :props="{
              value: 'id',
              label: 'orgName',
              children: 'children',
              emitPath: false,
              checkStrictly: true,
            }"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="所属角色:" prop="mmcRoleId">
          <el-select
            v-model="ruleForm.mmcRoleId"
            clearable
            placeholder="请选择所属角色"
          >
            <el-option
              v-for="item in roleList"
              :key="item.roleId"
              :label="item.roleName"
              :value="item.roleId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <div class="flex"> -->
          <el-form-item label="是否机构管理员:" prop="orgAdmin">
            <el-radio-group v-model="ruleForm.orgAdmin">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
            <!-- <el-switch v-model="ruleForm.orgAdmin"></el-switch> -->
          </el-form-item>
          <el-form-item label="是否为飞手:" prop="isPilot">
            <el-radio-group v-model="ruleForm.isPilot">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
            <!-- <el-switch v-model="ruleForm.isPilot"></el-switch> -->
          </el-form-item>
          <el-form-item label="是否取得相应警航证:" prop="isPoliceFlightCertificate">
            <el-radio-group v-model="ruleForm.isPoliceFlightCertificate">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
            <!-- <el-switch v-model="ruleForm.isPoliceFlightCertificate"></el-switch> -->
          </el-form-item>
        <!-- </div> -->
        <el-form-item label="用户层级:" prop="userLevel">
          <el-select
            v-model="ruleForm.userLevel"
            clearable
            placeholder="请选择用户层级"
          >
            <el-option
              v-for="item in hierarchyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="登陆方式:" prop="loginType">
          <el-select
            v-model="ruleForm.loginType"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in loginTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        
        <el-form-item label="可控无人机类型:" prop="cateId">
          <el-select
            v-model="ruleForm.cateId"
            clearable
            placeholder="请选择可控无人机类型"
          >
            <el-option
              v-for="item in cateList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="登录密码:" prop="password">
          <el-input
            v-model.trim="ruleForm.password"
            type="password"
            clearable
            id="password"
            placeholder="请输入登录密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码:" prop="confirmPassword">
          <el-input
            v-model.trim="ruleForm.confirmPassword"
            type="password"
            clearable
            id="confirmPassword"
            placeholder="请确认密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户状态:" prop="status" v-if="id">
          <el-radio-group v-model="ruleForm.status">
            <el-radio label="1">正常</el-radio>
            <el-radio label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="警航证正面照:" prop="flightCertificatePathFront">
          <el-upload
            :action="url"
            :http-request="handle_http_request_1"
            :multiple="false"
            accept=".png, .jpg"
            :limit="1"
            :on-remove="onRemove_1"
          >
            <el-button size="small" type="primary" style="margin: 0"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip">支持jpg/png格式</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="警航证反面照:" prop="flightCertificatePathBack">
          <el-upload
            :action="url"
            :http-request="handle_http_request_2"
            :multiple="false"
            accept=".png, .jpg"
            :limit="1"
            :on-remove="onRemove_2"
          >
            <el-button size="small" type="primary" style="margin: 0"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip">支持jpg/png格式</div>
          </el-upload>
        </el-form-item> -->
        <el-form-item label="备注说明:" prop="comment">
          <el-input
            v-model="ruleForm.comment"
            clearable
            type="textarea"
            placeholder="请输入备注说明"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btn" @click="submit">提交</div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  props:['form'],
  data() {
    let validatePassword = (rule, value, callback) => {
      let password = /^[a-zA-Z0-9]{6,18}$/;
    if (!value) {
      callback(new Error('请输入登录密码'))
    } else if (!password.test(value) && value != '******') {
      callback(new Error('登录密码需由6-18位数字字母组成'))
    } else {
      callback()
    }
  };
    let validatorCfmPsd = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请再次输入密码"));
      } else if (value != this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    let validateName = (rule, value, callback) => {
    if (!value) {
      callback(new Error('请输入用户名称'))
    } else if (value.length > 10) {
      callback(new Error('长度限制在10位数以内'))
    } else {
      callback()
    }
  };
    let validateUserName = async (rule, value, callback) => {
      let username = /^[0-9A-Za-z]{5,12}$/;
      if (!value) {
        callback(new Error("请输入用户账号"));
      } else if (!username.test(value)) {
        callback(new Error("用户账号需由5-12位数字字母组成"));
      }
      else {
        callback();
      }
    };
    let validatePhone = async (rule, value, callback) => {
      let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!value) {
        callback(new Error("请输入手机号码"));
      } else if (!phone.test(value)) {
        callback(new Error("请输入正确的手机号码"));
      }
      else {
        callback();
      }
    };
    let validateRemark = (rule, value, callback) => {
      if (value && value.length > 30) {
        callback(new Error("长度限制在30位数以内"));
      } else {
        callback();
      }
    };
    return {
      url: "",
      ruleForm: {
        password: null,
        confirmPassword: null,
        mmcRoleId: null,
        userLevel: null,
        loginType: 1,
        uavType: null,
        // type: [],
        orgAdmin: 0,
        isPilot: 0,
        isPoliceFlightCertificate: 0,
        type: 0,
      },
      rules: {
        username: [
          {
            required: true,
            validator: validateUserName,
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            validator: validateName,
            trigger: "blur",
          },
        ],
        userLevel: [
          {
            required: true,
            message: '请选择用户层级',
            trigger: 'change'
          }
        ],
        uavType: [
          {
            required: true,
            message: '请选择可控飞机类型',
            trigger: 'change'
          }
        ],
        loginType: [
          {
            required: true,
            message: '请选择登陆方式',
            trigger: 'change'
          }
        ],
        password: [
          {
            required: true,
            validator: validatePassword,
            trigger: "blur",
          },
        ],
        confirmPassword: [
          {
            required: true,
            validator: validatorCfmPsd,
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        organizationId: [
          {
            required: true,
            message: "请选择所属机构",
            trigger: "change",
          },
        ],
        mmcRoleId: [
          {
            required: true,
            message: "请选择所属角色",
            trigger: "change",
          },
        ],
        remark: [
          {
            validator: validateRemark,
            trigger: "blur",
          },
        ],
      },
      hierarchyList:[
        {
          id: 0,
          name: '一级'
        },
        {
          id: 1,
          name: '二级'
        },
        {
          id: 2,
          name: '三级'
        }
      ],
      loginTypeList: [
        {
          id: 0,
          name: '数字证书'
        },
        {
          id: 1,
          name: '用户名密码'
        },
        {
          id: 2,
          name: '人脸识别'
        }
      ],
      cateList: [],
      options: [],
      roleList:[]
    };
  },
  methods: {
    /*无人机型号 */
    async getCate() {
      let res = await API.DEVICE.ListCate();
      this.cateList = res;
    },
    /*机构 */
    async getOptions() {
      let res = await API.DEPARTMENT.Drd();
      // each(res);
      this.options = res || [];
    },
    async getroleList() {
      let data = await API.DEVICE.roleList();
      console.log(data,'asdbhhjbdhjas');
      this.roleList = data || [];
    },
    async handle_http_request(e) {
      let file = e.file,
        fd = new FormData();
      fd.append("uploadFiles", file);
      let res = await API.MAPMANAGE.fileUpload(fd);
      if (res.status == 1) {
        this.ruleForm.image = res.data[0].url;
      } else {
        this.$message.error(res?.message);
      }
    },
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if(this.ruleForm.id){
            API.DEVICE.userEdit(this.ruleForm).then((res) => {
            if (res.status == 1) {
              this.$message.success("编辑成功!");
              this.$emit("closeuser");
            }
          });
          }else{
            API.DEVICE.userAdd(this.ruleForm).then((res) => {
              if (res.status == 1) {
                this.$message.success("创建成功!");
                this.$emit("closeuser");
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    changePic(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]; //这一步，是 展示最后一次选择文件
      }
    },
    onRemove() {
      this.ruleForm.image = null;
    },
  },
  mounted() {
    let baseUrl = process.env.VUE_APP_BASE_URL;
    this.url = baseUrl + "/upload/v2_uploads";
    console.log(this.url,'jfdbshjahjfgasuf');
    this.getCate();
    this.getroleList()
    this.getOptions();
    if(this.form != null){
      this.ruleForm = this.form
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: fixed;
  left: 596px;
  top: 10%;
  width: 676px;
  height: 800px;
  background: rgba(0, 39, 121, 0.5);
  box-shadow: 0px 2px 8px 0px rgba(1, 162, 255, 0.7),
    inset 0px 0px 64px 0px rgba(26, 138, 227, 0.35),
    inset 0px 0px 8px 0px #019aff;
  border: 1px solid #43deff;
  backdrop-filter: blur(2px);
  .content_head {
    display: flex;
    justify-content: space-between;
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border: 1px solid #43deff;
    align-items: center;
    padding: 0 16px;
    .left {
      display: flex;
      align-items: center;
      .line {
        width: 3px;
        height: 15px;
        background: #ffbd36;
        margin-right: 5px;
      }
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ffffff;
      text-shadow: 0px 2px 4px #136791;
    }
    .right {
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #00ffff;
      background-clip: text;
    }
  }
  .content_box {
    width: 643px;
    height: 700px;
    padding: 16px;
    overflow-y: auto;
    overflow-x: hidden;
    .flex {
  display: flex;
}
    .btn {
      margin-left: 120px;
      width: 94px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      background: rgba(23, 70, 216, 0.2);
      box-shadow: 0px 2px 4px 0px rgba(23, 33, 60, 0.5),
        inset 0px 0px 16px 0px rgba(33, 137, 255, 0.4),
        inset 0px 0px 4px 0px #00a7ff;
      border-radius: 6px;
      // border: 1px solid;
      border-image: linear-gradient(
          180deg,
          rgba(138, 218, 255, 1),
          rgba(82, 179, 255, 0)
        )
        1 1;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #00d3ff;
    }
  }
.el-form-item {
  margin-bottom: 18px;
}
/deep/ .el-form-item__label {
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  color: #92d9ff;
  letter-spacing: 0;
  font-weight: 700;
}
/deep/ .el-select .el-input__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 316px;
  height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}
/deep/ .el-cascader .el-input__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 316px !important;
  height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}
/deep/ .el-textarea .el-textarea__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 316px;
  // height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}
/deep/ .el-input .el-input__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 316px;
  height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}
.el-select,
.el-input,
.el-cascader {
  width: 316px !important;
}
/deep/ .el-cascader .el-input{
  width: 316px !important;
}
}
</style>