<template>
  <div class="content dialog1027">
    <div class="content_head dialog-header" v-interact>
      <div class="head_left">
        <div class="dialog-header__icon"></div>
        <div class="fontO center dialog-header__title">
          {{ id ? "编辑" : "新建" }}
        </div>
      </div>
      <div class="close mr20 dialog-header__close" @click="close">关闭</div>
    </div>
    <div class="content_box dialog-content">
      <el-form
        :model="ToneForm"
        :rules="rules"
        ref="ToneForm"
        label-position="left"
      >
        <el-form-item label="名称:" prop="name">
          <el-input
            v-model="ToneForm.name"
            disabled
            clearable
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="次数:" prop="totalTimes">
          <el-input-number
            v-model="ToneForm.totalTimes"
            :min="1"
            :max="500"
          ></el-input-number>
        </el-form-item> -->
        <!-- <el-form-item label="类型:" prop="dispatchType">
          <el-radio-group v-model="ToneForm.dispatchType" @change="changeType">
            <el-radio label="周期点调">周期点调</el-radio>
            <el-radio label="临时点调">临时点调</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="时间:" prop="time" class="ml14">
          <el-date-picker
            prefix-icon="ccc"
            v-model="pickTime"
            type="datetimerange"
            range-separator="至"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            popper-class="flight-log"
            collapse-tags
            :picker-options="changePicker"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="点调分局:" prop="pid">
          <el-select v-model="pid" @change="changeOrg">
                <el-option
                  v-for="item in orgList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
        </el-form-item>
        <!-- <el-form-item label="参调单位:" prop="organizationsList">
          <el-cascader
          v-if="ToneForm.dispatchType == '临时点调'"
            v-model="ToneForm.organizationsList"
            :options="unitList"
            clearable
            :showAllLevels="false"
            placeholder="请选择所属机构"
            :props="{
              value: 'id',
              label: 'orgName',
              children: 'children',
              disabled: 'feedBack',
              emitPath: false,
              checkStrictly: true,
              multiple: true,
            }"
          ></el-cascader>
          <el-cascader
            v-if="ToneForm.dispatchType == '周期点调'"
            v-model="ToneForm.organizationsList"
            :options="unitList"
            clearable
            :showAllLevels="false"
            placeholder="请选择所属机构"
            :props="{
              value: 'id',
              label: 'orgName',
              children: 'children',
              emitPath: false,
              checkStrictly: true,
              multiple: true,
            }"
          ></el-cascader>
        </el-form-item> -->
      </el-form>
      <div class="org_list">
        <div class="head">参调单位</div>
        <div class="th">
          <div class="td">单位名称</div>
          <div class="td">无人机编号</div>
          <div class="td">目的地</div>
          <div class="td">操作</div>
        </div>
        <div class="list">
          <div class="tbody" v-for="(item, index) in list" :key="index">
            <div class="td">
              <el-cascader
                v-model="item.organizationId"
                :options="unitList"
                @change="(val) => onChange(val, index)"
                clearable
                :showAllLevels="false"
                placeholder="请选择所属机构"
                :props="{
                  value: 'id',
                  label: 'orgName',
                  children: 'children',
                  emitPath: false,
                  checkStrictly: true,
                }"
              ></el-cascader>
            </div>
            <div class="td">
              <el-select v-model="item.uavId" clearable="">
                <el-option
                  v-for="item2 in item.dronelist"
                  :key="item2.id"
                  :label="item2.deviceName"
                  :value="item2.id"
                />
              </el-select>
            </div>
            <div class="td">
              <el-autocomplete
                v-model="item.description"
                :fetch-suggestions="handle_query_address_async"
                placeholder="请输入目标位置"
                :trigger-on-focus="false"
                @select="(val) => handle_query_select_async(val, item)"
                clearable
              >
                <template slot-scope="{ item }">
                  <div>
                    <span style="font-size: 14px">{{ item.name }}</span>
                    <!-- <span
                    style="font-size: 12px; color: #999; margin-left: 12px"
                    >{{ item.address }}</span
                  > -->
                  </div>
                </template>
              </el-autocomplete>
            </div>
            <div class="td">
              <div class="delbtn btn1027" @click="deleteOrg(index)">删除</div>
            </div>
          </div>
        </div>
        <div class="add" @click="addOrg">
          <span class="iconfont icon-tianjia"></span>
        </div>
      </div>
      <div class="btn-box1027">
        <div class="btn1027" @click="sumbit">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import data from "../../../../pages/home/Controlled_Airspace/data";
export default {
  props: ["id", "type"],
  components: {},
  data() {
    return {
      pickTime: [],
      list: [
        {
          description: "",
          organizationId: null,
          dronelist: [],
          uavId: "",
        },
      ],
      pid: null,
      orgList: [
        {
          id: 60615,
          name: "亭湖分局"
        },
        {
          id: 60780,
          name: "盐都分局"
        },
        {
          id: 64428,
          name: "经开区分局"
        },
        {
          id: 245971,
          name: "盐南分局"
        },
        {
          id: 246140,
          name: "市局警种部门"
        },
      ],
      ToneForm: {
        list: [],
        name: "",
      },
      isShow: false,
      uavShow: false,
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        organizationsList: [
          {
            required: true,
            message: "请选择参调单位",
            trigger: "change",
          },
        ],
        totalTimes: [
          {
            required: true,
            message: "请填入次数",
            trigger: "blur",
          },
        ],
      },
      unitList: [],
      changePicker: {
        disabledDate(date) {
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
    };
  },
  mounted() {
    if (this.type == 0 || this.type == 2) {
      this.ToneForm.name = this.time();
    }
    if (this.id) {
      this.getForm();
    }
  },
  methods: {
    changeOrg(e) {
      this.ToneForm.name = this.time();
      this.getUnitList(e)
    },
    time() {
      let now = new Date();
      let year = now.getFullYear(); //得到年份
      let month = now.getMonth() + 1 > 9 ? now.getMonth() + 1 : "0" + (now.getMonth() + 1); //得到月份
      let date = now.getDate() > 9 ? now.getDate() : "0" + now.getDate(); //得到日期
      // let day = now.getDay();//得到周几
      let hour = now.getHours() > 9 ? now.getHours() : "0" + now.getHours(); //得到小时数
      let minute =
        now.getMinutes().toString().length > 1
          ? now.getMinutes()
          : 0 + now.getMinutes().toString(); //得到分钟数
      let second = now.getSeconds() > 9 ? now.getSeconds() : "0" + now.getSeconds(); //得到秒数
      return (
        "点调"+
        "-" +
        year +
        month +
        date +
        hour +
        minute +
        second
        
      );
    },
    async onChange(id, index) {
      if (id) {
        let res = await API.TONE.getuavlist(id);
        if(res){
          this.list.forEach(val=>{
              res.forEach((val2,i)=>{
              if(val.uavId == val2.id){
                res.splice(i,1)
              }
            })
          })
        }
        this.list[index].dronelist = res;
      } else {
        this.list[index].dronelist = [];
      }
      this.list[index].uavId = null;
    },
    handle_query_address_async(address, cb) {
      API.MAP.Geo({ keywords: address })
        .then((res) => {
          cb(res.data.data.rows);
        })
        .catch((e) => cb([]));
    },
    handle_query_select_async(val, item) {
      item.description = val.name;
    },
    addOrg() {
      let data = {
        description: "",
        organizationId: null,
        dronelist: [],
        uavId: "",
      };
      this.list.push(data);
    },
    deleteOrg(e) {
      if (e == 0) {
        this.$message.error("至少有一个参调单位！");
      } else {
        this.list.splice(e, 1);
      }
    },
    async getUnitList(e) {
      let data = await API.DEPARTMENT.getAllOrganList({ organId: e });
      this.ToneForm.name = data.orgName + "-" + this.ToneForm.name
      this.unitList = data.children || [];
    },
    close() {
      this.$emit("close");
      this.$refs.ToneForm.resetFields();
    },
    changeType(e) {
      this.getUnitList();
    },
    async getForm() {
      let res = await API.TONE.detail(this.id);

      if (this.type == 1) {
        this.ToneForm.id = res.id;
        this.ToneForm.name = res.name;
        this.$set(this.pickTime, 0, res.startTime);
        this.$set(this.pickTime, 1, res.endTime);
      }
      res.dispatchFeedBackDtos.forEach(async (val, i) => {
        console.log(val, "valval");
        let res = await API.TONE.getuavlist(val.organizationId);
        const data = {
          description: val.description,
          organizationId: val.organizationId,
          uavId: val.uavId,
          dronelist: res,
        };
        this.$set(this.list, i, data);
        //   this.list[i].description = val.description
        //  this.list[i].organizationId = val.organizationId
        //  this.list[i].uavId = val.uavId
      });
    },
    async sumbit() {
      this.isShow = true;
      this.uavShow = true;
      this.ToneForm.list = [];
      this.list.forEach((val, i) => {
        if (val.organizationId) {
          let data = {};
          data.description = val.description;
          data.organizationId = val.organizationId;
          data.uavId = val.uavId;
          this.ToneForm.list.push(data);
        }
      });
      if (this.ToneForm.list.length == 0) {
        this.$message.error("至少有一个参调单位！");
        return;
      } else {
        this.ToneForm.list.forEach((val) => {
          if (!val.description) {
            this.isShow = false;
          }
          if (!val.uavId) {
            this.uavShow = false;
          }
        });
      }
      if (!this.isShow) {
        this.$message.error("请选择目的地！");
        return;
      }
      if (!this.uavShow) {
        this.$message.error("请选择无人机！");
        return;
      }
      if (!this.pickTime) {
        this.$message.error("请选择起止时间！");
        return false;
      }
      if (this.pickTime.length != 0) {
        this.ToneForm.startTime = this.pickTime[0];
        this.ToneForm.endTime = this.pickTime[1];
      } else {
        this.$message.error("请选择起止时间！");
        return false;
      }
      this.$refs["ToneForm"].validate(async (valid) => {
        if (valid) {
          if (!this.ToneForm.id) {
            let res = await API.TONE.insertDispatch(this.ToneForm);
            if (res == true) {
              this.$message.success("新增点调成功！");
            } else {
              this.$message.error(res);
              return;
            }
          } else {
            let res = await API.TONE.update(this.ToneForm);
            if (res.code != 200) {
              this.$message.error(res.message);
              return;
            } else {
              this.$message.success(res);
            }
          }
          this.$refs.ToneForm.resetFields();
          this.close();
        } else {
          return false;
        }
      });
      this.ToneForm.list = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: fixed;
  left: 596px;
  top: 10%;
  width: 676px;
  height: 500px;
  // background: rgba(0, 39, 121, 0.5);
  // box-shadow: 0px 2px 8px 0px rgba(1, 162, 255, 0.7),
  //   inset 0px 0px 64px 0px rgba(26, 138, 227, 0.35),
  //   inset 0px 0px 8px 0px #019aff;
  // border: 1px solid #43deff;
  backdrop-filter: blur(2px);
  .content_head {
    .head_left {
      .fontO {
      }
    }
    .close {
    }
  }
  .content_box {
    // width: 643px;
    // height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
    /deep/.el-form{
      display: flex;
      flex-wrap: wrap;
      .el-form-item{
        display: flex;
        flex-direction: column;
        .el-form-item__content{
          width: 280px;
          .el-input{
            width: 100%;
            .el-input__inner{
              width: 100%;
            }
          }
          .el-date-editor{
            height: 100%;
            width: 360px;
          }
          .el-select{
            width: 100%;
            .el-input__suffix{
              right: 0;
            }
          }
        }
      }
    }
    .org_list {
      width: 100%;
      display: flex;
      flex-direction: column;
      .head {
        text-align: center;
        color: #fff;
      }
      .th {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .td {
          width: calc(100% / 4);
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #fff;
        }
      }
      .list {
        max-height: 180px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        .tbody {
          margin: 12px 0;
          display: flex;
          justify-content: space-around;
          .td {
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(100% / 4);
            height: 40px;
            .el-cascader {
              width: 150px;
            }
            /deep/ .el-input .el-input__inner {
              background: rgba(11, 23, 49, 0.8);
              border: 1px solid rgba(14, 123, 117, 0.63);
              width: 150px;
              height: 40px;
              font-family: MicrosoftYaHei;
              font-size: 12px;
              color: #43deff;
              letter-spacing: 0;
              font-weight: 400;
            }
            .delbtn {
              width: 100px;
              height: 30px;
              color: #fff;
              text-align: center;
              line-height: 30px;
              background: red;
            }
          }
        }
      }
      .add {
        margin-bottom: 12px;
        border: 1px solid #fff;
        width: 100%;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #fff;
      }
    }
  }
}
.button {
  align-self: center;
  width: 95px;
  height: 37px;
  background: rgba(28, 67, 191, 0.6) !important;
  box-shadow: 0px 2px 4px 0px rgba(23, 33, 60, 0.5),
    inset 0px 0px 16px 0px rgba(33, 137, 255, 0.4),
    inset 0px 0px 4px 0px #00a7ff;
  border: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(138, 218, 255, 1),
      rgba(82, 179, 255, 0)
    )
    1 1;
  border-radius: 0px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #43deff;
  line-height: 37px;
  text-align: center;
}
</style>
<style lang="scss" scoped>
.btn {
  margin-left: 120px;
  width: 94px;
  height: 38px;
  text-align: center;
  line-height: 38px;
  background: rgba(23, 70, 216, 0.2);
  box-shadow: 0px 2px 4px 0px rgba(23, 33, 60, 0.5),
    inset 0px 0px 16px 0px rgba(33, 137, 255, 0.4),
    inset 0px 0px 4px 0px #00a7ff;
  border-radius: 6px;
  // border: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(138, 218, 255, 1),
      rgba(82, 179, 255, 0)
    )
    1 1;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #00d3ff;
}
.el-form-item {
  margin-bottom: 18px;
}
/deep/ .el-form-item__label {
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  color: #92d9ff;
  letter-spacing: 0;
  font-weight: 700;
}
/deep/ .el-select .el-input__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 316px;
  height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}
/deep/.el-select {
  .el-input__suffix {
    right: 34px;
  }
}
/deep/ .el-cascader .el-input__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 316px;
  height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}
/deep/ .el-textarea .el-textarea__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 316px;
  // height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}
/deep/ .el-input .el-input__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 316px;
  height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}
/deep/ .el-input-number .el-input__inner {
  width: 180px;
}
.el-select,
.el-input,
.el-cascader {
  width: 316px;
}
/deep/.el-cascader {
  max-height: 120px;
  .el-cascader__tags {
    max-height: 78px;
    overflow-y: auto;
  }
  .el-cascader__tags::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
//时间选择器样式
/deep/ .el-range-input {
  width: 140px;
  height: 32px !important;
}
/deep/ .el-date-editor {
  padding: 0;
  border: 1px solid rgba(14, 123, 117, 0.63);
  height: 32px;
  background: rgba(11, 23, 49, 0.8) !important;
}
/deep/ .el-date-editor .el-range-input {
  background: rgba(11, 23, 49, 0.8);
  width: 390px;
  color: #fff;
}
/deep/.el-range-separator {
  color: #fff;
}
.flight-log {
  // border: none;
  width: 320px;
  .el-picker-panel__body-wrapper {
    background: #0d224f;
    border: 1px solid #00aeff;
    color: #fff;

    .el-input__inner {
      color: #fff;
      border: 1px solid #00aeff;
    }

    .el-date-table th {
      // border-bottom: solid 1px #00aeff;
      color: #fff;
    }

    .el-date-table td.in-range div {
      background-color: #00aeff;
    }

    .el-input--small .el-input__inner {
      background-color: #0d224f;
    }

    .el-picker-panel__icon-btn {
      color: #fff;
    }
  }

  .el-picker-panel__footer {
    background-color: #0d224f !important;
  }

  .el-button.el-button--mini.is-plain,
  .el-button.is-disabled.is-plain {
    background-color: transparent !important;
    border: none;
    color: #00aeff;
  }
}
</style>
<style lang="scss">
.el-autocomplete-suggestion {
  min-width: 150px;
  width: auto !important;
}
</style>
