<template>
  <div class="cpt-command-device-drone-popup">
    <div class="header" v-interact>
      <div class="hd-box">{{ status_info.name }}</div>
      <div class="close_box" @click="() => $emit('handleClose')">关闭</div>
    </div>
    <div
      class="bd-box"
      style="height: calc(100% - 74px)"
    >
      <div class="tb-box" v-if="isShow">
        <div class="tb-hd-box">
          <div class="tb-hd">警情内容</div>
          <div class="tb-hd">警情类型</div>
          <div class="tb-hd">航线名称</div>
          <div class="tb-hd">执行状态</div>
          <div class="tb-hd">责任单位</div>
        </div>
        <div class="tb-bd-box">
          <div class="tb-tr" v-for="item in list" :key="item.id">
            <div class="td">
              {{ item.name || '暂无' }}
            </div>
            <div class="td">
              {{ item.typeTitle || '暂无' }}
            </div>
            <div class="td">
              {{ item.flightLineName || '暂无' }}
            </div>
            <div class="td" :style="{ color: statusColor[get_status(item)] }">
              {{ get_situation_status(item) }}
            </div>
            <div class="td">
              {{ item.anotherName || '暂无' }}
            </div>
          </div>
        </div>
      </div>
      <div class="tb-box" v-else>
        <div class="tb-hd-box">
          <div class="tb-hd">任务名称</div>
          <div class="tb-hd">任务类型</div>
          <div class="tb-hd">航线名称</div>
          <div class="tb-hd">执行状态</div>
          <div class="tb-hd">责任单位</div>
        </div>
        <div class="tb-bd-box">
          <div class="tb-tr" v-for="item in list" :key="item.id">
            <div class="td">
              {{ item.taskTitle || '暂无' }}
            </div>
            <div class="td">
              {{ item.taskTypeTitle || '暂无' }}
            </div>
            <div class="td">
              {{ item.airlineTitle || '暂无' }}
            </div>
            <div class="td" :style="{ color: statusColor[get_status(item)] }">
              {{ get_status(item) }}
            </div>
            <div class="td">
              {{ item.anotherName || '暂无' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tb-pagination">
      <el-pagination
        small
        :page-sizes="[10, 20, 30, 40]"
        :current-page.sync="pagination.pageNo"
        :page-size="pagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.totalCount"
        @size-change="handle_size_change"
        @current-change="handle_page_change"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import API from '@/api'
export default {
  props: {
    status_info: {
      type: Object,
      default: () => {},
    },
    organId:{
      type: Number,
      default: () => 1
    }
  },
  watch: {
    status_info: {
      handler(value) {
        if (value.name == '警情任务') {
          this.isShow = true
          this.isNormal = false
          this.get_situation_list()
        } else if (value.name == '常态任务') {
          this.isShow = false
          this.isNormal = true
          this.get_normal_list()
        } else {
          this.isShow = false
          this.isNormal = false
          this.get_urgent_list()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      list: [],
      isShow: true,
      isNormal: false,
      statusColor: {
        待执行: '#FF972B',
        执行中: '#2F76E4',
        已完成: '#89EC50',
        待审批: '#FF972B',
        待派发: '#19DFDF',
        审批不通过: '#DC143C',
      },
      pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
      },
    }
  },
  methods: {
    handle_size_change(size) {
      this.pagination.pageSize = size
      if(this.status_info.name == "警情任务"){
        this.get_situation_list()
      }else if(this.status_info.name == "常态任务"){
        this.get_normal_list()
      }else{
        this.get_urgent_list()
      }
    },
    handle_page_change(page) {
      this.pagination.pageNo = page
      if(this.status_info.name == "警情任务"){
        this.get_situation_list()
      }else if(this.status_info.name == "常态任务"){
        this.get_normal_list()
      }else{
        this.get_urgent_list()
      }
    },
    async get_situation_list() {
      let res = await API.TASK.List({
        cate: 4,
        orgId: this.organId,
        // pageSize: 10000,
        ...this.pagination,
      })
      this.list = res.data
      this.pagination.pageNo = res.pageNo
      this.pagination.pageSize = res.pageSize
      this.pagination.totalCount = res.totalCount
    },
    async get_normal_list() {
      let res = await API.TASK.List({
        cate: 3,
        orgId: this.organId,
        taskCateId: 2,
        ...this.pagination,
      })
      this.list = res.data
      this.pagination.pageNo = res.pageNo
      this.pagination.pageSize = res.pageSize
      this.pagination.totalCount = res.totalCount
    },
    async get_urgent_list() {
      let res = await API.TASK.List({
        cate: 3,
        orgId: this.organId,
        taskCateId: 3,
        ...this.pagination,
      })
      this.list = res.data
      this.pagination.pageNo = res.pageNo
      this.pagination.pageSize = res.pageSize
      this.pagination.totalCount = res.totalCount
    },
    get_situation_status(item) {
      if (item.taskSituation == -1) {
        item.taskSituation = 5
      }
      const obj = {
        0: '待审批',
        1: '审批不通过',
        2: '待执行',
        3: '执行中',
        4: '已完成',
        5: '待派发',
      }
      return obj[item.taskSituation]
    },
    get_status(item) {
      let val = item
      let text = ''
      if (val.applyStatus == 1 && val.papplyStatus == 1) {
        text = '审批通过'

        if (val.executeStatus == 0) {
          text = '待执行'
        } else if (val.executeStatus == 1) {
          text = '已完成'
        } else {
          text = '执行中'
        }
      } else if (val.applyStatus == 2 || val.papplyStatus == 2) {
        text = '审批不通过'
      } else {
        text = '待审批'
      }
      return text
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/styles/var.scss';
.tdclass1 {
  color: #00b900 !important;
}
.tdclass3 {
  color: red !important;
}
.tdclass2 {
  color: yellow !important;
}
.tdclass5 {
  color: #b3bbc5 !important;
}
.cpt-command-device-drone-popup {
  width: 643px;
  height: 362px;
  box-sizing: border-box;
  position: absolute;
  top: 200px;
  left: 50%;
  margin-left: -321px;
  z-index: 10;
  background: rgba(16, 30, 62, 0.9);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 13px;
  .header {
    height: 44px;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;
  }
  .close_box {
    position: absolute;
    right: 28px;
    top: 13px;
    font-size: 18px;
    color: #00ffff;
    opacity: 0.8;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
  .hd-box {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 700;
    line-height: 44px;
    padding-left: 20px;
  }
  .bd-box {
    height: calc(100% - 74px);
    box-sizing: border-box;
    padding: 0 10px 15px;
    .tb-box {
      box-sizing: border-box;
      height: 100%;
      .tb-hd-box {
        display: flex;
        justify-content: space-between;
        height: 40px;
        .tb-hd {
          height: 100%;
          flex: 1;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          line-height: 52px;
          text-align: center;
          font-size: 16px;
          color: #00c6ff;
        }
      }
      .tb-bd-box {
        height: calc(100% - 40px);
        overflow: auto;
        .tb-tr {
          display: flex;
          box-sizing: border-box;
          &:hover {
            cursor: pointer;
            background: rgba(0, 34, 140, 0.2);
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 15px 0 #00b6ff;
            border-radius: 5px;
          }
          .td {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            padding: 10px 0;
            text-align: center;
            font-family: MicrosoftYaHei;
            word-break: break-word;
            box-sizing: border-box;
            font-size: 14px;
            color: #dce9ff;
            font-weight: 400;
          }
        }
      }
    }
  }
  .tb-pagination {
    height: 30px;
    display: flex;
    align-items: center;
    ::v-deep .el-pagination {
      height: 30px;
      display: flex;
      align-items: center;
      button {
        background: transparent;
        font-size: 12px;
        color: #889fb2;
        text-align: center;
        font-weight: 400;
        &:disabled {
          background: transparent;
          color: #889fb2;
          opacity: 0.3;
        }
      }
      .el-pagination__total {
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #00ffff;
        letter-spacing: 0;
        line-height: 16px;
        font-weight: 400;

        text-align: center;
        height: 30px;
        line-height: 30px;
        display: inline-block;
        margin: 0px;
      }
      .el-pagination__sizes {
        height: 30px;
        line-height: 30px;
        margin: 0px;
        .el-select {
          .el-input {
            width: 60px;
            padding-right: 20px;
            margin: 0;
            .el-input__inner {
              background: transparent;
              border: none;
              line-height: 30px;
              font-size: 12px;
              color: #b5e5ff;
              text-align: center;
              font-weight: 400;
              padding: 0px;
            }
          }
        }
      }
      .el-pager {
        .number {
          background: transparent;
          font-size: 12px;
          color: #889fb2;
          text-align: center;
          font-weight: 400;
          &.active {
            background: #00b6ff;
            border-radius: 2px;
            color: #ffffff;
          }
        }
        .more {
          background: transparent;
          color: #889fb2;
        }
      }
      .el-pagination__jump {
        font-size: 12px;
        color: #889fb2;
        text-align: center;
        font-weight: 400;
        height: 30px;
        line-height: 30px;
        margin-left: 6px;
        .el-input {
          .el-input__inner {
            border: 1px solid rgba(36, 146, 252, 0.3);
            border-radius: 2px;
            background: rgba(12, 13, 20, 0.5);
            color: #889fb2;
          }
        }
      }
    }
    ._jumper {
      font-size: 12px;
      color: #889fb2;
      text-align: center;
      font-weight: 400;
      display: flex;
      align-items: center;
      white-space: nowrap;
      ::v-deep .el-input {
        .el-input__inner {
          background: rgba(12, 13, 20, 0.5);
          border: 1px solid rgba(36, 146, 252, 0.3);
          border-radius: 2px;
          width: 33px;
          height: 24px;
          color: #889fb2;
          padding: 0;
          text-align: center;
        }
      }
      ._jumper_btn {
        width: 51px;
        height: 22px;
        background: #333c57;
        border: 1px solid $primary-border-color;
        border-radius: 2px;
        padding: 0px;
        font-size: 14px;
        color: $primary-color;
        letter-spacing: -0.08px;
        text-align: center;
        font-weight: 400;
        margin-left: 4px;
      }
    }
  }
}
</style>
