<!-- 区域规划 -->
<template>
  <div>
    <div class="ctx-box">
      <div class="form-box">
        <el-form ref="form" :model="form" :rules="rules" label-width="96px">
          <el-form-item label="航线名称">
            <el-input :disabled="true" v-model="airline.filename" />
          </el-form-item>
          <!-- <el-form-item label="目标位置" prop="destination">
            <div class="item-group" :title="form.destination">
              <el-autocomplete
                :popper-append-to-body="false"
                v-model="destination.name"
                :fetch-suggestions="handle_query_address_async"
                placeholder="请输入目标位置"
                :trigger-on-focus="false"
                @select="handle_select_address"
                clearable
              >
                <template slot-scope="{ item }">
                  <div>
                    <span style="font-size: 14px">{{ item.name }}</span>
                    <span style="font-size: 12px; color: #999; margin-left: 12px">{{ item.address }}</span>
                  </div>
                </template>
              </el-autocomplete>
              <div @click="handle_pick" class="el-icon-location-outline"></div>
            </div>
          </el-form-item> -->
          <el-form-item label="场景" prop="scene">
            <el-select v-model="scene" :popper-append-to-body="false" @change="handle_change">
              <el-option
                v-for="item in scene_list"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="相机" prop="cameraId">
            <el-select v-model="cameraId" :popper-append-to-body="false">
              <el-option
                v-for="item in mounteList"
                :key="item.model"
                :label="item.name"
                :value="item.model"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="覆盖间距">
            <div class="item-group">
              <el-input-number
                v-model="gridSpacing"
                :min="1"
                :max="360"
                label="描述文字"
                oninput="value=value.replace(/[^0-9.]/g,'')"
              ></el-input-number>
              <span class="unit">( m )</span>
            </div>
          </el-form-item>
          <el-form-item label="网格角度">
            <div class="item-group">
              <el-input-number
                v-model="gridAngle"
                :min="0"
                :max="360"
                label="描述文字"
                oninput="value=value.replace(/[^0-9.]/g,'')"
              ></el-input-number>
              <span class="unit">( ° )</span>
            </div>
          </el-form-item>
          <el-form-item label="坐标点">
            <div class="item-group">
              <div
                class="point"
                @click="getPoints"
              >{{ pointlist.length == 0 ? 0 : pointlist.length }}个</div>
            </div>
          </el-form-item>
          <el-form-item label="ai识别">
            <div class="item-group">
              <el-select
                v-model="aiType"
                multiple
                :popper-append-to-body="false"
                @change="handle_change_ai"
              >
                <el-option key="1" label="人脸识别" value="人脸识别"></el-option>
                <el-option key="2" label="车牌识别" value="车牌识别"></el-option>
              </el-select>
            </div>
            <div class="face_car">
              <div class="face_ai cp" v-if="faceShow" @click="faceShow = true">人脸识别</div>
              <div class="car_ai cp ml12" v-if="carShow" @click="carShow = true">车牌识别</div>
            </div>
          </el-form-item>
          <el-form-item label="高度" prop="alt">
            <div class="item-group">
              <el-input
                v-model.number="form.alt"
                oninput="value=value.replace(/[^0-9.]/g,'')"
              />
              <span class="unit">( m )</span>
            </div>
          </el-form-item>
          <el-form-item label="速度">
            <div class="item-group">
              <el-input
                oninput="value=value.replace(/[^0-9.]/g,'')"
                v-model="airline.line.baseSpeed"
              />
              <span class="unit">( m/s )</span>
            </div>
          </el-form-item>
          <el-form-item label="航线标签" prop="lat">
            <el-select v-model="labvalue" multiple placeholder="请选择航线标签">
              <el-option
                v-for="item in LablistL"
                :key="item.id"
                :label="item.labelName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="bottom-box h60">
      <!-- <div class="iconfont icon-zengjia" title="添加"></div> -->
      <div @click="handle_save_line" class="manual1027Btn tc cp" title="生成航线">生成航线</div>
      <div @click="add_line" class="manual1027BtnDel tc cp" title="保存航线">保存航线</div>
    </div>
    <div class="points" v-if="pointShow">
      <div class="head" v-interact>
        <div class="jcsb">
          <img src="~@/assets/images/mount/mount_head.png" />
          <span>坐标点</span>
        </div>
        <div class="close mr10 cf" @click="pointShow = false">关闭</div>
      </div>
      <div class="tb-box">
        <div class="tb-hd-box">
          <div class="tb-hd">序号</div>
          <div class="tb-hd">坐标点</div>
          <div class="tb-hd">操作</div>
        </div>
        <div class="tb-bd-box">
          <div class="tb-tr" v-for="(item, index) in pointlist" :key="index">
            <div class="td">{{ index + 1 }}</div>
            <div class="td">
              <div>{{ item.lon + "," + item.lat || "暂无" }}</div>
            </div>
            <div class="td last-td">
              <el-tooltip effect="dark" content="删除" placement="top">
                <div @click="handle_remove_point(index)" class="ctrl-icon">
                  <img class="ctrl-icon__del" src="~@/assets/images/command/airway_del.png" />
                </div>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="car" v-if="carShow">
      <div class="head" v-interact>
        <div class="jcsb">
        <img src="~@/assets/images/mount/mount_head.png" />
        <span>车牌识别</span>
        </div>
        <div class="close mr10 cf" @click="carShow = false">关闭</div>
      </div>
      <div class="tb-box">
        <div class="td">
          目标车牌：
          <el-input v-model="carData.carnum"></el-input>
        </div>
      </div>
    </div>
    <div class="face" v-if="faceShow">
      <div class="head" v-interact>
        <div class="jcsb">
          <img src="~@/assets/images/mount/mount_head.png" />
          <span>人脸识别</span>
        </div>
        <div class="close mr10 cf" @click="faceShow = false">关闭</div>
      </div>
      <div class="tb-box">
        <div class="td">
          姓名：
          <el-input v-model="faceData.name"></el-input>
        </div>
        <div class="td">
          身份证号：
          <el-input v-model="faceData.id"></el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { Utils } from "@/lib/cesium";
import { nanoid } from "nanoid";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { isNull } from "@antv/util";
import axios from "axios";
import qs from "qs";

let positions = [];
let handler = null;
let data_srouce = null;
let location_icon = null;

let noFlyEntities = [];
let electricFenceEntities = [];

let point_index = null;
let point_uuid = null;
let point_uuid_next = null;
let isEditting = false;

export default {
  components: {},
  data() {
    let validateLon = (rule, value, callback) => {
      // let reg =
      //   /^[\-\+]?(0?\d{1,2}\.\d{1,5}|1[0-7]?\d{1}\.\d{1,5}|180\.0{1,5})$/;
      if (value < -180 || value > 180) {
        callback(new Error("请输入正确经度值（-180.0-180.0）"));
      } else {
        callback();
      }
    };
    let validateLat = (rule, value, callback) => {
      // let reg = /^[\-\+]?([0-8]?\d{1}\.\d{1,5}|90\.0{1,5})$/;
      if (value < -90 || value > 90) {
        callback(new Error("请输入正确纬度值（-90.0-90.0）"));
      } else {
        callback();
      }
    };
    let validateAlt = (rule, value, callback) => {
      if (value > 150) {
        callback(new Error("航点高度不可超过150m"));
      } else {
        callback();
      }
    };
    let validateNestId = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择鹰巢"));
      } else {
        callback();
      }
    };
    return {
      optionsLine: null,
      pointShow: false,
      faceData: { name: "", id: "" },
      carData: { carnum: "" },
      faceShow: false,
      carShow: false,
      pointlist: [],
      labvalue: [],
      LablistL: [],
      mounteList: [],
      rules: {
        lat: [{ validator: validateLat, trigger: "change" }],
        lon: [{ validator: validateLon, trigger: "change" }],
        alt: [{ validator: validateAlt, trigger: "change" }],
        nestId: [{ validator: validateNestId, trigger: "change" }],
      },
      time: null,
      visibleFlag: true,
      isGetAddress: false,
      aiType: [],
      form: {
        lon: 0,
        lat: 0,
        alt: 100,
        pitch: 0, //-180~180
        gimbalPitch: 0, //0~-90
        actions: [],
        nestId: null,
      },
      gridAngle: null,
      gridSpacing:100,
      cameraId: null,
      destination: {
        name: "",
        lng: 0,
        lat: 0,
      },
      airline: {
        filename: null,
        line: {
          // baseSpeed: 10,
          baseSpeed: 6,
        },
        points: [],
      },
      typesShow: false,
      action: {
        visible: false,
        types: [
          {
            label: "无动作",
            actionType: "",
            param1: 1,
          },
          {
            label: "间隔拍照",
            input: "el-input-number",
            actionType: "TRIGGER",
            param1: 1,
          },
          {
            label: "悬停",
            input: "el-input-number",
            min: 0,
            // max: 32000,
            actionType: "STAY",
            param1: 10,
          },
          {
            label: "云台控制",
            input: "div",
            actionType: "GIMBAL_PITCH",
            param2: 0,
            param1: 0,
            param3: 0,
            param4: 2,
          },
          {
            label: "开始录像",
            actionType: "START_RECORD",
            param1: 1,
          },

          {
            label: "停止录像",
            actionType: "STOP_RECORD",
            param1: 1,
          },
          {
            label: "变焦",
            input: "el-input-number",
            min: 0,
            // max: 32000,
            actionType: "CAMERA_ZOOM",
            param1: 2,
            param2: 0,
          },
          {
            label: "拍照",
            actionType: "START_TAKE_PHOTO",
            param3: 1,
          },
        ],
        list: [],
        curr_index: 0,
      },
      scene: "巡查",
      scene_list: [
        { name: "巡查", id: 1 },
        { name: "预警", id: 2 },
        { name: "侦察", id: 3 },
        { name: "服务", id: 4 },
        { name: "救援", id: 5 },
        { name: "处置", id: 6 },
        { name: "打击", id: 7 },
        { name: "宣传", id: 8 },
      ],
    };
  },
  props: {
    flag: {
      type: Boolean,
      default: true,
    },
  },
  async mounted() {
    this.getlable();
    this.init_cesium_handler();
    let filename = "";
    if (this.user_info.departmentId == 1) {
      filename = `巡特警支队-${this.scene}`;
    } else {
      filename = `${
        this.user_info.parentDepName ? this.user_info.parentDepName + "-" : ""
      }${this.user_info.departmentName}-${this.scene}`;
    }
    let res = await API.AIRSPACE.GetFlightLineCount({ flightName: filename });
    // 对res进行判断,有时候会传来对象,但是值是对的,所以手动把值提取出来
    if (typeof res == "object") {
      res = res.data;
    }
    let filename_count = res + 1;
    filename_count =
      filename_count < 10 ? "0" + filename_count : filename_count;
    this.airline.filename = filename + filename_count;
    // this.get_air_space_list();
    // this.get_electric_fence_list();
    // this.get_institutional_fence_list();
    this.getMount();
  },
  beforeDestroy() {
    this.destroy_cesium_handler();
    this.clear_entities();
  },
  methods: {
    getPoints() {
      this.pointShow = true;
    },
    async getlable() {
      this.LablistL = await API.AIRWAY.labelList();
    },
    change_airway(val) {
      this.$emit("change_airway", val);
    },
    // 获取挂载列表
    async getMount() {
      let data = await API.SITUATION.getAllMountTypeList();
      this.mounteList = data || [];
    },
    handle_change_ai() {
      if (this.aiType.some((val) => val == "人脸识别")) {
        this.faceShow = true;
      }else{
        this.faceShow = false;
      }
      if (this.aiType.some((val) => val == "车牌识别")) {
        this.carShow = true;
      }else{
        this.carShow = false
      }
    },
    async handle_change() {
      let filename = "";
      if (this.user_info.departmentId == 1) {
        filename = `巡特警支队-${this.scene}`;
      } else {
        filename = `${
          this.user_info.parentDepName ? this.user_info.parentDepName + "-" : ""
        }${this.user_info.departmentName}-${this.scene}`;
      }
      let res = await API.AIRSPACE.GetFlightLineCount({ flightName: filename });
      // 对res进行判断,有时候会传来对象,但是值是对的,所以手动把值提取出来
      if (typeof res == "object") {
        res = res.data;
      }
      let filename_count = res + 1;
      filename_count =
        filename_count < 10 ? "0" + filename_count : filename_count;
      this.airline.filename = filename + filename_count;
    },
    init_point(data) {
      Object.keys(data).map((key) => {
        if (this.form.hasOwnProperty(key)) {
          this.form[key] = data[key];
        }
      });
    },

    testEmit(item) {
      this.$emit("add", item);
    },

    reset_form() {
      let alt = this.form.alt;
      let nestId = this.form.nestId;

      this.$refs["form"] && this.$refs["form"].resetFields();
      //继承上一个点的高度
      this.form.alt = alt;
      //继承上一个点的鹰巢id
      this.form.nestId = nestId;
    },
    handler_current_point(index) {
      let points = this.pointlist;
      this.action.curr_index = index + 1;
      this.init_point(points[this.action.curr_index - 1]);
    },

    handle_save_point() {
      this.pointlist.push({ ...this.form });
    },
    handle_remove_point(i) {
      let index = i;
      let points = this.pointlist;
      let point = points[index];
      if (point) {
        points.splice(index, 1);
        positions.splice(index, 1);
        this.remove_point(point);
        this.remove_label(point);

        /**
         * 如果后面没有点
         */
        let after = points[index];
        if (!after) {
          if (index - 1 < 0) {
            this.action.curr_index = 0;
          } else {
            this.action.curr_index -= 1;
          }
        }

        points.length
          ? this.init_point(points[this.action.curr_index - 1])
          : this.reset_form();
      }
    },

    remove_point(point) {
      data_srouce.entities.removeById(point.uuid);
    },
    create_point(uuid, position) {
      let wgs84 = Utils.transformCartesian2WGS84(position);
      let points = this.pointlist;
      if (data_srouce && this.visibleFlag) {
        let entities = data_srouce.entities;
        entities.add({
          name: "airline_point",
          id: uuid,
          position: Utils.transformWGS842Cartesian({
            lng: wgs84.lng,
            lat: wgs84.lat,
            alt: wgs84.alt + 10,
          }),
          point: {
            pixelSize: 20,
            color: Cesium.Color.RED,
            fillColor: Cesium.Color.RED,
            // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND, // supermap版本会导致拖动显示错误
          },
          label: {
            text: new Cesium.CallbackProperty(() => {
              let index = points.findIndex((item) => item.uuid === uuid);
              return String(index + 1);
            }, false),
            scale: 0.5,
            font: "bold 24px Microsoft YaHei",
            fillColor: Cesium.Color.WHITE,
            horizontalOrigin: Cesium.VerticalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.CENTER,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            showBackground: false,
            outlineWidth: 0,
          },
        });
      }
    },

    remove_label(point) {
      data_srouce.entities.removeById(`label_${point.uuid}`);
    },
    create_label(uuid, before, after, alt) {
      if (data_srouce) {
        let entities = data_srouce.entities;
        let before_wgs84 = Utils.transformCartesian2WGS84(before);
        let after_wgs84 = Utils.transformCartesian2WGS84(after);

        let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2;
        let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2;
        let alt = (after_wgs84.alt + before_wgs84.alt) / 2;

        let position = Utils.transformWGS842Cartesian({
          lng: center_lng,
          lat: cetner_lat,
          alt: alt + 10,
        });

        let text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`;

        entities.add({
          id: `label_${uuid}`,
          position: position,
          label: {
            text: text,
            scale: 0.5,
            font: "bold 30px Microsoft YaHei",
            fillColor: Cesium.Color.fromCssColorString("#fff"),
            horizontalOrigin: Cesium.VerticalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            showBackground: true,
            backgroundPadding: new Cesium.Cartesian2(10, 10),
          },
        });
      }
    },
    update_label(uuid, before, after) {
      let entities = data_srouce.entities;
      let id = `label_${uuid}`;
      let entity = entities.getById(id);

      let before_wgs84 = Utils.transformCartesian2WGS84(before);
      let after_wgs84 = Utils.transformCartesian2WGS84(after);

      let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2;
      let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2;
      let alt = (after_wgs84.alt + before_wgs84.alt) / 2;

      let position = Utils.transformWGS842Cartesian({
        lng: center_lng,
        lat: cetner_lat,
        alt: alt + 10,
      });
      let text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`;

      entity.position = position;
      entity.label.text = text;
    },
    // 延时调用
    debounce(fn, wait) {
      // 问题:卡顿时页面不进行跳转,进而可以存储多次
      // 关闭定时器
      clearTimeout(this.time);
      // 定时器置空
      this.time = null;
      // 启动定时器
      this.time = setTimeout(() => {
        fn.apply(this, arguments);
        // 解决思路:因为程序里有跳转功能,所以可以置空必填项,这样即使卡顿也不会多次存储
        this.destination.name = "";
      }, wait);
    },
    create_label_space(uuid, before, after, alt) {
      if (data_srouce) {
        let entities = data_srouce.entities;

        let center_lng = (before.longitude + after.longitude) / 2;
        let cetner_lat = (before.latitude + after.latitude) / 2;
        let alt = 0;

        let position = Utils.transformWGS842Cartesian({
          lng: center_lng,
          lat: cetner_lat,
          alt: alt + 10,
        });

        // let text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`;

        entities.add({
          id: `label_${uuid}`,
          position: position,
          label: {
            // text: text,
            scale: 0.5,
            font: "bold 30px Microsoft YaHei",
            fillColor: Cesium.Color.fromCssColorString("#fff"),
            horizontalOrigin: Cesium.VerticalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            showBackground: true,
            backgroundPadding: new Cesium.Cartesian2(10, 10),
          },
        });
      }
    },
    async save_line() {
      let sourceType = null;
      let nestId = null;
      if (this.flag) {
        sourceType = 2;
        nestId = this.form.nestId;
      } else {
        sourceType = 1;
      }
      this.airline.baseSpeed = Number(this.airline.baseSpeed);
      let listLngLon = [];
      this.pointlist.forEach((val) => {
        let poi = {
          latitude: val.lat,
          longitude: val.lon,
        };
        listLngLon.push(poi);
      });
      let alt = this.pointlist[0].alt;
      if(!this.cameraId) return this.$message.error("未选择相机！");
      let data = {
        altitude: this.pointlist[0].alt,
        gridAngle: this.gridAngle,
        speed: this.airline.line.baseSpeed,
        gimbalName: this.cameraId,
        // gimbalName:"MMC_Gimbal_Z30Pro",
        coverPoint:0,
        listLatLng: listLngLon,
        frontalOverlap: 5,
        sideOverlap: 5,
        gridSpacing:this.gridSpacing
      };
      let res = await API.FCKERNEL.spaceLine(data);
      if (!res.commitLatLng) {
        // this.$message.error(res.message);
      } else {
        this.airline.points = [];
        this.airline.waypoints = [];
        let list = res.commitLatLng;
        this.remove_point_gps();
        this.clear_entities();
        let entities = data_srouce.entities;
        list.forEach((val, i) => {
          let poidata = {
            lon: val.longitude,
            lat: val.latitude,
            alt: alt,
            pitch: 0,
            gimbalPitch: 0,
            actions: [],
            nestId: null,
            uuid: i,
          };
          this.airline.points.push(poidata);
          let waypoi = {
            coordinate: {
              latitude: val.latitude,
              longitude: val.longitude,
            },
            altitude: alt,
            frame: 3,
            speed: this.airline.line.baseSpeed,
            waypointActions: [],
          };
          this.airline.waypoints.push(waypoi);

          if (list.length > i + 1) {
            let before = val;
            let after = list[i + 1];
            this.create_label_space(i, before, after);
          }
        });
        let viewer = window.viewer;
        setTimeout(() => {
          let polylines = entities.add({
            polyline: {
              material: Cesium.Color.RED,
              width: 3,
              positions: new Cesium.CallbackProperty(() => {
                return list.map((item) => {
                  return Utils.transformWGS842Cartesian({
                    lng: item.longitude,
                    lat: item.latitude,
                    alt: 10,
                  });
                });
              }, false),
            },
          });
          console.log(this.pointlist);
          let positionAirspace = []
          this.pointlist.forEach(val=>{
            positionAirspace.push(val.lon)
            positionAirspace.push(val.lat)
          })
          let location_polygon = entities.add({
              polygon: {
                hierarchy: {
                  positions: Cesium.Cartesian3.fromDegreesArray(positionAirspace),
                },
                outline: true,
                outlineColor: Cesium.Color.WHITE,
                outlineWidth: 2,
                material: Cesium.Color.fromCssColorString("green").withAlpha(0.3)
              },
              show: true,
              id: "positionAirspace",
            })
        }, 20);
        // await viewer.dataSources.add(data_srouce);
      }
      let points = this.airline.points
      let distance = points.reduce((total, item, index) => {
        let dist = 0;
        if (points[index + 1]) {
          let cd = Cesium.Cartesian3.distance(
            Utils.transformWGS842Cartesian({ lng: item.lon, lat: item.lat }),
            Utils.transformWGS842Cartesian({
              lng: points[index + 1].lon,
              lat: points[index + 1].lat,
            })
          );
        // console.log(cd,"cdcd");
          // dist = total + cd;
          dist = cd;
        }
        return total + parseInt(dist);
      }, 0);
      // console.log(this.airline,"dddddddd");
      // return

      let options = {
        distance,
        flightCourseJson: JSON.stringify(this.airline),
        flightName: this.airline.filename,
        pointCount: points.length,
        time: parseInt(distance / this.airline.line.baseSpeed),
        destination: this.destination.name,
        lng: this.destination.lng,
        lat: this.destination.lat,
        labelIds: this.labvalue,
        sourceType,
        nestId,
        lineType: 3,
      };
      this.optionsLine = options;
    },
    add_line() {
      let aiList = [];
      let aiList2 = [];
      if (this.aiType.some((val) => val == "人脸识别")) {
        let data = {
          id: 1,
          type: "人脸识别",
          switch: true,
          check: false,
        };
        let data2 = {
          id: 1,
          type: "人脸识别",
          switch: false,
          check: false,
        };
        aiList.push(data);
        aiList2.push(data2);
      }
      if (this.aiType.some((val) => val == "车牌识别")) {
        let data = {
          id: 2,
          type: "车牌识别",
          switch: true,
          check: false,
        };
        let data2 = {
          id: 2,
          type: "车牌识别",
          switch: false,
          check: false,
        };
        aiList.push(data);
        aiList2.push(data2);
      }
      let json = JSON.parse(this.optionsLine.flightCourseJson);
      json.points[0].aiList = aiList;
      json.points[json.points.length - 1].aiList = aiList2;
      json.faceData = this.faceData;
      json.carData = this.carData;
      // console.log(json);
      // return
      let linejson = JSON.stringify(json);
      this.optionsLine.flightCourseJson = linejson;
      // console.log(this.optionsLine,"optionsLineoptionsLine");
      // console.log(json,"linejson");
      // return
      this.$emit("add", this.optionsLine);
      this.$emit("exitNest_list");
    },
    // 提交表格
    handle_save_line() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // 调用提交
          this.debounce(this.save_line, 1000);
        } else {
          return false;
        }
      });
    },

    async init_cesium_handler() {
      let viewer = window.viewer;
      let currentPoint;
      // 修复规划航线时
      viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
        Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
      );

      if (data_srouce) {
        data_srouce.entities.removeAll();
        viewer.dataSources.remove(data_srouce, true);
        data_srouce = null;
        positions = [];
      }
      data_srouce = new Cesium.CustomDataSource("airway_edit");

      await viewer.dataSources.add(data_srouce);

      let entities = data_srouce.entities;
      let polyline = entities.add({
        id:"gps_line",
        polyline: {
          material: Cesium.Color.RED,
          width: 3,
          positions: new Cesium.CallbackProperty(() => {
            return positions.map((item) => {
              let wgs84 = Utils.transformCartesian2WGS84(item);
              return Utils.transformWGS842Cartesian({
                lng: wgs84.lng,
                lat: wgs84.lat,
                alt: wgs84.alt + 10,
              });
            });
          }, false),
        },
      });

      // viewer.scene.globe.depthTestAgainstTerrain = true;
      handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);

      handler.setInputAction(async (movement) => {
        let windowPosition = movement.position;
        let pickedObject = viewer.scene.pick(windowPosition);

        if (this.isGetAddress) {
          let position = Utils.getCartesian3FromPX(viewer, movement.position);
          let wgs84 = Utils.transformCartesian2WGS84(position);
          console.log(wgs84);
          let address = await API.MAP.Regeo({
            location: `${wgs84.lng},${wgs84.lat}`,
          });

          if (address) {
            this.destination.name = address;
            this.destination.lng = wgs84.lng;
            this.destination.lat = wgs84.lat;
          } else {
            this.$el_message("地址解析失败", () => {}, "error");
          }

          this.isGetAddress = false;
          return;
        }

        if (
          Cesium.defined(pickedObject) &&
          pickedObject.id?.name === "airline_point"
        ) {
          // 航点编辑
          let entity = pickedObject.id;
          point_uuid = entity.id;
          let points = this.pointlist;
          let index = points.findIndex((item) => item.uuid === entity.id);

          if (index > -1) {
            point_index = index;
            if (index + 1 < points.length) {
              point_uuid_next = points[index + 1].uuid;
            }
            this.handler_current_point(index);
          }

          currentPoint = entity;
          isEditting = true;
          viewer.scene.screenSpaceCameraController.enableRotate = false;
          viewer.scene.screenSpaceCameraController.enableZoom = false;
        }
      }, Cesium.ScreenSpaceEventType.LEFT_DOWN);

      handler.setInputAction(async (movement) => {
        let windowPosition = movement.position;
        let pickedObject = viewer.scene.pick(windowPosition);
        // if (pickedObject) {
        // }

        if (this.isGetAddress) {
          let position = Utils.getCartesian3FromPX(viewer, movement.position);
          let wgs84 = Utils.transformCartesian2WGS84(position);
          let address = await API.MAP.Regeo({
            location: `${wgs84.lng},${wgs84.lat}`,
          });

          if (address) {
            this.destination.name = address;
            this.destination.lng = wgs84.lng;
            this.destination.lat = wgs84.lat;
          } else {
            this.$el_message("地址解析失败", () => {}, "error");
          }

          this.isGetAddress = false;
          return;
        }

        if (
          Cesium.defined(pickedObject) &&
          pickedObject.id?.name === "airline_point"
        ) {
          // 航点编辑
          // let entity = pickedObject.id;
          // point_uuid = entity.id;
          // let points = this.airline.points;
          // let index = points.findIndex((item) => item.uuid === entity.id);
          // if (index > -1) {
          //   point_index = index;
          //   if (index + 1 < points.length) {
          //     point_uuid_next = points[index + 1].uuid;
          //   }
          //   this.handler_current_point(index);
          // }
          // currentPoint = entity;
          // isEditting = true;
          // viewer.scene.screenSpaceCameraController.enableRotate = false;
          // viewer.scene.screenSpaceCameraController.enableZoom = false;
        } else {
          // 航点添加
          let position = Utils.getCartesian3FromPX(viewer, movement.position);

          if (Cesium.defined(position) && this.visibleFlag) {
            this.reset_form();
            if (positions.length + 1) {
              let points = this.pointlist;
              if (this.action.curr_index <= points.length + 1) {
                this.action.curr_index = points.length + 1;
              } else {
                this.action.curr_index += 1;
              }
            }

            positions.push(position);
            let wgs84 = Utils.transformCartesian2WGS84(position);

            this.form.lon = wgs84.lng;
            this.form.lat = wgs84.lat;
            let uuid = (this.form.uuid = nanoid());
            this.handle_save_point();

            if (positions.length > 1) {
              let before = positions[positions.length - 2];
              let after = positions[positions.length - 1];

              this.create_label(uuid, before, after);
            }
            this.create_point(uuid, position);
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

      // 对鼠标移动事件的监听
      handler.setInputAction((event) => {
        if (isEditting && currentPoint) {
          // let windowPosition = event.startPosition;
          // let ellipsoid = viewer.scene.globe.ellipsoid;
          // let cartesian = viewer.camera.pickEllipsoid(windowPosition, ellipsoid);
          let cartesian = Utils.getCartesian3FromPX(
            viewer,
            event.startPosition
          );

          if (!cartesian) {
            return;
          }
          currentPoint.position = cartesian;

          if (!(typeof point_index == undefined) && positions?.length > 1) {
            positions[point_index] = cartesian;
            let current_wgs84 = Utils.transformCartesian2WGS84(cartesian);
            this.pointlist[point_index].lon = current_wgs84.lng;
            this.pointlist[point_index].lat = current_wgs84.lat;
            if (point_index === this.action.curr_index) {
              this.form.lon = current_wgs84.lng;
              this.form.lat = current_wgs84.lat;
            }

            if (point_index > 0) {
              this.update_label(
                point_uuid,
                cartesian,
                positions[point_index - 1]
              );
            }
            if (point_uuid_next) {
              this.update_label(
                point_uuid_next,
                cartesian,
                positions[point_index + 1]
              );
            }
          }
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

      // 对鼠标抬起事件的监听
      handler.setInputAction((event) => {
        point_index = null;
        point_uuid_next = null;
        isEditting = false;
        currentPoint = undefined;
        // viewer.render()
        setTimeout(() => {
          viewer.scene.screenSpaceCameraController.enableRotate = true;
          viewer.scene.screenSpaceCameraController.enableZoom = true;
        }, 300);
      }, Cesium.ScreenSpaceEventType.LEFT_UP);
    },
    remove_point_gps(){
      try {
        let viewer = window.viewer;
        viewer.scene.globe.depthTestAgainstTerrain = false;
        if (handler) {
          // handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
          handler && handler.destroy() && (handler = null);
          handler = null;
        }

        if (data_srouce) {
          data_srouce.entities.removeById("gps_line")
        }
      } catch (error) {}
    },
    destroy_cesium_handler() {
      try {
        let viewer = window.viewer;
        viewer.scene.globe.depthTestAgainstTerrain = false;
        if (handler) {
          // handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
          handler && handler.destroy() && (handler = null);
          handler = null;
        }

        if (data_srouce) {
          data_srouce.entities.removeAll();
          // viewer.dataSources.remove(data_srouce, true);
          data_srouce = null;
          // positions = [];
        }
      } catch (error) {}
    },

    handle_pick() {
      this.isGetAddress = true;
    },

    handle_query_address_async(address, cb) {
      API.MAP.Geo({ keywords: address })
        .then((res) => {
          cb(res.data.data.rows);
        })
        .catch((e) => cb([]));
    },

    handle_select_address(item) {
      if (item) {
        let location = item.location.split(",");
        this.destination.name = item.name;
        this.destination.lng = Number(location[0]);
        this.destination.lat = Number(location[1]);

        let cesium_layer = this.g_cesium_layer();
        let viewer = cesium_layer.viewer();
        let potiion = Utils.transformWGS842Cartesian({
          lng: this.destination.lng,
          lat: this.destination.lat,
          alt: 1000,
        });
        viewer.camera.flyTo({
          destination: potiion,
        });
        this.init_location_icon(potiion);
      }
    },

    init_location_icon(position) {
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();

      if (location_icon) {
        location_icon.position = position;
      } else {
        location_icon = viewer.entities.add({
          position,
          billboard: {
            width: 36,
            height: 36,
            image: require("@/assets/images/icons/icon_location.png"),
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.Bottom,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          },
        });
      }
    },

    destroy_location_icon() {
      if (!location_icon) {
        return location_icon;
      }
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();
      viewer.entities.remove(location_icon);
      location_icon = null;
    },

    async get_air_space_list() {
      let res = await API.AIRSPACE.GetNotFlyAreas();
      res.forEach((item) => {
        let positions = item.region.split(" ").join(",").split(",");
        if (positions.length > 0) {
        }
        let hierarchy = Cesium.Cartesian3.fromDegreesArray(positions);
        let position = Cesium.BoundingSphere.fromPoints(hierarchy).center;
        if (item.regionType === "POLYGON") {
          if (positions.length % 2 !== 0) return;
          if (item.typeId === 1) {
            var entity = viewer.entities.add({
              name: "Red polygon on surface",
              position,
              label: {
                text: item.name,
                font: "14px monospace",
                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new Cesium.Cartesian2(5, 5),
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
              },
              polygon: {
                hierarchy,
                material: Cesium.Color.RED.withAlpha(0.5),
                outline: true,
                outlineColor: Cesium.Color.BLACK,
              },
            });
            noFlyEntities.push(entity);
          }
        }
      });
    },
    async get_electric_fence_list() {
      let res = await API.AIRSPACE.GetAreaList();
      res.forEach((item) => {
        let positions = item.region.split(" ").join(",").split(",");
        if (item.regionType === "POLYGON") {
          if (positions.length % 2 !== 0) return;
          let hierarchy = Cesium.Cartesian3.fromDegreesArray(positions);
          let position = Cesium.BoundingSphere.fromPoints(hierarchy).center;
          if (item.typeId === 2) {
            var entity = viewer.entities.add({
              name: "Red polygon on surface",
              position,
              label: {
                text: item.name,
                font: "14px monospace",
                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new Cesium.Cartesian2(5, 5),
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
              },
              polygon: {
                hierarchy,
                material: Cesium.Color.GREEN.withAlpha(0.5),
                outline: true,
                outlineColor: Cesium.Color.BLACK,
              },
            });
            electricFenceEntities.push(entity);
          }
        }
      });
    },
    async get_institutional_fence_list() {
      let res = await API.AIRSPACE.GetCurrentAreaList();
      res.forEach((item) => {
        let positions = item.region.split(" ").join(",").split(",");
        if (item.regionType === "POLYGON") {
          if (positions.length % 2 !== 0) return;
          let hierarchy = Cesium.Cartesian3.fromDegreesArray(positions);
          let position = Cesium.BoundingSphere.fromPoints(hierarchy).center;
          if (item.typeId === 2) {
            var entity = viewer.entities.add({
              name: "Red polygon on surface",
              position,
              label: {
                text: item.name,
                font: "14px monospace",
                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new Cesium.Cartesian2(5, 5),
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
              },
              polygon: {
                hierarchy,
                material: Cesium.Color.CYAN.withAlpha(0.5),
                outline: true,
                outlineColor: Cesium.Color.BLACK,
              },
            });
            electricFenceEntities.push(entity);
          }
        }
      });
    },
    clear_entities() {
      noFlyEntities.forEach((item) => {
        viewer.entities.remove(item);
      });
      electricFenceEntities.forEach((item) => {
        viewer.entities.remove(item);
      });
      noFlyEntities = [];
      electricFenceEntities = [];
    },
    change_close() {
      let viewer = window.viewer;
      viewer.scene.globe.depthTestAgainstTerrain = false;
      this.change_page();
    },
  },
  computed: {
    ...mapGetters(["user_info"]),
    cesium_layer() {
      return this.g_cesium_layer();
    },
  },

  inject: ["g_cesium_layer"],
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";
@import "~@/styles/mixins.scss";
@import "~@/styles/form.scss";

// /deep/.el-scrollbar {
//   width: 355px !important;
// }
// /deep/.el-select-dropdown {
//   /deep/.el-scrollbar {
//     /deep/.el-select-dropdown__wrap {
//       width: 355px !important;
//     }
//   }
// }

.manual1027Btn {
  width: 84px;
  height: 32px;
  line-height: 32px;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 5px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
  color: #fff;
}
.manual1027BtnDel {
  width: 84px;
  height: 32px;
  line-height: 32px;
  background: #06517d;
  border-radius: 5px;
  border: 1px solid #417595;
  backdrop-filter: blur(1px);
  color: #fff;
}
.active {
  background-color: #1f3bc0;
}
::v-deep {
  .el-select {
    width: 355px;

    .el-input {
      height: 100%;
    }
  }
}

.cpt-command-airway-edit {
  position: absolute;
  left: 100px;
  top: 140px;
  width: 600px;
  height: 770px;
  box-sizing: border-box;
  background: url("~@/assets/images/plate_bg_01.png") no-repeat;
  background-size: 100% 100%;

  .img-close {
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .title-box {
    height: 56px;
    box-sizing: border-box;
    padding-left: 112px;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-right: 80px;

    .title {
      font-family: PangMenZhengDao;
      font-size: 22px;
      color: #dce9ff;
      font-weight: 400;
      // line-height: 3.2;
      margin-right: 30px;
    }

    .select {
      font-family: PangMenZhengDao;
      font-size: 22px;
      color: #00ffff;
      text-align: center;
      font-weight: 400;
    }

    &::before {
      content: " ";
      display: block;
      position: absolute;
      height: 1px;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(
        270deg,
        rgba(0, 117, 221, 0) 0%,
        #3ea4d5 50%,
        rgba(0, 117, 221, 0) 100%
      );
    }
  }

  .ctx-box {
    margin-top: 10px;
    // height: 600px;
    height: calc(100% - 136px);
    box-sizing: border-box;
    overflow: auto;

    .form-box {
      ::v-deep .el-form {
        .divider {
          @include linear_gradient_border(0, auto, 0, 0);
          height: 12px;
        }

        .el-form-item {
          margin-bottom: 16px;
          .unit {
            font-family: MicrosoftYaHeiUI;
            font-size: 18px;
            color: rgb(179, 201, 203);
            font-weight: 400;
            margin-left: 12px;
          }
          .item-group {
            display: flex;
            align-items: center;
            .point {
              text-align: center;
              width: 356px;
              height: 32px;
              background: rgba(2, 23, 61, 0.01);
              // box-shadow: inset 0px 0px 6px 2px #2647EE;
              border-radius: 2px;
              border: 1px solid #315ec7;
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #ffffff;
              line-height: 32px;
            }

            .unit {
              font-family: MicrosoftYaHeiUI;
              font-size: 18px;
              color: rgb(179, 201, 203);
              font-weight: 400;
              margin-left: 12px;
            }

            .iconfont {
              color: rgb(179, 201, 203);
              font-size: 30px;
              margin-left: 0.3em;
              cursor: pointer;
            }

            .el-icon-location-outline {
              color: rgb(179, 201, 203);
              font-size: 30px;
              margin-left: 0.3em;
              cursor: pointer;
            }
          }

          .el-form-item__label {
            font-family: Microsoft YaHei;
            font-size: 16px;
            color: #ccedff;
            text-align: right;
            font-weight: 400;
          }

          .el-select-dropdown {
            min-width: 356px !important;
          }

          .el-input {
            width: 356px;
            height: 40px;

            .el-input__inner {
              width: 100%;
              font-family: MicrosoftYaHeiUI;
              font-size: 15px;
              color: #08c2d1;

              background: #02173d;
              border: 1px solid #2e59bc;
            }
          }

          .el-input-number {
            width: 356px;

            .el-input-number__decrease,
            .el-input-number__increase {
              bottom: 1px;
              background: #183574;
              color: #fff;
              border-radius: 0;
              border: none;
            }

            .el-input-number__decrease {
              left: 1px;
            }

            .el-input-number__increase {
            }
          }
        }
        .action-item {
          position: relative;
          .el-form-item {
            .end {
              position: absolute;
              right: 5px;
            }
            .el-form-item__content {
              display: flex;
            }
          }
        }
      }
    }
  }

  .bottom-box {
    box-sizing: border-box;
    // padding-bottom: 20px;
    // height: 88px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    @include linear_gradient_border(0, auto, 0, 0);

    .iconfont {
      cursor: pointer;
      font-size: 44px;
      color: #08c2d1;
    }
  }

  // 动作列表
  .action-list {
    position: fixed;
    left: 40%;
    top: 30%;
    background: rgba(0, 23, 79, 0.7);
    box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
      inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
    border-radius: 13px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      height: 44px;
      background: rgba(16, 65, 215, 0.2);
      box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
      border-radius: 10px 10px 0 0;
    }

    .hd-box {
      font-family: MicrosoftYaHei-Bold;
      font-size: 18px;
      color: #00ffff;
      letter-spacing: 0;
      font-weight: 700;
      margin-left: 20px;
      display: flex;
    }

    .types {
      background: rgba(0, 23, 79, 0.7);
      box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
        inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
      border-radius: 13px;
      top: 10px;
      right: -170px;
      color: #fff;
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      div:hover {
        color: #00ffff;
      }
    }

    .actions-box {
      .title-box {
        display: flex;
        align-items: center;

        .title {
          font-size: 24px;
          width: 120px;
        }
      }

      .list-box {
        max-height: 280px;
        overflow: auto;
        margin-top: 12px;
        padding: 0 20px;

        ::v-deep {
          .el-input-number {
            // margin-left: 180px;

            .el-input {
              width: 180px;
            }
          }
        }

        .action-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid #fff;
          box-sizing: border-box;
          padding: 12px;
          margin: 8px 0;

          &:hover {
            background-color: #ccc;
          }

          .item-label {
            white-space: nowrap;
          }
          .lsit {
            display: flex;
            align-items: center;
          }
          .item-input {
            display: flex;
            align-items: center;
            white-space: nowrap;
          }

          .item-inner {
            display: flex;
            align-items: center;
          }

          .iconfont {
            color: red;
            cursor: pointer;
            font-size: 20px;
          }
        }
      }
    }

    .footer {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }

  .right-box {
    display: flex;
    // justify-content: center;
    justify-content: space-around;

    align-items: center;
    text-align: center;
    font-weight: 400;
    // line-height: 3.8;
    margin-top: 10px;

    font-family: PangMenZhengDao;
    font-size: 18px;
    color: #00ffff;
    text-align: right;
    font-weight: 400;

    .before-point {
      margin-right: 12px;
    }

    .before-point,
    .after-point {
      cursor: pointer;
      user-select: none;
      margin-left: 15px;

      &.disabled {
        cursor: not-allowed;
      }
    }

    .no {
      margin: 0 10px;
    }
  }
}
.head {
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(16, 65, 215, 0.2);
  box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
  border-radius: 10px 10px 0 0;
  font-family: YouSheBiaoTiHei;
  color: #14faff;
  .jcsb{
    line-height: 26px;
    text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
    background: linear-gradient(
      135deg,
      #e3aa77 0%,
      #f5cda9 38%,
      #f9ecd3 58%,
      #fcdbb1 79%,
      #edb07a 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .close{
    cursor: pointer;
  }
}
.points {
  position: absolute;
  top: 100px;
  right: -340px;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;

  .tb-box {
    width: 300px;
    height: 200px;
    background: rgba(8, 26, 58, 0.8);
    border-radius: 4px;
    border: 1px solid #fff;
    padding: 0 !important;
    margin: 10px;

    .tb-hd-box {
      width: 100%;
      height: 42px;
      background: #0c1f3d;
      display: flex;
      justify-content: space-around;

      .tb-hd {
        text-align: center;
        height: 100%;
        line-height: 42px;
        width: calc(100% / 3);
        white-space: nowrap;
        font-size: 16px;
        font-family: YouSheBiaoTiHei;
        color: #ebf9ff;
        letter-spacing: 1px;
        font-weight: 400;
      }

      .tb-hd:nth-child(6) {
        width: 400px;
      }
    }

    .tb-bd-box {
      width: 100%;
      height: calc(100% - 42px);
      overflow: auto;

      .tb-tr:nth-child(odd) {
        background: rgba(73, 135, 210, 0.2);
      }

      .tb-tr:nth-child(even) {
        background: #0c1f3d;
      }

      .tb-tr {
        height: 42px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        // cursor: pointer;
        &:hover {
          box-shadow: inset 0px 0px 10px 2px #3fcbff;
        }

        .td {
          color: #fff;
          width: calc(100% / 3);
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
        }

        .td:nth-child(6) {
          width: 400px;
        }

        .last-td {
          text-align: center;
          // width: 170px;

          .iconfont {
            margin-right: 7px;
          }
        }
      }
    }
  }
}
.car {
  position: absolute;
  bottom: 200px;
  right: -340px;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  .tb-box {
    width: 300px;
    height: 60px;
    background: rgba(8, 26, 58, 0.8);
    border-radius: 4px;
    padding: 5px;
    margin: 10px;
    display: flex;
    align-items: center;
    .td {
      display: flex;
      color: #fff;
      white-space: nowrap;
      align-items: center;
      justify-content: end;
      .el-input {
        width: 220px;
      }
    }
  }
}
.face {
  position: absolute;
  bottom: 10px;
  right: -340px;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  .tb-box {
    width: 300px;
    height: 120px;
    background: rgba(8, 26, 58, 0.8);
    border-radius: 4px;
    padding: 5px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    .td {
      display: flex;
      color: #fff;
      white-space: nowrap;
      align-items: center;
      justify-content: end;
      margin-top: 10px;
      .el-input {
        width: 220px;
      }
    }
  }
}
.face_car{
  line-height: 14px;
  display: flex;
  color: #00ffff;
}
</style>

<style lang="scss"></style>
