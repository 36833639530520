<template>
  <div class="monitoring_leftListBox">
    <div
      class="monitoring_leftListBox-item"
      v-for="(item, index) in btnList"
      :class="{ active: item.id == select }"
      @click="change(item, index)"
      :key="index"
    >
      <i class="icon iconfont" :class="item.icon" />
      {{ item.title }}
    </div>
  </div>
</template>

<script>
import data from './data'
export default {
  data() {
    return {
      ...data,
    }
  },
  created(){
    // console.log("shenmedjnd",this.btnList)
  },
  methods: {
    change(item, index) {
      this.$emit('changeType', item)
      this.select = item.id
    },
  },
}
</script>

<style scoped lang='scss'>
.monitoring_leftListBox {
  width: 136px;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
  justify-content: center;
  flex-wrap: wrap;
  padding: 24px 16px 0;
  flex-shrink: 0;
  box-sizing: border-box;
  margin-right: 24px;

  .monitoring_leftListBox-item {
    box-sizing: border-box;
    width: 104ox;
    height: 36px;
    background: rgba(2, 23, 61, 1);
    border: 1px rgba(49, 94, 199, 1) solid;
    border-radius: 2px;
    margin-bottom: 24px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #d2d9ff;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.active {
      background-image: url('~@/assets/images/monitoring/btn_active.png');
      background-size: 100% 100%;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
    }

    &:hover {
      background-image: url('~@/assets/images/monitoring/btn_active.png');
      background-size: 100% 100%;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
    }

    .icon {
      margin-right: 8px;
      font-size: 14px;
    }
  }
}
</style>