<template>
  <div class="cpt-command-device-mount cpt-command-device-common">
    <div class="head pl8">
      <img src="~@/assets/images/mount/mount_head.png" />
      <div class="fontO">人员统计</div>
    </div>
    <div class="divSC">
      <div class="search-box-statistics">
        <el-cascader
          ref="cascaderRef"
          @change="CSshow"
          v-model="filter.organId"
          :options="options"
          clearable
          :show-all-levels="false"
          placeholder="请选择单位"
          :props="{
            children: 'children',
            label: 'orgName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
          }"
        ></el-cascader>
        <el-select
          class="h40 ml20"
          v-model="filter.type"
          placeholder="身份"
          clearable
        >
          <el-option :value="0" label="民警"></el-option>
          <el-option :value="1" label="辅警"></el-option>
          <el-option :value="2" label="飞行助手"></el-option>
        </el-select>
        <el-select
          class="h40 ml20"
          v-model="filter.dayType"
          placeholder="活跃度"
          clearable
        >
          <el-option :value="3" label="当日活跃"></el-option>
          <el-option :value="2" label="月度活跃"></el-option>
          <el-option :value="1" label="半年度活跃"></el-option>
          <el-option :value="0" label="年度活跃"></el-option>
        </el-select>
      </div>
      <div class="btn">
        <div @click="handle_search" class="btn1027">搜索</div>
        <div @click="handle_export" class="btn1027">导出</div>
      </div>
    </div>
    <div class="bd-box">
      <div class="tb-box">
        <div class="tb-hd-box">
          <div class="tb-hd table serial">序号</div>
          <div class="tb-hd table">警员姓名</div>
          <div class="tb-hd table">所属单位</div>
          <div class="tb-hd table">身份</div>
          <div class="tb-hd table">手机号</div>
          <div class="tb-hd table">登录天数</div>
          <div class="tb-hd table">最近上线时间</div>
        </div>
        <div class="tb-bd-box">
          <div class="tb-tr" v-for="(item, index) in list" :key="index">
            <div class="td w80">
              {{ item.serial || "暂无" }}
            </div>
            <div class="td">
              {{ item.name || "暂无" }}
            </div>
            <div class="td">
              {{ item.anotherName || 0 }}
            </div>
            <div class="td">
              {{ item.type == 0 ? "民警" : item.type == 1 ? "辅警" : "飞行助手" }}
            </div>
            <div class="td">
              {{
                item.phone || 0
              }}
            </div>
            <div class="td">
              {{
                item.loginDays || 0
              }}
            </div>
            <div class="td">
              {{ item.maxLoginTime || "暂无" }}
            </div>
          </div>
        </div>
        <div class="tb-pagination">
          <el-pagination
            small
            :page-sizes="[10, 20, 30, 40]"
            :current-page.sync="filter.pageNo"
            :page-size="filter.pageSize"
            layout="total, prev, pager, next"
            :total="total"
            @current-change="handleSizeChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      list: [],
      filter: {
        organId: null,
        pageNo: 1,
        pageSize: 10,
      },
      options: [],
      total:0
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  async mounted() {
    this.filter.organId = this.user_info.departmentId;
    await this.getOptions();
    await this.getStatistics();
  },

  methods: {
    CSshow() {
      // console.log(this.$refs.cascaderRef.dropDownVisible,'refffffff')
      this.$nextTick(() => {
        this.$refs.cascaderRef.dropDownVisible = false;
      });
    },
    async handle_search() {
      await this.getStatistics();
    },
    async handle_export() {
      let type = this.filter.type == 0 || this.filter.type ? "&type="+this.filter.type : ""
      let organId = this.filter.organId ? "&organId="+this.filter.organId : ""
      let dayType =  this.filter.dayType ? "&dayType="+this.filter.dayType : ""
      let url = `${process.env.VUE_APP_BASE_URL}/hawksystemserver/personnelStatistics/exportPersonnelStatistics?FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}${type}${dayType}${organId}`;
      window.open(url);
    },
    async getOptions() {
      let res = await API.DEPARTMENT.Drd();
      this.options = [res] || [];
    },
    async getStatistics() {
      let res = await API.TABLEEXPORT.getPersonnelStatistics(this.filter);
      this.list = res.data
      this.list.forEach((item, n) => {
        item.serial = (this.filter.pageNo - 1) * this.filter.pageSize + n + 1;
      });
      this.total = res.totalCount;
    },
    handleSizeChange(pageNo) {
      this.getStatistics();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/person1027.scss";
.cpt-command-device-mount {
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  backdrop-filter: blur(1px);
  width: 48.6% !important;
  border: 1px solid #315ec7;
  .head {
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;
    display: flex;
    .fontO {
      font-size: 20px;
      font-family: YouSheBiaoTiHei;
      color: #14faff;
      line-height: 26px;
      text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
      background: linear-gradient(
        135deg,
        #e3aa77 0%,
        #f5cda9 38%,
        #f9ecd3 58%,
        #fcdbb1 79%,
        #edb07a 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .divSC {
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 16px;
  }
  .search-box-statistics {
    display: flex;
    align-items: center;
  }
  .btn {
    display: flex;
    align-items: center;
  }
  .el-cascader {
    width: auto;
    height: 40px;
    margin-top: 0px;
  }
  .el-select {
    display: flex;
    align-items: center;
  }

  .td {
    width: calc(100% / 6);
  }
  .act {
    background: url("~@/assets/images/command/twobg.png");
    background-size: 100%;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #d2d9ff;
    line-height: 33px;
    text-align: center;
    width: 92px !important;
    height: 33px !important;
  }
}
.table {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tb-hd {
  font-size: 16px !important;
  // font-family: MicrosoftYaHei-Bold, MicrosoftYaHei !important;
  font-weight: normal !important;
  color: #fff !important;
  text-shadow: 0px 2px 4px #020118 !important;
}
.serial{
  flex: none !important;
  width: 80px;
}
.cpt-command-device-common {
  height: 509px;
  .bd-box .tb-box {
    .tb-bd-box {
      height: 340px;
      overflow: auto;
    }
  }
}
.bd-box {
  margin-left: 16px;
  width: 96%;
  background: rgba(8, 26, 58, 0.8);
  border-radius: 4px;
  border: 1px solid #315ec7;
  box-sizing: border-box;
  .tb-hd-box {
    padding-top: 5px;
    box-sizing: border-box;
  }
}
.tb-bd-box::-webkit-scrollbar {
  width: 4px !important;
}

/deep/ .icon-danweipaiming {
  span {
    margin-left: 4px;
  }
}

.btn1027 {
  padding: 5px 20px;
  white-space: nowrap;
  margin-left: 10px;
  height: 32px;
  margin-right: 0 !important;
}
</style>
