<!-- 人员管理cesium关闭的地方 -->
<template>
  <div class="page-command-person">
    <Person />
  </div>
</template>

<script>
import data from "./data";
import methods from "./methods";
import Person from "@/components/command/person";

export default {
  data() {
    return data;
  },
  methods: {
    ...methods,
  },
  components: { Person },
  mounted() {
    // this.$store.commit("device/IS_SHOW", false);
    // this.$store.commit("device/TOOLBAR_IS_SHOW", false);
  },
  beforeDestroy() {
    // this.$store.commit("device/IS_SHOW", true);
    // this.$store.commit("device/TOOLBAR_IS_SHOW", true);
  },
};
</script>

<style lang="scss" scoped></style>
