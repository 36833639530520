<!-- 常态任务1027 -->
<template>
  <div class="cpt-command-task-normal">
    <div class="search-box">
      <div class="btn-add" @click="handle_click">创建常态任务</div>
      <div class="flexs waikuang">
        <el-input
          v-model="search.taskTitle"
          class="input-name"
          placeholder="请输入任务名称"
          clearable
        />
      </div>
      <div class="flexs waikuang">
        <el-autocomplete
          class="inline-input input-name"
          v-model="search.userName"
          :fetch-suggestions="querySearch"
          placeholder="请输入创建人"
          @select="handleSelect"
          clearable
          popper-class="xiala"
        ></el-autocomplete>
      </div>
      <div class="flexs waikuang">
        <!-- <img src="@/assets/newImage/dalou.png" alt="" />
        <div class="xian"></div> -->
        <el-cascader
          class="input-orgname"
          placeholder="请选择责任单位"
          v-model="search.orgId"
          :options="unitList"
          :show-all-levels="false"
          :props="{
            children: 'children',
            label: 'orgName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
          }"
          clearable
        >
        </el-cascader>
      </div>
      <div class="flexs waikuang">
        <!-- <img src="@/assets/newImage/lanquan.png" alt="" />
        <div class="xian"></div> -->
        <el-select
          v-model="search.taskSituation"
          class="input-excute"
          placeholder="请选择执行状态"
          clearable
        >
          <el-option label="待审批" :value="0"></el-option>
          <el-option label="审批不通过" :value="1"></el-option>
          <el-option label="待执行" :value="2"></el-option>
          <el-option label="执行中" :value="3"></el-option>
          <el-option label="已完成" :value="4"></el-option>
        </el-select>
      </div>
      <div @click="get_list" class="btn1027">搜索</div>
      <div @click="show_all_track" class="btn1027">历史轨迹</div>
      <div class="btn1027" @click="returnEX" v-if="examineData.active==2">返回</div>
    </div>
    <div class="tb-box">
      <div class="tb-hd-box">
        <div class="tb-hd">任务名称</div>
        <div class="tb-hd">航线名称</div>
        <!-- <div class="tb-hd">任务方式</div> -->
        <div class="tb-hd">任务类型</div>
        <div class="tb-hd">执行状态</div>
        <div class="tb-hd">责任单位</div>
        <div class="tb-hd">飞手</div>
        <div class="tb-hd">预计开始时间</div>
        <div class="tb-hd last-tb-hd">操作</div>
      </div>
      <div v-if="!loading" class="tb-bd-box">
        <template v-if="list.length > 0">
          <div class="tb-tr" v-for="item in list" :key="item.id">
            <!-- 结构:最外层为td层,第二层为element的标签提示,判定此格子里的字符有没有超过9个 -->
            <!-- 超过9个就开启提示标签,没有就关闭 -->
            <!-- 第三层的button是和第二层一起的 -->
            <!-- 第四层的div为动画层,超过9个字符就赋予动画效果 -->
            <!-- 第一排 -->

            <div class="td">
              {{ item.taskTitle || "暂无" }}
            </div>
            <!-- 第一排 -->
            <!-- 第二排 -->
            <div class="td">
              {{ item.airlineTitle || "暂无" }}
            </div>
            <!-- 第二排 -->
            <!-- <div class="td">{{ map.type[item.type] || '暂无' }}</div> -->
            <!-- 第三排 -->
            <div class="td">{{ item.taskTypeTitle || "暂无" }}</div>
            <!-- 第三排 -->
            <!-- 第四排 -->
            <div class="td" :style="{ color: statusColor[get_status(item)] }">
              {{ get_status(item) }}
            </div>
            <!-- 第四排 -->
            <!-- 第五排 -->
            <div class="td">
              {{ item.anotherName || "暂无" }}
            </div>
            <!-- 第五排 -->
            <!-- 第六排 -->
            <div class="td">
              {{
                item.team && item.team.length ? item.team.join("、") : "暂无"
              }}
            </div>
            <!-- 第六排 -->
            <!-- 第七排 -->
            <div class="td">{{ item.startedAt || "暂无" }}</div>
            <!-- 第七排 -->
            <!-- 第八排 -->
            <div class="td last-td">
              <el-tooltip effect="dark" content="审批" placement="top">
                <div
                  @click="handle_show_approve(item)"
                  class="iconfont icon-shenhe"
                  v-if="
                    !isSelf(item.organizationId) &&
                    (isJH ? item.applyStatus == 0 : item.papplyStatus == 0)
                  "
                ></div>
              </el-tooltip>
              <el-tooltip effect="dark" content="重新提交" placement="top">
                <div
                  @click="handle_resubmit(item, 7)"
                  class="iconfont icon-zhongxintijiao"
                  v-if="
                    (item.applyStatus == 2 || item.papplyStatus == 2) &&
                    item.userId == user_info.uid
                  "
                ></div>
              </el-tooltip>
              <el-tooltip effect="dark" content="下载" placement="top">
                <div
                  class="iconfont icon-a-xiazai2px"
                  @click="handle_upload(item)"
                  :class="item.taskStatus == 1 ? '' : 'disable'"
                ></div>
              </el-tooltip>
              <el-tooltip effect="dark" content="详情" placement="top">
                <div
                  @click="handle_resubmit(item, 8)"
                  class="iconfont icon-xiangqing"
                ></div>
              </el-tooltip>
              <el-tooltip effect="dark" content="定位" placement="top">
                <div
                  @click="handle_location(item, 2)"
                  class="iconfont icon-dingwei"
                ></div>
              </el-tooltip>
              <el-tooltip effect="dark" content="航线预览" placement="top">
                <div
                  @click="handle_air_line_preview(item, 1)"
                  class="iconfont icon-guiji"
                ></div>
              </el-tooltip>
              <!-- <el-tooltip effect="dark" content="视频" placement="top">
              <div
              :class="
                  item.applyStatus == 1 &&
                  item.papplyStatus == 1 &&
                  item.executeStatus == 1
                    ? ''
                    : 'disable'"
                @click="handle_video_Player(item)"
                class="iconfont icon-lishishipin"
              ></div>
            </el-tooltip> -->

              <el-tooltip effect="dark" content="图片" placement="top">
                <div
                  :class="
                    item.applyStatus == 1 &&
                    item.papplyStatus == 1 &&
                    item.executeStatus == 1
                      ? ''
                      : 'disable'
                  "
                  @click="handle_image(item)"
                  class="iconfont icon-lishizhaopian"
                ></div>
              </el-tooltip>
              <el-tooltip effect="dark" content="历史轨迹" placement="top">
                <div
                  @click="handle_histoty_track(item)"
                  class="iconfont icon-lishiguiji"
                  :class="
                    item.applyStatus == 1 &&
                    item.papplyStatus == 1 &&
                    item.executeStatus == 1
                      ? ''
                      : 'disable'
                  "
                ></div>
              </el-tooltip>
              <el-tooltip effect="dark" content="删除任务" placement="top">
                <div
                  :class="item.taskStatus != 3 ? '' : 'disable'"
                  @click="deleteTask(item)"
                  class="iconfont icon-shanchu"
                ></div>
              </el-tooltip>
            </div>
          </div>
        </template>
        <div v-else-if="!loading && list.length == 0" class="no-data">
          暂无数据
        </div>
      </div>
      <div v-else class="loading-data">
        <div>
          <i class="el-icon-loading"></i>
          <p>加载中...</p>
        </div>
      </div>
    </div>
    <div class="tb-pagination">
      <div class="zongji">
        <span>共</span>
        <span>{{ pagination.totalCount }}</span>
        <span>条</span>
      </div>
      <span class="douhao zongji">,</span>
      <div class="zongji dangqianye">
        <span>{{ pagination.pageNo }}</span>
        <span>/</span>
        <span>{{ zongye(pagination.totalCount) }}</span>
        <span>页</span>
      </div>
      <el-pagination
       
        :page-sizes="[10, 20, 30, 40]"
        :current-page.sync="pagination.pageNo"
        :page-size="pagination.pageSize"
        layout="prev, pager, next"
        :total="pagination.totalCount"
        @size-change="handle_size_change"
        @current-change="handle_page_change"
      >
      </el-pagination>

      <div class="zhuan">
        <span>前往</span>
        <input type="text" v-model="qianVal" />
        <span>页</span>
      </div>
      <el-button class="btnqueding" @click="queding">确定</el-button>
    </div>
    <el-dialog
      center
      title="审批"
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="approve_form.visible"
      :append-to-body="true"
      top="20em"
      width="30%"
    >
      <div>
        <div
          class="w20 h20 pa top20 right20 cp"
          @click="approve_form.visible = false"
        >
          <img class="dib wih100 ht100" src="~@/assets/images/close.png" />
        </div>
        <el-form label-width="80px">
          <el-form-item label="备注">
            <el-input v-model="approve_form.approvalRemark" type="textarea" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" style="fotterBtn">
        <div type="danger" @click="handle_approve(0)">不通过</div>
        <div type="primary" @click="handle_approve(1)">通过</div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { downloadData } from "@/utils/download";
import API from "@/api";
let history_entity = null;
let history_Line = null;
let history_line_arr = [];
export default {
  inject: [
    "flight_task_chage_view",
    "handle_video_Player",
    "handle_air_line_preview",
    "handle_location",
    "handle_image",
    "handle_histoty_track",
    "change_cate_id",
  ],
  computed: {
    ...mapGetters(["user_info"]),
    isJH() {
      return this.user_info && this.user_info.departmentId == 2;
    },
  },
  props: {
    numId: {
      type: Number,
    },
    examineData:{
      type: Object,
      default: () => {}
    }
  },
  watch: {
    examineData(val){
        this.search.taskSituation = val.status
        this.search.orgId = val.orgId
        this.get_list(1)
    },
    numId: async function (val, old) {
      console.log(val, "新值");
      await this.get_list();
      await this.getUnitList();
      await this.getUsername();
    },
    "$store.state.device.ctls_list"(newData) {
      this.get_list();
    },
  },
  data() {
    return {
      qianVal: "", //跳转页码标签的值
      statusColor: {
        待执行: "#FFBD36",
        执行中: "#00B4FF",
        已完成: "#00D45C",
        待审批: "#FFBD36 ",
        审批不通过: "#F42E2E",
      },
      curr_task: null,
      approve_form: {
        approvalRemark: null,
        visible: false,
      },
      search: {
        taskTitle: null,
        orgId: null,
        type: null,
        taskSituation: null,
      },
      list: [],
      loading: false,
      unitList: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
      },
      map: {
        type: {
          0: "申报",
          1: "报备",
        },
      },
      timer: null,
      ws: "",
      restaurants: [],
    };
  },
  async mounted() {
    if(this.examineData.active == 2){
        this.search.taskSituation = this.examineData.status
        this.search.orgId = this.examineData.orgId
        this.get_list(1)
    }else{
      await this.get_list();
    }
    await this.getUnitList();
    await this.getUsername();
    // this.start_timer();
  },
  destroyed() {
    let viewer = window.viewer;
    // this.stop_timer();
    this.ws && this.ws.close();
    this.ws = null;
    history_line_arr.forEach((item) => {
      viewer.entities.remove(item);
    });
  },
  methods: {
    returnEX(){
      if(this.examineData){
        this.$emit("returnEX",this.examineData)
      }
    },
    async deleteTask(item) {
      let res = await API.TASK.deleteTaskById(item.id);
      if (res == 1) {
        this.$message.success("操作成功");
        this.get_list();
      }
    },
    //获取创建人
    async getUsername() {
      let res = await API.HOME.getUsername();
      res.forEach((val) => {
        val.value = val.name;
      });
      this.restaurants = res;
    },
    //创建人下拉
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelect(item) {
      // console.log(item,'55565465465456');
    },
    // 手动页码跳转
    queding() {
      this.handle_page_change(this.qianVal);
    },
    // 计算总页码
    zongye(num) {
      let a = num / 10;
      var result = a.toString().indexOf(".");
      if (result != -1) {
        let b = parseInt(a);
        return b + 1;
      } else {
        return a;
      }
    },
    async show_all_track() {
      let viewer = window.viewer;
      try {
        // 获取轨迹总数量
        let res = await API.TASK.allNormalTrackNum();
        let totalCount = res;
        // 逐条请求数据
        for (let i = 1; i <= totalCount; i++) {
          let res = await API.TASK.allNormalTrack(i, 1);
          if (res.length >= 1) {
            let item = res[0];
            let key_name = Object.keys(item)[0];
            // item = item[key_name];
            if (item[key_name]?.length) {
              let polyline = item[Object.keys(item)[0]];
              let positions = [];
              polyline.forEach((item, index) => {
                positions.push(
                  Number(item.longitude),
                  Number(item.latitude),
                  100
                );
              });

              history_Line = viewer.entities.add({
                name: "Red line on terrain",
                polyline: {
                  positions:
                    Cesium.Cartesian3.fromDegreesArrayHeights(positions),
                  width: 5,
                  material: Cesium.Color.RED,
                  // clampToGround: true,
                },
              });
              // if (i == 1) {
              //   viewer.flyTo(history_Line);
              // }
              history_line_arr.push(history_Line);
            }
          }
        }
      } catch (error) {
        // this.$el_message('无历史轨迹')
      }
    },
    handle_upload(item) {
      downloadData(
        `/hawksystemserver/export/normalcyDownload/${item.id}?FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}`,
        "常态任务报告"
      );
    },
    handle_click() {
      this.flight_task_chage_view(6);
      this.change_cate_id(2);
    },
    async handle_resubmit(item, state) {
      let res = await API.TASK.FlightDetail(item.id);
      this.$store.commit("task/FLIGHT_DETAIL", res);
      this.flight_task_chage_view(state);
    },
    isSelf(id) {
      return this.user_info && this.user_info.departmentId == id;
    },
    async getUnitList() {
      let data = await API.DEPARTMENT.Drd();
      this.unitList = [data] || [];
    },
    async get_list() {
      this.loading = true;
      let { data, pageNo, pageSize, totalCount } = await API.TASK.List({
        cate: 3,
        taskCateId: 2,
        ...this.pagination,
        ...this.search,
      });
      this.list = data || [];
      this.pagination.pageNo = pageNo;
      this.pagination.pageSize = pageSize;
      this.pagination.totalCount = totalCount;
      this.loading = false;
    },
    get_status(item) {
      let val = item;
      let text = "";
      if (val.applyStatus == 1 && val.papplyStatus == 1) {
        text = "审批通过";
        if (val.executeStatus == 0) {
          text = "待执行";
        } else if (val.executeStatus == 1) {
          text = "已完成";
        } else {
          text = "执行中";
        }
      } else if (val.applyStatus == 2 || val.papplyStatus == 2) {
        text = "审批不通过";
      } else {
        text = "待审批";
      }
      return text;
    },
    handle_size_change(size) {
      this.pagination.pageSize = size;
      this.get_list();
    },
    handle_page_change(page) {
      this.pagination.pageNo = page;
      this.get_list();
    },
    handle_show_approve(val) {
      this.$store.commit("situation/SITUATION_IS_SHOW", false);
      this.$store.commit("situation/SITUATION_DETAIL", val);
      this.flight_task_chage_view(9);
      // this.curr_task = val
      // this.approve_form.visible = true
    },
    handle_approve(status) {
      this.$el_confirm(
        "确定提交么？",
        async () => {
          let res = await API.TASK.Edit({
            id: this.curr_task.id,
            taskCateId: this.curr_task.taskCateId,
            status,
            approvalRemark: this.approve_form.approvalRemark,
          });
          this.$el_message("审批成功");
          this.get_list();
          this.approve_form.visible = false;
        },
        () => {
          this.approve_form.approvalRemark = null;
          this.approve_form.visible = false;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-command-task-normal {
  @import "~@/styles/flightTask.scss";
  height: calc(100% - 125px);

  .btn-add {
    cursor: pointer;
    width: 170px !important;
    height: 32px !important;
    background-image: url("~@/assets/yingkong1027/btn_hei.png") !important;
    background-size: 100% 100% !important;
    box-shadow: none !important;
    font-size: 14px !important;
    font-family: MicrosoftYaHei !important;
    color: #d2d9ff !important;
    line-height: 32px !important;
  }

  .btn-submit {
    // margin-left: 40px;
    cursor: pointer;
    margin-right: 16px;
  }

  .tb-box {
    padding: 0 !important;
    width: 1796px;
    height: 470px;
    background: rgba(8, 26, 58, 0.8);
    border-radius: 4px;
    border: 1px solid #315ec7;
    position: relative;
    .no-data {
      text-align: center;
      color: #fff;
      padding-top: 10%;
    }
    .loading-data {
      font-size: 16px;
      width: 1790px;
      height: 245px;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      // top: 0;
      // left: 20px;
      text-align: center;
      color: #fff;
      padding-top: 10%;
      // div{
      //   position: absolute;
      //   top: 50%;
      // }
    }
    .tb-hd-box {
      width: 100%;
      height: 42px;
      background: #0c1f3d;
      .tb-hd {
        height: 100%;
        line-height: 42px;
        width: calc(100% / 8);
        white-space: nowrap;
        font-size: 16px;
        font-family: YouSheBiaoTiHei;
        color: #ebf9ff;
        letter-spacing: 1px;
        font-weight: 400;
      }

      .last-tb-hd {
        width: 220px !important;
      }
    }

    .tb-bd-box {
      width: 100%;
      height: calc(100% - 42px);
      position: relative;

      .tb-tr:nth-child(odd) {
        background: rgba(73, 135, 210, 0.2);
      }
      .tb-tr:nth-child(even) {
        background: #0c1f3d;
      }
      overflow-y: auto;
      .tb-tr {
        margin: 0 0 4px 0px;
        cursor: pointer;
        &:hover {
          background: rgba(2, 19, 96, 0.2);
          box-shadow: inset 0px 0px 10px 2px #3fcbff;
          border: 0px solid transparent;
        }
        .td {
          width: calc(100% / 8);
          overflow: hidden;
        }

        .last-td {
          width: 220px !important;

          .iconfont {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
.tb-bd-box::-webkit-scrollbar {
  width: 0 !important;
}
// ---------------------------------------------------------------
.search-box {
  height: auto !important;
  margin: 12px 0 12px 0;
  margin-left: 0 !important;
  box-sizing: border-box;
  padding: 0 0 0 463px;
}
// 输入框样式
.flexs {
  display: flex;
}
.waikuang {
  width: 170px;
  height: 32px;
  background: #02173d;
  border-radius: 2px;
  border: 1px solid #315ec7;
  align-items: center;
  margin: 0 16px 0 0;
  img {
    width: 20px;
    height: 20px;
    margin: 0 8px 0 8px;
  }
}
.xian {
  width: 1px;
  height: 22px;
  border-left: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(67, 222, 255, 0),
      rgba(67, 222, 255, 1),
      rgba(67, 222, 255, 0)
    )
    1 1;
}
// 所有输入框样式
/deep/ .waikuang .input-name .el-input__inner {
  width: 150px !important;
  background: rgba(13, 50, 92, 0) !important;
  border: 0px solid #43deff !important;
  align-items: center !important;
  padding-left: 15px !important;
}
/deep/ .waikuang .el-cascader .el-input__inner {
  width: 150px !important;
  background: rgba(13, 50, 92, 0) !important;
  border: 0px solid #43deff !important;
  align-items: center !important;
  padding-left: 15px !important;
}
/deep/ .waikuang .el-select .el-input__inner {
  width: 150px !important;
  background: rgba(13, 50, 92, 0) !important;
  border: 0px solid #43deff !important;
  align-items: center !important;
  padding-left: 15px !important;
}
.btn_sousuo {
  width: 79px !important;
  height: 32px !important;
  background-image: url("~@/assets/yingkong1027/btn_lan.png") !important;
  background-size: 100% 100% !important;
  box-shadow: none !important;
  font-size: 14px !important;
  font-family: MicrosoftYaHei !important;
  color: #d2d9ff !important;
  line-height: 32px !important;
}
// 滚动动画
.animate {
  padding-left: 20px;
  // font-size: 12px;
  // color: #000;
  display: inline-block;
  white-space: nowrap;
  animation: 5s wordsLoop linear infinite normal;
}

@keyframes wordsLoop {
  0% {
    transform: translateX(100%);

    -webkit-transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);

    // -webkit-transform: translateX(-100%);
  }
}
/deep/ .el-tooltip {
  background-color: transparent;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  border: 0px solid;
}

// 页签样式
.zongji {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #b5e5ff;
  line-height: 16px;
}
.douhao {
  margin: 0 5px 0 5px;
}
.dangqianye {
  margin: 0 20px 0 0;
}
/deep/.el-pager .number:hover {
  border-radius: 2px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  background: rgba(38, 71, 238, 0.71) !important;
  width: 35.5px;
  height: 28px;
  line-height: 28px;
}

// .el-pagination {
//   width: 100%;
// }

/deep/ .el-pagination--small .el-pager .number {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #889fb2;
}
/deep/.active {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  background: rgba(38, 71, 238, 0.71) !important;
}
.btnqueding {
  width: 79px;
  height: 32px;
  background-image: url("~@/assets/yingkong1027/btn_hei.png");
  background-size: 100% 100%;
  border: 0px solid transparent !important;
  margin: 0 0 0 24px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #43deff;
  line-height: 9px;
}
.tb-pagination {
  margin: 24px 0 19px 0;
  display: flex;
  justify-content: end;
}
.zhuan {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #889fb2;
  line-height: 16px;
  input {
    width: 60px;
    border-radius: 2px;
    border: 1px solid #b6d4ff;
    text-align: center;
    background: rgba(12, 13, 20, 0.5);
    margin: 0 5px 0 5px;
    color: #fff;
    outline: 0px solid;
  }
}
.wu {
  opacity: 0.7;
}
</style>
<style lang="scss">
.xiala .el-scrollbar {
  background: rgba(0, 3, 36, 0.9) !important;
}
.xiala .el-scrollbar li {
  color: #00aeff !important;
}
.xiala .el-scrollbar ul li:hover {
  background: #34466e !important;
}
.btn{
  color: #fff;
  cursor: pointer;
}


</style>
