<!-- 警情派发-航线规划 -->
<template>
  <div class="cpt-command-situation-edit">
    <div class="header" v-interact>
      <div class="hd-box ml20">警情派发</div>
      <div class="hd-box cp mr20" @click="handleCancer">关闭</div>
    </div>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
      <el-form-item label="警情事件内容" prop="content">
        <div class="jqstyle">{{ruleForm.content}}</div>
      </el-form-item>
      <el-form-item label="目标位置" prop="destination">
        <div class="jcsb">
          <div class="jqstyle">{{ruleForm.incidentAddress}}</div>

          <el-tooltip effect="dark" content="定位" placement="top">
            <div
              @click="() => $emit('handleLocation', situation_detail)"
              class="el-icon-location-outline"
            ></div>
          </el-tooltip>
        </div>
      </el-form-item>
      <el-form-item label="警情类型" prop="bjlxmc">
        <div class="jqstyle">{{ruleForm.bjlxmc}}</div>
      </el-form-item>
      <el-form-item label="需协飞单位">
        <el-button size="mini" @click="checked2=true">最优调度</el-button>
        <!-- <el-checkbox v-model="checked2" class="text">最优调度</el-checkbox> -->
      </el-form-item>
      <el-form-item type="textarea" class="assist" label="派发单位">
        <div class="jqstyle">{{ ruleForm.policeUnitTitle }}</div>
      </el-form-item>
    </el-form>
    <div class="btn">
      <el-button class="Schedul" @click="handleClickFn">派发</el-button>
      <el-button class="Schedul w98 ml20" @click="visible=true">无需派发</el-button>
    </div>
    <el-dialog
      class="policeSituationvisibleBox"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :visible.sync="visible"
    >
      <div class="pup_head">
        <div class="flex">
          <div class="pup_img">
            <img src="~@/assets/zong_lan_ye_img/jinye.png" style="width: 100%; height: 100%" alt />
          </div>
          <div class="fontOne">无需派发任务提示</div>
        </div>
        <div class="fontTwo" @click="visible = false;">关闭</div>
      </div>
      <div class="line"></div>
      <div class="content">
        <div class=" mt30 mb10 ml27 tc  f20 cf">
          是否确认无需派发？
          <!-- 备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注：
          <el-select
            class="ml6 w400"
            v-model="remark"
            placeholder="请选择或填写备注信息"
            size="mini"
            multiple
            filterable
            allow-create
            default-first-option
          >
            <el-option
              v-for="(item,index) in policeSituationDataType"
              :key="index"
              :label="item.name"
              :value="item.name"
            />
          </el-select> -->
        </div>
      </div>
      <div class="flex mb10 mt30">
        <div class="doneNo" @click="visible=false">取消</div>
        <div class="doneY" @click="handleCancerclose">确认</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      // 无需派发时填写的备注信息
      remark: '无需派发',
      policeSituationDataType: [
        { name: "设备维修" },
        { name: "人不在岗" },
        { name: "无需协飞" },
        
      ],
      visible: false,
      resourceVisible: false,
      mounteList: [],
      checked2: false,
      ruleForm: {
        content: null,
        incidentAddress: null, //目标位置
        startPosition: null, //起始位置
        flight_line_id: null,
        jing_qing_type: null,
        policeUnitTitle: null,
        taskStatus: null,
        organizationId: null,
        userIds: null,
        remark: null,
        taskId: null,
        id: null,
        mountType: null,
        resourceName: null,
        assistUnit: null,
        bjlxmc: null,
        applyStatus: null,
      },
      rules: {
        flight_line_id: [
          { required: true, message: "请选择航线", trigger: "change" },
        ],
      },
      lineList: [],
      unitList: [],
      flyerList: [],
      typeList: [],
      destination: {
        // name: '',
        lng: 0,
        lat: 0,
      },
      startPosition: {
        // name: '',
        lng: 0,
        lat: 0,
      },
      selectList: null,
      selectArr: [],
    };
  },
  computed: {
    ...mapGetters(["situation_detail"]),
  },
  inject: ["flight_task_chage_view", "change_page", "change_airway_visible"],
  async mounted() {
    await this.getMount();
    await this.getLineList(),
      await this.getUnitList(),
      await this.getFlyerList(),
      // await this.getTypeList(),
      this.getDetail();
    this.$emit("handleLocation", this.situation_detail);
  },
  methods: {
    exit() {
      this.$emit("removeEntities");
      this.flight_task_chage_view(1);
    },
    handle_click() {
      this.change_page(4);
      this.change_airway_visible();
      this.$store.commit("situation/IS_FROM_SITUATION_EDIT", true);
    },
    async handleCancerclose() {
      let res = await API.SITUATION.updateJingQingInfoById({
        id: this.ruleForm.id,
        approvalStatus: 7,
        remark:  '无需派发'
      });
      this.visible=false
      this.handleCancer();
    },
    handleCancer() {
      this.$emit("removeEntities");
      // this.resourceVisible = false;
      let time = Date.now();
      // 刷新飞行任务-警情任务列表
      this.$store.commit("device/SET_JQ_LIST", time);
      // 疑似跳转功能
      this.flight_task_chage_view(1);
    },
    // 接收子组件勾选资源单位
    acceptSelectFn(val) {
      this.ruleForm.policeUnitTitle = val.organizationName;
      this.ruleForm.organizationId = val.organizationId;
    },
    changeVal() {
      this.$refs.elcascader.dropDownVisible = false;
    },
    getDetail() {
      const keys = Object.keys(this.ruleForm);
      console.log(this.situation_detail, "this.situation_detail");
      keys.forEach((item) => {
        this.ruleForm[item] = this.situation_detail[item];
      });

      this.ruleForm.organizationId = this.situation_detail["organizationId"];
    },
    async handleClickFn() {
      let { organizationId, id } = this.ruleForm;
      let res = await API.SITUATION.dispatchPoliceTask({
        id,
        organizationId: organizationId,
      });
      this.$message.success(res);
      this.$emit("removeEntities");
      //  this.$el_message("提交成功");
      let time = Date.now();
      // 刷新飞行任务-警情任务列表
      this.$store.commit("device/SET_JQ_LIST", time);
      // 跳转功能
      this.flight_task_chage_view(1);
    },
    async getLineList() {
      let data = await API.AIRWAY.Drd();
      this.lineList = data || [];
    },
    async getUnitList() {
      let data = await API.DEPARTMENT.Drd();
      this.unitList = [data] || [];
    },
    async getFlyerList() {
      let data = await API.USER.DrdPilot();
      this.flyerList = data || [];
    },
    async getTypeList() {
      let data = await API.SITUATION.ListTpye();
      this.typeList = data || [];
    },
    // 获取挂载列表
    async getMount() {
      let data = await API.SITUATION.getAllMountTypeList();
      console.log("------data", data);
      this.mounteList = data || [];
    },
  },
  watch: {
    "$store.state.device.key_line"(newData) {
      this.getLineList();
    },
    checked2(val) {
      this.flight_task_chage_view(1, val, true);
    },
  },
  beforeDestroy() {
    this.flight_task_chage_view(1, false, true);
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-message-box {
    .el-input__inner {
      color: #3a8ee6 !important;
      background: #02173d;
      border: 1px solid #1251cd;
    }
  }
}
@import "~@/styles/form.scss";
.cpt-command-situation-edit {
  position: absolute;
  left: 90px;
  top: 180px;
  width: 417px;
  // height: 784px;
  box-sizing: border-box;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 13px;
  ::v-deep {
    .el-textarea.is-disabled .el-textarea__inner {
      width: 300px;
      min-height: 60px;
    }
  }
  .policeSituationvisibleBox {
    top: 200px;
    left: 657px;
    width: 600px;
    display: flex;
    flex-direction: column;

    .head {
      // background: rgba(50, 2, 2, 0.7);
      // box-shadow: inset 0 0 56px 0 rgba(255, 64, 64, 0.39);
      // border-radius: 10px 10px 0 0;
      display: flex;
      justify-content: space-between;
      font-family: MicrosoftYaHei-Bold;
      font-size: 16px;
      color: #fff;
      text-align: center;
      font-weight: 700;
    }

    .pup_head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 16px 0 12px;
      align-items: center;
      height: 32px;
      background: linear-gradient(
        180deg,
        #9198ff 0%,
        rgba(45, 81, 153, 0.45) 40%,
        #05091a 100%
      );
      box-shadow: inset 0px 0px 10px 2px #3f9dff;
      border-radius: 10px 10px 0px 0px;
      border: 1px solid #427dff;

      .pup_img {
        display: flex;
        width: 19px;
        height: 25px;
        margin: 0 4px 0 0;
      }

      .fontOne {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .fontTwo {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #d2dfff;
        cursor: pointer;
        line-height: 20px;
      }
    }

    .line {
      width: 457px;
      height: 2px;
      align-self: center;
      // background: #FD4D4D;
      // background-image: linear-gradient(
      //   244deg,
      //   rgba(255, 255, 255, 0) 0%,
      //   rgba(237, 89, 68, 1) 50%,
      //   rgba(255, 255, 255, 0) 100%
      // );
    }

    .content {
      padding-top: 16px;
      border-radius: 0 0 10px 10px;
      width: 100%;
      text-align: left;
      height: calc(100% - 35px);
      font-family: MicrosoftYaHei;
      font-size: 16px;
      color: #fff;
      line-height: 28px;
      font-weight: 400;
      disabled: flex;
    }

    .inputBox {
      width: 400px;
      // min-height: 120px;
      background-color: #02173d;
      border: 1px solid #2b56b7;
      font-size: 14px;
      color: #00aacb;
      border-radius: 5px;
      text-indent: 1rem;
      vertical-align: top;
    }

    .flex {
      min-width: 175px;
      align-self: center;
      display: flex;
      justify-content: space-between;
      line-height: 32px;

      .doneN {
        width: 56px;
        height: 32px;
        white-space: nowrap;
        cursor: pointer;
        background: #fff;
        border: 1px solid #dcdfe6;
        -webkit-appearance: none;
        text-align: center;
        box-sizing: border-box;
        outline: 0;
        margin: 0;
        transition: 0.1s;
        font-weight: 500;
        color: #606266;
      }

      .doneY {
        margin-left: 50px;
        color: #fff;
        min-width: 56px;
        height: 32px;
        white-space: nowrap;
        cursor: pointer;
        box-shadow: inset 0 0 5px #00ffff;
        background: rgba(4, 227, 227, 0.1);
        -webkit-appearance: none;
        text-align: center;
        box-sizing: border-box;
        outline: 0;
        margin: 0;
        transition: 0.1s;
        font-weight: 500;
      }

      .doneNo {
        width: 56px;
        height: 32px;
        white-space: nowrap;
        cursor: pointer;
        background: rgba(185, 7, 7, 0.4);
        border: 1px solid #e9092e;
        -webkit-appearance: none;
        text-align: center;
        box-sizing: border-box;
        outline: 0;
        margin: 0;
        transition: 0.1s;
        font-weight: 500;
        color: #ffffff;
      }
    }

    /deep/ .el-dialog {
      width: 533px;
      // height: 477px;
      margin-top: 0 !important;
      overflow: hidden;
      border-radius: 10px;

      background: transparent;
      background: rgba(0, 39, 121, 0.5);
      box-shadow: 0px 2px 8px 0px rgba(1, 162, 255, 70%),
        inset 0px 0px 64px 0px rgba(26, 138, 227, 35%),
        inset 0px 0px 8px 0px #019aff;
      border: 1px solid #43deff;

      .el-dialog__header {
        padding-bottom: 0;
        padding-top: 0px;

        .el-dialog__title {
          color: #fff;
        }
      }

      .el-dialog__body {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .content {
          padding-top: 16px;
          border-radius: 0 0 10px 10px;
          width: 100%;
          height: calc(100% - 35px);
          // padding: 30px;
          // background: url('../../assets/images/warn/yj.png') no-repeat 100%;
          font-family: MicrosoftYaHei;
          font-size: 16px;
          color: #00aacb;
          text-align: center;
          line-height: 28px;
          font-weight: 400;
        }

        .btn {
          cursor: pointer;
          margin-top: 8px;
          padding: 16px;
          background: rgba(255, 64, 64, 0.39);
          color: #fff;
          border-radius: 10px;
        }
      }
    }
  }
  .jqstyle {
    width: 255px;
    background: #02173d;
    border: 1px solid #1251cd;
    color: #3a8ee6;
    border-radius: 4px;
    line-height: 20px;
    padding: 5px;
    min-height: 21px;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px 10px 0 0;
  height: 44px;
  background: rgba(16, 65, 215, 0.2);
  box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
}
.hd-box {
  font-family: MicrosoftYaHei;
  font-size: 18px;
  color: #00ffff;
  letter-spacing: 0;
  font-weight: 400;
}

.el-form {
  padding-top: 30px;
  ::v-deep {
    .el-textarea__inner {
      height: 70px;
    }
  }
}
.btn {
  margin: 0 auto;
  // width: 153px;
  margin-top: 20px;
  margin-bottom: 30px;
  .Schedul {
    display: inline-block;
    background: rgba(0, 34, 140, 0.2);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 15px 0 #00b6ff;
    border-radius: 4px;
    font-family: PangMenZhengDao;
    font-size: 16px;
    width: 70px;
    color: #00ffff;
    text-align: center;
    font-weight: 400;
    height: 33px;
  }
}
.el-icon-location-outline {
  color: rgb(179, 201, 203);
  font-size: 30px;
  margin-top: 12px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
/deep/.el-checkbox__input {
  background: #000000;
  border: 1px solid #08c2d1;
  border-radius: 4px;
}
/deep/.el-checkbox__inner {
  border: none;
  background-color: transparent;
}
.assist {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #dcdfe3;
  font-weight: 400;
}
.assist-content {
  font-family: MicrosoftYaHeiUI;
  font-size: 15px;
  color: #08c2d1;

  background: rgba(2, 31, 51, 0.7);
  border: 1px solid #06b6e0;
  width: 375px;

  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: transparent;
}
/deep/ .el-dropdown {
  color: #08c2d1;
}
.track_btn {
  color: #b3c9cb;
  position: absolute;
  right: 18px;
  top: 3px;
  font-size: 20px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
</style>

<style lang="scss">
.cpt-situation-edit {
  width: 394px;
}
</style>
