<template>
  <div class="cpt-command-airway-list">
    <div class="header" v-interact>
      <div class="toubu">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="hd-box">实时调度</div>
      </div>

      <div class="right">
        <div class="hd-box cp mr20" @click="change_page">关闭</div>
      </div>
    </div>

    <div class="list-box">
      <div class="search-box">
        <div class="flex fangkuai">
          <el-input size="mini" v-model="search.name" class="item-input" placeholder="请输入点调名称" clearable />
        </div>
        <div class="flex fangkuai ml14" >
          <el-date-picker  size="mini" prefix-icon="ccc" v-model="pickTime" type="datetimerange" range-separator="至"
            value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" popper-class="flight-log">
          </el-date-picker>
        </div>
        <div class="flex  ml14">
          <el-cascader        size='mini' class="input-orgnameMini " placeholder="请选择责任单位" v-model="search.organId" :options="unitList"
            :show-all-levels="false" :props="{
              children: 'children',
              label: 'orgName',
              value: 'id',
        
              checkStrictly: true,
              emitPath: false,
            }" clearable></el-cascader>
        </div>
        <div class="item-plan cp center ml16" @click="getList">搜索</div>
        <div class="item-plan cp center ml14" @click="rec">重置</div>
        <div class="item-plan cp center ml14" @click="exportDone">导出</div>
        <div class="item-plans cp center ml14" @click="addTone(0)">
          新建点调
        </div>
        <div class="waixian">
          <!-- <div @click="$emit('add')" class="item-plan cp center">航线规划</div> -->
        </div>
      </div>
      <div class="tb-box">
        <el-table ref="tableT" :cell-style="cellStyle" :data="toneList" height="470"
          @select="handleSelectionChange">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column align="center" prop="name" label="点调名称"></el-table-column>
          <el-table-column align="center" prop="orgNames" label="参调单位">
            <template slot-scope="scope">
              <el-tooltip effect="dark" :content="scope.row.orgNames" placement="top">
                <div class="orgNames">{{ scope.row.orgNames }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="startTime" label="开始时间"></el-table-column>
          <el-table-column align="center" prop="endTime" label="结束时间"></el-table-column>
          <el-table-column align="center" prop="done" label="状态">
            <template slot-scope="scope">
              <div v-if="scope.row.done">已完成</div>
              <div v-else>未完成</div>
            </template>
          </el-table-column>
          <el-table-column align="center" width="250" label="操作">
            <template slot-scope="scope">
              <div class="btn" v-if="scope.row.isRelease == 1">
                <div class="item-btn" v-if="scope.row.doneStatus == 0" @click="sonClick(2, scope.row.id)">
                  开始点调
                </div>
                <div class="item-btn" v-if="scope.row.doneStatus == 1" @click="sonClick(2, scope.row.id)">
                  继续点调
                </div>
                <div class="item-btn" @click="sonClick(1, scope.row.id)">
                  查看
                </div>
                <div class="item-btn" v-if="scope.row.doneStatus == 2" @click="addTone(2, scope.row)">
                  再次点调
                </div>
              </div>
              <div class="btn" v-else>
                <div class="cp item-btn" @click="handleClick(0, scope.row)">
                  发布
                </div>
                <div class="cp item-btn" @click="addTone(1, scope.row)">
                  编辑
                </div>
                <div class="cp item-btn" @click="handleClick(2, scope.row)">
                  删除
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="tb-pagination">
        <div class="zongji">
          <span>共</span>
          <span class="tiaoshu">{{ pagination.totalCount }}</span>
          <span>条</span>
        </div>
        <span class="douhao zongji">,</span>
        <div class="zongji dangqianye">
          <span>{{ pagination.pageNo }}</span>
          <span>/</span>
          <span>{{ zongye(pagination.totalCount) }}</span>
          <span>页</span>
        </div>
        <el-pagination small :page-sizes="[10, 20, 30, 40]" :current-page.sync="dangqianye"
          :page-size="pagination.pageSize" layout="prev, pager, next" :total="pagination.totalCount"
          @current-change="page_change" @size-change="size_change"></el-pagination>
        <div class="zhuan">
          <span>前往</span>
          <input type="text" oninput="value=value.replace(/[^\d]/g,'')" v-model="qianVal" />
          <span>页</span>
        </div>
        <!-- 不知道page-change指向哪,只能直接拿过来用 -->
        <el-button class="btnqueding" @click="changePage">确定</el-button>
      </div>
    </div>
    <ToneForm ref="ToneForm" v-if="ToneFormShow" :type="type" :id="formId" @close="rec" />
    <Start ref="Start" v-if="startShow" :doneDisable="doneDisable" :id="itemId" @close="closeStart" />
  </div>
</template>

<script>
import API from "@/api";
import ToneForm from "./addUpdate";
import Start from "./start";
import { mapGetters } from "vuex";
export default {
  inject: ["g_cesium_layer", "change_page"],
  data() {
    return {
      ToneFormShow: false,
      startShow: false,
      itemId: null,
      formId: null,
      type: 0,
      qianVal: "",
      unitList: [],
      pickTime: null,
      dangqianye: 1,
      search: {
        // name: "",
        // organId: null,
        pageNo: 1,
        pageSize: 10,
      },
      toneList: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
      },
      doneDisable: true,
      changeId: null,
    };
  },
  components: { ToneForm, Start },
  computed: {
    ...mapGetters(["user_info"]),
  },
  mounted() {
    this.getUnitList();
    this.getList();
  },
  methods: {
    //表格行颜色
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex % 2 == 1) {
        return "background: #0c1f3d;";
      } else {
        return "background:rgba(73,135,210,0.2);";
      }
    },
    handleSelectionChange(e, row) {
      if (e.length == 0) {
        this.changeId = null;
        return;
      } else {
        this.changeId = row.id;
        this.$refs.tableT.clearSelection();
        this.$refs.tableT.toggleRowSelection(row, true);
      }
    },
    exportDone() {
      if (this.changeId) {
        let ids = this.changeId;
        let url = `${process.env.VUE_APP_BASE_URL}/hawksystemserver/dispatch/exportDispatchReport?id=${ids}&FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}`;
        window.open(url);
      } else {
        this.$message.error("无导出数据！");
      }
    },
    closeStart() {
      this.startShow = false;
      this.search = {
        pageNo: this.pagination.pageNo,
        pageSize: 10,
      };
      this.getList();
    },
    async getUnitList() {
      let data = await API.DEPARTMENT.Drd();
      this.unitList = [data] || [];
    },
    rec() {
      this.ToneFormShow = false;
      this.pickTime = null;
      this.search = {
        pageNo: this.pagination.pageNo,
        pageSize: 10,
      };
      this.changeId = null;
      this.getList();
    },
    async getList() {
      if (this.pickTime) {
        this.search.start = this.pickTime[0];
        this.search.end = this.pickTime[1];
      } else {
        this.search.start = null;
        this.search.end = null;
      }
      console.log("发送的",this.search)
      let res = await API.TONE.getSettingPage(this.search);
      console.log(res)
      this.pagination.totalCount = res.totalCount;
      // if(res.data){
      //   res.data.forEach(async (val) => {
      //     let res2 = await API.TONE.getItemPageBySettingId({settingId:val.id})
      //     val.sonList = res2.data
      //   });
      // }
      // console.log(res.data,"toneList");
      this.toneList = res.data || [];
    },
    async addTone(type, val) {
      this.type = type;
      if (type == 1) {
        if (val) {
          this.formId = val.id;
        }
      } else if (type == 0) {
        this.formId = null;
      } else {
        if (val) {
          this.formId = val.id;
        }
      }
      this.ToneFormShow = true;
    },
    page_change(e) {
      // console.log(e,"page_change");
      this.search.pageNo = e;
      this.getList();
      this.pagination.pageNo = e;
    },
    size_change(e) {
      console.log(e, "size_change");
    },
    changePage() {
      if (this.qianVal > this.zongye(this.pagination.totalCount)) {
        this.qianVal = this.zongye(this.pagination.totalCount)
      }
      this.search.pageNo = this.qianVal
      this.getList()
      this.pagination.pageNo = this.qianVal
    },
    /**
     * 点调的操作
     */
    handleClick(type, val) {
      if (type == 0) {
        this.$confirm("是否发布点调信息？", "确认", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          customClass: "uav_controlPane",
          showClose: false,
        })
          .then(async () => {
            let res = await API.TONE.release({ dispatchId: val.id });
            this.$message.success(res);
            this.rec();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      } else if (type == 1) {
      } else if (type == 2) {
        this.$confirm("是否删除点调信息？", "确认", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          customClass: "uav_controlPane",
          showClose: false,
        })
          .then(async () => {
            let res = await API.TONE.delete(val.id);
            this.rec();
            this.$message.success(res);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },
    /**
     * 子点调的操作
     */
    async sonClick(type, id) {
      console.log(id);
      this.itemId = id;
      if (type == 1) {
        this.doneDisable = false;
      } else if (type == 2) {
        // let res = await API.TONE.startDispatch({itemDispatchId: id})
        // this.getList()
        this.doneDisable = true;
      } else {
        this.doneDisable = true;
      }
      this.startShow = true;
    },
    // 计算总页码
    zongye(num) {
      let a = num / 10;
      var result = a.toString().indexOf(".");
      if (result != -1) {
        let b = parseInt(a);
        return b + 1;
      } else {
        return a;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";

.cpt-command-airway-list {
  @import "~@/styles/flightTask.scss";
  position: absolute;
  left: 10px;
  top: 99px;
  width: 1820px;
  height: 630px;
  box-sizing: border-box;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    background: linear-gradient(180deg,
        #9198ff 0%,
        rgba(45, 81, 153, 0.45) 40%,
        #05091a 100%);
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;

    .toubu {
      display: flex;

      .hd-box {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(135deg,
            #e3aa77 0%,
            #f5cda9 38%,
            #f9ecd3 58%,
            #fcdbb1 79%,
            #edb07a 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .right {
      display: flex;

      .hd-box {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #d2dfff;
      }
    }
  }

  .list-box {
    // width: 1132px;
    height: calc(100% - 56px);
    box-sizing: border-box;
    padding: 0 16px 0 16px;

    .search-box {
      margin-left: 0 !important;
      // height: 80px;
      height: auto;
      margin: 12px 0 12px 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      .fangkuai {
        border: 1px solid #315ec7;
        background: #02173d;
      }

      .item-plan {
        width: 64px;
        height: 32px;
        background: url("~@/assets/images/command/personN.png") no-repeat;
        background-size: 100% 100%;
        // background: rgba(38,71,238,0.7);
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 32px;

        &:hover {
          background: url("~@/assets/images/command/twobg.png");
        }
      }

      .item-plans {
        width: 92px;
        height: 32px;
        background: url("~@/assets/images/command/personN.png") no-repeat;
        background-size: 100% 100%;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 32px;

        &:hover {
          background: url("~@/assets/images/command/fourbg.png");
        }
      }

      .item-plan:hover {
        // opacity: 0.5;
      }

      .item-input {
        width: 168px;
        margin-left: 10px;
        // margin-right: 10px;
        color: #ffffff;

        ::v-deep .el-input__inner {
          background: rgba(2, 31, 51, 0);
          border: 0px solid #06b6e0;
          border-radius: 4px;
          font-family: MicrosoftYaHeiUI;
          font-size: 16px;
          color: #ffffff;
          font-weight: 400;
          padding-left: 0;

          &::placeholder {
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #397c8b;
            line-height: 19px;
          }
        }
      }
    }

    .tb-box {
      padding: 0 !important;
      width: 1796px;
      height: 470px;

      .btn {
        display: flex;
        justify-content: space-around;

        .item-btn {
          width: 64px;
          height: 28px;
          line-height: 28px;
          background: url("~@/assets/images/command/personN.png") no-repeat;
          background-size: 100% 100%;

          &:hover {
            background: rgba(38, 71, 238, 0.71);
            box-shadow: inset 0px 1px 3px 0px #ffffff,
              inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
            border-radius: 4px;
            // border: 1px solid;
            border-image: linear-gradient(180deg,
                rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 0)) 1 1;
          }
        }
      }

      /deep/ .el-table {
        background: rgba(8, 26, 58, 0.8);
        &::before {
    height: 0px;
}
        .el-table__expanded-cell {
          background: transparent;
        }

        .el-table__body-wrapper {
          // width: 100%;
          // max-height: 600px;
          // overflow-y: auto;
        }

        .el-table__empty-text {
          color: #fff;
        }

        .el-table__expand-icon {
          color: #fff;
        }

        tr {
          background: transparent;
        }

        thead {
          background: rgba(8, 26, 58, 0.8);
          border-radius: 4px;
          border: 1px solid #315ec7;
          tr{
            th{
              border: 0 !important;
            }
          }

          .el-table__cell {
            background: transparent;
            font-size: 16px;
            font-family: YouSheBiaoTiHei;
            color: #ebf9ff;
            line-height: 22px;
            letter-spacing: 1px;
          }
        }

        tbody {
          tr {
            color: #fff;
            font-size: 14px;
            td{
              border: 0 !important;
            }
          }

          tr:hover>td {
            background: transparent !important;
          }
        }
      }
    }

    .tb-pagination {
      float: right;
      margin: 24px 0 19px 0;

      .zongji {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #B6D4FF;
        line-height: 16px;

        .tiaoshu {
          color: #315ec7;
        }
      }

      .douhao {
        margin: 0 5px 0 5px;
      }

      .dangqianye {
        margin: 0 20px 0 0;
      }

      .zhuan {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #B6D4FF;
        line-height: 16px;

        input {
          width: 48px;
          min-width: 48px;
          max-width: auto;
          text-align: center;
          background: rgba(12, 13, 20, 0.5);
          border-radius: 2px;
          border: 1px solid rgba(36, 146, 252, 0.3);
          margin: 0 5px 0 5px;
          color: #fff;
          outline: 0px solid;
        }
      }

      .btnqueding {
        margin: 0 0 0 8px;
        width: 79px;
        height: 32px;
        background: url("~@/assets/images/command/true.png");
        line-height: 1px;
        color: #315ec7;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #43DEFF;
      }

      .btnqueding:hover {
        opacity: 0.5 !important;
      }
    }
  }
}

/deep/ .fangkuai .el-cascader .el-input__inner {
  width: 150px !important;
  background: rgba(13, 50, 92, 0) !important;
  border: 0px solid #315ec7 !important;
  align-items: center !important;
  padding-left: 15px !important;
}

//时间选择器样式
/deep/ .waikuang .el-range-input {
  width: 140px;
}

/deep/ .fangkuai .el-date-editor {
  padding: 0;
  border: 0px solid;
  background: rgba(13, 50, 92, 0) !important;
}

/deep/ .el-date-editor .el-range-input {
  background: rgba(2, 31, 51, 0.1);
  width: 390px;
  color: #fff;
}

.flight-log {
  border: none;

  .el-picker-panel__body-wrapper {
    background: #0d224f;
    border: 1px solid #00aeff;
    color: #fff;

    .el-input__inner {
      color: #fff;
      border: 1px solid #00aeff;
    }

    .el-date-table th {
      // border-bottom: solid 1px #00aeff;
      color: #fff;
    }

    .el-date-table td.in-range div {
      background-color: #00aeff;
    }

    .el-input--small .el-input__inner {
      background-color: #0d224f;
    }

    .el-picker-panel__icon-btn {
      color: #fff;
    }
  }

  .el-picker-panel__footer {
    background-color: #0d224f !important;
  }

  .el-button.el-button--mini.is-plain,
  .el-button.is-disabled.is-plain {
    background-color: transparent !important;
    border: none;
    color: #00aeff;
  }
}

.orgNames {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
}

/deep/.el-table__header-wrapper {
  .el-table-column--selection {
    .el-checkbox {
      visibility: hidden;
    }
  }
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: transparent;
  border: 1px solid #F0F3FF;
  opacity: 1;
}

/deep/.el-checkbox__inner {
  opacity: 0.6;
}

/deep/.el-cascader {
  line-height: 32px !important;
}
</style>
