<!-- 无人机飞行统计 -->
<template>
  <div class="cpt-command-device-flight">
    <!-- <div class="hd-box">无人机飞行统计</div> -->
    <div>
      <div class="flexs justify-between items-center">
        <div class="flexs height-">
          <div class="waikuang active">
            <i class="icon iconfont icon-kekongwurenji" style="font-size: 16px; margin-right: 4px;" />
            <span>无人机飞行统计</span>
          </div>
          <div class="waikuang" @click="shebei">
            <img src="~@/assets/images/device/device_icon1.svg" style="width: 16px; height: 16px; margin-right: 5px;" />
            <span>设备工作管理状态</span>
          </div>
        </div>
        <div class="search-box">
          <el-cascader
            v-model="filter.organId"
            :options="options"
            clearable
            :show-all-levels="false"
            placeholder="请选择单位"
            :props="{
              children: 'children',
              label: 'orgName',
              value: 'id',
              checkStrictly: true,
              emitPath: false,
            }"
          ></el-cascader>
          <el-button @click="handle_search" class="btn">搜索</el-button>
        </div>
      </div>
      <div class="bd-box">
        <div class="bd-box-content">
          <div class="num">{{ count }}<span class="num_unit">次</span></div>
          <img
            src="~@/assets/images/device/device_count1.gif"
            class="image cp"
            @click="handle_click(8,filter.organId)"
          />
          <div class="title">无人机飞行架次统计</div>
        </div>
        <div class="bd-box-content">
          <div class="num">
            {{ (distance / 1000).toFixed(2) }}<span class="num_unit">km</span>
          </div>
          <img
            src="~@/assets/images/device/device_count2.gif"
            class="image cp"
            @click="handle_click(9,filter.organId)"
          />
          <div class="title">无人机飞行里程统计</div>
        </div>
        <div class="bd-box-content">
          <div class="num">{{ time }}<span class="num_unit">h</span></div>
          <img
            src="~@/assets/images/device/device_count3.gif"
            class="image cp"
            @click="handle_click(10,filter.organId)"
          />
          <div class="title">无人机飞行时长统计</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";
// import status from "@/components/command/device/status/index.vue";
export default {
  components: { status },
  data() {
    return {
      options: [],

      filter: {
        organId: null,
        type: 3,
      },
      count: null,
      distance: null,
      time: null,
    };
  },
  async mounted() {
    this.filter.organId = this.user_info.departmentId;

    await this.getMountList();
    await this.getOptions();
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  methods: {
    //切换到设备工作管理状态
    shebei() {
      this.$parent.circle_diagram_status();
    },
    handle_click(e,organId) {
      this.$emit("clickChart", e, organId);
    },
    handle_search() {
      this.getMountList();
    },
    async getOptions() {
      let res = await API.DEPARTMENT.Drd();
      this.options = [res] || [];
    },
    async getMountList() {
      let res = await API.DEVICE.FlightInfo(this.filter);
      res.forEach((item) => {
        if (item.type == 1) {
          this.count = item.data;
        } else if (item.type == 2) {
          this.distance = item.data;
        } else if (item.type == 3) {
          this.time = item.data;
        }
      });
    },
    formatSeconds(value) {
      let result = parseInt(value);
      let h =
        Math.floor(result / 3600) < 10
          ? "0" + Math.floor(result / 3600)
          : Math.floor(result / 3600);
      let m =
        Math.floor((result / 60) % 60) < 10
          ? "0" + Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60);
      let s =
        Math.floor(result % 60) < 10
          ? "0" + Math.floor(result % 60)
          : Math.floor(result % 60);
      result = `${h} : ${m} : ${s}`;
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-command-device-flight {
  height: 318px;
  background: rgba(12,34,73,0.7);
  border-radius: 10px;
  border: 1px solid rgba(26,92,246,0.5);
  backdrop-filter: blur(1px);
  padding: 16px 16px 34px;
  box-sizing: border-box;

  .hd-box {
    height: 56px;
    font-family: PangMenZhengDao;
    font-size: 22px;
    color: #dce9ff;
    font-weight: 400;
    line-height: 70px;
    padding-left: 92px;
    overflow: hidden;
  }
  .search-box {
    display: flex;

    .btn {
      margin-left: 15px;
    }
    ::v-deep {
      .el-button {
        width: 64px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('~@/assets/images/monitoring/btn_active1.png');
        font-family: PangMenZhengDao;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #D2D9FF;
        border-radius: 2px;
        border-width: 0;
        opacity: 1 !important;
        &:hover {
          opacity: .7 !important;
        }
      }
      .el-input__inner {
        background: #000000;
        border: 1px solid #00aeff;
        font-family: MicrosoftYaHeiUI;
        font-size: 16px;
        color: #00aeff;
        border-radius: 0;
      }
    }

    .el-cascader ::v-deep{
      width: 170px;
      height: 32px;
      transform: translateY(-4px);

      input {
        height: 32px;
        font-size: 14px;
        color: RGBA(121, 132, 152, 1);
        background: #02173D;
        border-radius: 2px;
        border: 1px solid #315EC7;
        box-sizing: border-box;
      }
    }
  }
  .bd-box {
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin-top: 44px;
    .bd-box-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    .title {
      font-size: 16px;
      font-family: YouSheBiaoTiHei;
      color: #EBF9FF;
      line-height: 22px;
      letter-spacing: 1px;
      margin-top: 14px;
    }
    .image {
      width: 140px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    .num {
      font-size: 24px;
      font-family: LCDBold;
      color: #01A8FF;
      line-height: 22px;
      letter-spacing: 1px;
      margin-bottom: 14px;

      .num_unit {
        font-size: 12px;
        font-family: YouSheBiaoTiHei;
        color: #EBF9FF;
        line-height: 14px;
        letter-spacing: 1px;
        color: #EBF9FF;
        margin-left: 2px;
      }
    }
  }
}
.flexs {
  display: flex;
}
.waikuang {
  height: 32px;
  border: 1px rgba(49, 94, 199, 1) solid;
  border-radius: 3px;
  background-color: rgba(2, 23, 61, 1);
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #D2D9FF;
  cursor: pointer;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;

  &.active{
    background-image: url('~@/assets/images/monitoring/btn_active.png');
    background-size: 100% 100%;
  }

  &:hover {
    background-image: url('~@/assets/images/monitoring/btn_active.png');
    background-size: 100% 100%;
  }
}
.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}
.btn-start {
  background: rgba(20, 45, 92, 0.6);
  box-shadow: 1px 1px 2px 0px rgba(3, 16, 50, 0.5);
  border-radius: 6px;
  opacity: 0.6;
  border: 1px solid #43deff;
}
.el-cascader-menu__list {
  max-width:327px;

  background: rgba(0, 3, 36, 0.9);
}

/deep/.el-input__icon.el-icon-arrow-down.is-reverse{
  margin-top: 0px !important;
}
</style>
  
