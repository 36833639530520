import { render, staticRenderFns } from "./detailNest.vue?vue&type=template&id=ed2a3852&scoped=true&"
import script from "./detailNest.vue?vue&type=script&lang=js&"
export * from "./detailNest.vue?vue&type=script&lang=js&"
import style0 from "./detailNest.vue?vue&type=style&index=0&id=ed2a3852&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed2a3852",
  null
  
)

export default component.exports