import API from '@/api'
import { Utils } from '@/lib/cesium'
let show_areas = []

export default {
  async handle_show(item) {
    let cesium_layer = this.g_cesium_layer()
    let viewer = cesium_layer.viewer()
    let positions = item.regionList
    let position = Utils.transformWGS842Cartesian({
      lng: Number(positions[0]),
      lat: Number(positions[1]),
      alt: 10000,
    })
    viewer.camera.flyTo({
      destination: position,
    })
    this.airspace_init_location_polygon(item.regionType, positions, item)
  },
  async handle_hide(item) {
    let cesium_layer = this.g_cesium_layer()
    let viewer = cesium_layer.viewer()
    show_areas.forEach((val,i) => {
      if (val.id == item.id) {
        // console.log(val,"val");
        show_areas.splice(i,1)
        viewer.entities.remove(val)
      }
    })
    //删除
    let positions = item.regionList
    let position = Utils.transformWGS842Cartesian({
      lng: Number(positions[0]),
      lat: Number(positions[1]),
      alt: 100000,
    })
    viewer.camera.flyTo({
      destination: position,
    })
  },
  init_location_polygon(regionType, positions, item) {
    console.log('init_location_polygon', regionType, positions, item);
    let cesium_layer = this.g_cesium_layer()
    let viewer = cesium_layer.viewer()

    if (regionType == 'CIRCLE') {
      let location_polygon = viewer.entities.add({
        position: Cesium.Cartesian3.fromDegrees(positions[0], positions[1]),
        ellipse: {
          semiMajorAxis: positions[2],
          semiMinorAxis: positions[2],
          material: Cesium.Color.fromCssColorString(item.color || 'red').withAlpha(0.3)
            /* item.typeId == 2
              ? Cesium.Color.GREEN.withAlpha(0.5)
              : Cesium.Color.RED.withAlpha(0.5), */
        },
        show: true,
        id: item.id,
      })
      show_areas.push(location_polygon)
    }
    if (regionType == 'POLYGON') {
      let location_polygon = viewer.entities.add({
        polygon: {
          hierarchy: {
            positions: Cesium.Cartesian3.fromDegreesArray(positions),
          },
          outline: true,
          outlineColor: Cesium.Color.WHITE,
          outlineWidth: 2,
          material: Cesium.Color.fromCssColorString(item.color || 'red').withAlpha(0.3)
        },
        show: true,
        id: item.id,
      })
      show_areas.push(location_polygon)
    }
  },
}
