<template>
  <div class="cpt-command-airway-list">
    <div class="header" v-interact>
      <div class="toubu">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="hd-box">详情</div>
      </div>
      <div class="right">
        <div class="hd-box cp mr20" @click="$emit('closeD')">关闭</div>
      </div>
    </div>
    <div class="detail_box">
      <div class="detail_info">
        <div class="head">
          <div class="line"></div>
          <div class="text">基础信息</div>
        </div>
        <div class="infos">
        <div class="info">
          <div class="item">
            <div class="title">所在区域：</div>
            <div class="text">{{detail.address || '暂无'}}</div>
          </div>
          <div class="item">
            <div class="title">日期：</div>
            <div class="text">{{detail.detectionTime || '暂无'}}</div>
          </div>
        </div>
        <div class="info">
          <div class="item">
            <div class="title">单位名称：</div>
            <div class="text">{{detail.point || '暂无'}}</div>
          </div>
          <div class="item">
            <div class="title">设备编号：</div>
            <div class="text">{{detail.deviceSerial || '暂无'}}</div>
          </div>
        </div>
        <div class="info">
          <div class="item">
            <div class="title">鹰眼岗：</div>
            <div class="text">{{detail.nestEye || '暂无'}}</div>
          </div>
          <div class="item">
            <div class="title">检测人员：</div>
            <div class="text">{{detail.inspector || '暂无'}}</div>
          </div>
        </div>
        </div>
      </div>
      <div class="detail_list">
        <div class="head">
          <div class="line"></div>
          <div class="text">无人机检查清单</div>
        </div>
        <div class="list">
          <el-table :cell-style="cellStyle" :data="tableData" style="width: 100%" :span-method="objectSpan">
        <el-table-column prop="project" label="分类" align="center" width="46">
        </el-table-column>
        <el-table-column prop="name" label="项目" align="center" width="160">
        </el-table-column>
        <el-table-column prop="title" label="要求" align="center">
        </el-table-column>
        <el-table-column prop="status" label="判定" align="center" width="200">
          <template slot-scope="scope">
            <div v-if="scope.row.status == 1">正常</div>
            <div v-else>异常</div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center" width="200">
        </el-table-column>
      </el-table>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  props: {
    list: {
      type: Object,
      default: {},
    },
    patrolId:{
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      detail: {},
      tableData:[],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    //表格行颜色
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex % 2 == 1) {
        return "background:#081A3A;";
      } else {
        return "background:rgba(73,135,210,0.2);";
      }
    },
    async getList() {
      let res = await API.DEVICE.getWbDetail(this.patrolId);
      this.detail = res;
      this.detailList(this.detail.inventorys)
    },
    // 检查清单
    detailList(data) {
      let array = [];
      const arr = data.map(item => {
        item.childs[0].inventorys[0].rowNum = item.childs.length;
        const child = item.childs.map(chil => {
          chil.project = item.name;
          const inventorys = chil.inventorys.map(son => {
            chil.project = item.name;
            son.project = chil.project;
            son.name = chil.name;
            array.push(son);
          });
        });
      });
      this.tableData = array;
    },
    objectSpan({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        return {
          rowspan: row.rowNum,
          colspan: 1
        };
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";

.cpt-command-airway-list {
  @import "~@/styles/flightTask.scss";
  padding-bottom: 30px;
  position: absolute;
  left: calc(50% - 500px);
  top: calc(50% - 500px);
  width: 1030px;
  box-sizing: border-box;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: 1132px;
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;

    .toubu {
      display: flex;

      .hd-box {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .right {
      display: flex;

      .hd-box {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #d2dfff;
      }
    }
  }
  .detail_box {
    max-height: 588px;
    overflow: auto;
    padding: 0 16px;
    .detail_info {
      .head {
        margin: 16px 0;
        display: flex;
        align-items: center;
        .line {
          width: 4px;
          height: 16px;
          background: #ffd800;
          border-radius: 2px;
        }
        .text {
          margin-left: 12px;
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .infos{
        display: flex;
        justify-content: space-between;
        .info {
          display: flex;
          flex-direction: column;
          .item {
            white-space: nowrap;
            // margin-right: 96px;
            display: flex;
            align-items: center;
            line-height: 24px;
            .title {
              white-space: nowrap;
              width: 70px;
              text-align: right;
              font-size: 14px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #ffffff;
            }
            .text {
              margin-left: 12px;
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #00dfff;
            }
          }
        }
      }
    }
    .detail_list{
      .head {
        margin: 32px 0;
        display: flex;
        align-items: center;
        .line {
          width: 4px;
          height: 16px;
          background: #ffd800;
          border-radius: 2px;
        }
        .text {
          margin-left: 12px;
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .list{
        /deep/ .el-table {
        // background: transparent;
        background: rgba(8, 26, 58, 0.8);

        .el-table__expanded-cell {
          background: transparent;
        }

        .el-table__body-wrapper {
          // width: 100%;
          // max-height: 600px;
          // overflow-y: auto;
        }

        .el-table__empty-text {
          color: #fff;
        }

        .el-table__expand-icon {
          color: #fff;
        }

        tr {
          background: transparent;
        }

        thead {
          background: rgba(8, 26, 58, 0.8);
          border-radius: 4px;
          border: 1px solid #315ec7;

          .el-table__cell {
            background: transparent;
            font-size: 16px;
            font-family: YouSheBiaoTiHei;
            color: #ebf9ff;
            line-height: 22px;
            letter-spacing: 1px;
          }
        }

        tbody {
          tr {
            color: #fff;
            font-size: 14px;
          }

          tr:hover>td {
            background: transparent !important;
          }
        }
      }
      }
    }
  }
}
</style>
