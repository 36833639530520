<template>
  <div class="cpt-command-airway-lists">
    <div class="header" v-interact>
      <div class="head_left ml16">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="fontO center">{{ "未完成" }}</div>
      </div>

      <div class="close mr20 cp" @click="$emit('close')">关闭</div>
    </div>

    <div class="list-box">
      <div class="tb-box">
        <el-table
          :data="startList"
          max-height="600"
        >
          <el-table-column
            width="150"
            align="center"
            prop="index"
            label="序号"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="orgName"
            label="单位名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="remark"
            label="未完成原因"
          ></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      startList:[]
    };
  },
  mounted() {
    this.startList = this.data
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";
.cpt-command-airway-lists {
  @import "~@/styles/flightTask.scss";
  width: 60%;
  position: absolute;
  left: 400px;
  top: 99px;
  box-sizing: border-box;
  background: rgba(0, 39, 121, 0.8);
  // box-shadow: 0px 2px 8px 0px rgba(1, 162, 255, 0.7),
  //   inset 0px 0px 64px 0px rgba(26, 138, 227, 0.35),
  //   inset 0px 0px 8px 0px #019aff;
  // border: 1px solid #43deff;
  backdrop-filter: blur(2px);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    line-height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;
    .head_left {
      display: flex;
      .fontO {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
    }
  }
  .hd-box {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 700;
    width: auto;
    white-space: nowrap;
  }

  .list-box {
    // width: 1132px;
    background: rgba(8, 26, 58, 0.8);
    // background: rgba(0, 39, 121, 0.5);
  box-shadow: 0px 2px 8px 0px rgba(1, 162, 255, 0.7),
    inset 0px 0px 64px 0px rgba(26, 138, 227, 0.35),
    inset 0px 0px 8px 0px #019aff;
  border: 1px solid #43deff;
  border-top: none;
  border-radius: 0 0 10px 10px;
    height: calc(100% - 56px);
    box-sizing: border-box;
    padding: 16px 16px 0 16px;
    .search-box {
      margin-left: 0 !important;
      // height: 80px;
      height: auto;
      margin: 24px 0 24px 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .item-plan {
        width: 79px;
        height: 32px;
        background: rgba(28, 67, 191, 0.6) !important;
        box-shadow: 0px 2px 4px 0px rgba(23, 33, 60, 0.5),
          inset 0px 0px 16px 0px rgba(33, 137, 255, 0.4),
          inset 0px 0px 4px 0px #00a7ff;
        border: 1px solid;
        border-image: linear-gradient(
            180deg,
            rgba(138, 218, 255, 1),
            rgba(82, 179, 255, 0)
          )
          1 1;
        border-radius: 0px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #43deff;
        line-height: 16px;
      }
      .item-plan:hover {
        opacity: 0.5;
      }
    }
    .tb-box {
      padding: 0 !important;
      margin: 0 0 27px 0;
      /deep/ .el-table {
        border: none;
        background: transparent;
        .el-table__expanded-cell {
          background: transparent;
        }
        .el-table__body-wrapper {
          // width: 100%;
          // max-height: 600px;
          // overflow-y: auto;
          border: none;
        }
        .el-table__empty-text {
          color: #fff;
        }
        .el-table__expand-icon {
          color: #fff;
        }
        tr {
          background: transparent;
        }
        thead {
          margin-top: 10px;
          background: #0c1f3d;
          th{
            border: none;
          }
          .el-table__cell {
            background: transparent;
            font-family: MicrosoftYaHei-Bold;
            font-size: 16px;
            color: #b5e5ff;
            letter-spacing: 0;
            font-weight: 700;
          }
        }
        tbody {
          tr {
            color: #fff;
            font-size: 14px;
            border: none;
            td{
            border: none;
            }
          }
          tr:nth-of-type(even){
            background: #0c1f3d;
          }
          tr:nth-of-type(odd){
            background: rgba(73, 135, 210, 0.2);
          }
          tr:hover > td {
            background: transparent !important;
          }
        }
      }
    }
    .startTime{
      width: 80px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      background: green;
    }
    .overTime{
      width: 80px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      background: red;
    }
  }
}
.el-radio{
  color: #fff;
}
/deep/.el-radio__input.is-checked .el-radio__inner {
    border-color: #409EFF;
    background: #409EFF;
}
</style>
