export default {
  btnList: [
    {
      id: 10,
      icon: "icon-wurenji2",
      componentType: "Server",
      title: "服务器",
    },
    {
      id: 1,
      icon: "icon-wurenji2",
      componentType: "Map",
      title: "无人机",
    },
    {
      id: 2,
      icon: "icon-yingchao2",
      active: "icon8",
      componentType: "Map",
      title: "鹰巢",
    },
    {
      id: 3,
      icon: "icon-jizhan",
      componentType: "Map",
      title: "基站",
    },
    {
      id: 5,
      icon: "icon-guazai",
      componentType: "Mount",
      title: "挂载",
    },
    // {
    //   id: 4,
    //   default: "icon4",
    //   active: "icon10",
    //   componentType:'Map',
    //   title:'停机坪'

    // },

    {
      id: 6,
      icon: "icon-zhengzaichongdian",
      componentType: "Battery",
      title: "电池",
    },
  ],
  select: 10,
};