<template>
  <div class="wrap">
    <el-tree
      :data="layerList"
      :props="defaultProps"
      @node-click="handle_node_click"
    >
			<span class="custom-tree-node" slot-scope="{ node, data }">
        <span>{{ node.label || '暂无' }}</span>
        <span v-if="!data.children">
          <el-button
            type="primary"
            size="mini"
						class="btn-eye"
            :class="{active: checkList.indexOf(data.id) > -1}"
            @click.stop="() => on_show_layer(data)">
          </el-button>
          <!-- <el-button
            type="primary"
            size="mini"
						class="btn-deleted"
            @click.stop="() => remove(node, data)">
          </el-button> -->
        </span>
      </span>
		</el-tree>
  </div>
</template>

<script>
import API from "@/api";
import { layerProvider } from '../../common.js'
import { primitives_3d_tiles } from '@/utils/global'

export default {
  data() {
    return {
			layerList: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      checkList: []
    };
  },
	methods: {
		async get_layer_list() {
      let res = await API.MAPMANAGE.GetLayerList();
			this.layerList = res
      // this.$refs.tree.filter(this.layerList)
		},
		handle_node_click(item) {
      let index = this.checkList.indexOf(item.id)
      if(index > -1) {
        try {
          primitives_3d_tiles[item.id].show = true
          viewer.flyTo(primitives_3d_tiles[item.id])
        } catch (error) {
        }
      }else{
        this.on_show_layer(item)
      }

      // let cesium_layer = this.g_cesium_layer();
      // let viewer = cesium_layer.viewer();

      // if(layerProvider[item.id]) {
      //   let index = this.checkList.indexOf(item.id)
      //   if(index > -1) {
      //     this.checkList.splice(index, 1);
      //   }else{
      //     this.checkList.push(item.id)
      //   }
      //   layerProvider[item.id].show = true
      //   viewer.flyTo(layerProvider[item.id])
      //   return
      // } else {
      //   this.on_show_layer(item)
      // }
		},
		on_show_layer(item) {
      let viewer = window.viewer

      let index = this.checkList.indexOf(item.id)
      if(index > -1) {
        this.checkList.splice(index, 1);
        // layerProvider[item.id].show = false
        primitives_3d_tiles[item.id].show = false
      }else{
        this.checkList.push(item.id)
        
        if(layerProvider[item.id]) {
          layerProvider[item.id].show = true
          viewer.flyTo(layerProvider[item.id])
          return
        }

        let { type, catalogId } = item
        if(catalogId == 3) {
          type = '3dtiles'
        } else if(catalogId == 2) {
          type = 'tms'
        }

        if (type == '3dtiles') {
          this.add_3d_tiles(item)
        } else if (type == 'geojson') {
          this.add_geo_json(item)
        } else if (type == 'tms') {
          this.add_tms(item)
        } else if (type == 'wmts') {
          this.add_wmts(item)
        }
      }
		},
		add_3d_tiles(item) {
      viewer.flyTo(primitives_3d_tiles[item.id])
      try {
        primitives_3d_tiles[item.id].show = true
        viewer.flyTo(primitives_3d_tiles[item.id])
      } catch (error) {
      }

			// let cesium_layer = this.g_cesium_layer();
      // let viewer = cesium_layer.viewer();

      // if (item.url < 6) {
      //   return
      // }
      // let tileset = new Cesium.Cesium3DTileset({
      //   url: item.url,
      //   maximumScreenSpaceError: 2, // The maximum screen space error used to drive level of detail refinement. 值越小细节越好，默认16
      //   maximumMemoryUsage: 2048, // The maximum amount of memory in MB that can be used by the tileset. 显存调整到1G，目前低配的计算机显存差不多这个参数
      //   // skipLevelOfDetail: true
      // })
      // tileset.id = item.id
      // //倾斜摄影添加到球体上
      // viewer.scene.primitives.add(tileset)
      // viewer.flyTo(tileset)
      // layerProvider[item.id] = tileset
    },
		add_geo_json(item) {
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();
      let dataSourceItem = Cesium.GeoJsonDataSource.load(item.url, {
        stroke: Cesium.Color.GOLD,
        fill: Cesium.Color.GOLD.withAlpha(0.5),
        // maximumScreenSpaceError: 16,
        // maximumNumberOfLoadedTiles: 1024,
      })
      dataSourceItem.then(function(dataSource) {
        dataSource.name = item.name
        let dataSources = viewer.dataSources.add(dataSource)
        viewer.flyTo(dataSources)
        dataSource.id = item.id
        layerProvider[item.id] = dataSources
      })
    },
    add_tms(item) {
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();
      let urlTemplateImageryProvider = new Cesium.TileMapServiceImageryProvider({ 
        url: item.url,
      })
      let tmsLayer = viewer.imageryLayers.addImageryProvider(
        urlTemplateImageryProvider
      )
      viewer.flyTo(tmsLayer)
      layerProvider[item.id] = tmsLayer
    },
    add_wmts(item) {
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();
      let image = new Cesium.WebMapTileServiceImageryProvider({
        url: item.url,
        layer: 'nurc:Img_Sample',
        format: 'image/jpeg',
        style: 'raster',
        maximumLevel: 21,
        tileMatrixSetID: 'EPSG:900913',
        // maximumScreenSpaceError: 16,
        // maximumNumberOfLoadedTiles: 1024,
      })
      let wtmsLayer = viewer.imageryLayers.addImageryProvider(image)
      viewer.flyTo(wtmsLayer)
      layerProvider[item.id] = wtmsLayer
    },
    show_test_3dtiles() {
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();

      var tileset = new Cesium.Cesium3DTileset({
        // url: 'http://127.0.0.1:8081/yanchenggaotiezhan/tileset.json',
        url: 'http://127.0.0.1:8081/DAZONGHUv4/tileset.json',
      })
      viewer.scene.primitives.add(tileset)
      viewer.flyTo(tileset)
    }
	},
	mounted() {
		this.get_layer_list()
		// this.show_test_3dtiles()
	},
  inject: ["g_cesium_layer"],
};
</script>

<style lang="scss" scoped>
  .wrap {
    height: 100%;
    overflow-y: scroll;
  }
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
	.btn-eye {
		width: 24px;
		height: 24px;
		// line-height: 28px;
		background: url("~@/assets/images/map-manage/eye-disabled.svg") center no-repeat;
		background-size: 100% 100%;
		border: none;
    &.active {
      background: url("~@/assets/images/map-manage/eye.svg") center no-repeat;
      background-size: 100% 100%;
    }
	}
	.btn-deleted {
		width: 24px;
		height: 24px;
		// line-height: 28px;
		background: url("~@/assets/images/map-manage/deleted.svg") center no-repeat;
		background-size: 80% 80%;
		border: none;
	}
	::v-deep {
		.el-tree {
			color: #E0E1E5;
			background: #161d2d;
		}
		.el-tree-node__content {
			height: 32px;
		}
    .el-tree-node__content:hover,  .el-tree-node__content:focus{
      background-color: #263542; 
    }
    .el-tree-node:focus>.el-tree-node__content {
      background-color: #263542;
    }
	}
</style>