<template>
  <div>
    <video class="bk" autoplay muted loop controls="false" name="media" >
      <source src="~@/assets/new_yingshi/ys_bj.mp4" type="video/mp4">
    </video>
    <index1 v-if="showIndex1" @change="changeIndex" @changePage="type => $emit('changePage', type)" />
    <index2 v-else @change="changeIndex" @changePage="type => $emit('changePage', type)" />
  </div>
</template>

<script>
import index1 from './index1';
import index2 from './index2';

export default {
  name: 'CommandHome',
  components: {
    index1,
    index2,
  },
  data(){
    return {
      showIndex1: true
    }
  },
  methods: {
    changeIndex(){
      this.showIndex1 = !this.showIndex1;
    }
  }
}
</script>

<style lang="scss" scoped>
.bk{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1920px;
  height: 1080px;
}

//所有控件
video::-webkit-media-controls-enclosure{ 
    display: none;
}

.observe-header {
  width: 100%;
  height: 104px;
}
</style>