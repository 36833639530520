<template>
  <div class="cpt-command-task-normal">
    <div class="search-box">
      <div class="btn-add" icon="el-icon-plus" @click="handle_click">
        创建临时紧急任务
      </div>
      <div class="flexs waikuang">
        <el-input
          v-model="search.taskTitle"
          class="input-name"
          placeholder="请输入任务名称"
          clearable
        />
      </div>
      <div class="flexs waikuang">
        <el-autocomplete
          class="inline-input input-name"
          clearable
          v-model="search.userName"
          :fetch-suggestions="querySearch"
          placeholder="请输入创建人"
          @select="handleSelect"
          popper-class="xiala"
        ></el-autocomplete>
      </div>
      <div class="flexs waikuang">
        <el-cascader
          class="input-orgname"
          placeholder="请选择责任单位"
          v-model="search.orgId"
          :options="unitList"
          :show-all-levels="false"
          :props="{
            children: 'children',
            label: 'orgName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
          }"
          clearable
        >
        </el-cascader>
      </div>
      <div class="flexs waikuang">
        <el-select
          v-model="search.taskSituation"
          class="input-excute"
          placeholder="请选择执行状态"
          clearable
        >
          <el-option label="待审批" :value="0"></el-option>
          <el-option label="审批不通过" :value="1"></el-option>
          <el-option label="待执行" :value="2"></el-option>
          <el-option label="执行中" :value="3"></el-option>
          <el-option label="已完成" :value="4"></el-option>
        </el-select>
      </div>
      <div @click="get_list" class="btn1027">搜索</div>
    </div>
    <div class="tb-box">
      <div class="tb-hd-box">
        <div class="tb-hd">任务名称</div>
        <div class="tb-hd">航线名称</div>
        <!-- <div class="tb-hd">任务方式</div> -->
        <div class="tb-hd">任务类型</div>
        <div class="tb-hd">执行状态</div>
        <div class="tb-hd">责任单位</div>
        <div class="tb-hd">飞手</div>
        <div class="tb-hd">预计开始时间</div>
        <div class="tb-hd last-tb-hd">操作</div>
      </div>
      <div v-if="!loading" class="tb-bd-box">
        <template  v-if="list.length>0">
          <div class="tb-tr" v-for="item in list" :key="item.id">
            <!-- 任务名称 -->
            <div class="td">
              {{ item.taskTitle || "暂无" }}
            </div>
            <!-- 航线名称 -->
            <div class="td">
              {{ item.airlineTitle || "暂无" }}
            </div>
            <!-- <div class="td">{{ map.type[item.type] || '暂无' }}</div> -->
            <!-- 任务类型 -->
            <div class="td">{{ item.taskTypeTitle || "暂无" }}</div>
            <!-- 执行状态 -->
            <div class="td" :style="{ color: statusColor[get_status(item)] }">
              {{ get_status(item) }}
            </div>
            <!-- 责任单位 -->
            <div class="td">
              {{ item.anotherName || "暂无" }}
            </div>
            <!-- 飞手 -->
            <div class="td">
              {{ item.team && item.team.length ? item.team.join("、") : "暂无" }}
            </div>
            <!-- 预计开始时间 -->
            <div class="td">{{ item.startedAt || "暂无" }}</div>
            <div class="td last-td">
              <el-tooltip effect="dark" content="审批" placement="top">
                <div
                  @click="handle_show_approve(item)"
                  class="iconfont icon-shenhe"
                  v-if="
                    !isSelf(item.organizationId) &&
                    (isJH ? item.applyStatus == 0 : item.papplyStatus == 0)
                  "
                ></div>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                content="重新提交"
                placement="top"
                v-if="
                  (item.applyStatus == 2 || item.papplyStatus == 2) &&
                  item.userId == user_info.uid
                "
              >
                <div
                  @click="handle_resubmit(item, 7)"
                  class="iconfont icon-zhongxintijiao"
                ></div>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                content="下载"
                placement="top"
                :disabled="item.taskStatus == 1 ? false : true"
              >
                <div
                  class="iconfont icon-a-xiazai2px"
                  @click="handle_upload(item)"
                  :class="{ jinyong: item.taskStatus != 1 }"
                ></div>
              </el-tooltip>
              <el-tooltip effect="dark" content="详情" placement="top">
                <div
                  @click="handle_resubmit(item, 8)"
                  class="iconfont icon-xiangqing"
                ></div>
              </el-tooltip>
              <el-tooltip effect="dark" content="定位" placement="top">
                <div
                  @click="handle_location(item, 3)"
                  class="iconfont icon-dingwei"
                ></div>
              </el-tooltip>
              <el-tooltip effect="dark" content="航线预览" placement="top">
                <div
                  @click="handle_air_line_preview(item, 1)"
                  class="iconfont icon-guiji"
                ></div>
              </el-tooltip>
              <!-- <el-tooltip effect="dark" content="视频" placement="top">
                <div
                :class="
                    item.applyStatus == 1 &&
                    item.papplyStatus == 1 &&
                    item.executeStatus == 1
                      ? ''
                      : 'disable'"
                  @click="handle_video_Player(item)"
                  class="iconfont icon-lishishipin"
                ></div>
              </el-tooltip> -->
              <el-tooltip effect="dark" content="图片" placement="top">
                <div
                :class="
                    item.applyStatus == 1 &&
                    item.papplyStatus == 1 &&
                    item.executeStatus == 1
                      ? ''
                      : 'disable'"
                  @click="handle_image(item)"
                  class="iconfont icon-lishizhaopian"
                ></div>
              </el-tooltip>
              <el-tooltip effect="dark" content="历史轨迹" placement="top">
                <div
                  @click="handle_histoty_track(item)"
                  class="iconfont icon-lishiguiji"
                  :class="
                    item.applyStatus == 1 &&
                    item.papplyStatus == 1 &&
                    item.executeStatus == 1
                      ? ''
                      : 'disable'
                  "
                ></div>
              </el-tooltip>
              <el-tooltip effect="dark" content="删除任务" placement="top">
                <!-- v-show="item.taskStatus != 3" -->
                <div
                  @click="deleteTask(item)"
                  class="iconfont icon-shanchu"
                  :class="{ jinan: item.taskStatus == 3 }"
                ></div>
              </el-tooltip>
            </div>
          </div>
        </template>
        <div v-else-if="!loading && list.length == 0" class="no-data">
          暂无数据
        </div>
      </div>
      <div v-else class="loading-data">
        <div>
          <i class="el-icon-loading"></i>
          <p>加载中...</p>
        </div>
      </div>
    </div>
      <div class="tb-pagination">
        <div class="zongji">
          <span>共</span>
          <span>{{ pagination.totalCount }}</span>
          <span>条</span>
        </div>
        <span class="douhao zongji">,</span>
        <div class="zongji dangqianye">
          <span>{{ pagination.pageNo }}</span>
          <span>/</span>
          <span>{{ zongye(pagination.totalCount) }}</span>
          <span>页</span>
        </div>
        <el-pagination
          small
          :page-sizes="[10, 20, 30, 40]"
          :current-page.sync="pagination.pageNo"
          :page-size="pagination.pageSize"
          layout=" prev, pager, next"
          :total="pagination.totalCount"
          @size-change="handle_size_change"
          @current-change="handle_page_change"
        >
        </el-pagination>
        <div class="zhuan">
          <span>前往</span>
          <input type="text" v-model="qianVal" />
          <span>页</span>
        </div>
        <!-- 不知道page-change指向哪,只能直接拿过来用 -->
        <el-button class="btnqueding" @click="queding">确定</el-button>
      </div>
    <el-dialog
      center
      title="审批"
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="approve_form.visible"
      :append-to-body="true"
      top="20em"
      width="30%"
    >
      <div>
        <div
          class="w20 h20 pa top20 right20 cp"
          @click="approve_form.visible = false"
        >
          <img class="dib wih100 ht100" src="~@/assets/images/close.png" />
        </div>
        <el-form label-width="80px">
          <el-form-item label="备注">
            <el-input v-model="approve_form.approvalRemark" type="textarea" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" style="fotterBtn">
        <div type="danger" @click="handle_approve(0)">不通过</div>
        <div type="primary" @click="handle_approve(1)">通过</div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { downloadData } from "@/utils/download";
import API from "@/api";
export default {
  inject: [
    "flight_task_chage_view",
    "handle_video_Player",
    "handle_air_line_preview",
    "handle_location",
    "handle_image",
    "handle_histoty_track",
    "change_cate_id",
  ],
  computed: {
    ...mapGetters(["user_info"]),
    isJH() {
      return this.user_info && this.user_info.departmentId == 2;
    },
  },
  data() {
    return {
      qianVal: "",
      aivisible: true,
      statusColor: {
        待执行: "#FFBD36",
        执行中: "#00B4FF",
        已完成: "#00D45C",
        待审批: "#FFBD36 ",
        审批不通过: "#F42E2E",
      },
      ws: "",
      curr_task: null,
      approve_form: {
        approvalRemark: null,
        visible: false,
      },
      search: {
        taskTitle: null,
        orgId: null,
        type: null,
        taskSituation: null,
      },
      list: [],
      loading: false,
      unitList: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
      },
      map: {
        type: {
          0: "申报",
          1: "报备",
        },
      },
      timer: null,
      restaurants: [],
    };
  },
  props: {
    numId: {
      type: Number,
    },
  },
  watch: {
    numId: async function (val, old) {
      await this.get_list();
      await this.getUnitList();
      await this.getUsername();
    },
     "$store.state.device.ctls_list"(newData) {
      this.get_list();
    },
  },
  async mounted() {
    await this.get_list();
    await this.getUnitList();
    await this.getUsername();
  },

  destroyed() {
    this.ws && this.ws.close();
    this.ws = null;
  },
  methods: {
    async deleteTask(item) {
      let res = await API.TASK.deleteTaskById(item.id);
      if (res == 1) {
        this.$message.success("操作成功");
        this.get_list();
      }
    },
    //获取创建人
    async getUsername() {
      let res = await API.HOME.getUsername();
      res.forEach((val) => {
        val.value = val.name;
      });
      this.restaurants = res;
    },
    //创建人下拉
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelect(item) {
      // console.log(item,'55565465465456');
    },
    // 手动页码跳转
    queding() {
      this.handle_page_change(this.qianVal);
    },
    // 计算总页码
    zongye(num) {
      let a = num / 10;
      var result = a.toString().indexOf(".");
      if (result != -1) {
        let b = parseInt(a);
        return b + 1;
      } else {
        return a;
      }
    },
    handle_upload(item) {
      if (item.taskStatus == 1) {
        downloadData(
          `/hawksystemserver/export/temporaryDownload/${item.id}?FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}`,
          "常态任务报告"
        );
      }
    },
    handle_ai() {},
    handle_click() {
      this.flight_task_chage_view(6);
      this.change_cate_id(3);
    },
    async handle_resubmit(item, state) {
      let res = await API.TASK.FlightDetail(item.id);
      this.$store.commit("task/FLIGHT_DETAIL", res);
      this.flight_task_chage_view(state);
    },
    isSelf(id) {
      return this.user_info && this.user_info.departmentId == id;
    },
    async getUnitList() {
      let data = await API.DEPARTMENT.Drd();
      this.unitList = [data] || [];
    },
    async get_list() {
      this.loading = true
      let { data, pageNo, pageSize, totalCount } = await API.TASK.List({
        cate: 3,
        taskCateId: 3,
        ...this.pagination,
        ...this.search,
      });
      this.list = data || [];
      // console.log(this.list,"1027临时紧急任务列表")
      this.pagination.pageNo = pageNo;
      this.pagination.pageSize = pageSize;
      this.pagination.totalCount = totalCount;
      this.loading = false
    },
    get_status(item) {
      let val = item;
      let text = "";
      if (val.applyStatus == 1 && val.papplyStatus == 1) {
        text = "审批通过";

        if (val.executeStatus == 0) {
          text = "待执行";
        } else if (val.executeStatus == 1) {
          text = "已完成";
        } else {
          text = "执行中";
        }
      } else if (val.applyStatus == 2 || val.papplyStatus == 2) {
        text = "审批不通过";
      } else {
        text = "待审批";
      }
      return text;
    },
    handle_size_change(size) {
      this.pagination.pageSize = size;
      this.get_list();
    },
    handle_page_change(page) {
      this.pagination.pageNo = page;
      this.get_list();
    },
    handle_show_approve(val) {
      this.$store.commit("situation/SITUATION_IS_SHOW", false);
      this.$store.commit("situation/SITUATION_DETAIL", val);
      this.flight_task_chage_view(9);
      // this.curr_task = val
      // this.approve_form.visible = true
    },
    handle_approve(status) {
      this.$el_confirm(
        "确定提交么？",
        async () => {
          let res = await API.TASK.Edit({
            id: this.curr_task.id,
            taskCateId: this.curr_task.taskCateId,
            status,
            approvalRemark: this.approve_form.approvalRemark,
          });
          this.$el_message("审批成功");
          this.get_list();
          this.approve_form.visible = false;
        },
        () => {
          this.approve_form.approvalRemark = null;
          this.approve_form.visible = false;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-command-task-normal {
  @import "~@/styles/flightTask.scss";
  height: calc(100% - 125px);

  .btn-add {
    cursor: pointer;
  }

  .btn-submit {
    margin-left: 40px;
    cursor: pointer;
  }

  .tb-box {
    padding: 0 !important;
    width: 1796px;
    height: 470px;
    background: rgba(8, 26, 58, 0.8);
    border-radius: 4px;
    border: 1px solid #315ec7;
    .tb-hd-box {
      width: 100%;
      height: 42px;
      background: #0c1f3d;
      .tb-hd {
        height: 100%;
        line-height: 42px;
        width: calc(100% / 8);
        white-space: nowrap;
        font-size: 16px;
        font-family: YouSheBiaoTiHei !important;
        color: #ebf9ff;
        letter-spacing: 1px;
        font-weight: 400;
      }

      .last-tb-hd {
        width: 220px !important;
      }
    }
    .loading-data {
      font-size: 16px;
      width: 1790px;
      height: 245px;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      // top: 0;
      left: 20px;
      text-align: center;
      color: #fff;
      padding-top: 10%;
      // div{
      //   position: absolute;
      //   top: 50%;
      // }
    }
    .tb-bd-box {
      width: 100%;
      height: calc(100% - 42px);
      position: relative;
      .no-data{
       text-align: center;
        color: #fff;
        padding-top: 10%;
      }
      
      .tb-tr:nth-child(odd) {
        background: rgba(73, 135, 210, 0.2) !important;
      }
      .tb-tr:nth-child(even) {
        background: #0c1f3d !important;
      }
      overflow-y: auto;
      .tb-tr {
        .td {
          width: calc(100% / 8);
        }

        .last-td {
          width: 220px !important;

          .iconfont {
            margin-right: 6px;
          }
        }
      }
    }
  }
}

.ai {
  width: 324px;
  position: fixed;
  top: 20%;
  right: 8%;
  padding: 0 8px;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 1px 3px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 24px 0 rgba(0, 184, 255, 0.5);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .head {
    width: 100%;
    height: 44px;
    line-height: 44px;
    padding: 0 8px;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 9px 0 rgba(0, 182, 255, 0.6);
    border-radius: 6px 6px 0 0;
    display: flex;
    justify-content: space-between;

    .title {
      font-family: MicrosoftYaHei-Bold;
      font-size: 18px;
      color: #43deff;
      letter-spacing: 0;
      font-weight: 700;
    }

    .right {
      display: flex;
      font-family: MicrosoftYaHei;
      font-size: 18px;
      color: #43deff;
      letter-spacing: 0;
      text-align: right;
      font-weight: 400;
    }
  }

  .content {
    // display: flex;
    // flex-direction: column;
    div {
      display: flex;

      div {
        margin: 12px;
        width: 80px;
        height: 80px;
        background: rgba(23, 70, 216, 0.2);
        box-shadow: inset 0 0 3px 0 #00a7ff,
          inset 0 0 10px 0 rgba(33, 137, 255, 0.4);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: MicrosoftYaHeiUI;
        font-size: 10px;
        color: #43deff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;

        span {
          color: #43deff;
          font-size: 40px;
          margin-bottom: 4px;
        }
      }
    }
  }
}
// ---------------------------------------------------
.search-box {
  height: auto !important;
  margin: 12px 0 12px 0;
  margin-left: 0 !important;
  box-sizing: border-box;
  padding: 0 0 0 464px;
  .btn-add {
    width: 170px !important;
    height: 32px !important;
    background-image: url("~@/assets/yingkong1027/btn_hei.png") !important;
    background-size: 100% 100% !important;
    box-shadow: none !important;
    font-size: 14px !important;
    font-family: MicrosoftYaHei !important;
    color: #d2d9ff !important;
    line-height: 32px !important;
  }
  .btn-submit {
    width: 64px !important;
    height: 32px !important;
    background-image: url("~@/assets/yingkong1027/btn_lan.png") !important;
    background-size: 100% 100% !important;
    font-size: 14px !important;
    font-family: MicrosoftYaHei !important;
    color: #d2d9ff !important;
    line-height: 32px !important;
    box-shadow: none !important;
    margin-left: 0px !important;
  }
}
.flexs {
  display: flex;
}
.waikuang {
  width: 170px;
  height: 32px;
  background: #02173d;
  border-radius: 2px;
  border: 1px solid #315ec7;
  align-items: center;
  margin: 0 16px 0 0;
}
.xian {
  width: 1px;
  height: 22px;
  border-left: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(67, 222, 255, 0),
      rgba(67, 222, 255, 1),
      rgba(67, 222, 255, 0)
    )
    1 1;
}
// 所有输入框样式
/deep/ .waikuang .input-name .el-input__inner {
  width: 150px !important;
  background: rgba(13, 50, 92, 0) !important;
  border: 0px solid #43deff !important;
  align-items: center !important;
  padding-left: 15px !important;
}
/deep/ .waikuang .el-cascader .el-input__inner {
  width: 150px !important;
  background: rgba(13, 50, 92, 0) !important;
  border: 0px solid #43deff !important;
  align-items: center !important;
  padding-left: 15px !important;
}
/deep/ .waikuang .el-select .el-input__inner {
  width: 150px !important;
  background: rgba(13, 50, 92, 0) !important;
  border: 0px solid #43deff !important;
  align-items: center !important;
  padding-left: 15px !important;
}
// 表格样式
.tb-hd-box {
  height: 38px !important;
  background: rgba(0, 39, 121, 0.2);
  border: 1px solid rgba(207, 234, 255, 0.33);
  .tb-hd {
    // height: 38px !important;
    font-size: 16px !important;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei !important;
    font-weight: bold !important;
    color: #e5f9ff !important;
    line-height: 38px !important;
  }
}
.tb-bd-box {
  height: 430px;
  .tb-tr {
    // height: 38px !important;
    background-image: url("~@/assets/newImage/tiaokaung.png");
    background-size: 100% 100%;
    margin: 4px 0 0 0;
    border: 1px solid transparent;
    cursor: pointer;
    .td {
      overflow: hidden;
    }
    &:hover {
      background: rgba(2, 19, 96, 0.2);
      box-shadow: inset 0px 0px 10px 2px #3fcbff;
      border: 1px solid #70f6f9;
    }
  }
}
.tb-bd-box::-webkit-scrollbar {
  width: 0 !important;
}
// 滚动动画
.animate {
  padding-left: 20px;
  // font-size: 12px;
  // color: #000;
  display: inline-block;
  white-space: nowrap;
  animation: 5s wordsLoop linear infinite normal;
}

@keyframes wordsLoop {
  0% {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
/deep/ .el-tooltip {
  background-color: transparent;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  border: 0px solid;
}

.zongji {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #b5e5ff;
  line-height: 16px;
}
.douhao {
  margin: 0 5px 0 5px;
}
.dangqianye {
  margin: 0 20px 0 0;
}
/deep/.el-pager .number:hover {
  border-radius: 2px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  background: rgba(38, 71, 238, 0.71) !important;
  width: 14px;
  height: 22px;
  line-height: 22px;
}
/deep/ .el-pagination--small .el-pager .number {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #889fb2;
}
/deep/.active {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  background: rgba(38, 71, 238, 0.71) !important;
}
.btnqueding {
  width: 79px !important;
  height: 32px !important;
  box-shadow: none !important;
  background-image: url("~@/assets/yingkong1027/btn_hei.png") !important;
  background-size: 100% 100% !important;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #43deff;
  line-height: 4px;
  margin: 0 0 0 24px;
  &:hover {
    opacity: 0.5 !important;
  }
}
.tb-pagination {
  margin: 0 0 19px 0;
  display: flex;
  justify-content: end;
}
.zhuan {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #889fb2;
  line-height: 16px;
  input {
    width: 60px;
    border-radius: 2px;
    border: 1px solid #b6d4ff;
    background: rgba(12, 13, 20, 0.5);
    border-radius: 2px;
    margin: 0 5px 0 5px;
    color: #fff;
    outline: 0px solid;
  }
}
.tb-pagination {
  margin: 27px 0 0 0;
}
.jinyong {
  cursor: not-allowed !important;
  opacity: 0.5;
}
</style>
<style lang="scss">
.xiala .el-scrollbar {
  background: rgba(0, 3, 36, 0.9) !important;
}
.xiala .el-scrollbar li {
  color: #00aeff !important;
}
.xiala .el-scrollbar ul li:hover {
  background: #34466e !important;
}
.jinan {
  pointer-events: none;
  opacity: 0.7;
}
</style>