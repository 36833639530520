<template>
  <div class="cpt-command-airway-list">
    <div class="header" v-interact>
      <div class="toubu">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="hd-box">详情</div>
      </div>
      <div class="right">
        <div class="hd-box cp mr20" @click="$emit('closede')">关闭</div>
      </div>
    </div>
    <div class="detail_box">
      <div class="detail_info">
        <div class="head">
          <div class="line"></div>
          <div class="text">基础信息</div>
        </div>
        <div class="info">
          <div class="item">
            <div class="title">站点名称：</div>
            <div class="text">{{ detail.address || "暂无" }}</div>
          </div>
          <div class="item">
            <div class="title">巡视时间：</div>
            <div class="text">{{ detail.detectionTime || "暂无" }}</div>
          </div>
          <div class="item">
            <div class="title">巡视人员：</div>
            <div class="text">{{ detail.inspector || "暂无" }}</div>
          </div>
        </div>
      </div>
      <div class="detail_list">
        <div class="head">
          <div class="line"></div>
          <div class="text">基站检查清单</div>
        </div>
        <div class="list">
          <table
            class="tableData"
            v-for="(tableList, index) in tableData"
            :key="index"
          >
            <tr v-if="index == 0">
              <th class="classification">分类</th>
              <th class="project">项目</th>
              <th class="ask">要求</th>
              <th class="status">判定</th>
              <th class="remark">备注</th>
            </tr>
            <tr v-if="index != 0" style="height: 15px"></tr>
            <tr v-for="(item, index) in tableList" :key="index">
              <td
                class="classification"
                v-if="index === 0"
                :rowspan="tableList.length"
              >
                <span>{{ item.project || "暂无" }}</span>
              </td>
              <td class="project">{{ item.name || "暂无" }}</td>
              <td class="ask">{{ item.title || "暂无" }}</td>
              <td>
                <template>
                  <div
                    class="status"
                    v-if="item.status == 1"
                    style="color: rgba(0, 212, 92, 1)"
                  >
                    正常
                  </div>
                  <div
                    class="status"
                    v-else
                    style="color: rgba(244, 46, 46, 1)"
                  >
                    异常
                  </div>
                </template>
              </td>
              <td class="remark">{{ item.remark }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="detail_trouble">
        <div class="head">
          <div class="line"></div>
          <div class="text">存在问题及处理结果</div>
        </div>
        <div class="trobules">
          <div class="trobule" v-for="item in trobule" :key="item.id">
            <div class="info">
              <div class="desc">问题描述：</div>
              <div class="text">{{ item.description || "/" }}</div>
            </div>
            <div class="info">
              <div class="desc">处理方法：</div>
              <div class="text">{{ item.treatment || "/" }}</div>
            </div>
            <div class="info">
              <div class="desc">处理结果：</div>
              <div class="text">{{ item.treatmentResult || "/" }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  props: ["patrolId"],
  data() {
    return {
      detail: {},
      tableData: [],
      trobule: [],
    };
  },

  mounted() {
    this.getList();
  },
  methods: {
    //表格行颜色
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex % 2 == 1) {
        return "background:#081A3A;";
      } else {
        return "background:rgba(73,135,210,0.2);";
      }
    },
    async getList() {
      let res = await API.DEVICE.getWbDetail(this.patrolId);
      this.detail = res;
      this.trobule = this.detail.troubles;
      this.detailList(this.detail.inventorys);
    },
    // 检查清单
    // 检查清单
    detailList(data) {
      let array = [];
      const arr = data.map((item) => {
        const child = item.childs.map((chil) => {
          chil.inventorys[0].rowNum = chil.inventorys.length;
          chil.project = item.name;
          const inventorys = chil.inventorys.map((son) => {
            chil.project = item.name;
            son.project = chil.project;
            son.name = chil.name;
            array.push(son);
          });
        });
      });

      // this.tableData = array;
      // console.log(this.tableData, "this.tableData");
      let proList = array.reduce((acc, task) => {
        let { project } = task;
        // 检查结果数组中是否已经存在相同project的子数组
        let existingArray = acc.find((arr) => arr[0].project === project);
        if (existingArray) {
          existingArray.push(task);
          console.log(existingArray);
        } else {
          // 如果不存在相同project的子数组，则创建一个新的子数组并添加进结果数组中
          acc.push([task]);
        }

        return acc;
      }, []);

      this.tableData = proList;
    },
    objectSpan({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        return {
          rowspan: row.rowNum,
          colspan: 1,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";

.cpt-command-airway-list {
  @import "~@/styles/flightTask.scss";
  padding-bottom: 30px;
  position: absolute;
  left: calc(50% - 500px);
  top: calc(50% - 500px);
  width: 1030px;
  box-sizing: border-box;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: 1132px;
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;

    .toubu {
      display: flex;

      .hd-box {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .right {
      display: flex;

      .hd-box {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #d2dfff;
      }
    }
  }
  .detail_box {
    max-height: 588px;
    overflow: auto;
    padding: 0 16px;
    .detail_info {
      .head {
        margin: 16px 0;
        display: flex;
        align-items: center;
        .line {
          width: 4px;
          height: 16px;
          background: #ffd800;
          border-radius: 2px;
        }
        .text {
          margin-left: 12px;
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .info {
        display: flex;
        flex-wrap: nowrap;
        .item {
          white-space: nowrap;
          margin-right: 196px;
          display: flex;
          align-items: center;
          line-height: 24px;
          .title {
            white-space: nowrap;
            width: 70px;
            text-align: right;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #ffffff;
          }
          .text {
            margin-left: 12px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #00dfff;
          }
        }
      }
    }
    .detail_list {
      .head {
        margin: 32px 0;
        display: flex;
        align-items: center;
        .line {
          width: 4px;
          height: 16px;
          background: #ffd800;
          border-radius: 2px;
        }
        .text {
          margin-left: 12px;
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #ffffff;
        }
      }

      .list {
        color: rgba(235, 249, 255, 1);
        font-size: 14px;
        table {
          width: 100%;
          border-collapse: collapse;
          background-color: #0c2249 70%;
          tr {
            th {
              font-size: 16px;
              font-family: YouSheBiaoTiHei;
              font-weight: normal;
            }
            td {
              line-height: 30px;
              text-align: center;
            }
            &:nth-of-type(even) {
              background-color: rgba(73, 135, 210, 0.2);
            }
            &:nth-of-type(odd) {
              background-color: rgba(9, 30, 65, 0.7);
            }

            .classification {
              width: 46px;
              text-align: center;
              border-right: 6px solid rgba(9, 30, 65, 0.8);;
              span {
                writing-mode: vertical-rl;
                letter-spacing: 0.5em;
              }
            
            }

            .project {
              width: 150px;
              padding: 10px 15px;
              
            }
            .ask {
              width: 400px;
              padding: 0 50px;
              
            }
            .status {
              width: 100px;

            }
          }
        }
      }
    }
    .detail_trouble {
      .head {
        margin: 16px 0;
        display: flex;
        align-items: center;
        .line {
          width: 4px;
          height: 16px;
          background: #ffd800;
          border-radius: 2px;
        }
        .text {
          margin-left: 12px;
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .trobules {
        .trobule {
          display: flex;
          justify-content: space-between;
          .info {
            display: flex;
            .desc {
              font-size: 14px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #ffffff;
            }
            .text {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #00dfff;
            }
          }
        }
      }
    }
  }
}

/deep/ .el-table td.el-table__cell,
/deep/ .el-table th.el-table__cell.is-leaf {
  border: none;
}
</style>
