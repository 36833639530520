<template>
  <div class="cpt-command-device-common">
    <div class="search-box">
      <!-- <el-select v-model="active" class="input-excute">
        <el-option label="电池管理" :value="1"></el-option>
        <el-option label="挂载管理" :value="2"></el-option>
        <el-option label="基站管理" :value="3"></el-option>
      </el-select> -->

      <div class="" style="display: flex;">
          <div class="waikuang" @click="uavBtn(1)">
          <img
            src="~@/assets/images/equipmentImges-isActive/无人机管理.svg"
            class="imgs"
            alt=""
          />
          <span>无人机管理</span>
        </div>
           <div class="waikuang" @click="yingchaoBtn(2)">
          <img
            src="~@/assets/images/equipmentImges-isActive/鹰巢管理.svg"
            class="imgs"
            alt=""
          />
          <span>鹰巢管理</span>
        </div>
         <div
          class="waikuang"
          @click="jizhan"
          :class="{ 'active': active == 3 }"
        >
        <img v-if="active == 3"
            src="~@/assets/images/equipmentImges/基站管理.png"
            class="imgs-active"
            alt=""
          />
          <img v-else
            src="~@/assets/images/equipmentImges-isActive/基站管理.svg"
            class="imgs"
            alt=""
          />
          <span>基站管理</span>
        </div>
        <div
          class="waikuang"
          @click="guazai"
          :class="{ 'active': active == 2 }"
        >
        <img v-if="active == 2"
            src="~@/assets/images/equipmentImges/挂载管理.png"
            class="imgs-active"
            alt=""
          />
          <img v-else
            src="~@/assets/images/equipmentImges-isActive/挂载管理.svg"
            class="imgs"
            alt=""
          />
          <span>挂载管理</span>
        </div>
        <div
          class="waikuang"
          @click="dianchi"
          :class="{ 'active': active == 1 }"
        >
          <img v-if="active == 1"
            src="~@/assets/images/equipmentImges/电池管理.png"
            class="imgs-active"
            alt=""
          />
          <img v-else
            src="~@/assets/images/equipmentImges-isActive/电池管理.svg"
            class="imgs"
            alt=""
          />
          <span>电池管理</span>
        </div>
        
       
      
     
        <div class="waikuang" @click="addDevice">
          <span>注册设备</span>
        </div>
      </div>
      <div class="search-box-right" v-show="!(active == 3)">
        <el-cascader
          v-model="search.organId"
          :options="options"
          :show-all-levels="false"
          clearable
          placeholder="请选择单位"
          :props="{
            children: 'children',
            label: 'orgName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
          }"
        ></el-cascader>
        <el-dropdown>
          <!-- <div class="iconfont icon-baobei"></div> -->
          <div class="baobei flex justify-between item-center">
            <span>{{ btnName == "" ? "请选择报备类型" : btnName }}</span>
            <span class="el-icon-arrow-down el-icon--right"></span>
          </div>
          <el-dropdown-menu slot="dropdown" class="el-dropdown-menu-special">
            <el-dropdown-item v-for="item in dropdownList" :key="item.id">
              <span @click="handle_click(item.id, item.name)">
                {{ item.name }}
              </span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-date-picker
          v-model="pickTime"
          @change="handle_change"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          popper-class="device-common"
        >
        </el-date-picker>
      </div>
    </div>
    <Battery
      ref="battery"
      :search="search"
      v-show="active == 1"
      @getDetail="getDetail"
    />
    <Mount
      ref="mount"
      :search="search"
      v-show="active == 2"
      @getDetail="getDetail"
    />
    <BaseStation ref="basestation" v-show="active == 3" @getwb="getwb" @getDetail="getDetail" />
    <Device
      v-if="deviceVisible"
      @closedevice="shuaxin"
      :active="active"
      :form="form"
    />
    <WbList v-if="wbShow" :list="wbData" @close="wbShow = false" @detail="handleDetail"/>
    <WbDetail v-if="deShow" :patrolId="patrolId" @closede="closede"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import API from "@/api";
import Battery from "./battery";
import Mount from "./mount";
import BaseStation from "./base-station";
import Device from "@/components/header-right/deviceadd.vue";
import WbList from "../main/list"
import WbDetail from "../main/detail"

export default {
  components: {
    Battery,
    Mount,
    BaseStation,
    Device,
    WbList,
    WbDetail,
  },
  data() {
    return {
      roleId: JSON.parse(localStorage.getItem("user_info")).data.roles[0]
        .roleId,
      deviceVisible: false,
      dropdownList: [
        { name: "已报备", id: 1 },
        { name: "未报备", id: 0 },
        { name: "显示全部", id: 2 },
      ],
      active: 1,
      options: [],
      pickTime: null,
      search: {
        pageSize: 10000,
        organId: null,
        startDate: null,
        endDate: null,
        isReporting: null,
      },
      dian: "active",
      gua: "active",
      ji: "active",
      btnName: "",
      form: null,
      wbData: null,
      wbShow: false,
      deShow: false,
      patrolId: null,
    };
  },
  async mounted() {
    this.search.organId = this.user_info.departmentId;
    await this.getOptions();
    this.uavBtn(1)
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  watch: {
    active() {
      this.search.organId = null;
      this.search.startDate = null;
      this.search.endDate = null;
      this.search.isReporting = null;
    },
  },
  methods: {
    handleDetail(id){
      this.patrolId = id
      this.wbShow = false
      this.deShow = true
},
closede(){
  this.wbShow = true
  this.deShow = false
},
    dianchi() {
      this.active = 1;
    },
    guazai() {
      this.active = 2;
    },
    jizhan() {
      this.active = 3;
    },
    uavBtn(num) {
      this.active = 4;
      this.$parent.uav_zhuangtai(num);
    },
    yingchaoBtn(num) {
      this.active = 5;
      this.$parent.uav_zhuangtai(num);
    },
    handle_click(e, item) {
      this.btnName = item;
      if (e == 2) {
        this.search.isReporting = null;
      } else {
        this.search.isReporting = e;
      }
    },
    addDevice() {
      const permissions = this.$store.state.user.user_info.permissions;
      const permissionList = permissions.map((item) => item.permissionSign);
      if (this.active == 1) {
        if (permissionList.indexOf("equipment:flight:battery:add") == -1) {
          this.$message.error("暂无权限操作");
        } else {
          this.deviceVisible = true;
        }
      }
      if (this.active == 2) {
        if (permissionList.indexOf("equipment:flight:mount:add") == -1) {
          this.$message.error("暂无权限操作");
        } else {
          this.deviceVisible = true;
        }
      }
      if (this.active == 3) {
        if (permissionList.indexOf("equipment:flight:baseStation:add") == -1) {
          this.$message.error("暂无权限操作");
        } else {
          this.deviceVisible = true;
        }
      }
      if (this.active == 4) {
        if (permissionList.indexOf("equipment:flight:uav:add") == -1) {
          this.$message.error("暂无权限操作");
        } else {
          this.deviceVisible = true;
        }
      }
      if (this.active == 5) {
        if (permissionList.indexOf("equipment:flight:nest:add") == -1) {
          this.$message.error("暂无权限操作");
        } else {
          this.deviceVisible = true;
        }
      }
    },
    shuaxin() {
      this.deviceVisible = false;
      this.form = null;
      if (this.active == 1) {
        this.$refs.battery.getBatteryList();
      } else if (this.active == 2) {
        this.$refs.mount.getMountList();
      } else if (this.active == 3) {
        this.$refs.basestation.getBaseStationList();
      }
    },
    getwb(data){
      this.wbShow = true
      this.wbData = data
    },
    getDetail(val) {
      this.form = val;
      this.deviceVisible = true;
    },
    async handle_change(e) {
      if (e?.length) {
        this.search.startDate = e[0];
        this.search.endDate = e[1];
      } else {
        this.search.startDate = null;
        this.search.endDate = null;
      }
    },
    async getOptions() {
      let data = await API.DEPARTMENT.Drd();
      this.options = [data] || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-command-device-common ::v-deep{
  width: 100%;
  box-sizing: border-box;
  height: 100%;

  .tb-box .tb-bd-box {
    height: calc(100vh - 576px);
    overflow: auto;
  }

  .search-box {
    display: flex;
    margin-bottom: 12px;
    justify-content: space-between;
    .search-box-right {
      display: flex;
      // justify-content: space-around;
      // width: 300px;
    }
    .iconfont {
      color: #00aeff;
      font-size: 24px;
      line-height: 40px;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }

  .tb-box .tb-bd-box .tb-tr .iconfont {
    color: #fff !important;
    font-size: 20px;

    &:hover {
      color: RGBA(0, 231, 255, 1) !important;
    }
  }
}
.device-common {
  border: none;
  .el-picker-panel__body-wrapper {
    background: #0d224f;
    border: 1px solid #00aeff;
    color: #fff;
    .el-picker-panel__content {
      border: 1px solid #00aeff;
    }
    .el-date-table th {
      border-bottom: solid 1px #00aeff;
      color: #fff;
    }
    .el-date-table td.in-range div {
      background-color: #00aeff;
    }
    .el-picker-panel__icon-btn {
      color: #fff;
    }
  }
}
.flex {
  display: flex;
}
.waikuang {
  height: 32px;
  border: 1px rgba(49, 94, 199, 1) solid;
  border-radius: 3px;
  background-color: rgba(2, 23, 61, 1);
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #D2D9FF;
  cursor: pointer;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;

  &.active{
    background-image: url('~@/assets/images/monitoring/btn_active.png');
    background-size: 100% 100%;
  }

  &:hover {
    background-image: url('~@/assets/images/monitoring/btn_active.png');
    background-size: 100% 100%;
  }
}
.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}
.btn-start {
  background: rgba(20, 45, 92, 0.6);
  box-shadow: 1px 1px 2px 0px rgba(3, 16, 50, 0.5),
    inset 0px 0px 20px 0px #43deff;
  border-radius: 6px;
  border: 1px solid #43deff;
  font-family: MicrosoftYaHei;
  color: #43deff;
  opacity: 1;
}
.xian {
  width: 100%;
  height: 1px;
  background: linear-gradient(
    207deg,
    rgba(36, 146, 252, 0) 0%,
    #2492fc 53%,
    rgba(36, 146, 252, 0) 100%
  );
  margin: 16px 0 0 0;
}
.distance-between {
  padding: 16px 24px 0 24px;
}
/deep/ .search-box-right .el-date-editor {
  width: 354px !important;
  height: 32px;
  cursor: pointer;
  background: #02173D;
  border-radius: 2px;
  border: 1px solid #315EC7;

  ::-webkit-input-placeholder {
    font-family: MicrosoftYaHeiUI !important;
    color: #00aeff !important;
  }
  .el-range-separator {
    font-family: MicrosoftYaHeiUI !important;
    line-height: 24px;
    color: RGBA(121, 132, 152, 1) !important;
  }
  .el-range__icon {
    line-height: 24px;
    color: RGBA(121, 132, 152, 1) !important;
  }

  .el-range__close-icon{
    width: 0;
  }

  .el-range-input{
    width: 43%;
    background: rgba(0, 0, 0, 0);

    &::placeholder{
      color: RGBA(121, 132, 152, 1) !important;
    }
    
  }
}

/deep/ .search-box-right .el-input--suffix {
  width: 170px;
  height: 32px;
  border-radius: 2px;
  
  .el-input__inner {
    background-color: #02173D;
    border: 1px solid #315EC7;
    color: RGBA(121, 132, 152, 1);

    &::placeholder {
      color: RGBA(121, 132, 152, 1) !important;
    }
  }
  .el-input__icon {
    color: #00aeff !important;
  }
  ::-webkit-input-placeholder {
    font-family: MicrosoftYaHeiUI !important;
    color: #00aeff !important;
  }
}
.el-cascader ::v-deep {
  width: auto;
  height: 32px;
  transform: translate(0, -4px);

  input {
    height: 32px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: RGBA(121, 132, 152, 1);
  }
}


.baobei {
  width: 170px;
  height: 32px;
  background: #02173D;
  border-radius: 2px;
  border: 1px solid #315EC7;
  box-sizing: border-box;
  padding-left: 12px;
  color: RGBA(121, 132, 152, 1);
  margin-right: 14px;
  :last-child {
    margin: 0 13px 0 0;
    line-height: 40px !important;
  }
}
.item-center {
  align-items: center;
}
/deep/ .tb-tr:hover {
  // height: 56px;
  background: rgba(20, 45, 92, 0.6);
  box-shadow: inset 0px 0px 20px 0px rgba(0, 182, 255, 0.7);
  align-items: center !important;
  .td {
    font-family: MicrosoftYaHei !important;
    color: #00c6ff !important;
  }
  .yijian_baobei {
    background: #1ca4c3;
  }
}
/deep/ .tb-tr {
  align-items: center !important;
}
/deep/ .tb-box {
  background: rgba(15, 11, 62, 0.5) !important;
}
.imgs-active{
  width: 24px;
  height: 24px;
}
.imgs {
  width: 18px;
  height: 18px;
  margin-right: 3px;
  margin-left: 3px;
}
.waikuang:hover {
 
}
/deep/ .el-input__inner {
  color: #00aeff;
  border-radius: 0;
}
/deep/ .el-range-input {
  color: #00aeff !important;
}

.el-dropdown-menu-special{
  background: #0d224f;
  border: 1px solid #178890;
}
</style>