<template>
  <div class="page-command-home">
    <BoxSidebar direction="left">
      <PlateItem title="图层管理">
        <el-tabs v-model="activeName" class="tabs">
          <el-tab-pane label="区域图层" name="first">
            <LayerManage />
          </el-tab-pane>
          <el-tab-pane label="标注管理" name="second">
            <Labels />
          </el-tab-pane>
          <el-tab-pane label="标签管理" name="third">
            <Tags />
          </el-tab-pane>
        </el-tabs>
      </PlateItem>
    </BoxSidebar>
  </div>
</template>

<script>

import BoxSidebar from "@/components/box-sidebar";
import PlateItem from "@/components/box-sidebar-item";
import LayerManage from "../layer-manage"
import Labels from "../labels"
import Tags from "../tags"

export default {
  components: {
    BoxSidebar,
    LayerManage,
    Labels,
    Tags,
    PlateItem
  },
  data() {
    return {
      activeName: 'first',
    };
  }
};
</script>

<style lang="scss" scoped>
.tabs {
  width: 100%;
  padding-top: 20px;
  color: #E0E1E5;
  height: calc(100% - 50px);
  overflow: hidden;
}
::v-deep {
  .el-tabs__nav{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .el-tabs__nav-scroll {
    background: #242E43;
    padding: 0 20px;
  }
  .el-tabs__item {
    font-size: 16px;
    color: #E0E1E5;
    text-align: center;
  }
  .el-tabs__item.is-active {
    color: #00FFFF;
  }
  .el-tabs__nav-wrap::after {
    display: none;
  }
  .el-tabs__active-bar {
    display: none;
  }
  .el-tabs__content {
    height: calc(100% - 50px);
    padding: 0 5px;
  }
  .el-tab-pane {
    height: 100%;
  }
} 
</style>