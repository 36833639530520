<template>
  <div class="page-command-flight-task">
    <!-- 飞行任务 -->
    <FlightTaskList
      @returnEX="(e)=>$emit('returnEX',e)"
      v-show="visible == 1"
      @handleLocation="flightTask_handle_position"
      @changeActive="(e)=>$emit('changeActive',e)"
      :active="active"
      :num="visible"
      :examineData="examineData"
    />
    <!-- 派发 -->
    <SituationEdit
      v-if="visible == 2"
      @removeEntities ="flightTask_removeEntities"
      @handleLocation="flightTask_handle_position_v2"
      ref="situationEdit"
    />
    <!-- 资源调度 -->
    <SituationResource
      v-if="visible == 2 && isShow == true"
      @handleLocation="flightTask_handle_position"
      @acceptSelect="accept_select"
    />
    <SituationDetail @close='SituationDetailClose'  v-if="visible == 3" />
    <VideoMedia
      v-if="visible == 1 && mediaIsShow"
      :historyVideo="flightTask_video"
      @changeMediaIsShow="mediaIsShow = false"
    />
    <ImageMedia v-if="visible == 5" :historyImage="flightTask_imageList" />
    <TaskCreate :type="state" v-if="visible == 6" ref="taskCreate" />
    <TaskResubmit v-if="visible == 7" />

    <TaskDetail v-if="visible == 8" />
    <CheckPopup v-if="visible == 9" />

    <Distribution     @handleLocation="flightTask_handle_position" v-if="visible == 11" />
    <DetailEdit @close='SituationDetailClose' v-if="visible == 12" />
  </div>
</template>

<script>
// 飞行任务1027
import FlightTaskList from "@/components/command/flight-task";

import SituationEdit from "@/components/command/flight-task/situation/edit";
import SituationResource from "@/components/command/flight-task/situation/resource";
import SituationDetail from "@/components/command/flight-task/situation/detail";
import VideoMedia from "@/components/command/flight-task/video-media";
import ImageMedia from "@/components/command/flight-task/image-media";
import TaskCreate from "@/components/command/flight-task/create";
import TaskResubmit from "@/components/command/flight-task/resubmit";
import TaskDetail from "@/components/command/flight-task/detail";
import CheckPopup from "@/components/command/flight-task/check-popup";
import Distribution from "@/components/command/flight-task/Distribution";
import DetailEdit from "@/components/command/flight-task/detailEdit";

import data from "./data";
import methods from "./methods";
export default {
  components: {
    FlightTaskList,
    SituationEdit,
    SituationResource,
    SituationDetail,
    VideoMedia,
    ImageMedia,
    TaskCreate,
    TaskResubmit,
    TaskDetail,
    CheckPopup,
    Distribution,
    DetailEdit,
  },
  props: {
    active: {
      type: Number,
      default: () => 2,
    },
    visible: {
      type: Number,
      default: () => 1,
    },
    examineData:{
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ...data,
      isShow: false,
      mediaIsShow: false,
      state: null,
    };
  },
  provide() {
    return {
      flight_task_chage_view: this.chage_view,
      flight_jqtask_chage_view:this.SituationDetailClose,
      handle_video_Player: this.flightTask_handleVideoPlayer,
      handle_image: this.flightTask_handleImage,
      handle_air_line_preview: this.flightTask_handleAirLinePreview,
      handle_location: this.flightTask_handleLocation,
      handle_histoty_track: this.flightTask_handleHistotyTrack,
      change_cate_id: this.change_cate_id,
      change_media_is_show: this.change_media_is_show,
    };
  },
  inject: ["g_cesium_toolbar", "g_cesium_layer"],
  watch: {
    examineData(val){
      // console.log(val,"page");
    },
    "$store.state.device.jq_list"(newData) {
      this.active = 1;
    },
    "visible"(num){
    this.visibleSTatus()
    }

  },
  mounted(){
this.visibleSTatus()
  },
  beforeDestroy(){
     this.$store.commit("device/SET_WARNING_NOTICE", true);
  },
  methods: {
    ...methods,
    //回到警情任务列表
    SituationDetailClose(num){
      if(num==1){
      this.visible = 1;
      this.active = 1;
      }else{
      this.visible = 1;
        this.active = num
      }
    },
    chage_view(item, isShow, state) {
      console.log(item, isShow, state,'item, isShow, state');
      if (state) {
        this.isShow = isShow;
        if (isShow === false && this.$refs.situationEdit) {
          this.$refs.situationEdit.checked2 = false;
        }
      } else {
          this.visible = item;
        if(item==1){
          this.visible = 1;
      // this.active = 2;
      this.$parent.active = 2
        }else if(item == 10){
          this.visible = 1;
        this.$parent.active = 3
        }
      }
    this.visibleSTatus()
    },
    visibleSTatus(){
    if(this.visible==1){
     this.$store.commit("device/SET_WARNING_NOTICE", true);
      }else if(this.visible == 2){
     this.$store.commit("device/SET_WARNING_NOTICE", false);
      }
      console.log('visibleSTatus',this.visible);
    },
    change_cate_id(num) {
      let _this = this;
      this.state = num;
      this.$nextTick(() => {
        if (_this.$refs.taskCreate) {
          _this.$refs.taskCreate.form.cateId = num;
        }
      });
    },
    change_media_is_show(status) {
      this.mediaIsShow = status;
    },
    accept_select(arr, resourceName) {
      if (this.$refs.situationEdit) {
        this.$refs.situationEdit.acceptSelectFn(arr, resourceName);
      }
    },
  },
};
</script>
