<template>
  <div :id="id" class="box">
    <div class="pine"></div>
    <div class="box-wrap">
      <div class="tc cf mt20 wrapTitle">{{ id }}</div>
      <div class="close" @click="closeClick">X</div>

      <div class="content">
        <div class="data-li">
          <div class="data-value">
            <span class="label-num yellowColor">{{ state }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- <img src="./layer_border.png" alt="Norway"> -->
  </div>
</template>

<script>
export default {
  name: "DynamicLabel",
  data() {
    return {
      show: true,
    };
  },
  props: {
    title: {
      type: String,
      default: "标题",
    },
    id: {
      type: String,
      default: "001",
    },
    state: {
      type: String,
      default: "001",
    },
  },
  methods: {
    closeClick() {
      if (this.closeEvent) {
        this.closeEvent();
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.wrapTitle {
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  color: #00ffff;
  letter-spacing: 0;
  font-weight: 700;
  animation: fontColor 1s;
}
.box {
  width: 200px;
  position: relative;
  z-index: 20;
  bottom: 0;
  left: 0;
}
.close {
  position: absolute;
  color: #fff;
  top: 1px;
  right: 10px;
  text-shadow: 2px 2px 2px #022122;
  cursor: pointer;
}
.box-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(22, 29, 45, 0.9);
  border: 1px solid #009fff;
  box-shadow: inset 0 0 7px 0 #008fff;
}
.box-wrap .area {
  position: absolute;
  top: 20px;
  right: 0;
  width: 95%;
  height: 30px;
  background: rgba(22, 29, 45, 0.9);
  border-radius: 30px 0px 0px 0px;
}
.data-value {
  max-width: 190px;
}
.pine {
  position: absolute;
  // left: 0;
  // bottom: -83px;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  line-height: 120px;
  text-indent: 5px;
}

.pine::before {
  // content: "";
  // position: absolute;
  // left: -12px;
  // bottom: -116px;
  // width: 16%;
  // height: 60px;
  // box-sizing: border-box;
  // border-bottom: 1px solid #002520d2;
  // transform-origin: bottom center;
  // transform: rotateZ(135deg) scale(1.5);
  // animation: slash 0.5s;
  // filter: drop-shadow(1px 0px 2px #002520d2);
  /* transition: slash 2s; */
}

.area .area-title {
  text-align: center;
  line-height: 30px;
}
.textColor {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  text-shadow: 1px 1px 5px #002520d2;
}
.yellowColor {
  font-size: 14px;
  font-weight: 600;
  table-layout: fixed;
  word-wrap: break-word;
  overflow: hidden;
  color: #fff;
  animation: fontTitle 1s;
}

.fontColor {
  font-size: 16px;
  font-weight: 800;
  color: #ffffff;
  text-shadow: 1px 1px 5px #002520d2;
}
.content {
  // padding: 55px 10px 10px 10px;
  min-height: 60px;
  text-indent: 1em;
}
.content .data-li {
  display: flex;
  justify-items: start;
}

@keyframes fontColor {
  0% {
    color: #ffffff00;
    text-shadow: 1px 1px 5px #00252000;
  }
  40% {
    color: #ffffff00;
    text-shadow: 1px 1px 5px #00252000;
  }
  100% {
    text-shadow: 1px 1px 5px #002520d2;
  }
}
@keyframes fontTitle {
  0% {
    color: #ffffff00;
    text-shadow: 1px 1px 5px #00252000;
  }
  40% {
    color: #ffffff00;
    text-shadow: 1px 1px 5px #00252000;
  }
  100% {
    color: #ffffff;
    text-shadow: 1px 1px 5px #002520d2;
  }
}
@keyframes slide {
  0% {
    border: 1px solid #38e1ff00;
    background-color: #38e1ff00;
    box-shadow: 0 0 10px 2px #29baf100;
  }

  100% {
    border: 1px solid #38e1ff00;
    background-color: #38e1ff00;
    box-shadow: 0 0 10px 2px #29baf100;
  }
}
@keyframes area {
  0% {
    width: 0%;
  }
  25% {
    width: 0%;
  }

  100% {
    width: 95%;
  }
}

/* img{
            position:absolute;
            left:30%;
            top:0;
            width: 100%;
            box-shadow: 0 0 10px 2px #29baf1;
        } */

@keyframes slash {
  0% {
    transform: rotateZ(135deg) scale(0);
  }

  100% {
    transform: rotateZ(135deg) scale(1.5);
  }
}
</style>
