<!-- 报表导出 -->
<template>
  <div class="page-command-person">
    <TableExport />
  </div>
</template>

<script>
import data from "./data";
import methods from "./methods";
import TableExport from "@/components/command/TableExport";

export default {
  data() {
    return data;
  },
  methods: {
    ...methods,
  },
  components: { TableExport },
};
</script>

<style lang="scss" scoped></style>
