<template>
  <div class="page-command-flight-task">
    <!-- 勤务考核 -->
    <ExamineList :examineActive="examineActive" @jumpRoute="(e)=>$emit('jumpRoute',e)"/>
  </div>
</template>

<script>
import ExamineList from "@/components/command/examine";

import data from "./data";
import methods from "./methods";
export default {
  components: {
    ExamineList,
  },
  props: {
    examineActive:{
      type: Number,
      default: () => 1
    }
  },
  data() {
    return {
      ...data,
    };
  },
  provide() {},
  inject: ["g_cesium_toolbar", "g_cesium_layer"],
  watch: {},
  mounted(){},
  methods: {},
};
</script>
