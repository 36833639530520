<!-- 警情任务1027 -->
<template>
  <div class="cpt-command-task-situation">
    <div class="search-box">
      <div class="flexs waikuang">
        <el-input
          v-model="search.jingqing_title"
          class="input-name"
          placeholder="请输入警情事件"
          clearable
        />
      </div>
      <div class="flexs waikuang">
        <el-cascader
          class="input-orgnameMini"
          placeholder="请选择责任单位"
          v-model="search.orgId"
          :options="unitList"
          :show-all-levels="false"
          :props="{
            children: 'children',
            label: 'orgName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
          }"
          clearable
        ></el-cascader>
      </div>
      <div class="flexs waikuang">
        <!-- <img src="@/assets/newImage/lanquan.png" alt="" />
        <div class="xian"></div>-->
        <el-select
          v-model="search.status"
          class="input-excute"
          placeholder="请选择执行状态"
          :popper-append-to-body="false"
          clearable
        >
          <el-option label="已签收" :value="5"></el-option>
          <el-option label="已派发" :value="6"></el-option>
          <el-option label="拒收" :value="7"></el-option>
          <el-option label="未签收" :value="8"></el-option>
          <el-option label="待派发" :value="-1"></el-option>
          <el-option label="待审批" :value="0"></el-option>
          <el-option label="审批不通过" :value="1"></el-option>
          <el-option label="待执行" :value="2"></el-option>
          <el-option label="执行中" :value="3"></el-option>
          <el-option label="已完成" :value="4"></el-option>
          <el-option label="无需派发" :value="9"></el-option>
        </el-select>
      </div>
      <div @click="get_list(1)" class="btn1027">搜索</div>
      <div class="btn1027" @click="returnEX" v-if="examineData.active ==1">返回</div>
    </div>
    <div class="tb-box">
      <div class="tb-hd-box">
        <div class="tb-hd-triangle"></div>
        <div class="tb-hd">事件内容</div>
        <div class="tb-hd">警情类型</div>
        <div class="tb-hd">任务状态</div>
        <div class="tb-hd">响应状态</div>

        <div class="tb-hd">责任单位</div>
        <div class="tb-hd w150">联系人</div>
        <div class="tb-hd">报警时间</div>
        <div class="tb-hd">推送时间</div>

        <div class="tb-hd">派发时间</div>

        <div class="tb-hd last-tb-hd">操作</div>
        <!-- -->
      </div>
      <div v-if="!loading" class="tb-bd-box">
        <template v-if="list.length > 0">
          <div v-for="(item, index) in list" :key="index" class="hangse">
            <div class="tb-tr">
              <div class="td">{{ item.name || "暂无" }}</div>
              <div class="td">{{ item.lhlbmc || "暂无" }}</div>
              <div class="td" :style="{ color: statusColor[get_status(item)] }">
                {{ get_status(item) }}
              </div>
              <div class="td">
                {{ get_responseStatus(item.responseStatus) }}
              </div>
              <div class="td">{{ item.anotherName || "暂无" }}</div>
              <div class="td w150">
                {{ item.policeUser || item.policeLeader || "暂无" }}
              </div>
              <div class="td">{{ item.bjdhsj || "暂无" }}</div>
              <div class="td">{{ item.addtime || "暂无" }}</div>

              <div class="td">{{ item.dispatchTime || "暂无" }}</div>

              <div class="td jcsb last-td">
                <el-tooltip
                  v-if="isJH && item.applyStatus == 0"
                  effect="dark"
                  content="审批"
                  placement="top"
                >
                  <div
                    @click="handle_show_approve(item)"
                    class="iconfont icon-shenhe"
                  ></div>
                </el-tooltip>
                <el-tooltip
                  effect="dark"
                  content="派发"
                  placement="top"
                  v-if="
                    item.applyStatus != 1 &&
                    item.taskSituation == -1 &&
                    policeSituation
                  "
                >
                  <div
                    class="iconfont icon-a-bianji2px"
                    @click="Distribution(item, 2)"
                  ></div>
                </el-tooltip>
                <el-tooltip
                  effect="dark"
                  content="航线规划"
                  placement="top"
                  v-if="
                    item.taskSituation == 5 && user_info.orgCode == item.orgCode
                  "
                >
                  <div
                    class="iconfont icon-lishiguiji"
                    @click="handle_edit(item, 11)"
                  ></div>
                </el-tooltip>
                <!-- <el-tooltip effect="dark" content="重新提交" placement="top">
                  <div @click="handle_resubmit(item, 2)" class="iconfont icon-zhongxintijiao" v-if="
                    (item.applyStatus == 2 || item.papplyStatus == 2) &&
                    item.userId == user_info.uid
                  "></div>
                </el-tooltip>-->
                <!--   <el-tooltip
                  effect="dark"
                  content="下载"
                  placement="top"
                  v-if="item.taskStatus == 1"
                >
                  <div
                    class="iconfont icon-a-xiazai2px"
                    @click="handle_upload(item)"
                  ></div>
                </el-tooltip>
                -->

                <el-tooltip effect="dark" content="详情" placement="top">
                  <div
                    @click="Distribution(item, 3)"
                    class="iconfont icon-xiangqing"
                  ></div>
                </el-tooltip>
                <el-tooltip effect="dark" content="定位" placement="top">
                  <div
                    @click="() => $emit('handleLocation', item)"
                    class="iconfont icon-dingwei"
                  ></div>
                </el-tooltip>
                <el-tooltip effect="dark" content="备注" placement="top">
                  <div
                    class="iconfont icon-bianji"
                    @click="Distribution(item, 12)"
                  ></div>
                </el-tooltip>
                <!-- <el-tooltip effect="dark" content="航线预览" placement="top">
                  <div
                    @click="handle_air_line_preview(item)"
                    class="iconfont icon-guiji"
                  ></div>
                </el-tooltip>-->
                <!-- <el-tooltip effect="dark" content="视频" placement="top">
                  <div
                    :class="item.taskSituation == 4 ? '' : 'disable'"
                    @click="handle_video_Player(item, 1)"
                    class="iconfont icon-lishishipin"
                  ></div>
                </el-tooltip>-->
                <!-- <el-tooltip effect="dark" content="图片" placement="top">
                  <div
                    :class="item.taskSituation == 4 ? '' : 'disable'"
                    @click="handle_image(item, 1)"
                    class="iconfont icon-lishizhaopian"
                  ></div>
                </el-tooltip>
                <el-tooltip effect="dark" content="轨迹" placement="top">
                  <div
                    @click="handle_histoty_track(item, 1)"
                    class="iconfont icon-lishiguiji"
                    :class="item.taskSituation == 4 ? '' : 'disable'"
                  ></div>
                </el-tooltip>-->
              </div>
            </div>
          </div>
        </template>
        <div v-else-if="!loading && list.length == 0" class="no-data">
          暂无数据
        </div>
      </div>
      <div v-else class="loading-data">
        <div>
          <i class="el-icon-loading"></i>
          <p>加载中...</p>
        </div>
      </div>
    </div>
    <div class="tb-pagination">
      <div class="zongji">
        <span>共</span>
        <span>{{ pagination.totalCount }}</span>
        <span>条</span>
      </div>
      <span class="douhao zongji">,</span>
      <div class="zongji dangqianye">
        <span>{{ pagination.pageNo }}</span>
        <span>/</span>
        <span>{{ zongye(pagination.totalCount) }}</span>
        <span>页</span>
      </div>
      <el-pagination
        small
        v-if="pageshow"
        :page-sizes="[10, 20, 30, 40]"
        :current-page.sync="pagination.pageNo"
        :page-size="pagination.pageSize"
        layout="prev, pager, next"
        :total="pagination.totalCount"
        @size-change="handle_size_change"
        @current-change="handle_page_change"
      ></el-pagination>
      <div class="zhuan">
        <span>前往</span>
        <input type="text" v-model="qianVal" />
        <span>页</span>
      </div>
      <!-- 不知道page-change指向哪,只能直接拿过来用 -->
      <el-button class="btnqueding" @click="qunding">确定</el-button>
    </div>
    <el-dialog
      :show-close="false"
      center
      title="审批"
      :close-on-click-modal="false"
      :visible.sync="approve_form.visible"
      :append-to-body="true"
      top="20em"
      width="30%"
    >
      <div>
        <div
          class="w20 h20 pa top20 right20 cp"
          @click="approve_form.visible = false"
        >
          <img class="dib wih100 ht100" src="~@/assets/images/close.png" />
        </div>
        <el-form label-width="80px">
          <el-form-item label="备注">
            <el-input v-model="approve_form.approvalRemark" type="textarea" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" style="display: flex; justify-content: center">
        <div class="jcsb">
          <div
            @click="handle_approve(0)"
            class="w114 h40 lh40 cf f22 cp mr40"
            style="background-color: #c24848"
          >
            不通过
          </div>
          <div
            @click="handle_approve(1)"
            class="w114 h40 lh40 cf f22 cp ml40"
            style="background-color: #129c9c"
          >
            通过
          </div>
        </div>
      </span>
    </el-dialog>
    <Distribution
      @init="get_list"
      @close="distributionType = null"
      v-if="distributionType == 11"
    ></Distribution>
    <DetailEdit
      @init="get_list"
      @close="distributionType = null"
      v-if="distributionType == 12"
    ></DetailEdit>
    <SituationDetail
      @init="get_list"
      @close="distributionType = null"
      v-if="distributionType == 3"
    ></SituationDetail>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { downloadData } from "@/utils/download";
import Distribution from "@/components/command/flight-task/Distribution";
import DetailEdit from "@/components/command/flight-task/detailEdit";
import SituationDetail from "@/components/command/flight-task/situation/detail";

import API from "@/api";
export default {
  inject: [
    "flight_task_chage_view",
    "handle_video_Player",
    "handle_air_line_preview",
    "handle_image",
    "handle_histoty_track",
  ],
  props:{
    examineData:{
      type: Object,
      default: () => {}
    }
  },
  components: {
    Distribution,
    DetailEdit,
    SituationDetail,
  },
  computed: {
    ...mapGetters(["user_info"]),
    isJH() {
      return this.user_info && this.user_info.departmentId == 2;
    },
  },
  watch: {
    examineData(val){
      // if(val.status){
        // if(val.orgType){
          this.search.status = val.status
          this.search.orgId = val.orgId
        // }else{
        //   this.search.status = val.status
        // }
        this.get_list(1)
      // }
    },
    "$store.state.device.jq_list"(newData) {
      this.search = {
        taskTitle: null,
        orgId: null,
        type: null,
        executeStatus: null,
        status:null
      };
      this.get_list();
    },
  },

  async mounted() {
    // if(this.examineData.status){
      if(this.examineData.active == 1){
        this.search.status = this.examineData.status
        this.search.orgId = this.examineData.orgId
        await this.get_list(1)
      }else{
        await this.get_list();
      }
    const permissions = this.$store.state.user.user_info.permissions;
    const permissionList = permissions.map((item) => item.permissionSign);
    if (
      permissionList.indexOf(
        "hawk:control:flightTask:policeSituation:dispatch"
      ) > -1
    ) {
      this.policeSituation = true;
    } else {
      this.policeSituation = false;
    }
    this.flight_task_chage_view(1, false, true);
    await this.getUnitList();
    this.ws = new WebSocket(process.env.VUE_APP_WS_URL_ALARM);
    let { appid, username } = JSON.parse(
      localStorage.getItem("user_info")
    ).data;
    let token = JSON.parse(localStorage.getItem("user_info")).data[
      "mmc-identity"
    ];
    this.ws.onopen = () => {
      this.ws.send(
        JSON.stringify({
          type: 100,
          systemCode: "mmc",
          state: 1,
          username,
          token,
          appId: appid,
        })
      );
    };

    this.ws.onmessage = (e) => {
      let metadata = JSON.parse(e.data);
      if (metadata.type == 700) {
        if (metadata.data.wsSendUrlEnums) {
          if (metadata.data.wsSendUrlEnums.includes((num) => num == 1)) {
            this.get_list(1);
          }
        }
      }
      if (metadata.type == 500 && metadata.msgnum == 4500) {
        if (metadata.data.urlList != "device_waring/home/list") {
          this.get_list(1);
        }
      }
    };
  },
  beforeDestroy() {
    this.ws && this.ws.close();
    this.ws = null;
  },
  data() {
    return {
      qianVal: "", //手动写的跳转,没有相应样式的组件
      distributionType: null,
      icollapse: false,
      // 派发权限
      policeSituation: false,
      pageshow: true,
      statusColor: {
        待执行: "#FF972B",
        执行中: "#2F76E4",
        已完成: "#89EC50",
        待审批: "#19DFDF",
        待派发: "#F7E64D",
        审批不通过: "#DC143C",
        已签收: "#34BFFF",
        已派发: "#16FFC1",
        拒收: "#FF6738",
        未签收: "#CDFF3E",
      },
      ws: "",
      curr_task: null,
      approve_form: {
        approvalRemark: null,
        visible: false,
      },
      search: {
        taskTitle: null,
        orgId: null,
        type: null,
        executeStatus: null,
        status:null
      },
      list: [],
      loading: false,
      unitList: [],
      pagination: {
        pageNo: null,
        pageSize: 10,
        totalCount: 0,
      },
      timer: null,
    };
  },
  methods: {
    returnEX(){
      if(this.examineData){
        this.$emit("returnEX",this.examineData)
      }
    },
    //派发
    async Distribution(item, state) {
      let res = await API.SITUATION.Detail(item.id);
      // 保存警情数据 打开派发警情页面
      this.$store.commit("situation/SITUATION_DETAIL", res);
      this.flight_task_chage_view(state);
      this.distributionType = 2;
    },
    // 页码跳转
    qunding() {
      this.handle_page_change(this.qianVal);
    },
    // 计算总页码
    zongye(num) {
      let a = num / 10;
      var result = a.toString().indexOf(".");
      if (result != -1) {
        let b = parseInt(a);
        return b + 1;
      } else {
        return a;
      }
    },
    // expand(item) {
    //   this.icollapse = !this.icollapse
    //   item.visible = !item.visible
    // },

    async handle_resubmit(item, state) {
      let res = await API.SITUATION.Detail(item.id);
      res.applyStatus = 2;
      this.$store.commit("situation/SITUATION_DETAIL", res);
      this.flight_task_chage_view(state);
    },
    async getUnitList() {
      let data = await API.DEPARTMENT.Drd();
      this.unitList = [data] || [];
    },
    async handle_edit(item, state) {
      let res = await API.SITUATION.Detail(item.id);
      this.$store.commit("situation/SITUATION_DETAIL", res);
      this.flight_task_chage_view(state);
    },
    handle_upload(item) {
      downloadData(
        `/hawksystemserver/export/alertDownload/${item.taskId}?FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}`,
        "警情报告"
      );
    },
    async get_list(page) {
      if (page) {
        this.pagination.pageNo = page;
      }
      this.loading = true;
      let { data, pageNo, pageSize, totalCount } = await API.TASK.List({
        cate: 4,
        ...this.pagination,
        ...this.search,
      });
      this.list = [];
      data.map((ele) => {
        this.list.push({
          ...ele,
          visible: false,
        });
      });
      this.pagination.pageNo = pageNo;
      this.pagination.pageSize = pageSize;
      this.pagination.totalCount = totalCount;
      this.loading = false;
    },
    get_status(item) {
      if (item.taskSituation == -1) {
        return "待派发";
      }
      const obj = {
        5: "已签收",
        // 5: "待规划航线",
        0: "待审批",
        1: "审批不通过",
        2: "待执行",
        3: "执行中",
        4: "已完成",
        6: "已派发",
        7: "拒收",
        8: "未签收",
        9: "无需派发",
      };
      return obj[item.taskSituation];
    },
    get_approvalStatus(item) {
      if (item == 0) {
        return "待审批";
      } else if (item == 1) {
        return "通过";
      } else if (item == 2) {
        return "驳回";
      } else {
        return "暂无";
      }
    },
    // 0：未响应，1：及时响应，2：超时响应
    get_responseStatus(item) {
      if (item == 0) {
        return "未响应";
      } else if (item == 1) {
        return "及时响应";
      } else if (item == 2) {
        return "超时响应";
      } else {
        return "暂无";
      }
    },
    handle_size_change(size) {
      this.pagination.pageSize = size;
      this.get_list();
    },
    handle_page_change(page) {
      this.pagination.pageNo = page;
      this.get_list();
    },
    handle_show_approve(val) {
      val.is_from_situation = true;
      this.$store.commit("situation/SITUATION_IS_SHOW", true);
      this.$store.commit("situation/SITUATION_DETAIL", val);
      this.flight_task_chage_view(9);
      // this.curr_task = val
      // this.approve_form.visible = true
    },
    handle_approve(status) {
      this.$el_confirm(
        "确定提交么？",
        async () => {
          await API.TASK.Edit({
            id: this.curr_task.taskId,
            status,
            approvalRemark: this.approve_form.approvalRemark,
          });
          this.$el_message("审批成功");
          this.get_list();
          this.approve_form.visible = false;
        },
        () => {
          this.approve_form.approvalRemark = null;
          this.approve_form.visible = false;
        }
      );
    },
  },
  filters: {
    status: function (value) {
      let obj = {
        0: "待执行",
        1: "执行完成",
        3: "执行中",
      };
      return obj[value];
    },
    resource: function (value) {
      let obj = {
        0: "待审批",
        1: "通过",
        2: "驳回",
        3: "执无需审批行中",
      };
      return obj[value];
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-command-task-situation {
  @import "~@/styles/flightTask.scss";
  height: calc(100% - 125px);

  .btn-submit {
    width: 64px !important;
    height: 32px !important;
    background-image: url("~@/assets/yingkong1027/btn_lan.png") !important;
    background-size: 100% 100% !important;
    font-size: 14px !important;
    font-family: MicrosoftYaHei !important;
    color: #d2d9ff !important;
    line-height: 32px !important;
    cursor: pointer;
  }

  .tb-box {
    padding: 0 !important;
    width: 1796px;
    height: 470px;
    background: rgba(8, 26, 58, 0.8);
    border-radius: 4px;
    border: 1px solid #315ec7;

    .tb-hd-box {
      width: 100%;
      height: 42px;
      background: #0c1f3d;

      .tb-hd {
        height: 100%;
        line-height: 42px;
        width: calc(100% / 7);
        white-space: nowrap;
        font-size: 16px;
        font-family: YouSheBiaoTiHei;
        color: #ebf9ff;
        letter-spacing: 1px;
        font-weight: 400;
      }
      .last-tb-hd {
        width: 220px !important;
      }
      // .tb-hd-triangle {
      //   width: 14px;
      //   background: #10181f;
      // }
    }
    .loading-data {
      font-size: 16px;
      width: 1790px;
      height: 245px;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      // top: 0;
      left: 20px;
      text-align: center;
      color: #fff;
      padding-top: 10%;
      // div{
      //   position: absolute;
      //   top: 50%;
      // }
    }
    .tb-bd-box {
      width: 100%;
      height: calc(100% - 42px);
      position: relative;
      .no-data {
        text-align: center;
        color: #fff;
        padding-top: 10%;
      }
      .hangse:nth-child(odd) {
        background: rgba(73, 135, 210, 0.2);
      }

      .hangse:nth-child(even) {
        background: #0c1f3d;
      }

      overflow-y: auto;

      .tb-tr {
        .td {
          width: calc(100% / 7);
        }

        .last-td {
          width: 220px !important;

          .iconfont {
            margin-right: 7px;
          }
        }
      }

      // .child-box {
      //   .last-td {
      //     width: 220px !important;
      //   }
      //   .tb-hd-triangle {
      //     width: 20px;
      //   }
      // }
    }

    // .collapse {
    //   transform: rotate(90deg);
    //   transition: 0.5s;
    // }
  }
}

// ---------------------------------------------------------------------
.flexs {
  display: flex;
}

.waikuang {
  width: 170px;
  height: 32px;
  background: #02173d;
  border-radius: 2px;
  border: 1px solid #315ec7;
  align-items: center;
  margin: 0 16px 0 0;

  img {
    width: 20px;
    height: 20px;
    margin: 0 8px 0 8px;
  }
}

.xian {
  width: 1px;
  height: 22px;
  border-left: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(67, 222, 255, 0),
      rgba(67, 222, 255, 1),
      rgba(67, 222, 255, 0)
    )
    1 1;
}

// 所有输入框样式
/deep/ .waikuang .input-name .el-input__inner {
  // height: 32px !important;
  background: rgba(13, 50, 92, 0) !important;
  border: 0px solid #43deff !important;
  align-items: center !important;
  padding-left: 15px !important;
}

/deep/ .waikuang .el-cascader .el-input__inner {
  height: 32px !important;
  background: rgba(13, 50, 92, 0) !important;
  border: 0px solid #43deff !important;
  align-items: center !important;
  padding-left: 15px !important;
}

/deep/ .waikuang .el-select .el-input__inner {
  // height: 32px !important;
  background: rgba(13, 50, 92, 0) !important;
  border: 0px solid #43deff !important;
  align-items: center !important;
  padding-left: 15px !important;
}

// 页签样式
.zongji {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #b5e5ff;
  line-height: 16px;
}

.douhao {
  margin: 0 5px 0 5px;
}

.dangqianye {
  margin: 0 20px 0 0;
}

/deep/.el-pager .number:hover {
  border-radius: 2px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  background: rgba(38, 71, 238, 0.71) !important;
  width: 14px;
  height: 22px;
  line-height: 22px;
}

/deep/ .el-pagination--small .el-pager .number {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #889fb2;
}

/deep/.active {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  background: rgba(38, 71, 238, 0.71) !important;
}

.btnqueding {
  margin: 0 0 0 8px;
  width: 79px;
  // height: 32px;
  background-image: url("~@/assets/yingkong1027/btn_hei.png");
  background-size: 100% 100%;
  line-height: 5px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #43deff;
  border: 0px solid transparent;

  &:hover {
    opacity: 0.5 !important;
  }
}

.tb-pagination {
  margin: 24px 0 0px 0;
  display: flex;
  justify-content: end;
}

.zhuan {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #889fb2;
  line-height: 16px;

  input {
    width: 48px;
    min-width: 48px;
    max-width: auto;
    text-align: center;
    // height: 28px;
    background: rgba(12, 13, 20, 0.5);
    border-radius: 2px;
    border: 1px solid #b6d4ff;
    margin: 0 5px 0 5px;
    color: #fff;
    outline: 0px solid;
  }
}

// 顶部
.search-box {
  height: auto !important;
  margin: 12px 0 12px 0;
  margin-left: 0 !important;
  box-sizing: border-box;
  padding: 0 0 0 464px;
}
</style>
