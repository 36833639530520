<!-- 鹰控头部 -->
<template>
  <div>
    <!-- <div class="btn" @click="handleClick"></div> -->
    <div class="cpt-home_header">
      <div class="home-header_inner">
        <div class="header-bg_box">
          <div class="yingkong" @click="handleClick">
          </div>
          <div class="flex gundongtiao">
            <div class="flex" style="">
              <template>
                <div class="gundong">
                  <ul class="ul">
                    <li
                      class="tap"
                      @mouseover="tapyi(item.id)"
                      @mouseout="tapyichu"
                      v-for="item in optionsLeft"
                      :key="item.id"
                      @click="tap_click(item)"
                      :class="{ jin: item.id == JinId }"
                    >
                      <span>{{ item.title }}</span>
                    </li>
                  </ul>
                </div>
              </template>
            </div>
            <!-- <tianqi class="jianju"></tianqi> -->
            <!-- <div class="header-text"></div> -->
            <HeaderRight :data="user" class="jl" />
          </div>
        </div>

        <!-- <HeaderRight :data="user" /> -->
      </div>
    </div>
    <!-- <div class="tou">
      
    </div> -->
  </div>
</template>

<script>
import HeaderRight from "@/components/header-right/index_v5";
//箭头
import zuo from "@/assets/newImage/zuojian.png";
import zuoJin from "@/assets/newImage/zuojianJin.png";
import you from "@/assets/newImage/youjian.png";
import youJin from "@/assets/newImage/youjianJin.png";
// bus
import Bus from "@/assets/ligature";
// 引入 天气状况
import tianqi from "@/components/header-left/index_v5.vue";

const menus = function () {
  return [
    // 跳转问题
    // { id: 2, title: '警情中心' },
    // { id: 1, title: "首页" },
    // { id: 4, title: "航空航线" },
    // { id: 5, title: "飞行任务" },
    // { id: 9, title: "飞控中心" },
    // { id: 6, title: "人员管理" },
    // { id: 11, title: "设备监测" },
    // { id: 7, title: "设备管理" },
    // { id: 3, title: "空域管理" },
    // { id: 16, title: "实时点调" },
    // { id: 13, title: "服务器" },
    // { id: 8, title: '地图管理' },


    { id: 1, title: "首页" },
    { id: 5, title: "飞行任务" },
    { id: 4, title: "航空航线" },
    { id: 3, title: "空域管理" },
    { id: 16, title: "实时调度" },
    { id: 10, title: "勤务考核" },
    // { id: 20, title: "报表导出" },

    // { id: 1, title: "首页" },
    // { id: 6, title: "人员管理" },
    // { id: 7, title: "设备管理" },
    // { id: 11, title: "设备监测" },
    // { id: 16, title: "实时调度" },
    // { id: 5, title: "调度中心" },
  ];
};
export default {
  components: { HeaderRight, tianqi },
  props: {
    title: {
      type: String,
      default: () => "标题",
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    flag: {
      type: Number | String,
      default: () => 1,
    },
    type: {
      type: String,
      default: "首页", //类型可选, 首页 | 调度中心 | 所有 | 设备管理 | 设备监测 | 人员管理
    },
  },
  data() {
    return {
      JinId: 99,
      zuo: zuo,
      you: you,
      imgId: "",
      optionsLeft: [],
      arr: menus(),
    };
  },
  watch: {
    flag: function (vla, old) {
      // 监听flag的值来给相应的tap标签修改背景
      console.log(vla);
      if (vla == "1") {
        this.JinId = 1;
      } else if (vla == "3") {
        this.JinId = 3;
      } else if (vla == "11") {
        this.JinId = 11;
      } else if (vla == "4") {
        this.JinId = 4;
      } else if (vla == "5") {
        this.JinId = 5;
      } else if (vla == "6") {
        this.JinId = 6;
      } else if (vla == "7") {
        this.JinId = 7;
      } else if (vla == "12") {
        this.JinId = 12;
      } else if (vla == "16") {
        this.JinId = 16;
      }else if (vla == "10") {
        this.JinId = 10;
      }else if (vla == "20") {
        this.JinId = 20;
      }
    },
    type: {
      handler() {
        if(!this.type){
          this.type = "首页"
        }

        switch (this.type) {
          case "调度中心":
            this.optionsLeft = [
              { id: 1, title: "首页" },
              { id: 5, title: "飞行任务" },
              { id: 4, title: "航空航线" },
              { id: 3, title: "空域管理" },
              { id: 16, title: "实时调度" },
              { id: 10, title: "勤务考核" },
              // { id: 20, title: "报表导出" },
            ];
            break;

          case "设备监测":
            this.optionsLeft = [
              // 人员管理      设备管理     设备监测
              { id: 1, title: "首页" },
              { id: 11, title: "设备监测" },
              { id: 7, title: "设备管理" },
              { id: 6, title: "人员管理" },
              { id: 5, title: "调度中心" },
            ];
            break;

          case "设备管理":
            this.optionsLeft = [
              // 人员管理      设备管理     设备监测
              { id: 1, title: "首页" },
              { id: 7, title: "设备管理" },
              { id: 11, title: "设备监测" },
              { id: 6, title: "人员管理" },
              { id: 5, title: "调度中心" },
            ];
            break;

          case "人员管理":
            this.optionsLeft = [
              // 人员管理      设备管理     设备监测
              { id: 1, title: "首页" },
              { id: 6, title: "人员管理" },
              { id: 7, title: "设备管理" },
              { id: 11, title: "设备监测" },
              { id: 5, title: "调度中心" },
            ];
            break;

          case "首页":
            this.optionsLeft = [
              { id: 1, title: "首页" },
              { id: 6, title: "人员管理" },
              { id: 7, title: "设备管理" },
              { id: 11, title: "设备监测" },
              { id: 5, title: "调度中心" },
            ];
            break;

          default:
            this.optionsLeft = menus();
        }
      },
      immediate: true,
    },
    JinId:{
      handler(num, old){
        this.JinId = localStorage.getItem("currentPage") || 1;
      },
      immediate: true,
    }
  },

  created() {
    /**配置实时点调的展示
     * @param{*}
     */
    // const permissions = this.$store.state.user.user_info.permissions;
    // const permissionList = permissions.map((item) => item.permissionSign);
    // if (permissionList.indexOf("permission:done:web") > -1) {
    //   this.optionsLeft.push({ id: 16, title: "实时点调" })
    //   this.arr.push({ id: 16, title: "实时点调" })
    // }
  },
  mounted() {
    // if(this.$route.query.type){
    //   this.tap_click(JSON.parse(this.$route.query.item))
    // }
    // // 将数组序列化方便修改
    // let b = JSON.parse(JSON.stringify(this.optionsLeft));
    // // 判断是否从总览页跳过的还是原地空涮
    // if (this.$route.query.page == undefined) {
    //   // 如果是先跳转过来再原地空涮会造成数组混乱,所以这里将备用数组重新赋给它,保证空涮时数组不会乱
    //   this.optionsLeft = this.arr;
    //   // 涮新固定回到首页,所以直接给首页换背景
    // console.log(this.$router.query);
    // this.JinId = localStorage.getItem("currentPage") || 1;
    // console.log(this.JinId);
    // } else {
    //   // 如果是跳转过来的则根据传过来的id(this.$route.query.page)获取它在数组里下标
    //   let a = this.optionsLeft.findIndex(
    //     (arr) => arr.id == this.$route.query.page
    //   );
    //   // 判断是否从首页跳转过来,如果从首页跳转过来则不操作
    //  if(this.$route.query.page != 1){
    //    // 根据下标从数组里把它删除,返回删除的内容
    //    let c = b.splice(a, 1);
    //    // 把删除的内容重新放回数组,放回数组的第二个位置(永远在首页后面)
    //    b.splice(1, 0, c[0]);
    //  }
    //   // 重新赋值给数组
    //   this.optionsLeft = b;
    //   // 根据id给相应的tap标签换背景
    //   this.JinId = this.$route.query.page;
    // }
  },
  methods: {
    // 箭头事件
    zuoyi() {
      this.zuo = zuoJin;
    },
    zuoyichu() {
      this.zuo = zuo;
    },
    youyi() {
      this.you = youJin;
    },
    youyichu() {
      this.you = you;
    },
    /**
     * 111111
     */
    tapyi(id) {
      this.imgId = id;
    },
    tapyichu() {
      this.imgId = "";
    },
    // 箭头跳转,思路,输出数组第一个对象,然后push数组最后面,右箭头反之
    zuotiao() {
      const allLength = this.optionsLeft.length * 134;
      const boxLength = document.querySelector(".gundong").clientWidth;
      if (allLength < boxLength) return;
      const listEl = document.querySelector(".ul");
      const leftMove = parseInt(window.getComputedStyle(listEl, null)?.left);
      if (leftMove + boxLength < boxLength) {
        // 滚到
        listEl.style.left = "0px";
      } else if (leftMove < 0) {
        listEl.style.left = leftMove + 134 + "px";
      }
      //  let a = JSON.parse(JSON.stringify(this.optionsLeft));
      //  let b = a.splice(0, 1);
      // //  console.log(b[0].id,'什么');
      // if(b[0].id == 1){
      //   return
      // }else{
      //   // let b = a.splice(0, 1);
      // a.push(b[0]);
      // this.optionsLeft = a;
      // }
    },
    youtiao() {
      const allLength = this.optionsLeft.length * 134;
      const boxLength = document.querySelector(".gundong").clientWidth;
      if (allLength < boxLength) return;
      const listEl = document.querySelector(".ul");
      const leftMove = Math.abs(
        parseInt(window.getComputedStyle(listEl, null)?.left)
      );
      // console.log(leftMove,allLength);
      if (leftMove + boxLength >= allLength) {
        listEl.style.left = "-" + (allLength - boxLength) + "px";
      } else {
        listEl.style.left = "-" + (leftMove + 134) + "px";
      }
      // let a = JSON.parse(JSON.stringify(this.optionsLeft));
      // let b = a.splice(a.length - 1, 1);
      // // console.log(b[0].id,'什么');
      // if(b[0].id == 1){
      //   return
      // }else{
      //    a.unshift(b[0]);
      // this.optionsLeft = a;
      // }
    },

    // tap标签跳转功能
    tap_click(item) {
      // 由于尽量不动源代码,这里采用bus调用原方法
      // 调用路径@/components/command/page-tabs/index.vue
      console.log(item);
      Bus.$emit("tap", item);
      this.$emit("taps", item);
      //点击首页时不显示导航条
      if (item.id === 1) {
        this.$emit("update:type", "首页");
      } else if (item.id === 5) {
        //点飞行任务时到调度中心
        this.$emit("update:type", "调度中心");
      }
    },
    // 首页跳转功能
    handleClick() {
      // window.open('#/home', "_blank");
      // this.$router.push('/home')

      let { href } = this.$router.resolve({ path: "/home" });
      window.open(href, "_self");
      setTimeout(() => {
        this.$router.go(0);
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 58px;
  height: 25px;
  position: relative;
  z-index: 100;
  left: 50%;
  margin-left: -29px;
  margin-top: 8px;
  cursor: pointer;
}
.bg1 {
  background: linear-gradient(to bottom, #000 0%, transparent 100%);
}
.bg2 {
  background: center 0px url("~@/assets/images/command/Rectangle.png") no-repeat;
  background-size: 100% 100%;
}
.cpt-home_header {
  height: 80px;
  box-sizing: border-box;
  // background: #19223d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: url("~@/assets/images/command/header.png") no-repeat;
  background-size: cover;
  // background-size: 100% 100%;
  // background:  linear-gradient(to bottom, #000 0%, transparent 100%);

  .home-header_inner {
    height: 100%;
    position: relative;
    z-index: -99;
    // background-image: url("~@/assets/newImage/landi.png");
    background-size: 100% 100%;
    .header-bg_box {
      min-width: 1920px;
      width: 100vw;
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
      left: 0;
      display: flex;
      // background: center 0px url('~@/assets/images/command/command_top.png') no-repeat;
      // background: center 0px url("~@/assets/newImage/yingkongshouye.png")
      // no-repeat;
      background-size: 100% 100%;

      .yingkong {
        // 鹰控文字图片
        margin: 5px 0 0 5%;
        width: 150px;
        height: 80%;
        cursor: pointer;
      }
      .zuojian {
        // 左箭头
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin: 0 0 8px 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .youjian {
        // 右箭头
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin: 0 0 8px 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .gundong {
        position: relative;
        ul {
          left: 0px;
          list-style: none;
          // width: 100%;
          // height: 100%;
          display: flex;
          // overflow: scroll;
          .tap {
            width: 196px;
            height: 46px;
            position: relative;
            text-align: center;
            margin: 0 0px 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            // min-width: 133px;
            img {
              position: absolute;
              width: 134px;
              height: 34px;
              top: -10px;
              left: 0;
              cursor: pointer;
              // min-width: 133px;
            }
            span {
              font-size: 24px;
              font-family: YouSheBiaoTiHei;
              color: #ffffff;
              line-height: 30px;
              background: linear-gradient(
                180deg,
                #ffffff 0%,
                #ffffff 36%,
                #7897dc 100%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
      .gundong::-webkit-scrollbar {
        width: 0 !important;
      }
      .header-text {
        font-family: PangMenZhengDao;
        font-size: 32px;
        color: #ebf4ff;
        text-align: center;
        font-weight: 400;
        line-height: 45px;
        user-select: none;
        & > img {
          cursor: pointer;
        }
      }
    }
  }
}
.jianju {
  margin: 0 0px 0 2%;
}
.jl {
  margin: 0 0 0 50px;
  padding: 0 12px 0 0;
}
.flex {
  display: flex;
  position: relative;
}
.gundongtiao {
  margin: 0 0 0 92px;
  justify-content: space-between;
  width: 100%;
  // padding: 0 20px;
  padding: 0 0px 0 20px;

  .flex {
    margin-left: 16px;
    align-items: center;
  }
}
.tap {
  background-image: url("~@/assets/newImage/taplan1027.png");
  background-size: 100% 100%;
  line-height: 29px;
  cursor: pointer;
}
.tap:hover {
  background-image: url("~@/assets/newImage/tapJin1027.png");
  background-size: 100% 100%;
}
.jin {
  background-image: url("~@/assets/newImage/tapJin1027.png");
  background-size: 100% 100%;
}
</style>
