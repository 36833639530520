<template>
  <div class="cpt-command-device-drone-mount-popup">
    <div class="header" v-interact>
      <div class="hd-box">挂载详情</div>
      <div
        class="close_box"
        @click="() => $emit('handleClose')"
      >关闭</div>
    </div>
    <div class="content_box">
      <div class="bd-box">
        <div class="tb-box">
          <div class="tb-hd-box">
            <div class="tb-hd">挂载名称</div>
            <div class="tb-hd">挂载型号</div>
            <div class="tb-hd">挂载图片</div>
            <div class="tb-hd">挂载状态</div>
            <div class="tb-hd">归属无人机</div>
            <div class="tb-hd">所属单位</div>
            <div class="tb-hd">入库时间</div>
            <div class="tb-hd">出库时间</div>
          </div>
          <div class="tb-bd-box">
            <div class="tb-tr" v-for="item in list" :key="item.id">
              <div class="td">
                {{ item.name || '暂无' }}
              </div>
              <div class="td">
                {{ item.model || '暂无' }}
              </div>
              <div class="td">
                <el-image
                  :src="baseUrl + item.image"
                  :preview-src-list="[baseUrl + item.image]"
                >
                </el-image>
              </div>
              <div class="td" :class="`tdclass${item.state}`">
                {{ changeState(item.state) || '暂无' }}
              </div>
              <div class="td">
                {{ item.deviceName || '暂无' }}
              </div>
              <div class="td">
                {{ item.orgName || '暂无' }}
              </div>
              <div class="td">
                {{ item.inTime || '暂无' }}
              </div>
              <div class="td">
                {{ item.outTime || '暂无' }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page_num">
        共计<span>{{ totalCount }}</span
        >条
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api'
export default {
  props: {
    deviceId: {
      type: Number,
      default: () => 1,
    },
  },
  watch: {
    deviceId() {
      this.getMountList()
    },
  },
  data() {
    return {
      list: [],
      baseUrl: process.env.VUE_APP_IMG_URL,
      totalCount: 0,
    }
  },
  async mounted() {
    await this.getMountList();
  },
  methods: {
    async getMountList() {
      let { deviceId } = this
      let res = await API.DEVICE.ListMount({
        deviceId,
      })
      this.totalCount = res.length
      this.list = res || []
    },
    changeState(val) {
      const obj = { 1: '正常', 2: '损坏', 3: '维修', 4: '保养' }
      return obj[val]
    },
  },
}
</script>

<style lang="scss" scoped>
.tdclass1 {
  color: #00b900 !important;
}
.tdclass3 {
  color: yellow !important;
}
.tdclass2 {
  color: red !important;
}
.tdclass4 {
  color: #b3bbc5 !important;
}
.cpt-command-device-drone-mount-popup {
  width: 1267px;
  height: 557px;
  box-sizing: border-box;
  position: absolute;
  top: 300px;
  left: 30%;
  z-index: 11;
  background: rgba(16, 30, 62, 0.9);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 13px;
  .header {
    height: 64px;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;
  }
  .content_box{
    padding: 0 10px 15px;
  }
  .close_box {
    font-family: MicrosoftYaHei;
    font-size: 18px;
    color: #00ffff;
    position: absolute;
    right: 28px;
    top: 22px;
    opacity: 0.8;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
  .hd-box {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 700;
    line-height: 64px;
    padding-left: 30px;
  }
  .bd-box {
    height: calc(100% - 64px);
    box-sizing: border-box;
    .tb-box {
      box-sizing: border-box;
      height: 100%;
      .tb-hd-box {
        display: flex;
        justify-content: space-between;
        height: 52px;
        .tb-hd {
          height: 100%;
          flex: 1;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          line-height: 52px;
          text-align: center;
          font-size: 20px;
          color: #00aeff;
        }
      }
      .tb-bd-box {
        height: calc(100% - 52px);
        overflow: auto;
        .tb-tr {
          display: flex;
          box-sizing: border-box;
          border-bottom: 1px solid #263542;
          &:hover {
            cursor: pointer;
            background: rgba(0, 34, 140, 0.2);
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 15px 0 #00b6ff;
            border-radius: 5px;
          }
          .td {
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% / 5);
            padding: 10px 0;
            text-align: center;
            font-family: MicrosoftYaHei;
            word-break: break-word;
            box-sizing: border-box;
            font-size: 16px;
            color: #dce9ff;
            font-weight: 400;
          }
        }
      }
    }
  }
  .page_num {
    text-align: right;
    font-family: MicrosoftYaHei;
    font-size: 18px;
    color: #dce9ff;
    line-height: 24px;
    margin: 15px 20px 0 0;
    & > span {
      color: #00aeff;
    }
  }
  /deep/.el-image__inner {
    width: 100px;
    height: 100px;
  }
  /deep/.el-image__error {
    width: 90px;
    height: 70px;
  }
}
</style>
