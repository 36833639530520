<template>
  <div class="cpt-command-device-mount cpt-command-device-common">
    <div class="hd-box">飞手助手管理</div>
    <div class="search-box">
      <el-input v-model="filter.name" clearable placeholder="请输入飞行员姓名"></el-input>
      <el-input v-model="filter.phone" clearable placeholder="请输入手机号"></el-input>
      <el-button @click="handle_search" class="btn">搜索</el-button>
    </div>
    <div class="bd-box">
      <div class="tb-box">
        <div class="tb-hd-box">
          <div class="tb-hd">飞行员姓名</div>
          <div class="tb-hd">警员编号</div>
          <div class="tb-hd">手机号</div>
          <div class="tb-hd">所属单位</div>
        </div>
        <div class="tb-bd-box">
          <div class="tb-tr" v-for="(item,index) in list" :key="index">
            <div class="td">
              {{ item.name || '暂无' }}
            </div>
            <div class="td">
              {{ item.siren || '暂无' }}
            </div>
            <div class="td">
              {{ item.phone || '暂无' }}
            </div>
            <div class="td">
              {{ item.anotherName || '暂无' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  data() {
    return {
      list: [],
      filter:{
        pageSize:10000,
        name: null,
        phone: null,
        type: 2
      }
    };
  },

  mounted() {
    this.getFlightInfo()
  },
  
  methods: {
    handle_search(){
      this.getFlightInfo()
    },
    async getFlightInfo() {
      let res = await API.PERSON.Info(this.filter)
      this.list = res.data || []
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/person.scss';
.cpt-command-device-mount{
  background: url('~@/assets/images/device/info.png') no-repeat;
  background-size: 100% 100%;
  width: 30%;
  .td {
    width: calc(100% / 4);
  }
}
</style>