<!-- 航空航线1027 -->
<template>
  <div class="cpt-command-airway-list" :class="isNone ? 'down' : ''" v-show="showHiding == false">
    <div class="header" v-interact>
      <div class="toubu">
        <div class="tiao">
          <img src="~@/assets/yingkong1027/jinye.png" style="width: 100%; height: 100%" alt />
        </div>
        <div class="hd-box">航空航线</div>
      </div>
      <div class="right">
        <div class="fontOne cp mr20" @click="change_page">关闭</div>
      </div>
    </div>

    <div class="list-box">
      <div class="search-box">
        <div class="waixian">
          <div @click="$emit('add')" class="btn1027">航线规划</div>
        </div>
        <div class="flex fangkuai">
          <el-input
            v-model="keyword.flightName"
            class="item-input"
            placeholder="请输入航线名称"
            clearable
          />
        </div>
        <div class="flex fangkuai">
          <el-select
            v-model="keyword.sourceType"
            class="item-input"
            placeholder="请选择搜索类型"
            clearable
          >
            <el-option label="无人机航线" :value="1"></el-option>
            <el-option label="鹰巢航线" :value="2"></el-option>
          </el-select>
        </div>
        <div class="flex fangkuai">
          <el-select v-model="keyword.isSafe" class="item-input" placeholder="请选择安全状态" clearable>
            <el-option label="安全" :value="1"></el-option>
            <el-option label="待确定" :value="0"></el-option>
          </el-select>
        </div>
        <div class="flex fangkuai">
          <el-select v-model="keyword.type" class="item-input" placeholder="请选择空域状态" clearable>
            <el-option label="可用" :value="1"></el-option>
            <el-option label="待审批" :value="3"></el-option>
            <el-option label="通过" :value="4"></el-option>
            <el-option label="驳回" :value="5"></el-option>
          </el-select>
        </div>
        <div class="flex fangkuai">
          <el-input
            v-model="keyword.labelName"
            class="item-input"
            placeholder="请输入航线标签名称"
            clearable
          />
        </div>
        <div class="flex fangkuai">
          <el-autocomplete
            class="inline-input item-input"
            v-model="keyword.userName"
            :fetch-suggestions="querySearch"
            clearable
            placeholder="请输入创建人"
            @select="handleSelect"
            popper-class="xiala"
          ></el-autocomplete>
        </div>
        <div class="btn1027 ml20" @click="acc">搜索</div>
        <routeLabel
          :id="routeLabelId"
          ref="routeLabelRef"
          @close="routeLabel = false"
          @changeList="$emit('changelist')"
          class="routeLabel"
          v-if="routeLabel"
        ></routeLabel>
      </div>
      <div class="tb-box">
        <div class="tb-hd-box">
          <div class="tb-hd">航线ID</div>
          <div class="tb-hd">航线名称</div>
          <div class="tb-hd">航点数量(个)</div>
          <div class="tb-hd">航线长度(m)</div>
          <div class="tb-hd">预计时长(s)</div>
          <div class="tb-hd">所属单位</div>
          <div class="tb-hd">创建时间</div>
          <div class="tb-hd">空域状态</div>
          <!-- <div class="tb-hd">模式</div> -->
          <div class="tb-hd">安全状态</div>
          <div class="tb-hd">航线标签</div>
          <div class="tb-hd last-tb-hd">操作</div>
        </div>
        <div class="tb-bd-box">
          <div
            class="tb-tr"
            v-for="item in list"
            :key="item.id"
            @click="cssStyle(item.id)"
            :class="{ activeCss: cssId == item.id }"
          >
            <div class="td">
              <el-tooltip
                class="item"
                effect="dark"
                :disabled="item.id.length > 9 ? false : true"
                :content="String(item.id)"
                placement="top-start"
              >
                <el-button>{{ item.id || "暂无" }}</el-button>
              </el-tooltip>
            </div>
            <div class="td">
              <div>{{ item.flightName || "暂无" }}</div>
            </div>
            <div class="td">{{ item.pointCount || "暂无" }}</div>
            <!-- 航线长度 -->
            <div class="td">
              <div v-if="item.status == 1">{{ item.distance || "暂无" }}</div>
              <div v-else>暂无</div>
            </div>
            <!-- 预计时长 -->
            <div class="td" style="color: #19d864">{{ item.time || "暂无" }}</div>
            <!-- 所属单位 -->
            <div class="td">{{ item.organizationName || "暂无" }}</div>
            <!-- 创建时间 -->
            <div class="td">
              <div>{{ item.addTime || "暂无" }}</div>
            </div>
            <!-- 空域状态 -->
            <div class="td">
              <div v-if="item.status == 1" class="status">可用</div>
              <div v-else-if="item.status == 2" class="status" style="color: #2ca1e2">待申请</div>
              <div v-else-if="item.status == 3" class="status" style="color: #ffbd36">待审批</div>
              <div v-else-if="item.status == 4" class="status" style="color: #2bfdf1">通过</div>
              <div v-else-if="item.status == 5" class="status" style="color: #fb4a2d">驳回</div>
              <div v-else>暂无</div>
            </div>

            <!-- 安全状态 -->
            <div
              class="td"
              :style="{
              color:
                item.issafe == 1
                  ? '#19D864 !important'
                  : '#FFBD36 !important',
            }"
            >{{ item.issafe == 1 ? "安全" : "待确定" }}</div>
            <!-- 航线标签 -->
            <div class="td">{{ falgcomma(item.labelList) }}</div>
            <div class="td last-td" style="width: 15%">
              <el-tooltip effect="dark" content="预览" placement="top">
                <div
                  @click="handleAirLinePreview(item)"
                  class="ctrl-icon"
                  v-if="closePreview != item.id"
                >
                  <img class="ctrl-icon__view" src="~@/assets/images/command/airway_view.png" />
                </div>
                <div v-else @click="handleHide(item)" class="ctrl-icon">
                  <img class="ctrl-icon__view-no" src="~@/assets/images/command/airway_view_no.png" />
                </div>
              </el-tooltip>
              <el-tooltip effect="dark" content="删除" placement="top">
                <div @click="handleAirLineDelete(item)" class="ctrl-icon">
                  <img class="ctrl-icon__del" src="~@/assets/images/command/airway_del.png" />
                </div>
              </el-tooltip>
              <el-tooltip effect="dark" content="安全" placement="top">
                <div
                  @click="chaneg_safety(item)"
                  class="ctrl-icon"
                  :class="item.issafe == 1 ? 'disabled' : ''"
                >
                  <img
                    class="ctrl-icon__security"
                    src="~@/assets/images/command/airway_security.png"
                  />
                </div>
              </el-tooltip>
              <el-tooltip effect="dark" content="航线标签" placement="top">
                <div @click="showRouteLabel(item)" class="ctrl-icon">
                  <img class="ctrl-icon__label" src="~@/assets/images/command/airway_label.png" />
                </div>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="tb-pagination">
        <div class="zongji">
          <span>共</span>
          <span>{{ pagination.totalCount }}</span>
          <span>条</span>
        </div>
        <span class="douhao zongji">,</span>
        <div class="zongji dangqianye">
          <span>{{ pagination.pageNo }}</span>
          <span>/</span>
          <span>{{ zongye(pagination.totalCount) }}</span>
          <span>页</span>
        </div>
        <el-pagination
          :page-sizes="[10, 20, 30, 40]"
          :current-page.sync="dangqianye"
          :page-size="pagination.pageSize"
          layout="prev, pager, next"
          :total="pagination.totalCount"
          @current-change="(e) => $emit('page-change', e)"
          @size-change="(e) => $emit('size-change', e)"
        ></el-pagination>
        <div class="zhuan">
          <span>前往</span>
          <input type="text" v-model="qianVal" />
          <span>页</span>
        </div>
        <!-- 不知道page-change指向哪,只能直接拿过来用 -->
        <div
          class="btn1027"
          style="margin-left: 24px;"
          @click="$emit('page-change', qianVal), (dangqianye = qianVal)"
        >确定</div>
      </div>
    </div>
    <!-- 安全航线确认弹框 -->
    <el-dialog
      title
      :visible.sync="clearing_line"
      width="468px"
      :modal-append-to-body="false"
      class="bj"
    >
      <div class="header safety_heard">
        <div class="toubu">
          <div class="tiao">
            <img src="~@/assets/yingkong1027/jinye.png" style="width: 100%; height: 100%" alt />
          </div>
          <div class="hd-box">温馨提示</div>
        </div>
        <div class="right">
          <div class="fontOne cp mr20" @click="clearing_line = false">关闭</div>
        </div>
      </div>
      <div class="safety_center">
        <div class="text">确定改为安全航线吗？</div>
        <div class="btn">
          <div class="btn_xiao btn_an" @click="clearing_line = false">取消</div>
          <div class="btn_que btn_an" @click="safety">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api";
import Bubble from "./bubble.js";
import routeLabel from "./components/routeLabel.vue";

import { Utils } from "@/lib/cesium";
// 引用bus
import Bus from "@/assets/ligature.js";
let point_index = null;
let isEditting = false;
let airline_entitys = [];

export default {
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      default: () => [],
    },
    isNone: {
      type: Boolean,
      default: false,
    },
  },
  inject: ["g_cesium_layer", "change_page"],
  data() {
    return {
      qianVal: "", // 页码跳转输入框
      dangqianye: this.pagination.pageNo, //由于组件没有确定按钮,这个是用确定按钮赋值给它,由他触发页签的跳转功能
      routeLabelBtnItem: false,
      keyword: {},
      Flight: null,
      //航线标签的开关
      taglist: [],
      //选中时候的数据
      checkList: [],
      viewer: "",
      //箭头的开关
      flagtag: false,
      flightId: null,
      routeLabel: false,
      routeLabelId: "",
      bubbles: "",
      lineList: {
        id: "",
      },
      yeqian: "",
      restaurants: [],
      showHiding: false,
      cssId: null, // 点击样式
      preview: null,
      closePreview: null,
      cesiumLine: null,
      clearing_line: false, //安全航线弹框
      safetyCenter: null, //安全航线弹框内容
    };
  },

  mounted() {
    this.getlable();
    this.getUsername();
  },
  watch: {
    list: function (vla, old) {
      // console.log(vla, "456");
    },
  },
  components: { routeLabel },
  created() {
    this.yeqian = this.pagination;
    // 点击航线弹出图片
    Bus.$on("closeHiding", () => {
      if (this.showHiding == true) {
        this.showHiding = false;
      }
    });
  },
  methods: {
    handleHide(item) {
      this.closePreview = null;
      let viewer = window.viewer;
      let point_entity = viewer.entities.getById("polyline" + item.id);
      viewer.entities.remove(point_entity);
      this.cesiumLine = true;
      this.handleAirLinePreview();
    },
    cssStyle(num) {
      this.cssId = num;
    },
    // 隐藏输入面板
    hiding() {
      this.showHiding = true;
    },
    //获取创建人
    async getUsername() {
      let res = await API.HOME.getUsername();
      res.forEach((val) => {
        val.value = val.name;
      });
      this.restaurants = res;
    },
    //创建人下拉
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelect(item) {
      // console.log(item,'55565465465456');
    },
    // 搜索按钮功能
    acc() {
      this.pagination.pageNo = 1;
      this.dangqianye = 1;
      // console.log(this.keyword,'keeggydsdhs');
      this.$emit("search", this.keyword);
    },
    // 计算总页码
    zongye(num) {
      let a = num / 10;
      var result = a.toString().indexOf(".");
      if (result != -1) {
        let b = parseInt(a);
        return b + 1;
      } else {
        return a;
      }
    },
    falgcomma(val) {
      let str = "";
      val.forEach((item, index) => {
        if (index != val.length - 1) {
          str += String(item.labelName + ",");
        } else {
          str += String(item.labelName);
        }
      });

      return str;
    },
    routeLabelBtn() {
      // if (this.lineList.id != "") {
      //   if (!this.routeLabelBtnItem) {
      //     this.showBubble(this.lineList, this.viewer, this);
      //   } else {
      //     if (this.bubbles) {
      //       this.bubbles.windowClose();
      //     }
      //   }
      // }
      this.routeLabelBtnItem = !this.routeLabelBtnItem;
    },
    showRouteLabel(item) {
      this.routeLabelId = item.id;
      this.routeLabel = true;
    },
    async getlable() {
      this.taglist = await API.AIRWAY.labelList();
    },

    async chaneg_safety(e) {
      this.clearing_line = true;
      this.safetyCenter = e;
      // this.$el_confirm("确定改为安全航线吗？", async () => {
      //   let res = await API.AIRWAY.EditIssafe({ id: e.id, issafe: 1 });
      //   this.$message({
      //     type: "success",
      //     message: "修改成功",
      //   });
      //   this.$emit("chanegSafety");
      // });
    },
    // 改为弹框确认
    async safety() {
      let res = await API.AIRWAY.EditIssafe({
        id: this.safetyCenter.id,
        issafe: 1,
      });
      this.$message({
        type: "success",
        message: "修改成功",
      });
      this.clearing_line = false;
      this.acc();
    },
    clear_airline_entitys() {
      let viewer = window.viewer;
      airline_entitys.forEach((item) => {
        viewer.entities.remove(item);
      });
    },
    async handleAirLinePreview(item) {
      this.$emit("isN");
      let viewer = window.viewer;
      this.clear_airline_entitys();
      if (item?.flightCourseJson) {
        let positions = [];
        let center = [];
        let polyline = JSON.parse(item.flightCourseJson);
        let label_arr = [];
        let _this = this;

        polyline.points.forEach((item, index) => {
          positions.push(item.lon, item.lat, item.alt);
          label_arr.push(
            Cesium.Cartesian3.fromDegrees(item.lon, item.lat, item.alt)
          );
          let point_entity = null;
          if (index == polyline.points.length - 1) {
            point_entity = viewer.entities.add({
              position: Cesium.Cartesian3.fromDegrees(
                item.lon,
                item.lat,
                item.alt
              ),
              name: "show_airline_point",
              label: {
                text: String(item.alt) + "m",
                scale: 0.6,
                font: "bold 28px Microsoft YaHei",
                // fillColor: Cesium.Color.BLUE,
                fillColor: Cesium.Color.fromCssColorString("#FF7F09"),
                horizontalOrigin: Cesium.VerticalOrigin.CENTER,
                verticalOrigin: Cesium.VerticalOrigin.CENTER,
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
                showBackground: false,
                outlineWidth: 0,
                pixelOffset: new Cesium.Cartesian2(25, -10),
              },
            });
          } else {
            point_entity = viewer.entities.add({
              position: Cesium.Cartesian3.fromDegrees(
                item.lon,
                item.lat,
                item.alt
              ),
              name: "show_airline_point",
              point: {
                pixelSize: 20,
                color: Cesium.Color.RED,
                outlineWidth: 2,
                outlineColor: Cesium.Color.WHITE,
              },
              // label: {
              //   text: String(item.alt) + "m",
              //   scale: 0.6,
              //   font: "bold 28px Microsoft YaHei",
              //   // fillColor: Cesium.Color.BLUE,
              //   fillColor: Cesium.Color.fromCssColorString("#FF7F09"),
              //   horizontalOrigin: Cesium.VerticalOrigin.CENTER,
              //   verticalOrigin: Cesium.VerticalOrigin.CENTER,
              //   disableDepthTestDistance: Number.POSITIVE_INFINITY,
              //   showBackground: false,
              //   outlineWidth: 0,
              //   pixelOffset: new Cesium.Cartesian2(25, -10),
              // },
              label: {
                 text: new Cesium.CallbackProperty(() => {
                            return String(index + 1);
                          }, false),
                          font: "bold 20px Microsoft YaHei",
                          fillColor: Cesium.Color.fromCssColorString("white"),
                          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
                           verticalOrigin: Cesium.VerticalOrigin.CENTER,
                          disableDepthTestDistance: Number.POSITIVE_INFINITY,
                          showBackground: false,
                          outlineWidth: 0,
                          pixelOffset: new Cesium.Cartesian2(0, 0),
              },
            });
          }

          if (this.cesiumLine == true) {
            viewer.entities.remove(point_entity);
            this.cesiumLine = false;
            return;
          } else {
            airline_entitys.push(point_entity);
          }
          if (label_arr.length > 1) {
            let before = label_arr[label_arr.length - 2];
            let after = label_arr[label_arr.length - 1];

            _this.create_label(before, after);
          }
        });

        positions = Cesium.Cartesian3.fromDegreesArrayHeights(positions);
        let redLine = (this.viewer = viewer.entities.add({
          name: "Red line on terrain",
          id: "polyline" + item.id,
          positions: positions[0],
          polyline: {
            positions: new Cesium.CallbackProperty(() => {
              return positions;
            }, false),
            width: 10,
            // material: Cesium.Color.RED,
            material: new Cesium.PolylineArrowMaterialProperty(
              Cesium.Color.fromCssColorString("#00A9A9")
            ),
            // clampToGround: true,
          },
        }));
        viewer.flyTo(redLine);
        this.lineList = {
          id: item.id,
          positions: positions[0],
        };
        airline_entitys.push(redLine);

        let handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
        let __this = this;
        // console.log(0);
        if (this.routeLabelBtnItem) {
          // console.log(2, "a");
          this.showBubble(this.lineList, viewer, __this);
        } else {
          handler.setInputAction(async function (e) {
            // console.log(1);
            let pick = viewer.scene.pick(e.position);
            let elID = "polyline" + item.id;
            if (pick && pick.id && pick.id._id == elID) {
              let labelRes = await API.AIRWAY.getLabelById({ id: item.id });
              let state = "";
              let arr = [];
              if (labelRes.length > 0) {
                labelRes.forEach((element) => {
                  arr.push(element.labelName);
                });
                state = arr.join(" | ");
              } else {
                state = "暂无标签";
              }
              if (Cesium.defined(pick)) {
                let newData = {
                  viewer: viewer,
                  position: pick.id._positions,
                  name: "航线标签",
                  id: "polyline" + item.id,
                  state: state,
                };
                // console.log(newData, "newData");
                _this.bubble(newData);
              }
            } else {
              if (this.bubbles) {
                this.bubbles.windowClose();
              }
            }
          }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
        }
      }
      this.closePreview = item.id;
    },
    async showBubble(item, viewer, _this) {
      let labelRes = await API.AIRWAY.getLabelById({ id: item.id });
      let state = "";
      let arr = [];
      if (labelRes.length > 0) {
        labelRes.forEach((element) => {
          arr.push(element.labelName);
        });
        state = arr.join(" | ");
      } else {
        state = "暂无标签";
      }
      let newData = {
        viewer: viewer,
        position: item.positions,
        name: "航线标签",
        id: item.id,
        state: state,
      };
      _this.bubble(newData);
      return;
    },
    bubble(data) {
      // 显示弹框
      this.showHiding = false;
      // console.log(this.showHiding,'this.showHiding');
      if (this.bubbles) {
        this.bubbles.windowClose();
      }
      this.bubbles = new Bubble(data);
    },
    async handleAirLineDelete(item) {
      let res = await API.AIRWAY.Delete({
        id: item.id,
      });
      if (res) {
        this.$message({
          type: "success",
          message: "删除成功",
        });
        this.clear_airline_entitys();
        this.$emit("page-change", this.pagination.pageNo);
      }
    },
    create_label(before, after) {
      let viewer = window.viewer;
      let before_wgs84 = Utils.transformCartesian2WGS84(before);
      let after_wgs84 = Utils.transformCartesian2WGS84(after);

      let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2;
      let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2;
      let alt = (after_wgs84.alt + before_wgs84.alt) / 2;

      let position = Utils.transformWGS842Cartesian({
        lng: center_lng,
        lat: cetner_lat,
        alt: alt + 10,
      });

      let text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`;

      let label_entity = viewer.entities.add({
        id: `label_${before}`,
        position: position,
        label: {
          text: text,
          scale: 0.5,
          font: "bold 30px Microsoft YaHei",
          fillColor: Cesium.Color.fromCssColorString("#fff"),
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: true,
          backgroundPadding: new Cesium.Cartesian2(10, 10),
        },
      });
      airline_entitys.push(label_entity);
    },
    edit_airlin() {
      let viewer = window.viewer;
      let currentPoint;
      let handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      // 对鼠标按下事件的监听
      handler.setInputAction((event) => {
        let windowPosition = event.position;
        let pickedObject = viewer.scene.pick(windowPosition);
        if (Cesium.defined(pickedObject)) {
          let entity = pickedObject.id;

          if (entity.label && entity.label.text) {
            point_index = entity.label.text._value - 1;
          }

          if (entity.name === "airline_point") {
            currentPoint = entity;
          }

          isEditting = true;
          viewer.scene.screenSpaceCameraController.enableRotate = false;
          viewer.scene.screenSpaceCameraController.enableZoom = false;
        }
      }, Cesium.ScreenSpaceEventType.LEFT_DOWN);

      // 对鼠标移动事件的监听
      handler.setInputAction((event) => {
        if (isEditting && currentPoint) {
          let windowPosition = event.startPosition;
          let ellipsoid = viewer.scene.globe.ellipsoid;
          let cartesian = viewer.camera.pickEllipsoid(
            windowPosition,
            ellipsoid
          );

          if (!cartesian) {
            return;
          }
          currentPoint.position = cartesian;

          if (point_index) {
            positions[point_index] = cartesian;
          }
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

      // 对鼠标抬起事件的监听
      handler.setInputAction((event) => {
        point_index = null;
        isEditting = false;
        currentPoint = undefined;
      }, Cesium.ScreenSpaceEventType.LEFT_UP);
    },
    setTime(time) {
      return (
        time.substring(0, 4) + "/" + time.substr(4, 2) + "/" + time.substring(6)
      );
    },
  },
  destroyed() {
    if (this.bubbles) {
      this.bubbles.windowClose();
    }
    this.clear_airline_entitys();
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-input__icon {
  line-height: 28px !important;
}

@import "~@/styles/var.scss";

.cpt-command-airway-list {
  @import "~@/styles/flightTask.scss";

  .routeLabel {
    position: absolute;
    right: -395px;
    z-index: 10;
    top: 20px;
  }

  position: absolute;
  left: 10px;
  top: 99px;
  height: 630px;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);

  .tagbox {
    width: 226px;
    height: 205px;
    background: rgba(22, 29, 45, 0.9);
    border: 1px solid #009fff;
    box-shadow: inset 0 0 7px 0 #008fff;
    border-radius: 2px;
    position: absolute;
    top: 200px;
    right: -230px;
    padding: 20px;
    box-sizing: border-box;

    .tagboxheader {
      display: flex;

      .tabtext {
        font-family: MicrosoftYaHeiUI;
        font-size: 16px;
        color: #ccedff;
        font-weight: 400;
      }

      .tagselect {
        font-family: MicrosoftYaHeiUI;
        font-size: 12px;
        color: #66808f;
        font-weight: 400;
        width: 98px;
        height: 24px;
        background: #02132f;
        border: 1px solid #009fff;
        border-radius: 4px 4px 0 0;
        margin-top: -5px !important;
        line-height: 24px;
        display: flex;
        justify-content: space-between;
      }
    }

    .tagbody {
      width: 100px;
      height: 87px;
      // overflow: hidden;
      overflow-y: auto;
      // display: grid;
      background: #02132f;
      border: 1px solid #009fff;
      border-radius: 0 0 4px 4px;
      position: absolute;
      top: 40px;
      right: 22px;
      padding: 6px 10px;
      box-sizing: border-box;

      .text {
        font-family: MicrosoftYaHeiUI;
        font-size: 12px;
        color: #ccedff;
        font-weight: 400;
      }
    }

    .tagend {
      display: flex;
      position: absolute;
      bottom: 20px;

      .btnbox {
        width: 76px;
        height: 27px;
        background: rgba(0, 34, 140, 0.2);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 15px 0 #00b6ff;
        border-radius: 6px;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #00ffff;
        letter-spacing: 0;
        font-weight: 400;
        line-height: 27px;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .disabled {
    pointer-events: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1820px;
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;

    .right {
      display: flex;

      .all,
      .hand,
      .auto {
        width: 86px;
        height: 28px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #00ffff;
        letter-spacing: 0;
        font-weight: 400;
        background: rgba(0, 34, 140, 0.2);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 15px 0 #00b6ff;
        border-radius: 6px;
        line-height: 28px;
        text-align: center;
        margin-right: 8px;
        opacity: 0.5;
      }
    }
  }

  .hd-box {
    font-size: 20px;
    font-family: YouSheBiaoTiHei;
    color: #14faff;
    line-height: 26px;
    // text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
    background: linear-gradient(
      135deg,
      #e3aa77 0%,
      #f5cda9 38%,
      #f9ecd3 58%,
      #fcdbb1 79%,
      #edb07a 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
  }

  .list-box {
    height: calc(100% - 56px);
    box-sizing: border-box;
    padding: 0 12px 0 12px;

    .search-box {
      height: auto;
      margin: 12px 0 12px 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      .btn1027 {
        padding: 8px 18px;
      }

      .item-plan {
        width: 79px;
        height: 32px;
        background-image: url("~@/assets/yingkong1027/btn_lan.png");
        background-size: 100% 100%;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
      }

      .item-plan:hover {
        opacity: 0.5;
      }

      .item-input {
        width: 168px;
        margin-left: 10px;
        border: none;
        // margin-right: 10px;
        color: #08c2d1;
        border: none;

        ::v-deep .el-input__inner {
          height: 29px;
          background: rgba(2, 31, 51, 0);
          border: none;
          border-radius: 4px;
          font-family: MicrosoftYaHeiUI;
          font-size: 16px;
          color: #08c2d1;
          font-weight: 400;
          padding-left: 0;

          &::placeholder {
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #397c8b;
            line-height: 19px;
          }
        }
      }

      .andLinlineBtn {
        position: absolute;
        top: 65px;
        right: 160px;
        width: 130px;
        cursor: pointer;
        height: 32px;
        opacity: 0.8;
        font-family: PangMenZhengDao;
        font-size: 22px;
        color: #00ffff;
        text-align: center;
        font-weight: 400;
        line-height: 40px;
      }

      .routeLabelBtn {
        position: absolute;
        top: 65px;
        right: 20px;
        width: 130px;
        cursor: pointer;
        height: 40px;
        opacity: 0.8;
        font-family: PangMenZhengDao;
        font-size: 22px;
        color: #00ffff;
        text-align: center;
        font-weight: 400;
        line-height: 40px;
      }

      .routeLabelBtnDefault {
        background: rgba(0, 3, 36, 0.8);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5),
          inset 0 0 15px 0 rgba(0, 182, 255, 0.9);
        border-radius: 6px;
      }

      .routeLabelBtnActive {
        background: rgba(0, 34, 140, 0.2);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 10px 0 #00b6ff;
        border-radius: 6px;
      }
    }

    .tb-box {
      width: 1796px;
      height: 470px;
      background: rgba(8, 26, 58, 0.8);
      border-radius: 4px;
      border: 1px solid #315ec7;
      padding: 0 !important;
      margin: 0 0 27px 0;

      .tb-hd-box {
        width: 100%;
        height: 42px;
        background: #0c1f3d;

        .tb-hd {
          height: 100%;
          line-height: 42px;
          width: calc(100% / 8);
          white-space: nowrap;
          font-size: 16px;
          font-family: YouSheBiaoTiHei;
          color: #ebf9ff;
          letter-spacing: 1px;
          font-weight: 400;
        }

        .tb-hd:nth-child(6) {
          width: 400px;
        }
      }

      .tb-bd-box {
        width: 100%;
        height: calc(100% - 42px);

        .tb-tr:nth-child(odd) {
          background: rgba(73, 135, 210, 0.2);
        }

        .tb-tr:nth-child(even) {
          background: #0c1f3d;
        }

        .tb-tr {
          height: 42px;

          // cursor: pointer;
          &:hover {
            box-shadow: inset 0px 0px 10px 2px #3fcbff;
          }

          .td {
            width: calc(100% / 6.5);
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .td:nth-child(6) {
            width: 400px;
          }

          .last-td {
            text-align: center;
            width: 170px;

            .iconfont {
              margin-right: 7px;
            }
          }
        }
      }
    }
  }
}

.activeCss {
  box-shadow: inset 0px 0px 10px 2px #3fcbff !important;
}

.toubu {
  display: flex;

  .tiao {
    width: 26px;
    height: 26px;
    margin: 0 5px 0 8px;
  }

  .hd-box {
    // text-shadow: 0px 2px 4px #136791;
  }
}

.cpt-command-airway-list .tb-box {
  height: 100%;
}

.flex {
  display: flex;
}

.fangkuai {
  width: 170px;
  height: 32px;
  margin-left: 20px;
  background: #02173d;
  border-radius: 2px;
  border: 1px solid #315ec7;
}

/deep/ .el-input__suffix-inner {
  i {
    color: #43deff;
  }
}

// 滚动动画
.animate {
  padding-left: 20px;
  // font-size: 12px;
  // color: #000;
  display: inline-block;
  white-space: nowrap;
  animation: 5s wordsLoop linear infinite normal;
}

@keyframes wordsLoop {
  0% {
    transform: translateX(100%);

    -webkit-transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);

    // -webkit-transform: translateX(-100%);
  }
}

// @-webkit-keyframes wordsLoop {
//   0% {
//     transform: translateX(100%);

//     -webkit-transform: translateX(100%);
//   }

//   100% {
//     transform: translateX(-100%);

//     -webkit-transform: translateX(-100%);
//   }
// }
/deep/ .tb-tr .td {
  padding: 18px 0 !important;
}

.td {
  font-size: 14px !important;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN !important;
  font-weight: 400 !important;
  color: #ebf9ff !important;
}

// 提示框样式
/deep/ .td .el-tooltip {
  background: rgba(2, 19, 96, 0);
  border: 0px solid rgba(207, 234, 255, 0.33);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #ebf9ff;
}

// 空域状态
.status {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #00d45c;
  line-height: 19px;
}

//操作状态
.el-tooltip {
  opacity: 0.7;
}

.el-tooltip:hover {
  opacity: 1;
}

// 页签样式
.zongji {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #b5e5ff;
  line-height: 16px;
}

.douhao {
  margin: 0 5px 0 5px;
}

.dangqianye {
  margin: 0 20px 0 0;
}

/deep/.el-pager .number:hover {
  background: rgba(38, 71, 238, 0.71) !important;
  border-radius: 2px;
  color: #000 !important;
  width: 14px;
  height: 22px;
  line-height: 22px;
}

/deep/.el-pager .number:hover {
  border-radius: 2px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  background: rgba(38, 71, 238, 0.71) !important;
  width: 35.5px;
  height: 28px;
  line-height: 28px;
}

/deep/ .el-pagination--small .el-pager .number {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #889fb2;
}
/deep/.active {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  background: rgba(38, 71, 238, 0.71) !important;
}

.btnqueding {
  margin: 0 0 0 8px;
  width: 79px;
  height: 32px;
  background-image: url("~@/assets/yingkong1027/btn_hei.png");
  background-size: 100% 100%;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #43deff;
  line-height: 7px;
}

.btnqueding:hover {
  opacity: 0.5 !important;
}

.tb-pagination {
  margin: 0 0 19px 0;
  justify-content: end;
}

.zhuan {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #889fb2;
  line-height: 16px;

  input {
    width: 48px;
    min-width: 48px;
    max-width: auto;
    text-align: center;
    height: 28px;
    background: rgba(12, 13, 20, 0.5);
    border-radius: 2px;
    border: 1px solid rgba(36, 146, 252, 0.3);
    margin: 0 5px 0 5px;
    color: #fff;
    outline: 0px solid;
  }
}

// 说明
.shuo {
  margin: 0 0 29px 0;
}

.shuoming {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #43deff;
  line-height: 16px;
}

.maohao {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #43deff;
  line-height: 16px;
  margin: 0 14px 0 5px;
}

.icons {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #b3bbc5;
  line-height: 16px;
  margin: 0 12px 0 0;
}

/deep/ .el-tooltip__popper .is-dark {
  z-index: -100;
}
</style>
<style lang="scss" scoped>
.cpt-command-airway-list::v-deep {
  .xiala .el-scrollbar {
    background: rgba(0, 3, 36, 0.9) !important;
  }

  .xiala .el-scrollbar li {
    color: #00aeff !important;
  }

  .xiala .el-scrollbar ul li:hover {
    background: #34466e !important;
  }

  .xianshi {
    cursor: pointer;
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 24px;
    text-shadow: 0px 2px 4px #136791;
  }

  .fontOne {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #d2dfff;
    line-height: 20px;
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
    width: 468px;
    height: 196px;
    background: rgba(12, 34, 73, 0.7);
    border-radius: 10px;
    border: 1px solid rgba(26, 92, 246, 0.5);
    backdrop-filter: blur(1px);
  }

  .safety_heard {
    width: 100% !important;
  }

  .safety_center {
    width: 100%;
    height: calc(100% - 32px);
    box-sizing: border-box;
    padding: 36px 0 0 0;

    .text {
      text-align: center;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
    }

    .btn {
      margin: 44px 0 0 0;
      display: flex;
      justify-content: center;
      width: 100%;

      .btn_an {
        font-size: 14px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #e0f6ff;
        width: 90px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
      }

      .btn_xiao {
        background-image: url("~@/assets/yingkong1027/btn_lv.png");
        background-size: 100% 100%;
        margin: 0 62px 0 0;
      }

      .btn_que {
        background-image: url("~@/assets/yingkong1027/btn_lan.png");
        background-size: 100% 100%;
      }
    }
  }

  .ctrl-icon {
    margin-right: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .ctrl-icon__view {
      width: 21px;
    }

    .ctrl-icon__view-no {
      width: 21px;
    }

    .ctrl-icon__del {
      width: 20px;
    }

    .ctrl-icon__security {
      width: 18px;
    }

    .ctrl-icon__label {
      width: 20px;
    }
  }

  .bj .el-dialog {
    background: transparent !important;
  }

  .down {
    display: none;
  }
}
</style>