<template>
  <div class="wrap">
    <div class="search">
      <el-input placeholder="请输入筛选的标签名称" v-model="search_value" class="input-with-select" clearable>
        <el-button slot="append" icon="el-icon-search" @click="handle_search"></el-button>
      </el-input>
    </div>
    <div>
      <el-button type="primary" class="btn-add" @click="handle_add_tag">+新标签</el-button>
    </div>
    <div class="list">
      <div v-for="item in tagList" :key="item.id" class="item">
        <div>
          <span>{{item.name}}</span>
        </div>
        <div>
          <el-button type="text" @click="handle_edit_tag(item)">编辑</el-button>
          <el-button type="text" @click="handle_deleted_tag(item)">删除</el-button>
        </div>
      </div>
    </div>
    <div class="page">
      <el-pagination
        small
        background
        :pager-count="5"
        @current-change="handle_current_change"
        layout="prev, pager, next, jumper"
        :page-size="pageSize"
        :total="totalCount">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { mapMutations, mapGetters } from 'vuex'

export default {
  data() {
    return {
      search_value: '',
      pageSize: 20,
      pageNo: 1,
      totalCount: 0,
      tagList: []
    }
  },
  computed: {
    ...mapGetters(["current_tag_id", "current_tag_data"])
  },
  watch: {
    current_tag_id: {
      handler(val){
        if(val === 'update') {
          this.pageNo = 1
          this.get_tag_list()
        }
      },
      // deep:true
    },
  },
  methods: {
    ...mapMutations({
      set_tag_modal_visible(commit, bool) {
        commit('mapmanage/SET_TAG_MODAL_VISIBLE', bool)
      },
      set_current_tag_id(commit, id) {
        commit('mapmanage/SET_CURRENT_TAG_ID', id)
      },
      set_current_tag_data(commit, id) {
        commit('mapmanage/SET_CURRENT_TAG_DATA', id)
      }
    }),
    async get_tag_list() {
      let res = await API.MAPMANAGE.GetTagList({
        name: this.search_value,
        pageSize: this.pageSize,
        pageNo: this.pageNo
      });
			this.tagList = res.data
      this.totalCount = res.totalCount
    },
    handle_current_change(val) {
      this.pageNo = val
      this.get_tag_list()
    },
    handle_search() {
      this.pageNo = 1
      this.get_tag_list()
    },
    handle_add_tag() {
      this.set_tag_modal_visible(true)
      this.set_current_tag_id('add')
      this.set_current_tag_data({})
    },
    handle_edit_tag(item) {
      this.set_tag_modal_visible(true)
      this.set_current_tag_id(item.id)
      this.set_current_tag_data(item)
    },
    async handle_deleted_tag(item) {
      let res = await API.MAPMANAGE.DropTag({
        id: item.id
      });
      if(res) {
        this.$message({
          message: '删除成功',
          type: 'success'
        });
        this.get_tag_list()
      } else {
        this.$message.error('删除失败');
      }
    }
  },
  mounted() {
    this.get_tag_list()
  }
};
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
  .search {
    margin-top: 15px;
  }
}
::v-deep {
  .el-input__inner {
    color: #fff;
    background: #000000;
    border: 1px solid #08C2D1;
  }
  .el-input-group__append {
    font-size: 24px;
    font-weight: 700;
    color: #08C2D1;
    background: #000000;
    border-right: 1px solid #08C2D1;
    border-top: 1px solid #08C2D1;
    border-bottom: 1px solid #08C2D1;
    border-left: none;
  }
}
.btn-add {
  width: 100%;
  margin: 10px 0;
  background: #000000;
  border: 1px solid #08C2D1;
}
.item {
  display: flex;
  justify-content: space-between;
  line-height: 40px;
  .btn-deleted {
    color: #f56c6c;
  }
}
.list {
  padding: 0 5px;
  height: calc(100% - 180px);
  padding-top: 20px;
  overflow-y: scroll;
}
.page {
  padding: 0 0 20px;
}
</style>