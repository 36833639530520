<template>
    <SpotTone />
</template>

<script>
import SpotTone from '@/components/command/spottone/index'
import data from './data'
import methods from './methods'

export default {
  components: { SpotTone },
  data() {
    return data
  },
  methods: {
    ...methods,
  },
  inject: ['g_cesium_toolbar','g_cesium_layer'],
  mounted() {
    this.g_cesium_toolbar().moveToolbar('left')
  },
}
</script>
