<!-- 设备管理 -->
<!-- 设备出入库统计 -->
<template>
  <div class="cpt-command-device-library">
    <div class="flexs justify-between items-center">
      <div class="flexs height">
        <div class="waikuang active">
          <img
            src="~@/assets/images/equipmentImges/设备出入库统计.png"
            style="width: 16px; height: 16px; margin-right: 4px;"
            alt=""
          />
          <span>设备出入库统计</span>
        </div>
        <div class="waikuang" @click="Statistics">
          <img
            src="~@/assets/images/equipmentImges/设备状态统计.png"
            style="width: 15px; height: 15px; margin-right: 4px;"
            alt=""
          />
          <span>设备状态统计</span>
        </div>
      </div>
      <div class="header">
        <!-- <div class="hd-box">设备出入库统计</div> -->
        <el-select
          v-model="type"
          class="input-excute"
          placeholder="请选择设备类型"
        >
          <el-option label="无人机" :value="1"></el-option>
          <el-option label="鹰巢" :value="2"></el-option>
          <el-option label="挂载" :value="3"></el-option>
        </el-select>
      </div>
    </div>
    <div class="bd-box-chart">
      <div id="library_container"></div>
    </div>
  </div>
</template>

<script>
import { Chart } from "@antv/g2";
import API from "@/api";
export default {
  data() {
    return {
      libraryData: [],
      type: 1,
      chart: null,
    };
  },
  async mounted() {
    await this.getLibraryInfo();
    let arr = this.libraryData.reverse();
    this.init(arr, "library_container");
  },
  watch: {
    async type(newVal) {
      await this.getLibraryInfo();
      this.chart.destroy();
      let arr = this.libraryData.reverse();
      this.init(arr, "library_container");
    },
  },
  methods: {
    Statistics() {
      this.$parent.Equipment();
    },
    async getLibraryInfo() {
      let { type } = this;
      let res = await API.DEVICE.LibraryInfo({ type: type });
      this.libraryData = res;
    },
    init(data, dataName) {
      let _this = this;
      // let count_arr = []
      // let tickInterval = 2
      // data.forEach((item) => {
      //   count_arr.push(item.count)
      // })
      // let max_count = Math.max(...count_arr)
      // if (max_count <= 10) {
      //   tickInterval = 2
      // } else if (max_count > 10 && max_count <= 20) {
      //   tickInterval = 4
      // } else if (max_count > 20 && max_count <= 30) {
      //   tickInterval = 6
      // } else {
      //   tickInterval = 8
      // }

      const chart = (this.chart = new Chart({
        container: dataName,
        autoFit: true,
        height: 250,
        appendPadding: [0, 25, 0, 0], // 上，右，下，左
      }));
      chart.data(data);

      chart.axis("name", {
        title: null,
        tickLine: null,
        line: null,
        label: {
          autoHide: false,
          style: {
            fill: "#fff",
          },
        },
      });



      chart.scale("count", {
        nice: true,
        alias: "数量",
      });

      chart.axis("count", {
        label: null,
        title: {
          offset: 30,
          style: {
            fontSize: 12,
            fontWeight: 300,
            fill: "#fff",
          },
        },
        grid: null,
      });
      chart.legend(false);
      // chart.legend({
      //   // position: 'top',
      //   itemName: {
      //     style: {
      //       fill: '#fff',
      //     },
      //   },
      // })
      chart.coordinate().transpose();
      chart
        .interval({
          background: {
            style: {
              radius: 20,
              fill: "#07032C",
            },
          },
        })
        .position("name*count")
        .size(20)
        .label("count", {
          style: {
            fill: "#fff",
          },
          offset: 10,
        })
        .color("l(0) 0:#068CFF 1:#8F10FF")
        .style({ radius: [20, 20, 20, 20] });
      chart.interaction("element-active");
      chart.interaction("element-single-selected", {
        start: [{ trigger: "element:mousemove", action: "cursor:pointer" }],
        end: [{ trigger: "element:mouseleave", action: "cursor:default" }],
      });
      chart.render();

      chart.on("element:click", async (ev) => {
        if (ev?.data?.data) {
          let {
            data: {
              data: { id, status },
            },
          } = ev;
          // console.log(id,status,"发送数据")  1无人机 2鹰巢 3挂载
          _this.$emit("handleChart", id, status);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-command-device-library {
  box-sizing: border-box;
  width: 100%;
  height: 318px;
  background: rgba(12,34,73,0.7);
  border-radius: 10px;
  border: 1px solid rgba(26,92,246,0.5);
  backdrop-filter: blur(1px);
  padding: 16px;
  display: flex;
  flex-direction: column;

  .header {
    // display: flex;
    // justify-content: space-around;
    // margin-top: 25px;
    // padding-left: 50px;
  }
  .hd-box {
    font-family: PangMenZhengDao;
    font-size: 22px;
    color: #dce9ff;
    font-weight: 400;
  }
  ::v-deep {
    .el-select {
      .el-input__inner {
        width: 170px;
        height: 32px;
        background: #02173D;
        border-radius: 2px;
        border: 1px solid #315EC7;
      }
      .el-select__caret{
        line-height: 32px;
      }
    }
  }
}
.bd-box-chart {
  // height: 388px;
  margin-top: 12px;
  overflow: auto;
}
#library_container {
  height: 600px;
}

.flexs {
  display: flex;
}
.waikuang {
  height: 32px;
  border: 1px rgba(49, 94, 199, 1) solid;
  border-radius: 3px;
  background-color: rgba(2, 23, 61, 1);
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #D2D9FF;
  cursor: pointer;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;

  &.active{
    background-image: url('~@/assets/images/monitoring/btn_active.png');
    background-size: 100% 100%;
  }

  &:hover {
    background-image: url('~@/assets/images/monitoring/btn_active.png');
    background-size: 100% 100%;
  }
}
.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}
.btn-start {
  background: rgba(20, 45, 92, 0.6);
  box-shadow: 1px 1px 2px 0px rgba(3, 16, 50, 0.5);
  border-radius: 6px;
  opacity: 0.6;
  border: 1px solid #43deff;
}
.xian {
  width: 100%;
  height: 1px;
  background: linear-gradient(
    207deg,
    rgba(36, 146, 252, 0) 0%,
    #2492fc 53%,
    rgba(36, 146, 252, 0) 100%
  );
  margin: 16px 0 0 0;
}
.imgs {
  width: 24px;
  height: 24px;
}

/deep/.el-select__caret{
  margin-top: 0 !important;
}
</style>
