export function AddRadarScanPostStage(
  viewer,
  cartographicCenter,
  radius,
  scanColor,
  duration
) {
  var ScanSegmentShader =
    "uniform sampler2D colorTexture;\n" +
    "uniform sampler2D depthTexture;\n" +
    "varying vec2 v_textureCoordinates;\n" +
    "uniform vec4 u_scanCenterEC;\n" +
    "uniform vec3 u_scanPlaneNormalEC;\n" +
    "uniform vec3 u_scanLineNormalEC;\n" +
    "uniform float u_radius;\n" +
    "uniform vec4 u_scanColor;\n" +
    "vec4 toEye(in vec2 uv, in float depth)\n" +
    " {\n" +
    " vec2 xy = vec2((uv.x * 2.0 - 1.0),(uv.y * 2.0 - 1.0));\n" +
    " vec4 posInCamera =czm_inverseProjection * vec4(xy, depth, 1.0);\n" +
    " posInCamera =posInCamera / posInCamera.w;\n" +
    " return posInCamera;\n" +
    " }\n" +
    "bool isPointOnLineRight(in vec3 ptOnLine, in vec3 lineNormal, in vec3 testPt)\n" +
    "{\n" +
    "vec3 v01 = testPt - ptOnLine;\n" +
    "normalize(v01);\n" +
    "vec3 temp = cross(v01, lineNormal);\n" +
    "float d = dot(temp, u_scanPlaneNormalEC);\n" +
    "return d > 0.5;\n" +
    "}\n" +
    "vec3 pointProjectOnPlane(in vec3 planeNormal, in vec3 planeOrigin, in vec3 point)\n" +
    "{\n" +
    "vec3 v01 = point -planeOrigin;\n" +
    "float d = dot(planeNormal, v01) ;\n" +
    "return (point - planeNormal * d);\n" +
    "}\n" +
    "float distancePointToLine(in vec3 ptOnLine, in vec3 lineNormal, in vec3 testPt)\n" +
    "{\n" +
    "vec3 tempPt = pointProjectOnPlane(lineNormal, ptOnLine, testPt);\n" +
    "return length(tempPt - ptOnLine);\n" +
    "}\n" +
    "float getDepth(in vec4 depth)\n" +
    "{\n" +
    "float z_window = czm_unpackDepth(depth);\n" +
    "z_window = czm_reverseLogDepth(z_window);\n" +
    "float n_range = czm_depthRange.near;\n" +
    "float f_range = czm_depthRange.far;\n" +
    "return (2.0 * z_window - n_range - f_range) / (f_range - n_range);\n" +
    "}\n" +
    "void main()\n" +
    "{\n" +
    "gl_FragColor = texture2D(colorTexture, v_textureCoordinates);\n" +
    "float depth = getDepth( texture2D(depthTexture, v_textureCoordinates));\n" +
    "vec4 viewPos = toEye(v_textureCoordinates, depth);\n" +
    "vec3 prjOnPlane = pointProjectOnPlane(u_scanPlaneNormalEC.xyz, u_scanCenterEC.xyz, viewPos.xyz);\n" +
    "float dis = length(prjOnPlane.xyz - u_scanCenterEC.xyz);\n" +
    "float twou_radius = u_radius * 2.0;\n" +
    "if(dis < u_radius)\n" +
    "{\n" +
    "float f0 = 1.0 -abs(u_radius - dis) / u_radius;\n" +
    "f0 = pow(f0, 64.0);\n" +
    "vec3 lineEndPt = vec3(u_scanCenterEC.xyz) + u_scanLineNormalEC * u_radius;\n" +
    "float f = 0.0;\n" +
    "if(isPointOnLineRight(u_scanCenterEC.xyz, u_scanLineNormalEC.xyz, prjOnPlane.xyz))\n" +
    "{\n" +
    "float dis1= length(prjOnPlane.xyz - lineEndPt);\n" +
    "f = abs(twou_radius -dis1) / twou_radius;\n" +
    "f = pow(f, 3.0);\n" +
    "}\n" +
    "gl_FragColor = mix(gl_FragColor, u_scanColor, f + f0);\n" +
    "}\n" +
    "}\n";

  var _Cartesian3Center = Cesium.Cartographic.toCartesian(cartographicCenter);
  var _Cartesian4Center = new Cesium.Cartesian4(
    _Cartesian3Center.x,
    _Cartesian3Center.y,
    _Cartesian3Center.z,
    1
  );
  var _CartographicCenter1 = new Cesium.Cartographic(
    cartographicCenter.longitude,
    cartographicCenter.latitude,
    cartographicCenter.height + 500
  );
  var _Cartesian3Center1 = Cesium.Cartographic.toCartesian(
    _CartographicCenter1
  );
  var _Cartesian4Center1 = new Cesium.Cartesian4(
    _Cartesian3Center1.x,
    _Cartesian3Center1.y,
    _Cartesian3Center1.z,
    1
  );
  var _CartographicCenter2 = new Cesium.Cartographic(
    cartographicCenter.longitude + Cesium.Math.toRadians(0.001),
    cartographicCenter.latitude,
    cartographicCenter.height
  );
  var _Cartesian3Center2 = Cesium.Cartographic.toCartesian(
    _CartographicCenter2
  );
  var _Cartesian4Center2 = new Cesium.Cartesian4(
    _Cartesian3Center2.x,
    _Cartesian3Center2.y,
    _Cartesian3Center2.z,
    1
  );
  var _RotateQ = new Cesium.Quaternion();
  var _RotateM = new Cesium.Matrix3();
  var _time = new Date().getTime();
  var _scratchCartesian4Center = new Cesium.Cartesian4();
  var _scratchCartesian4Center1 = new Cesium.Cartesian4();
  var _scratchCartesian4Center2 = new Cesium.Cartesian4();
  var _scratchCartesian3Normal = new Cesium.Cartesian3();
  var _scratchCartesian3Normal1 = new Cesium.Cartesian3();
  var ScanPostStage = new Cesium.PostProcessStage({
    fragmentShader: ScanSegmentShader,
    uniforms: {
      u_scanCenterEC: function() {
        return Cesium.Matrix4.multiplyByVector(
          viewer.camera._viewMatrix,
          _Cartesian4Center,
          _scratchCartesian4Center
        );
      },
      u_scanPlaneNormalEC: function() {
        var temp = Cesium.Matrix4.multiplyByVector(
          viewer.camera._viewMatrix,
          _Cartesian4Center,
          _scratchCartesian4Center
        );
        var temp1 = Cesium.Matrix4.multiplyByVector(
          viewer.camera._viewMatrix,
          _Cartesian4Center1,
          _scratchCartesian4Center1
        );
        _scratchCartesian3Normal.x = temp1.x - temp.x;
        _scratchCartesian3Normal.y = temp1.y - temp.y;
        _scratchCartesian3Normal.z = temp1.z - temp.z;
        Cesium.Cartesian3.normalize(
          _scratchCartesian3Normal,
          _scratchCartesian3Normal
        );
        return _scratchCartesian3Normal;
      },
      u_radius: radius,
      u_scanLineNormalEC: function() {
        var temp = Cesium.Matrix4.multiplyByVector(
          viewer.camera._viewMatrix,
          _Cartesian4Center,
          _scratchCartesian4Center
        );
        var temp1 = Cesium.Matrix4.multiplyByVector(
          viewer.camera._viewMatrix,
          _Cartesian4Center1,
          _scratchCartesian4Center1
        );
        var temp2 = Cesium.Matrix4.multiplyByVector(
          viewer.camera._viewMatrix,
          _Cartesian4Center2,
          _scratchCartesian4Center2
        );
        _scratchCartesian3Normal.x = temp1.x - temp.x;
        _scratchCartesian3Normal.y = temp1.y - temp.y;
        _scratchCartesian3Normal.z = temp1.z - temp.z;
        Cesium.Cartesian3.normalize(
          _scratchCartesian3Normal,
          _scratchCartesian3Normal
        );
        _scratchCartesian3Normal1.x = temp2.x - temp.x;
        _scratchCartesian3Normal1.y = temp2.y - temp.y;
        _scratchCartesian3Normal1.z = temp2.z - temp.z;
        var tempTime = ((new Date().getTime() - _time) % duration) / duration;
        Cesium.Quaternion.fromAxisAngle(
          _scratchCartesian3Normal,
          tempTime * Cesium.Math.PI * 2,
          _RotateQ
        );
        Cesium.Matrix3.fromQuaternion(_RotateQ, _RotateM);
        Cesium.Matrix3.multiplyByVector(
          _RotateM,
          _scratchCartesian3Normal1,
          _scratchCartesian3Normal1
        );
        Cesium.Cartesian3.normalize(
          _scratchCartesian3Normal1,
          _scratchCartesian3Normal1
        );
        return _scratchCartesian3Normal1;
      },
      u_scanColor: scanColor,
    },
  });
  viewer.scene.postProcessStages.add(ScanPostStage);
  return ScanPostStage;
}

/*
  添加扫描线 depth关闭   lon:-74.01296152309055 lat:40.70524201566827 height:129.14366696393927
  viewer
  cartographicCenter 扫描中心
  maxRadius 最大半径 米
  scanColor 扫描颜色
  duration 持续时间 毫秒
  */
export function AddCircleScanPostStage(
  viewer,
  cartographicCenter,
  maxRadius,
  scanColor,
  duration
) {
  var ScanSegmentShader =
    "uniform sampler2D colorTexture;\n" +
    "uniform sampler2D depthTexture;\n" +
    "varying vec2 v_textureCoordinates;\n" +
    "uniform vec4 u_scanCenterEC;\n" +
    "uniform vec3 u_scanPlaneNormalEC;\n" +
    "uniform float u_radius;\n" +
    "uniform vec4 u_scanColor;\n" +
    "vec4 toEye(in vec2 uv, in float depth)\n" +
    " {\n" +
    " vec2 xy = vec2((uv.x * 2.0 - 1.0),(uv.y * 2.0 - 1.0));\n" +
    " vec4 posInCamera =czm_inverseProjection * vec4(xy, depth, 1.0);\n" +
    " posInCamera =posInCamera / posInCamera.w;\n" +
    " return posInCamera;\n" +
    " }\n" +
    "vec3 pointProjectOnPlane(in vec3 planeNormal, in vec3 planeOrigin, in vec3 point)\n" +
    "{\n" +
    "vec3 v01 = point -planeOrigin;\n" +
    "float d = dot(planeNormal, v01) ;\n" +
    "return (point - planeNormal * d);\n" +
    "}\n" +
    "float getDepth(in vec4 depth)\n" +
    "{\n" +
    "float z_window = czm_unpackDepth(depth);\n" +
    "z_window = czm_reverseLogDepth(z_window);\n" +
    "float n_range = czm_depthRange.near;\n" +
    "float f_range = czm_depthRange.far;\n" +
    "return (2.0 * z_window - n_range - f_range) / (f_range - n_range);\n" +
    "}\n" +
    "void main()\n" +
    "{\n" +
    "gl_FragColor = texture2D(colorTexture, v_textureCoordinates);\n" +
    "float depth = getDepth( texture2D(depthTexture, v_textureCoordinates));\n" +
    "vec4 viewPos = toEye(v_textureCoordinates, depth);\n" +
    "vec3 prjOnPlane = pointProjectOnPlane(u_scanPlaneNormalEC.xyz, u_scanCenterEC.xyz, viewPos.xyz);\n" +
    "float dis = length(prjOnPlane.xyz - u_scanCenterEC.xyz);\n" +
    "if(dis < u_radius)\n" +
    "{\n" +
    "float f = 1.0 -abs(u_radius - dis) / u_radius;\n" +
    "f = pow(f, 4.0);\n" +
    "gl_FragColor = mix(gl_FragColor, u_scanColor, f);\n" +
    "}\n" +
    "}\n";

  var _Cartesian3Center = Cesium.Cartographic.toCartesian(cartographicCenter);

  var _Cartesian4Center = new Cesium.Cartesian4(
    _Cartesian3Center.x,
    _Cartesian3Center.y,
    _Cartesian3Center.z,
    1
  );

  var _CartographicCenter1 = new Cesium.Cartographic(
    cartographicCenter.longitude,
    cartographicCenter.latitude,
    cartographicCenter.height + 500
  );

  var _Cartesian3Center1 = Cesium.Cartographic.toCartesian(
    _CartographicCenter1
  );

  var _Cartesian4Center1 = new Cesium.Cartesian4(
    _Cartesian3Center1.x,
    _Cartesian3Center1.y,
    _Cartesian3Center1.z,
    1
  );

  var _time = new Date().getTime();

  var _scratchCartesian4Center = new Cesium.Cartesian4();

  var _scratchCartesian4Center1 = new Cesium.Cartesian4();

  var _scratchCartesian3Normal = new Cesium.Cartesian3();

  var ScanPostStage = new Cesium.PostProcessStage({
    fragmentShader: ScanSegmentShader,

    uniforms: {
      u_scanCenterEC: function() {
        return Cesium.Matrix4.multiplyByVector(
          viewer.camera._viewMatrix,
          _Cartesian4Center,
          _scratchCartesian4Center
        );
      },

      u_scanPlaneNormalEC: function() {
        var temp = Cesium.Matrix4.multiplyByVector(
          viewer.camera._viewMatrix,
          _Cartesian4Center,
          _scratchCartesian4Center
        );

        var temp1 = Cesium.Matrix4.multiplyByVector(
          viewer.camera._viewMatrix,
          _Cartesian4Center1,
          _scratchCartesian4Center1
        );

        _scratchCartesian3Normal.x = temp1.x - temp.x;

        _scratchCartesian3Normal.y = temp1.y - temp.y;

        _scratchCartesian3Normal.z = temp1.z - temp.z;

        Cesium.Cartesian3.normalize(
          _scratchCartesian3Normal,
          _scratchCartesian3Normal
        );

        return _scratchCartesian3Normal;
      },

      u_radius: function() {
        return (
          (maxRadius * ((new Date().getTime() - _time) % duration)) / duration
        );
      },

      u_scanColor: scanColor,
    },
  });

  viewer.scene.postProcessStages.add(ScanPostStage);
  return ScanPostStage;
}

class CircleWaveMaterialProperty {
  constructor(options) {
    options = Cesium.defaultValue(options, Cesium.defaultValue.EMPTY_OBJECT);

    this._definitionChanged = new Cesium.Event();

    this._color = undefined;

    this._colorSubscription = undefined;

    this.color = options.color;

    this.duration = Cesium.defaultValue(options.duration, 1e3);

    this.count = Cesium.defaultValue(options.count, 2);

    if (this.count <= 0) this.count = 1;

    this.gradient = Cesium.defaultValue(options.gradient, 0.1);

    if (this.gradient < 0) this.gradient = 0;
    else if (this.gradient > 1) this.gradient = 1;

    this._time = performance.now();
  }
}

Object.defineProperties(CircleWaveMaterialProperty.prototype, {
  isConstant: {
    get: function() {
      return false;
    },
  },

  definitionChanged: {
    get: function() {
      return this._definitionChanged;
    },
  },

  color: Cesium.createPropertyDescriptor("color"),
});

CircleWaveMaterialProperty.prototype.getType = function(time) {
  return Cesium.Material.CircleWaveMaterialType;
};

CircleWaveMaterialProperty.prototype.getValue = function(time, result) {
  if (!Cesium.defined(result)) {
    result = {};
  }

  result.color = Cesium.Property.getValueOrClonedDefault(
    this._color,
    time,
    Cesium.Color.WHITE,
    result.color
  );

  result.time = (performance.now() - this._time) / this.duration;

  result.count = this.count;

  result.gradient = 1 + 10 * (1 - this.gradient);

  return result;
};

CircleWaveMaterialProperty.prototype.equals = function(other) {
  return (
    this === other ||
    (other instanceof CircleWaveMaterialProperty &&
      Cesium.Property.equals(this._color, other._color))
  );
};

Cesium.Material.CircleWaveMaterialType = "CircleWaveMaterial";

Cesium.Material.PolylineTrailSource = `czm_material czm_getMaterial(czm_materialInput materialInput)\n

                                      {\n

                                          czm_material material = czm_getDefaultMaterial(materialInput);\n

                                          material.diffuse = 1.5 * color.rgb;\n

                                          vec2 st = materialInput.st;\n

                                          vec3 str = materialInput.str;\n

                                          float dis = distance(st, vec2(0.5, 0.5));\n

                                          float per = fract(time);\n

                                          if (abs(str.z) > 0.001) {\n

                                              discard;\n

                                          }\n

                                          if (dis > 0.5) { \n

                                              discard; \n

                                          } else { \n

                                              float perDis = 0.5 / count;\n

                                              float disNum; \n                       

                                              float bl = .0; \n                       

                                              for (int i = 0; i <= 999; i++) { \n                           

                                                  if (float(i) <= count) { \n                               



                                                    disNum = perDis *

float(i) - dis + per / count; \n                             

                                                      if (disNum > 0.0) { \n                                 

                                                          if (disNum < perDis) { \n                                     

                                                              bl = 1.0 - disNum / perDis;\n

                                                          }\n                                 



                                                        else if

(disNum - perDis < perDis) { \n                                       

                                                                  bl = 1.0 - abs(1.0 - disNum / perDis); \n

                                                          } \n                                 

                                                          material.alpha = pow(bl, gradient); \n

                                                      } \n

                                                  } \n

                                              } \n

                                          } \n

                                      return material; \n

                                  } \n`;

Cesium.Material._materialCache.addMaterial(
  Cesium.Material.CircleWaveMaterialType,
  {
    fabric: {
      type: Cesium.Material.CircleWaveMaterialType,

      uniforms: {
        color: new Cesium.Color(1.0, 0.0, 0.0, 1.0),

        time: 1,

        count: 1,

        gradient: 0.1,
      },

      source: Cesium.Material.PolylineTrailSource,
    },

    translucent: function(material) {
      return !0;
    },
  }
);

Cesium.CircleWaveMaterialProperty = CircleWaveMaterialProperty;
