<!-- 鹰控汇集地 -->
<template>
  <div class="page-command">
    <Header
      title="鹰控"
      :user="user_info"
      @taps="taps"
      :type="headerType"
      :flag="page"
    />
    <PageTabs v-model="page" @isN="isNone = false" />

    <Home @changePage="changePage" v-if="page == 1" />
    <!-- 空域管理 -->
    <Airspace v-if="page == 3" />
    <!-- 航空航线 -->
    <Airway
      ref="airway"
      :isNone="isNone"
      @isN="isNone = true"
      v-if="page == 4"
    />
    <!-- 飞行任务 -->
    <FlightTask
      @returnEX="returnEX"
      :examineData="examineData"
      :active="active"
      :visible="visible"
      @close="change_page"
      @changeActive="(e) => (active = e)"
      ref="flightTask"
      v-show="page == 5"
    />
    <!-- 人员管理 -->
    <Person v-if="page == 6" />
    <!-- 设备管理 -->
    <Device v-if="page == 7" />
    <!-- 勤务考核 -->
    <Examine
      v-if="page == 10"
      :examineActive="examineActive"
      @jumpRoute="jumpRoute"
    />
    <TableExport v-if="page == 20"/>
    <!-- 图层管理 -->
    <!-- <MapManage v-if="page == 8" /> -->
    <!-- 设备监测 -->
    <Monitoring v-if="page == 11" />
    <!-- 影像管理 -->
    <!-- <ImageVideo v-if="page == 12" /> -->
    <!-- 实时点调 -->
    <SpotTone v-if="page == 16" />
    <NestList
      v-if="isNestList && page == 4"
      v-model="nest.selected"
      :async-list="list"
      :list="nest.list"
      @change="nestChange"
    />
    <!-- :list="nest.list" -->

    <!--  -->
    <!-- @item-location="nest_location" -->
  </div>
</template>

<script>
let locations = [];
import { mapGetters } from "vuex";
import Header from "@/components/command/header/index";
import PageTabs from "@/components/command/page-tabs/index";
import Home from "@/pages/command/home";
import NestList from "@/components/command/nest/list/index.vue";
import Airspace from "@/pages/command/airspace";
// 航空航线
import Airway from "@/pages/command/airway";

import MapManage from "@/pages/command/map-manage";
import Person from "@/pages/command/person/index";
import TableExport from "@/pages/command/tableExport/index";
import Device from "@/pages/command/device";
import Examine from "@/pages/command/examine";
import FlightTask from "@/pages/command/flight-task";
import Monitoring from "@/pages/command/monitoring";
import ImageVideo from "@/pages/command/imagevideo";
import SpotTone from "@/pages/command/spottone/index";
import API from "@/api";
export default {
  components: {
    Header,
    PageTabs,
    Home,
    Airspace,
    Airway,
    MapManage,
    Person,
    TableExport,
    Device,
    Examine,
    FlightTask,
    Monitoring,
    ImageVideo,
    SpotTone,
    NestList,
  },
  data() {
    return {
      page: 1,
      // 代表进入飞行任务页面后默认展示哪个页面 1代表警情 2代表常态 3代表临时任务 4代表飞行日志
      active: 2,
      visible: 1, //visible 2 代表派发页面
      isNone: false, //鹰控航空航线
      nest: {
        list: [],
        selected: {},
      },
      isNestList: false,
      headerType: "首页",
      examineData: {},
      examineActive: 1,
      sourcePage:null,//来源页面
    };
  },
  watch: {
    page: function (num, old) {

      localStorage.setItem("currentPage", num);
      localStorage.setItem("currentHeaderType", this.headerType);

      if (old == 5) {
        this.examineData = {};
      }
      if (num != 4) {
        this.isNestList = false;
      }

      //首页，设备监测，设备管理，人员管理，不需要展示地图
      if ([1, 11, 7, 6].includes(Number(num))) {
        this.$store.commit("mapmanage/SET_VISIBLE_MAIN_MAP", false);
        // this.SET_VISIBLE_MAIN_MAP(false);
      } else {
        this.$store.commit("mapmanage/SET_VISIBLE_MAIN_MAP", true);
        // this.SET_VISIBLE_MAIN_MAP(true);
      }

      if (![0, 5].includes(num)) {
        // console.log('window.$flightTask', window.$flightTask)
        //离开飞行任务时，清除航迹
        let viewer = window.viewer;
        if (window.$flightTask?.history_entity) {
          viewer.entities.remove(window.$flightTask.history_entity);
          window.$flightTask.history_entity = null;
        }
        if (window.$flightTask?.history_Line) {
          viewer.entities.remove(window.$flightTask.history_Line);
          window.$flightTask.history_Line = null;
        }
        if (window.$flightTask?.history_Name) {
          viewer.entities.remove(window.$flightTask.history_Name);
          window.$flightTask.history_Name = null;
        }
        if (window.$flightTask?.point_entity_began) {
          viewer.entities.remove(window.$flightTask.point_entity_began);
          window.$flightTask.point_entity_began = null;
        }
        if (window.$flightTask?.point_entity_over) {
          viewer.entities.remove(window.$flightTask.point_entity_over);
          window.$flightTask.point_entity_over = null;
        }
        if (window.$flightTask?.blueLine) {
          viewer.entities.remove(window.$flightTask.blueLine);
          window.$flightTask.blueLine = null;
        }
        if (window.$flightTask?.airline_entitys) {
          window.$flightTask?.airline_entitys.forEach((item) => {
            viewer.entities.remove(item);
          });
          window.$flightTask.airline_entitys = null;
        }
      }
    },

    sourcePage:function (num, old){
      localStorage.setItem("currentPage", this.sourcePage);
      console.log("currentPage", num);
    },

    headerType:function (num,old){
      localStorage.setItem("currentHeaderType", this.headerType);
      console.log("currentHeaderType", num);
    }
    // active:function(val,old){
    //   // if(old == 1){
    //     this.examineData = {}
    //   // }
    // },
    // $route(to, from) {
    //   if (to.fullPath !== "/command") {
    //     localStorage.removeItem("currentPage");
    //     localStorage.removeItem("currentHeaderType");
    //   }
    // },
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  inject: ["g_show_zysb", "g_hide_zysb", "g_cesium_resetJfq"],
  provide() {
    return {
      change_page: this.change_page,
      change_airway_visible: this.change_airway_visible,
      change_flightTask_view: this.change_flightTask_view,
      showNest_list: this.showNest_list,
    };
  },
  created() {
    // status = jq 代表要进入 任务列表中的警情任务
    let { page, version, status } = this.$route.query;
    console.log(status, "status");
    // this.page = page || 1;
    this.page = localStorage.getItem("currentPage") || page || 1; // 从本地存储中获取页面状态，默认为1
    this.headerType = localStorage.getItem("currentHeaderType") || "首页";

    console.log(this.page,this.headerType);
    // console.log(this.sourcePage);
    if (status == "jq") {
      this.active = 1;
    } else if (status == "pf") {
      this.active = 2;
      this.visible = 2;
    } else {
      this.active = 2;
    }
  },
  mounted() {
  
    if (this.$route.query.type) {
      this.headerType = this.$route.query.type;
      console.log(this.headerType);
      // this.$router.replace("/command");
      this.sourcePage = this.$route.query.page
    }
    if (this.page != 11) {
      this.$router.replace("/command");
      this.$store.commit("device/SET_FlAG", true);
    } else {
      //由于cesium地图默认创建，创建需要时间，而直接销毁cesium地图组件，会导致cesium未创建完成就立即调用destroy方法，出现报错，故设置为1秒后销毁
      /* this.$nextTick(() => {
        this.$store.commit('mapmanage/SET_VISIBLE_MAIN_MAP', false);
      }) */
    }

    document.title = "鹰控";
    // 回调执行函数 监控命名规则为 watch_+变量名
    this.g_bus.$on("watch_viewer", async () => {
      // 具体函数内容
      // await this.g_show_zysb(0, 0, 1);
      this.g_cesium_resetJfq();
      // console.log('this.page', this.page)
      if (this.page == 1) {
        await this.g_hide_zysb({ cate_id: 1 });
        this.$store.commit("device/SET_FlAG", true);
      }
    });
  },

  beforeUnmount() {
    localStorage.removeItem("currentPage");
    localStorage.removeItem("currentHeaderType");
  },

  methods: {
    returnEX(e) {
      console.log(e.active, "eeeeee");
      if (e.active == 2) {
        this.examineActive = 1;
      } else if (e.active == 1) {
        this.examineActive = 2;
      }
      setTimeout(() => {
        this.page = 10;
      }, 1000);
    },
    jumpRoute(val) {
      this.examineData = JSON.parse(JSON.stringify(val));
      this.active = this.examineData.active;
      this.page = 5;
    },
    taps(val) {
      console.log(val);
      this.headerType = val.title;
    },
    changePage(val) {
      console.log("changePage", val);
      this.page = val;

      switch (val) {
        case 5:
          this.headerType = "调度中心";
          break;

        case 1:
          this.headerType = "首页";
          break;

        case 6:
          this.headerType = "人员管理";
          break;

        case 7:
          this.headerType = "设备管理";
          break;

        case 11:
          this.headerType = "设备监测";
          break;

        default:
          this.headerType = "所有";
          break;
      }
    },
    showNest_list(flag) {
      this.isNestList = flag;
    },
    async list() {
      let data = await API.NEST.getNestList();
      //筛选可操控飞机鹰巢
      // data.map(item => item.devices= item.devices.filter(val =>
      //     [4, 6, 8, 9, 11].includes(val.id)
      // ))

      function each(tree, data) {
        return tree.map((item) => ({
          ...item,
          offLineCount: data.offLineCount,
          onLineCount: data.onLineCount,
          collapse: false,
          child_collapse: false,
          child: item.child && item.child.length && each(item.child),
        }));
      }
      if (data.tree && data.tree.length) {
        data = each(data.tree, data);
      }
      this.nest = {
        list: data || [],
        selected: this.nest.selected,
      };
      console.log(this.nest, "1231231");
    },
    nestChange(device) {
      console.log(device, "device");
      if (device) {
        if (device.latitude && device.longitude) {
          let viewer = window.viewer;
          let f = viewer.entities.add({
            position: Cesium.Cartesian3.fromDegrees(
              Number(device.longitude),
              Number(device.latitude)
            ),
            billboard: {
              image: require("@/assets/images/icons/icon_location.png"),
              width: 30,
              height: 30,
              distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0),
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
            },
          });
          locations.push(f);
          console.log(viewer, "viewer");
          console.log(f, "f");
          viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(
              Number(device.longitude),
              Number(device.latitude),
              30000
            ),
          });

          // let position = Utils.transformWGS842Cartesian({
          //     lng: device.longitude,
          //     lat: device.latitude,
          // });

          // viewer.camera.flyTo({
          //     destination: Utils.transformWGS842Cartesian({
          //         lng: device.longitude,
          //         lat: device.latitude,
          //         alt: 1000
          //     })
          // });

          // if (location_icon) {
          //     location_icon.position = position;
          // } else {
          //     location_icon = viewer.entities.add({
          //         position: viewer.scene.clampToHeight(position),
          //         billboard: {
          //             width: 36,
          //             height: 36,
          //             image: require("@/assets/images/icons/icon_location.png"),
          //             horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          //             verticalOrigin: Cesium.VerticalOrigin.Bottom,
          //             heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          //         },
          //     });
          // }
        } else {
          let viewer = window.viewer;
          locations.forEach((item, index) => viewer.entities.remove(item));
          this.$el_message("该鹰巢没有经纬度", () => {}, "warning");
        }
      }
    },

    cesium_layer_change_mode(fn, modes) {
      window.viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(
          120.218241,
          33.165767,
          21812.321476599747
        ),
        orientation: {
          heading: 0.006918732929549698,
          pitch: -0.6928665494487145,
          roll: 7.638334409421077e-14,
        },
        time: 5,
      });
    },
    // 鹰控关闭航空航线
    change_page(item) {
      if (item) {
        this.page = item;
      } else {
        this.page = 0;
      }
    },
    change_airway_visible() {
      let _this = this;
      this.$nextTick(() => {
        _this.$refs.airway.airway.add.visible = true;
      });
    },

    change_flightTask_view() {
      let _this = this;
      this.page = 5;
      this.$nextTick(() => {
        _this.$refs.flightTask.chage_view(11);
      });
    },
  },


  beforeRouteLeave(to, from, next) {
    if (from.fullPath == "/command") {
      localStorage.removeItem("currentPage");
      localStorage.removeItem("currentHeaderType");
    }
    next();
  },
};
</script>
<style lang="scss" scoped>
.task {
  background: rgba(9, 32, 87, 0.7);
  border-radius: 10px;
  color: #fff;
  position: absolute;
  top: 90px;
  left: 20px;
  text-align: center;
  line-height: 50px;
}
</style>
