<template>
  <div class="cpt-command-device-mount cpt-command-device-common mb14">
    <div class="head pl8">
      <img src="~@/assets/images/mount/mount_head.png" />
      <div class="fontO">警情统计</div>
    </div>
    <div class="divSC">
      <div class="search-box-statistics">
        <el-date-picker  size="mini" prefix-icon="ccc" v-model="pickTime" type="datetimerange" range-separator="至"
            value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" popper-class="flight-log">
          </el-date-picker>
      </div>
      <div class="btn">
        <div @click="handle_search" class="btn1027">搜索</div>
      </div>
    </div>
    <div class="bd-box">
      <div class="show">
      <div class="mile">
        <div class="num">{{jqData.receiveNumber || 0}}</div>
        <div class="type">接收</div>
      </div>
      <div class="mile">
        <div class="num">{{jqData.distributeNumber || 0}}</div>
        <div class="type">派发</div>
      </div>
      <div class="mile">
        <div class="num">{{jqData.approveNumber || 0}}</div>
        <div class="type">审批</div>
      </div>
      <div class="mile">
        <div class="num">{{jqData.finishNumber || 0}}</div>
        <div class="type">完成</div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  data() {
    return {
      pickTime: null,
      filter: {
        pageNo: 1,
        pageSize: 10,
      },
      jqData: {},
    };
  },

  mounted() {
    this.getRank();
  },

  methods: {
    handle_search() {
      this.getRank();
    },
    async getRank() {
      if (this.pickTime) {
        this.filter.startDate = this.pickTime[0];
        this.filter.endDate = this.pickTime[1];
      } else {
        this.filter.startDate = null;
        this.filter.endDate = null;
      }
      let res = await API.TABLEEXPORT.statisticsPoliceTaskStatusNumber(this.filter);
      console.log(res,"dsssss");
      this.jqData = res
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/person1027.scss";
.cpt-command-device-mount {
  height: 340px !important;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  backdrop-filter: blur(1px);
  width: 48.6% !important;
  border: 1px solid #315ec7;
  .head {
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;
    display: flex;
    .fontO {
      font-size: 20px;
      font-family: YouSheBiaoTiHei;
      color: #14faff;
      line-height: 26px;
      text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
      background: linear-gradient(
        135deg,
        #e3aa77 0%,
        #f5cda9 38%,
        #f9ecd3 58%,
        #fcdbb1 79%,
        #edb07a 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
    .divSC {
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 16px;
  }
  .search-box-statistics {
    display: flex;
    align-items: center;
  }
  .btn {
    display: flex;
    align-items: center;
  }
  .el-select{
    display: flex;
    align-items: center;
  }
  .btn1027 {
  padding: 5px 20px;
  white-space: nowrap;
  margin-left: 10px;
  margin-right: 0 !important;
  height: 32px;
}

}
.bd-box {
  margin-left: 16px;
  width: 96%;
  height: 70%;
  background: rgba(8, 26, 58, 0.8);
  border-radius: 4px;
  border: 1px solid #315ec7;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  .show{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .mile{
      display: flex;
      flex-direction: column;
      align-items: center;
      .num{
        color: crimson;
        font-size: 18px;
        line-height: 36px;
      }
      .type{
        color: #fff;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}
/deep/ .el-date-editor {
  margin-left: 16px;
  padding: 0;
  border: 0px solid;
  background: rgba(13, 50, 92, 0) !important;
}
/deep/ .el-date-editor .el-range-input {
  background: rgba(2, 31, 51, 0.1);
  width: 390px;
  color: #fff;
}
</style>
