<template>
  <div>
    <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
      >
        <el-form-item label="电池名称:" prop="batteryName">
          <el-input
            v-model="ruleForm.batteryName"
            clearable
            placeholder="请输入电池名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="电池编号:" prop="batteryId">
          <el-input
            v-model="ruleForm.batteryId"
            clearable
            placeholder="请输入电池编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="电池料号:" prop="batteryNumber">
          <el-input
            v-model="ruleForm.batteryNumber"
            clearable
            placeholder="请输入电池料号"
          ></el-input>
        </el-form-item>
        <el-form-item label="电池型号:" prop="batteryType">
          <el-input
            v-model="ruleForm.batteryType"
            clearable
            placeholder="请输入电池型号"
          ></el-input>
        </el-form-item>
        <el-form-item label="物料规格:" prop="materiel">
          <el-input
            v-model="ruleForm.materiel"
            clearable
            placeholder="请输入物料规格"
          ></el-input>
        </el-form-item>
        <el-form-item label="归属无人机:" prop="deviceHardId">
          <el-select
            v-model="ruleForm.deviceHardId"
            clearable
            placeholder="请选择归属无人机"
          >
            <el-option
              v-for="item in stateList"
              :key="item.deviceHardId"
              :label="item.deviceName"
              :value="item.deviceHardId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属机构:" prop="orgId">
        <el-cascader
          v-model="ruleForm.orgId"
          :options="options"
          clearable
          filterable
          :showAllLevels="false"
          placeholder="请选择所属机构"
          :props="{
            value: 'id',
            label: 'orgName',
            children: 'children',
            emitPath: false,
            checkStrictly: true,
          }"
        ></el-cascader>
      </el-form-item>
        <el-form-item label="出厂日期:" prop="factoryTime">
          <el-date-picker
            v-model="ruleForm.factoryTime"
            placeholder="请选择日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptionsStart"
            @change="dateChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="购入日期:" prop="addtime">
          <el-date-picker
            v-model="ruleForm.addtime"
            placeholder="请选择日期"
            value-format="yyyy-MM-dd"
            :disabled="!ruleForm.factoryTime"
            :picker-options="pickerOptionsEnd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="电池状态:" prop="batteryStatus">
          <el-radio-group v-model="ruleForm.batteryStatus">
            <el-radio :label="1">可用</el-radio>
            <el-radio :label="2">保养</el-radio>
            <el-radio :label="3">维修</el-radio>
            <el-radio :label="4">损坏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="异常信息:" prop="abnormalContent" v-if="ruleForm.batteryStatus != 1">
          <el-input
            v-model="ruleForm.abnormalContent"
            clearable
            placeholder="请输入异常信息"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btn-box1027">
        <div class="btn1027" @click="submit">提交</div>
      </div>
      
  </div>
</template>

<script>
import API from "@/api";
import formRules from '@/utils/formRules'
export default {
  props:['form'],
data() {
  let validateBatteryId = async (rule, value, callback) => {
      let reg = /^MMC-BAT-\d{6}$/
      let { data } = await API.BATTERY.IsExist({ code: value })
      if (!value) {
        callback(new Error('请输入电池编号'))
      } else if (!reg.test(value)) {
        callback(new Error('电池编号格式为MMC-BAT-000001'))
      } else if (data && value != this.oldForm.batteryId) {
        callback(new Error('电池编号已存在!'))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        batteryStatus: 1,
        factoryTime: null,
        addtime: null,
      },
      options: [],
      oldForm: {},
      rules: {
        batteryName: [
          {
            required: true,
            message: '请输入电池名称',
            trigger: 'blur'
          }
        ],
        batteryId: [
          {
            required: true,
            validator: validateBatteryId,
            trigger: 'change  ',
          },
        ],
        batteryNumber: [
          {
            validator: formRules.validateBatteryNumber,
            trigger: 'blur',
          },
        ],
        batteryType: [
          {
            validator: formRules.validateBatteryType,
            trigger: 'blur',
          },
        ],
        deviceHardId: [
          {
            required: true,
            message: '请选择归属无人机',
            trigger: 'change',
          },
        ],
      },
      stateList:[],
      pickerOptionsStart: {
        disabledDate: (time) => {
          return time.getTime() > Date.now() - 8.64e6
        },
      },
      pickerOptionsEnd: {
        disabledDate: (time) => {
          let date = new Date(this.ruleForm.factoryTime)
          date.setDate(date.getDate() - 1)
          return (
            time.getTime() > Date.now() - 8.64e6 ||
            time.getTime() < date.getTime()
          )
        },
      },
    };
  },
  methods: {
    /*机构 */
    async getOptions() {
      let res = await API.DEPARTMENT.Drd();
      // each(res);
      this.options = res || [];
    },
    dateChange(e) {
      if (!e) {
        this.ruleForm.addtime = null
      }
    },
    async getUav(){
      let res = await API.BATTERY.getUav()
      this.stateList = res
    },
    submit() {
      if(this.ruleForm.id && this.oldForm.batteryId == this.ruleForm.batteryId){
            // if(res == 1){
              API.BATTERY.Edit(this.ruleForm).then(res => {
                this.$message.success("编辑成功!");
                this.$emit("closedevice");
              })
            // }
          }else{
            this.$refs["ruleForm"].validate((valid) => {
              if (valid) {
                if(this.ruleForm.id){
                  // if(res == 1){
                    API.BATTERY.Edit(this.ruleForm).then(res => {
                      this.$message.success("编辑成功!");
                      this.$emit("closedevice");
                    })
                  // }
                }else{
                  API.DEVICE.battAdd(this.ruleForm).then((res) => {
                    // if (res == 1) {
                      this.$message.success("创建成功!");
                      this.$emit("closedevice");
                    // }
                  });
                }
              } else {
                return false;
              }
            });
          }
    },
  },
  mounted() {
    if(this.form != null){
      this.ruleForm = this.form
      this.oldForm = JSON.parse(JSON.stringify(this.form))
    }
    this.getOptions();
    this.getUav()
  },
}
</script>

<style lang="scss" scoped>
.btn {
      margin-left: 120px;
      width: 94px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      background: rgba(23, 70, 216, 0.2);
      box-shadow: 0px 2px 4px 0px rgba(23, 33, 60, 0.5),
        inset 0px 0px 16px 0px rgba(33, 137, 255, 0.4),
        inset 0px 0px 4px 0px #00a7ff;
      border-radius: 6px;
      // border: 1px solid;
      border-image: linear-gradient(
          180deg,
          rgba(138, 218, 255, 1),
          rgba(82, 179, 255, 0)
        )
        1 1;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #00d3ff;
    }
     .el-form-item {
    margin-bottom: 18px;
  }
  /deep/ .el-form-item__label {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
  }
  /deep/ .el-select .el-input__inner {
    background: rgba(11, 23, 49, 0.8);
    border: 1px solid rgba(14, 123, 117, 0.63);
    width: 316px;
    height: 38px;
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #43deff;
    letter-spacing: 0;
    font-weight: 400;
  }
  /deep/ .el-cascader .el-input__inner {
    background: rgba(11, 23, 49, 0.8);
    border: 1px solid rgba(14, 123, 117, 0.63);
    width: 316px;
    height: 38px;
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #43deff;
    letter-spacing: 0;
    font-weight: 400;
  }
  /deep/ .el-textarea .el-textarea__inner {
    background: rgba(11, 23, 49, 0.8);
    border: 1px solid rgba(14, 123, 117, 0.63);
    width: 316px;
    // height: 38px;
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #43deff;
    letter-spacing: 0;
    font-weight: 400;
  }
  /deep/ .el-input .el-input__inner {
    width: 316px;
    height: 32px;
    background: #02173D;
    border-radius: 2px;
    border: 1px solid #315EC7;
  }
  .el-select,
  .el-input,
  .el-cascader {
    width: 316px;
  }

</style>